/*
 * Text Input Component
 * */
import React, {useEffect, useState, memo, useRef} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { randomIdentifier } from '../../../utils/helpers';
import IconAddImageUpload from "../../../assets/images/icon_add_image_upload.svg";
import IconEventClose from "../../../assets/images/event_close.svg";
import ImageBoxComponent from "./ItemImageBoxAddMoreComponent";

const getBase64Img =  async (file) => {
  return new Promise(resolve => {
    let fileInfo;
    let baseURL = "";
    // Make new FileReader
    let reader = new FileReader();

    // Convert the file to base64 text
    reader.readAsDataURL(file);

    // on reader load somthing...
    reader.onload = () => {
      // Make a fileInfo Object
      // console.log("Called", reader);
      baseURL = reader.result;
      // console.log(baseURL);
      resolve(baseURL);
    };
    // console.log(fileInfo);
  });
};
const PreviewImage = (props) => {
  const [img, setImg] = useState(null)

  let file = props.file
  let index = props.index

  getBase64Img(file)
    .then(result => {
      setImg(result)
    })
    .catch(err => {
      console.log(err);
    })
  return (
    <div className='image-box-card image-box-card-render'>
      <div className="attachment__item attachment-img" >
        {props.showRemoveImg ? (<img className='icon-remove' src={IconEventClose} onClick={()=>props.removeFile(index)} />) : ''}
        <div className="input-selector">
          <div className="attachment__item__content">
            <img className="" src={img} />
          </div>

        </div>
      </div>
    </div>

  )
}

const PreviewImageUploaded = (props) => {
  const [img, setImg] = useState(null)

  let index = props.index
  // console.log(props,'PreviewImageUploaded')

  return (
      <div className='image-box-card image-box-card-render'>
          <div className="attachment__item attachment-img" >
              <img className='icon-remove' src={IconEventClose} onClick={()=>props.removeFile(index)} />
              <div className="input-selector">
                  <div className="attachment__item__content">
                      <img className="" src={props?.file?.url_medium} />
                  </div>

              </div>
          </div>
      </div>

  )
}


type Props = {
  handleChange: Function,
  onClick: Function,
  removeImg: Function,
  textCard:String,
  classCustom:String,
  type:String,
  confirmUpload:Boolean,
  nameFile:String,
  indexImg:Number,
  imgNotShow:Boolean,
  imageList:Array
};

const ImageBoxAddMoreComponent = ({
   handleChange,
   textCard,
   classCustom,
   removeImg,
   showRemoveImg,
   type,
   confirmUpload,
   onClick,
   nameFile,
   indexImg,
   imgNotShow,
   imageList
}: Props) => {


  const [fileUpload, setCheckFileUpload] = useState([]);

  useEffect(() => {
    setCheckFileUpload(imageList)
  }, [imageList]);


  const handleChangeImage1 = (e) => {

    let fileUploadChange = fileUpload.length > 0 ? fileUpload : []

    if(e?.file && e?.file.length != 0){
      // let fileUploadChangeGroup = [...fileUploadChange, ...e.file]
      setCheckFileUpload([...fileUpload,e.file])
      let dataUpLoadFile = {name:nameFile,file:e.file,fileUpload:[...fileUpload,e.file]}
      handleChange(dataUpLoadFile)
    }

  }
  const handleRemoveImg = (indexImgRemove) => {

    let dataFileUpLoad = fileUpload
    if (indexImgRemove > -1) {
      dataFileUpLoad.splice(indexImgRemove, 1); // 2nd parameter means remove one item only
    }

    setCheckFileUpload(dataFileUpLoad)

  }
  const removeFileRender = (indexImgRemove,typeFile = '') => {

    var dataFileUpLoad = [...fileUpload];

    if (indexImgRemove > -1) {
      dataFileUpLoad.splice(indexImgRemove, 1); // 2nd parameter means remove one item only
      setCheckFileUpload((dataFileUpLoad.length > 0 ? dataFileUpLoad : []))
      let afterRemove = {index:indexImgRemove,data:null,fileUpload:dataFileUpLoad,typeFile:typeFile}
      removeImg(afterRemove)
    }

    // setCheckFileUpload((dataFileUpLoad.length > 0 ? dataFileUpLoad : []))

  }

    const RenderImageBox = () => {
        // console.log(image,'RenderImageBox')
        return (<div className='card-upload d-flex align-items-center'>
        
          { !fileUpload  || fileUpload.length > 0 && 
            fileUpload.map((item,index)=>(
                                    item?.url_medium ? <PreviewImageUploaded file={item}
                                    key={index}
                                    index={index}
                                    showRemoveImg={true}
                                    removeFile={(index)=>removeFileRender(index,'uploaded')}
                                    /> : <PreviewImage file={item}
                                    key={index}
                                    index={index}
                                    showRemoveImg={true}
                                    removeFile={(index)=>removeFileRender(index)}
                                    />
                                ))
          }
          {fileUpload.length < 3 ? (<ImageBoxComponent
            nameFile={nameFile}
            handleChange={(e) => handleChangeImage1(e)}
            showRemoveImg={true} classCustom={''}
            removeImg={(e) => handleRemoveImg(e)}
            // textCard={`이벤트 설명사진${fileUpload.length+1} 등록`}
            textCard={textCard}
            indexImg={0}
            imgNotShow={true}
          />) : ''}

        </div>)
    }



  return (
      RenderImageBox()
  );
};

ImageBoxAddMoreComponent.defaultProps = {

  handleChange: () => {},
  onClick: () => {},
  removeImg: () => {},
  textCard:'',
  classCustom:'',
  showRemoveImg: '',
  type: 'style-img',
  confirmUpload: true,
  nameFile:'attachment',
  indexImg:0,
  imgNotShow:false,
  imageList:[]
};

export default memo(ImageBoxAddMoreComponent);
