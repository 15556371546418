/*
 * Text Input Component
 * */
import React, { useEffect, useState, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { randomIdentifier } from '../../../utils/helpers';

import IconArrowRight from "../../../assets/images/icon_arrow_right.svg";

type Props = {
  label: String,
  isRequired: boolean,
  title:String,
  time:String,
  status:String,
  classNameCustom:String,
  onClick: Function,
};

const ItemInListComponent = ({
  isRequired, title,onClick,time,status,classNameCustom,classNameStatus
}: Props) => {


  useEffect(() => {

  }, []);

  const _handleChange = () => {

  }



  return (
      <div className={`item-in-list ${classNameCustom}`} onClick={onClick}>
          <div className='info-item'>
              {status ? (<label className={`info-item__status ${classNameStatus}`}>{status}</label>) : ''}
              <label className='info-item__text'>{title}</label>
              {time ? (<label className='info-item__time'>{time}</label>) : ''}
          </div>
          <img src={IconArrowRight} />
      </div>
  );
};

ItemInListComponent.defaultProps = {
  isRequired: false,
  title:'',
  time:'',
  status:'',
  classNameCustom:'',
};

export default memo(ItemInListComponent);
