// @flow
import React, { memo, useState } from 'react';

type Props = {
  tabHeadings: Array,
  tabContents: Array,
  activeIndex: number,
  handleChangeTab: any,
  user: Object,
};

const TabsComponent = ({
  tabHeadings,
  tabContents,
  activeIndex,
  handleChangeTab,
}: Props) => {
  const [index, setIndex] = useState(activeIndex);

  const _handleChangeTab = (index) => {
    if (typeof handleChangeTab == 'function') {
      handleChangeTab(index);
    }
    setIndex(index);
  };

  return (
    <div className="tabs">
      <div className="tabs__style1">
        <div className="tabs__heading wrap">
          <ul className="tabs__heading__list">
            {tabHeadings.map((item, k) => {
              
              return (
                <>
                  <li
                    onClick={() => _handleChangeTab(k)}
                    key={k}
                    className={
                      `tabs__heading__item` +
                      (k === index ? 'active' : '')
                    }
                  >
                    {item}
                  </li>
                  
                </>
              );
            })}
          </ul>
        </div>
        <div className="tabs__content">
          {tabContents.map((item, k) => {
            return (
              <div
                key={k}
                className={
                  'tabs__content__section ' + (k === index ? 'active' : '')
                }
              >
                {item}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

TabsComponent.defaultProps = {
  activeIndex: 0,
  handleChangeTab: false,
};

export default memo(TabsComponent);
