import {Swipe} from "mirsahib-react-swipe-component";
import {randomIdentifier} from "../../../../utils/helpers";
import React, {useEffect, useState} from "react";

type Props = {
    id: String,
    show: boolean,
    handleClose: any
};

const SwipeComponent = ({
                            id,
                            type,
                            show,
                            children,
                            swipeDown,
                            heading,
                            closeButton,
                            title,
                            style,
                            handleClose,
                            overlay,
                            dataValueModal3 = 0,
                            refreshBarcode,
                            logo ='',
                            iconClose = '/images/icon_close_white.svg'
                        }: Props) => {
    const [isShow, setIsShow] = useState(show)
    const [modalY, setModalY] = useState(0)
    const [swipeDir, setSwipeDir] = useState('none')

    useEffect(() => {
        setIsShow(show);
        if(show) setModalY(0)
        else setModalY(100)
        // console.log(show)
        setSwipeDir('none')
    }, [show])
    const _handleClose = () => {
        setIsShow(false)
        if(typeof handleClose == 'function') handleClose(id, isShow)
    }
    const onSwipeEnd = () => {
        // console.log("Swipe Ended")
        if(swipeDir === 'down')
        _handleClose()
    }
    const onSwipeLeftListener = () => {
        // console.log("Swiped left")
    }
    const onSwipeRightListener = () => {
        // console.log("Swiped right")
    }
    const onSwipeUpListener = () => {
        // console.log("Swiped Up")
    }
    const onSwipeDownListener = (p) => {
        setSwipeDir('down')
        // console.log(p)
        // if (p.x !== 0) {
        //     // console.log(`Swipe x: ${p.x}`)
        // }
        // if (p && p.y && p.y !== 0) {
        //     setModalY(modalY+(p.y/100))
        //     // console.log(`Swipe y: ${p.y}`)
        // }
    }
    const onSwipeListener = (p) => {

    }

    return (
        <>
            <div style={{transform: `translateY(${modalY}%)`}} id={"swipe-up-down-"+id} className={"swipe-up-down " + (isShow ? " show" : "")}>
                <div className="swipe-up-down-container">
                    <Swipe
                        nodeName="div"
                        className="swipe-touch"
                        detectTouch={true}
                        detectMouse={false}
                        onSwipeEnd={onSwipeEnd}
                        onSwipedLeft={onSwipeLeftListener}
                        onSwipedRight={onSwipeRightListener}
                        onSwipedDown={onSwipeDownListener}
                        onSwipedUp={onSwipeUpListener}
                        onSwipe={onSwipeListener}>
                        <div className="swipe-up-down-swiper"><span/></div>
                    </Swipe>

                    {children}
                </div>
            </div>
        </>
    )
                        }
SwipeComponent.defaultProps = {
    id: randomIdentifier(),
    show: false
};


export default SwipeComponent;