import React, {useEffect, useState} from "react";
import {useNavigate,useParams } from "react-router-dom";


import {useDispatch} from "react-redux";
import MainLayout from "../../../commons/layout/MainLayout/index";

import HeaderHs2 from "../../../commons/components/Header/headerHs2";

import ROUTERS from "../../../commons/constans/router";
import InfiniteScroll from 'react-infinite-scroll-component';
import API, {ROUTES} from "../../../commons/api/api";

import moment from "moment";
import SkeletonLoadingComponent from "../../../commons/components/SkeletonLoading";
import Header from "../../../commons/components/Header/header";
import LeftIconHeader from "../../../assets/images/leftIconHeader.svg";
import IconDownload from "../../../assets/images/download.svg";
import {nl2br} from "../../../utils/helpers";


const RenderImageDetail = ({images}) => {
    let image_render = '';

    if(images && images.length > 0){
        images.map((value,key)=>{
            image_render += `<img src=${value.medium} />`
        })
    }
    return <div className="attachments" dangerouslySetInnerHTML={{__html: image_render}} />
}

const NotificationDetailPageComponent = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [stateDetail, setStateDetail] = useState(0);
    const [detailNofitication, setDetailNofitication] = useState({});
    const params = useParams();

    var id = params?.id ? params?.id : ''
    
    const backMenuHome = () => {
        navigate(ROUTERS.NOTIFICATION)
    }

    useEffect(() => {
        setDetailNofitication(props?.dataDetail)
    }, [props]);

    const openDetail = (id) => {
        // if(id == stateDetail){
        //     setStateDetail(0)
        // }else {
        //     setStateDetail(id)
        // }

    }

    const loadingDiv = <div className="wrapper">
        <section className="lists">
            <div className="lists__item last">
                <div className="lists__item__content w-100 loading-content">
                    <span><SkeletonLoadingComponent circle={false} width="100%" height="14px"/></span>
                    <h3><SkeletonLoadingComponent circle={false} width="30%" height="14px"/></h3>
                </div>
            </div>
        </section>
        <div className="container">
            <p className="content mb-0">
                <SkeletonLoadingComponent circle={false} width="100%" height="14px"/>
                <SkeletonLoadingComponent circle={false} width="100%" height="14px"/>
                <SkeletonLoadingComponent circle={false} width="100%" height="14px"/>
                <SkeletonLoadingComponent circle={false} width="100%" height="14px"/>
                <SkeletonLoadingComponent circle={false} width="100%" height="14px"/>
            </p>
        </div>

    </div>


    const convertUrlTObjectData = (url, type = 'album') => {
        if (!url) return null;
        let urlObj = new URL(url);
        let baseUrl = urlObj.origin;
        let dataArr = urlObj.pathname.split('/');
        let file_name = dataArr[dataArr.length - 1];
        var newArr = dataArr.slice(0, -1);
        let bucket_name = newArr.join('/')
        let data = {
            baseUrl: baseUrl,
            bucketName: bucket_name,
            fileName: file_name,
            url:url
        }
        // console.log(data,'data3333')
        return data;
    }



    const downloadFile = (item) => {
        if(item){
            let url = item?.url
            let data = convertUrlTObjectData(url || '');
            data.originName = item?.origin_name
            window.nativeDownloadAlbum(data);
        }
    }



    return (
        <div>
            <MainLayout
                pageID={"notification-detail-page"}
            >
                <Header
                    leftChild={LeftIconHeader}
                    handleLeftClick={()=>backMenuHome()}
                    leftIcon={true}
                    centerChild={'공지사항'}
                    centerIcon={false}
                    rightChild={false}
                    rightIcon={false}
                />
                <div className='wrapper'>
            {props?.loading ? (loadingDiv) : (id != '' ? (<div className="wrapper">
                <section className="lists">
                    <div className="lists__item last">
                        <div className="lists__item__content">
                            <h3 className='title'>{detailNofitication.title}</h3>
                            <span className='time'>{moment(detailNofitication?.published_at || detailNofitication?.created_at).format('Y-MM-DD')}</span>
                        </div>
                    </div>
                </section>
                <div className="container-description">

                    {detailNofitication?.content ? <div className="content " dangerouslySetInnerHTML={{ __html: nl2br(detailNofitication.content) }}/> : ''}
                    {/*{detailNofitication?.attachment_url ?*/}
                    {/*    (<RenderImageDetail images={detailNofitication?.attachment_url} />) : ''}*/}


                </div>
                {/*<a href={'/download?url='+detailNofitication?.attachment?.path.substring(1) + '&filename='+detailNofitication?.attachment?.origin_name} className='d-flex align-items-center item-file' style={{color:'#111'}}>*/}
                {detailNofitication?.attachment ?  <div className='attachment-file' onClick={()=>downloadFile(detailNofitication?.attachment)}>
                    <div className='d-flex align-items-center item-file'>
                        <img src={IconDownload} />
                        <p className='mb-0 pl-1 text-truncate'>{detailNofitication?.attachment?.origin_name}</p>
                    </div>
                </div> : '' }

            </div>) : '')}

                </div>
            </MainLayout>
        </div>
    );
};

export default NotificationDetailPageComponent;
