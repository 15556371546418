import React, {useEffect, useState} from "react";
import {useNavigate,useParams } from "react-router-dom";


import {useDispatch} from "react-redux";
import MainLayout from "../../../commons/layout/MainLayout/index";

import HeaderHs2 from "../../../commons/components/Header/headerHs2";

import ROUTERS from "../../../commons/constans/router";
import InfiniteScroll from 'react-infinite-scroll-component';
import API, {ROUTES} from "../../../commons/api/api";

import moment from "moment";
import SkeletonLoadingComponent from "../../../commons/components/SkeletonLoading";
import Header from "../../../commons/components/Header/header";
import LeftIconHeader from "../../../assets/images/leftIconHeader.svg";
import {nl2br} from "../../../utils/helpers";

const RenderImageDetail = ({images}) => {
    let image_render = '';
    console.log(images,'images')
    if(images && images.length > 0){
        images.map((value,key)=>{
            image_render += `<img src=${value.medium} />`
        })
    }
    return <div className="attachments" dangerouslySetInnerHTML={{__html: image_render}} />
}

const TermsOfUseDetailPageComponent = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [stateDetail, setStateDetail] = useState(0);
    const [detailTerms, setdetailTerms] = useState({});
    const params = useParams();
    console.log(params,'useParams 123',props)
    var id = params?.id ? params?.id : ''
    
    const backMenuHome = () => {
        navigate(ROUTERS.TERMS_OF_USE)
    }

    useEffect(() => {
        let header = document.querySelector('.header');
        let heightHeader = header.offsetHeight;

        if(!props?.loading){
            console.log(heightHeader,'heightHeader')
            document.getElementById('wrapper-detail').style.marginTop = heightHeader + 15 + 'px';
        }
    }, [props?.loading]);

    useEffect(() => {
        setdetailTerms(props?.dataDetail)
    }, [props]);

    const openDetail = (id) => {
        // if(id == stateDetail){
        //     setStateDetail(0)
        // }else {
        //     setStateDetail(id)
        // }

    }

    const loadingDiv = <div className="wrapper">
        <div className="container">
            <p className="content mb-0">
                <SkeletonLoadingComponent circle={false} width="100%" height="14px"/>
                <SkeletonLoadingComponent circle={false} width="100%" height="14px"/>
                <SkeletonLoadingComponent circle={false} width="100%" height="14px"/>
                <SkeletonLoadingComponent circle={false} width="100%" height="14px"/>
                <SkeletonLoadingComponent circle={false} width="100%" height="14px"/>
            </p>
        </div>

    </div>

    return (
        <div>
            <MainLayout
                pageID={"terms-detail-page"}
            >
                <Header
                    leftChild={LeftIconHeader}
                    handleLeftClick={()=>backMenuHome()}
                    leftIcon={true}
                    centerChild={detailTerms?.title}
                    centerIcon={false}
                    rightChild={false}
                    rightIcon={false}
                />
                <div className='wrapper' id={'wrapper-detail'}>
            {props?.loading ? (loadingDiv) : (id != '' ? (<div className="wrapper">
                
                <div className="container-description">
                    {/*<p className="content">{detailTerms.content}</p>*/}
                    {detailTerms?.content ? <div className="content " dangerouslySetInnerHTML={{ __html: nl2br(detailTerms.content) }}/> : ''}
                    {detailTerms?.attachment_url ?
                        (<RenderImageDetail images={detailTerms?.attachment_url} />) : ''}


                </div>



            </div>) : '')}

                </div>
            </MainLayout>
        </div>
    );
};

export default TermsOfUseDetailPageComponent;
