// /*/*
//  * Text Input Component
//  * */
// import React, { useEffect, useState, memo } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { useLocation, useNavigate } from 'react-router-dom';
// import { randomIdentifier } from '../../../utils/helpers';
// import 'bootstrap-daterangepicker/daterangepicker.css';
// import moment from 'moment'

// import IconArrowRight from "../../../assets/images/icon_arrow_right.svg";
// import DateRangePicker from "react-bootstrap-daterangepicker";

// import IconCalender from "../../../assets/images/icon_calender.svg";

// type Props = {
//   handleChange:Function,
//   onClick: Function,
// };

// const DatimePickerComponent = ({
//   handleChange,onClick,value
// }: Props) => {


//   useEffect(() => {

//   }, []);

//   const onChangeDatePicker = (e) => {
//         console.log('onChangeDatePicker',moment(e).format('YYYY-MM-DD'))
//       handleChange(moment(e).format('YYYY-MM-DD'))
//   }



//   return (
//     <>
//       <div className='item-calendar'>

//           <DateRangePicker
//               initialSettings={{
//                   parentEl:'modal-date',
//                   singleDatePicker: true,
//                   showDropdowns: true,
//                   startDate: value || moment().toDate(),
//                   timePicker: false,
//                   autoApply:true,
//                   locale: {
//                       format: 'Y-MM-DD',
//                   }
//               }}
//               onCallback={date => onChangeDatePicker(date)}
//           >
//               <input type="text" className="form-control" />

//           </DateRangePicker>
//           <img className='icon-calendar' alt='' src={IconCalender} />
//       </div>
//       <div id="modal-date" className=""></div>
//     </>
//   );
// };

// DatimePickerComponent.defaultProps = {
//     handleChange:()=>{},
//     onClick:()=>{},
// };

// export default memo(DatimePickerComponent);

import React, { useState, memo, useRef, forwardRef, useEffect } from "react";
import DatePicker from "react-datepicker";
import moment from "moment";

import "react-datepicker/dist/react-datepicker.css";

import IconArrowRight from "../../../assets/images/calendar-next.svg";
import IconArrowLeft from "../../../assets/images/calendar-prev.svg";
import ROUTERS from "../../constans/router";
import menuItems from "../../constans/menu";

let focus = false

const days = ['일', '월', '화', '수', '목', '금', '토']

const locale = {
  localize: {
    day: n => days[n],
    month: n => n
  },
  formatLong: {
    date: () => 'yyyy-mm-dd'
  }
}

const Input = forwardRef((
  { className, value, onClick, onChange, placeholder },
  ref
) => {
  return (
    <div onClick={onClick}>
      <input
        type="text"
        value={value}
        ref={ref}
        disabled
        readonly
        className={className + " form-control"}
        placeholder={placeholder || ''}
      />
    </div>
  );
})

Input.displayName = 'Input'

const CustomInput = forwardRef((props, ref) => {
  return <Input {...props} ref={ref} />;
});

CustomInput.displayName = 'CustomInput'

const touchstart = (event) => {
  event.stopPropagation();
}

const DatimePickerComponent = ({
  value,
  handleChange,
  minDate,
  maxDate,
  placeholder,
}) => {
  const [startDate, setStartDate] = useState(value || null);
  const [dateSelected, setDateSelected] = useState(value || null);

  useEffect(() => {
    setStartDate(value)
    setDateSelected(value)
  }, [value]);

  const pickerRef = useRef(null);
  const inputRef = useRef(null);

  const onChange = (date) => {
    setStartDate(date)
  }

  const handleDateSelect = (date) => {
    setDateSelected(date)
    setStartDate(null)
  }

  const apply = () => {
    handleChange(dateSelected || moment().toDate())
    setStartDate(dateSelected || moment().toDate())
    pickerRef.current.setOpen(false)
  }

  const handleCalendarOpen = () => {
    document.addEventListener('touchstart', touchstart, true);
  };

  const handleCalendarClose = () => {
    setStartDate(startDate || value)
    document.removeEventListener('touchstart', touchstart, true);
  };

  return (
    <DatePicker
      ref={pickerRef}
      onCalendarClose={handleCalendarClose}
      locale={locale}
      placeholderText={placeholder}
      renderCustomHeader={({
        date,
        changeYear,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }) => {
        let year = moment(date).year()
        return (
          <div className="text-center header">
            <div className="mb-2 d-flex align-items-center justify-content-between">
              {!prevMonthButtonDisabled ? <button type="button" onClick={() => changeYear(year -1 )}>
                <img src={IconArrowLeft} />
              </button> :  <button type="button"></button>}
              <span className="year">
                {year}
              </span>
              {!nextMonthButtonDisabled ? <button type="button" onClick={() => changeYear(year + 1)}>
                <img src={IconArrowRight} />
              </button> : <button type="button"></button>}
            </div>

            <div className="d-flex align-items-center justify-content-between">
              {!prevMonthButtonDisabled ? <button type="button" onClick={decreaseMonth}>
                <img src={IconArrowLeft} />
              </button> : <button type="button"></button>}
              <span className="month">
                {moment(date).month() + 1}월
              </span>
              {!nextMonthButtonDisabled ? <button type="button" onClick={increaseMonth}>
                <img src={IconArrowRight} />
              </button> : <button type="button"></button>}
            </div>
          </div>
        )
      }}
      dateFormat="yyyy-MM-dd"
      // autoFocus
      selected={startDate}
      // onChange={onChange}
      onSelect={handleDateSelect} //when day is clicked
      shouldCloseOnSelect={false}
      withPortal
      onCalendarOpen={handleCalendarOpen}
      customInput={<CustomInput inputRef={inputRef} />}
      minDate={minDate}
      maxDate={maxDate}
    >
      <div className="text-center picker-footer">
        <button onClick={apply} className="apply">닫기</button>
      </div>
    </DatePicker>
  );
};

export default memo(DatimePickerComponent);

