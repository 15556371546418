import * as type from './type';

export const pushDataStoreStepOne = (payload) => {
  return {
    type: type.PUSH_DATA_STORE_STEP_ONE,
    payload,
  };
};

export const pushDataStoreStepTwo = (payload) => {
  return {
    type: type.PUSH_DATA_STORE_STEP_TWO,
    payload,
  };
};

export const pushDataStoreStepThree = (payload) => {
  return {
    type: type.PUSH_DATA_STORE_STEP_THREE,
    payload,
  };
};

export const resetDataStoreStepOne = (payload) => {
  return {
    type: type.RESET_DATA_STORE_STEP_ONE,
    payload,
  };
};

export const resetDataStoreStepTwo = (payload) => {
  return {
    type: type.RESET_DATA_STORE_STEP_TWO,
    payload,
  };
};

export const resetDataStoreStepThree = (payload) => {
  return {
    type: type.RESET_DATA_STORE_STEP_THREE,
    payload,
  };
};

export const resetAllDataStoreSteps = (payload) => {
  return {
    type: type.RESET_ALL_DATA_STORE_STEPS,
    payload,
  };
};

export const getCodeLabelRequest = (params) => {
  return {
    type: type.GET_CODE_LABEL_REQUEST,
    params,
  };
}

export const getCodeLabelSuccess = (payload) => {
  return {
    type: type.GET_CODE_LABEL_SUCCESS,
    payload,
  };
}

export const getCodeLabelFaild = (payload) => {
  return {
    type: type.GET_CODE_LABEL_FAILD,
    payload,
  };
}

export const getCategoriesRequest = (params) => {
  return {
    type: type.GET_CATEGORIES_REQUEST,
    params,
  };
}

export const getCategoriesSuccess = (payload) => {
  return {
    type: type.GET_CATEGORIES_SUCCESS,
    payload,
  };
}

export const getCategoriesFaild = (payload) => {
  return {
    type: type.GET_CATEGORIES_FAILD,
    payload,
  };
}

export const createStoreRequest = (payload) => {
  return {
    type: type.CREATE_STORE_REQUEST,
    payload,
  };
}

export const createStoreSuccess = (payload) => {
  return {
    type: type.CREATE_STORE_SUCCESS,
    payload,
  };
}

export const createStoreFaild = (payload) => {
  return {
    type: type.CREATE_STORE_FAILD,
    payload,
  };
}

export const getListStoresRequest = (params) => {
  return {
    type: type.GET_LIST_STORE_REQUEST,
    params,
  };
}

export const getListStoresSuccess = (payload) => {
  return {
    type: type.GET_LIST_STORE_SUCCESS,
    payload,
  };
}

export const getListStoresFaild = (payload) => {
  return {
    type: type.GET_LIST_STORE_FAILD,
    payload,
  };
}

export const getDetailStoreRequest = (params) => {
  return {
    type: type.GET_DETAIL_STORE_REQUEST,
    params,
  };
}

export const getDetailStoreSuccess = (payload) => {
  return {
    type: type.GET_DETAIL_STORE_SUCCESS,
    payload,
  };
}

export const getDetailStoreFaild = (payload) => {
  return {
    type: type.GET_DETAIL_STORE_FAILD,
    payload,
  };
}

export const updateStoreRequest = (params) => {
  return {
    type: type.UPDATE_STORE_REQUEST,
    params,
  };
}

export const updateStoreSuccess = (payload) => {
  return {
    type: type.UPDATE_STORE_SUCCESS,
    payload,
  };
}

export const updateStoreFaild = (payload) => {
  return {
    type: type.UPDATE_STORE_FAILD,
    payload,
  };
}

export const syncStore = (payload) => {
  return {
    type: type.SYNC_STORE,
    payload,
  };
}

export const resetUpdateState = (payload) => {
  return {
    type: type.RESET_UPDATE_STORE,
    payload,
  };
}
