import React from "react";
import { STEPS } from "../../../../commons/constans/store";

type Props = {
  steps: Object
}

const HeaderSteps = ({steps}:Props) => {

  const stepsArr = [STEPS.ONE, STEPS.TWO, STEPS.THREE];

  return (
    <div className="header-steps">
      <ul>
        {
          stepsArr.map((item, idx) => (
            <li key={idx} className={steps.step == item ? 'active' : ''}>
              <span>{idx+1}</span>
            </li>
          ))
        }
      </ul>
    </div>
  )
}

export default HeaderSteps;