import { put, call, takeLatest } from 'redux-saga/effects';

import * as actionCreators from '../redux/actions';
import * as actionTypes from '../redux/actions/type';
import API from "../../../commons/api/api";

function* home(action) {
  console.log('call api home', action);
  try {
    // const response = yield call(() => API.post(ROUTES.API_ANNOUNCEMENT, JSON.stringify(action.payload)));
    const response = yield call(() =>
      API({ url: "https://jsonplaceholder.typicode.com/todos/" })
    );
    console.log("fetchlogInData", response);
    // yield put({ type: actionTypes.LOGIN_REQUEST, response });
    yield put(actionCreators.getUserHomeSuccess({ data: response }));
    
    if (response.ok) {
      const { data } = response.data.body;
     
    }
  } catch (error) {
    
  }
}

function* homeSaga() {
  console.log('vao saga homeSaga');
  yield takeLatest(actionTypes.GET_USER_HOME, home);
}

export default homeSaga;
