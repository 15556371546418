import React from 'react';
import {Fragment, useEffect, useState} from "react";
import MainLayout from '../../../commons/layout/MainLayout';

import LeftIconHeader from "../../../assets/images/leftIconHeader.svg";
import Header from '../../../commons/components/Header/header';
import { useParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import ItemInListComponent from "../../../commons/components/ItemInList/ItemInListComponent";
import API, {ROUTES} from "../../../commons/api/api";
import NotificationPageComponent from "../components";
import ROUTERS from "../../../commons/constans/router";
import {useRole, useUser} from "../../../commons/customHooks/Account";

const NotificationPageContainer = () => {

  const navigate = useNavigate();
  const handleLeftClick = () => {
    navigate(ROUTERS.MAIN);
  }

  const [notificationData, setNotificationData] = useState([])
  const [loadMore, setloadMore] = useState(false)
  const [loading, setLoading] = useState(true)

  const isUser = useRole('user');
  const isShop = useRole('shop');
  const isManager = useRole('manager');
  const {user} = useUser();


  const fetchData = async (param = '?per_page=20&order_by_created_at=desc') =>{
    try {
      console.log(user,'user33333')
      let typesNotice = ['ALL']
      if(isUser || isShop || isManager){
        if (isUser){
          typesNotice.push('USER')
        }else if(isShop){
          typesNotice.push('SHOP')
        }else {
          typesNotice.push('MANAGER')
        }
        typesNotice.map((item)=>{
          param = param + '&types[]='+item
        })
      }else {
        if(!user?.id){
          typesNotice.push('USER')
          typesNotice.map((item)=>{
            param = param + '&types[]='+item
          })

        }
      }

      const {data: response} = await API.get(ROUTES.API_NOTIFICATION+param);
      setNotificationData(response?.data);
      setLoading(false)
      if(response?.data?.current_page == response?.data?.last_page) {
        setloadMore(false)
      }else {
        setloadMore(true)
      }
    } catch (error) {
      console.error(error.message);
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  const loadMoreData = () => {
    // setloadMore(false)
  }



  return (
    <div>
      <MainLayout 
      pageID={"notification-page"}
    >
      <Header
        leftChild={LeftIconHeader}
        handleLeftClick={handleLeftClick}
        leftIcon={true}
        centerChild={'공지사항'}
        centerIcon={false}
        rightChild={false}
        rightIcon={false}
      />
      <div className='wrapper'>

      <NotificationPageComponent notificationData={notificationData} loading={loading}
                                 fetchData={fetchData} loadMore={loadMore} loadMoreData={loadMoreData}
      />




      </div>
    </MainLayout>
    </div>
  )
};

export default NotificationPageContainer;
