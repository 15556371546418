import React, {useEffect, useState} from "react";
import {useNavigate, Link, useLocation, useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import MainLayout from "../../../../commons/layout/MainLayout/index";
import HeaderHs2 from "../../../../commons/components/Header/headerHs2";
import API, {ROUTES} from "../../../../commons/api/api";
import {put} from "redux-saga/effects";
import * as actionCreators from "../../redux/actions";


import {hasWhiteSpace, validateEmail} from '../../../../utils/helpers';
import ROUTERS from "../../../../commons/constans/router";

import {formatPhoneNumber} from '../../../../utils/helpers'

import './_styles.scss';

import CountDownTime from "../../../../commons/components/CountDownTime";
import Loading from "../../../../commons/components/Loading";
import Header from "../../../../commons/components/Header/header";
import LeftIconHeader from "../../../../assets/images/leftIconHeader.svg";
import InputComponent from "../../../../commons/components/Input/InputComponent";
import ButtonComponent from "../../../../commons/components/Button/ButtonComponent";
import ModalComponent from "../../../../commons/components/Modal/ModalComponent";
import {loginRequest} from '../../redux/actions';


const RegisterComponent = ({type, loginRequest, resetState, isProcessing}: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [dataForm, setDataForm] = useState({
    phone_number: '',
    password: '',
    password_confirmation: '',
    verify_code: '',
  });

  const [openModal, setOpenModal] = useState({
    isShowDialog : false,
    content : '',
    action : '', //register_success ||
  });

  const [verifyPhoneNumber, setVerifyPhoneNumber] = useState('wait'); // status : wait,waiting,done

  const [checkPassword,setCheckPassword] = useState(false);

  const [buttonSubmitDisabled,setSubmitButtonDisabled] = useState(true);


  const initMinutes = 2;
  const initSecond = 59;

  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);


  useEffect(() => {
    // const rePass = /^(?=.*[0-9])(?=.*[a-z])/; //[a-z0-9]
    const rePass = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
    if(dataForm.password.length >= 8 && dataForm.password_confirmation.length >= 8 && (dataForm.password === dataForm.password_confirmation ) && rePass.test(dataForm.password)){
        setCheckPassword(true);
    }else{
      setCheckPassword(false);
    }
  },[dataForm.password,dataForm.password_confirmation]);

  useEffect(() => {
    if(verifyPhoneNumber === 'done' && checkPassword) {
      setSubmitButtonDisabled(false)
    }else{
      setSubmitButtonDisabled(true)
    }
  },[verifyPhoneNumber,checkPassword]);

  const handleChangeIpt = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    if (name === 'phone_number') {
      let valuePhoneNumber = value;
      valuePhoneNumber = value.replaceAll('-', '')
      if (valuePhoneNumber.length <= 11) {
        const re = /^[0-9\b]+$/;
        if (valuePhoneNumber === '' || re.test(valuePhoneNumber)) {
          setDataForm({
            ...dataForm,
            [name]: formatPhoneNumber(valuePhoneNumber),
            'verify_code' : '',
            'password' : '',
            'password_confirmation' : ''
          });
          setVerifyPhoneNumber('wait')
        }
      }
      return false;
    }
    setDataForm({
      ...dataForm,
      [name]: value
    })
  }

  const clickSendCodeNumberPhone = () => {
    let dataApi = {
      value : dataForm.phone_number,
      type : 'phone_number'
    }

    if(dataForm.phone_number.length === 13) {
      API.post(ROUTES.API_AUTH_VALIDATE,dataApi).then(res => {

        setOpenModal({
          isShowDialog : true,
          content : '인증번호가 발송 되었습니다.\n' +
            '발송된 인증 번호를 입력해 주세요.',
        });
        // setVerifyPhoneNumber('done');

        API.post(`${ROUTES.API_AUTH_SEND_CODE}?type=sms`,{phone_number:dataForm.phone_number }).then(res => {
          setVerifyPhoneNumber('waiting');
          setMinutes(initMinutes)
          setSeconds(initSecond)
        })

      }).catch(e =>{
        let error = e.response;
        if(error.status === 422) {
          let dataError = error.data.errors;
          let errorMessage = '';
          Object.keys(dataError).map(function (key, index) {
            errorMessage = dataError[key][0];
          });
          setOpenModal({
            isShowDialog : true,
            content : errorMessage,
          })

          if(errorMessage === '이미 존재하는 아이디입니다.'){
            setDataForm({
              ...dataForm,
              phone_number: ''
            })
          }

        }

      })
    }
  }

  const clickVerifyNumberPhone = () => {
    if(minutes === 0 && seconds === 0) {
      setOpenModal({
        isShowDialog : true,
        content : '인증번호 시간이 만료되었습니다.\n' +
          '인증 번호를 다시 받아주세요.',
      });
      return false;
    }

    let dataVerifyCode = {
      phone_number:dataForm.phone_number,
      code : dataForm.verify_code
    }
    API.post(`${ROUTES.API_AUTH_VERIFY_CODE}?type=sms`,dataVerifyCode).then(res => {
      if(res && res.data && res.data.status) {
        setVerifyPhoneNumber('done');
      }
    }).catch(e => {
      let error = e.response;
      if(error.status === 422) {
        //ma xac minh khong khop vui long nhap lai
        setOpenModal({
          isShowDialog : true,
          content : '인증번호가 일치하지 않습니다.\n' +
                      '다시 입력해 주세요.',
        });
      }
    })
  }

  const handleSubmitRegisterUser = () => {
    setSubmitButtonDisabled(true)
    API.post(ROUTES.API_AUTH_REGISTER_USER,dataForm).then(res => {
      setOpenModal({
        isShowDialog : true,
        content : '회원 가입이 완료 되었습니다.',
        action: 'register_success'
      });
    }).catch(e => {
      setSubmitButtonDisabled(false)
    })
  }

  let countDown = verifyPhoneNumber === 'waiting' && (minutes  || seconds ) ? <CountDownTime
    minutes={minutes}
    seconds={seconds}
    setMinutes={setMinutes}
    setSeconds={setSeconds}
  />: '';


  // let btnVerifyCodeDisabled = true;
  // if(btnVerifyCodeDisabled)


  return (
    <MainLayout pageID={'register-page'}>
      <Header
        leftChild={LeftIconHeader}
        handleLeftClick={() => {
          navigate(ROUTERS.POLICY_REGISTER)
        }}
        leftIcon={true}
        centerChild={'회원가입'}
        centerIcon={false}
        rightChild={false}
        rightIcon={false}
      />

      <div className={'wrapper'}>
        <div className={'row register-content'}>
          <div className={'col-12'}>

            <div className={'row mt-4'}>
              <div className={'col-12'}>
                <InputComponent
                  label={"아이디 (휴대폰 번호)"}
                  isRequired={false}
                  placeHolder={"아이디를 입력해 주세요."}
                  id={"id-input"}
                  name={"phone_number"}
                  value={dataForm.phone_number}
                  handleChange={handleChangeIpt}
                  // disabled={isProcessing}
                  readonly={false}
                  btnLabel={verifyPhoneNumber == 'wait' ? '본인 인증' : '재전송'}
                  btnClass={`ml-2 btn btn-custom ${verifyPhoneNumber == 'wait' ? 'register-wait' : 'register-verify'}`}
                  maxLength={13}
                  autoComplete={'off'}
                  btnHandleClick={clickSendCodeNumberPhone}
                  disabled={verifyPhoneNumber === 'done' ? true : false}
                  btnDisabled={verifyPhoneNumber === 'done' ? true : false}
                />
              </div>
            </div>

            {verifyPhoneNumber === 'waiting' || verifyPhoneNumber === 'done'?
              <div className={'row'}>
                <div className={'col-12'}>
                  <InputComponent
                    isRequired={false}
                    // errorMsg={errorForm?.phone_number || ''}
                    placeHolder={"인증 번호를 입력해 주세요."}
                    id={"id-input"}
                    name={"verify_code"}
                    value={dataForm.verify_code}
                    handleChange={handleChangeIpt}
                    disabled={verifyPhoneNumber === 'waiting' ? false : true}
                    readonly={false}
                    btnLabel={'확인'}
                    maxLength={4}
                    btnClass={'ml-2 btn btn-custom register-btn'}
                    btnDisabled={verifyPhoneNumber === 'waiting' && dataForm.verify_code.length > 0 ? false : true}
                    timeCountDown={countDown}
                    btnHandleClick={clickVerifyNumberPhone}
                  />
                </div>
              </div>
              : ''}

            <div className={'row mt-2'}>
              <div className={'col-12'}>
                <InputComponent
                  label={"비밀번호"}
                  isRequired={false}
                  // errorMsg={errorForm?.phone_number || ''}
                  placeHolder={"숫자와 영문을 포함한 8~20글자"}
                  id={"id-input"}
                  name={"password"}
                  value={dataForm.password}
                  handleChange={handleChangeIpt}
                  // disabled={isProcessing}
                  disabled={verifyPhoneNumber === 'done' ? false : true}
                  maxLength={20}
                />
              </div>
            </div>

            <div className={'row mt-2'}>
              <div className={'col-12'}>
                <InputComponent
                  label={"비밀번호 확인"}
                  isRequired={false}
                  // errorMsg={errorForm?.phone_number || ''}
                  placeHolder={"비밀번호를 다시 입력해 주세요."}
                  id={"id-input"}
                  name={"password_confirmation"}
                  value={dataForm.password_confirmation}
                  handleChange={handleChangeIpt}
                  // disabled={isProcessing}
                  disabled={verifyPhoneNumber === 'done' ? false : true}
                  errorMsg={checkPassword ? '비밀번호가 일치합니다.' : ''}
                  maxLength={20}
                />
              </div>
            </div>


            <div className={'row mt-2'}>
              <div className={'col-12'}>
                <ButtonComponent
                  label={'회원가입'}
                  cssClass={"btn btn-block"}
                  type={"button"}
                  // loading={true}
                  // disabled={propsButton.disableBtn}
                  // disabled={isProcessing}
                  handleClick={handleSubmitRegisterUser}
                  fixedBtn={true}
                  disabled={buttonSubmitDisabled}
                />
              </div>

            </div>

          </div>
        </div>
      </div>


      <ModalComponent
        id={``}
        show={openModal.isShowDialog}
        title={`알림`}
        submitBtn={`확인`}
        handleSubmit={()=>{

          let actionButton = openModal.action ? openModal.action : ''

          setOpenModal({
            ...openModal,
            isShowDialog : false,
            content : '',
            action: ''
          });

          if(actionButton === 'register_success') {
            // fetch api Login
            // let data = {
            //   fcm_token : window.appDevice?.fcmToken || '',
            //   user_version : window.appDevice?.userVersion || '',
            //   device_key : window.appDevice?.deviceKey || '',
            //   'phone_number': dataForm.phone_number,
            //   'password': dataForm.password,
            //   'remember_me': true,
            // }
            // dispatch(loginRequest(data))
            navigate(ROUTERS.LOGIN);
          }

        }}
      >
        <div style={{ whiteSpace : 'pre-wrap'}}>
          {openModal.content ?? ''}
        </div>
      </ModalComponent>

    </MainLayout>
  );
};

export default RegisterComponent;
