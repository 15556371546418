import { useEffect, useState, memo } from "react";
import { useNavigate } from "react-router-dom";
import ROUTERS from "../../../commons/constans/router";
import MainLayout from "../../../commons/layout/MainLayout/index";
import IconHeader from "../../../assets/images/intro_header.svg";
import {Link} from "react-router-dom";
import { useRole } from "../../../commons/customHooks/Account";


const IntroComponent = ({

}: Props) => {
  const navigate = useNavigate();
  
  return (
    <div className={'intro-page'}>
      <div className={'row mr-0 ml-0'}>
        <div className={'col-12 text-center header-icon'}>
          <img src={IconHeader} />
        </div>


        <div className={'col-12 text-center intro-title mt-3'}>
          <p className={'mt-1'}>IOT 플랫폼 앱</p>
          <p className={'mt-1'}>'친구야놀자'에 오신걸 환영합니다.</p>
        </div>


        <div className={'col-12 text-center intro-link mt-3'}>
          <div className={'mt-3'}>
            <Link className={'link-to'} to={ROUTERS.POLICY_REGISTER}>회원가입 하기</Link>
          </div>
          <div className={'mt-3'}>
            <Link className={'link-to'} to={ROUTERS.LOGIN}>기존회원 로그인하기</Link>
          </div>

          <div className={'mt-3'}>
            <Link className={'link-to'} to={ROUTERS.MAIN}>비회원 접속하기</Link>
          </div>

          <div className={'mt-3'}>
            <Link className={'link-to'} to={ROUTERS.FORGOT_PW}>비밀번호 찾기</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

IntroComponent.defaultProps = {
};

export default memo(IntroComponent);
