import React, {useEffect, useState} from "react";
import {useNavigate,useLocation} from "react-router-dom";

import {useDispatch} from "react-redux";
import MainLayout from "../../../commons/layout/MainLayout/index";
import ROUTERS from "../../../commons/constans/router";
import Header from "../../../commons/components/Header/header";
import LeftIconHeader from "../../../assets/images/leftIconHeader.svg";
import InputComponent from "../../../commons/components/Input/InputComponent";
import ButtonComponent from "../../../commons/components/Button/ButtonComponent";
import {formatPhoneNumber} from "../../../utils/helpers";
import API, {ROUTES} from "../../../commons/api/api";
import ModalComponent from "../../../commons/components/Modal/ModalComponent";

const ChangePassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const phone_number = location.state;

  const [dataForm, setDataForm] = useState({
    phone_number: phone_number,
    password: '',
    password_confirmation: '',
  });

  const [openModal, setOpenModal] = useState({
    isShowDialog : false,
    content : '',
    action : ''
  });

  const [checkPassword,setCheckPassword] = useState(false);

  useEffect(() => {
    const rePass = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
    if(dataForm.password.length >= 8 && dataForm.password_confirmation.length >= 8 && (dataForm.password === dataForm.password_confirmation ) && rePass.test(dataForm.password)){
      setCheckPassword(true);
    }else{
      setCheckPassword(false);
    }
  },[dataForm.password,dataForm.password_confirmation]);


  const handleChangeIpt = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    if (name === 'phone_number') {
      let valuePhoneNumber = value;
      valuePhoneNumber = value.replaceAll('-', '')
      if (valuePhoneNumber.length <= 11) {
        const re = /^[0-9\b]+$/;
        if (valuePhoneNumber === '' || re.test(valuePhoneNumber)) {
          setDataForm({
            ...dataForm,
            [name]: formatPhoneNumber(valuePhoneNumber)
          });
        }
      }
      return false;
    }
    setDataForm({
      ...dataForm,
      [name]: value
    })
  }

  const handleSubmit = () => {
    API.post(ROUTES.API_AUTH_UPDATE_BY_NUMBER,dataForm).then(res => {
      setOpenModal({
        isShowDialog : true,
        content : '비밀번호가 변경 되었습니다.',
        action : 'success'
      })
    })
  }

  return (
    <MainLayout pageID={'change-password-page'}>
      <Header
        leftChild={LeftIconHeader}
        handleLeftClick={() => {
          navigate(ROUTERS.FORGOT_PW)
        }}
        leftIcon={true}
        centerChild={'비밀번호 재설정'}
        centerIcon={false}
        rightChild={false}
        rightIcon={false}
      />

      <div className={'wrapper'}>
        <div className={'row change-password-content'}>
          <div className={'col-12'}>


            <div className={'row'}>
              <div className={'col-12 text-center'}>
                <p className={'title-page'}>새로운 비밀번호를 입력해 주세요!</p>
              </div>
            </div>

            <div className={'row mt-4'}>
              <div className={'col-12'}>
                <InputComponent
                  label={"비밀번호"}
                  isRequired={false}
                  // errorMsg={errorForm?.phone_number || ''}
                  placeHolder={"아이디를 입력해 주세요."}
                  name={"password"}
                  value={dataForm.password}
                  handleChange={handleChangeIpt}
                />
              </div>
            </div>


            <div className={'row mt-2'}>
              <div className={'col-12'}>
                <InputComponent
                  label={"비밀번호 확인"}
                  isRequired={false}
                  // errorMsg={errorForm?.phone_number || ''}
                  placeHolder={"아이디를 입력해 주세요."}
                  value={dataForm.password_confirmation}
                  name={'password_confirmation'}
                  handleChange={handleChangeIpt}
                  // disabled={isProcessing}
                  errorMsg={checkPassword ? '비밀번호가 일치합니다.' : ''}
                />
              </div>
            </div>


            <div className={'row mt-2'}>
              <div className={'col-12'}>
                <ButtonComponent
                  label={'비밀번호 변경'}
                  cssClass={"btn btn-block btn-custom"}
                  type={"button"}
                  // loading={true}
                  // disabled={propsButton.disableBtn}
                  // disabled={isProcessing}
                  handleClick={handleSubmit}
                  fixedBtn={true}
                  disabled={!checkPassword}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <ModalComponent
        id={``}
        show={openModal.isShowDialog}
        title={`알림`}
        submitBtn={`확인`}
        handleSubmit={()=>{

          let actionButton = openModal.action ? openModal.action : '';

          setOpenModal({
            ...openModal,
            isShowDialog : false,
            content : '',
            action: ''
          });

          if(actionButton === 'success'){
            navigate(ROUTERS.LOGIN);
          }
        }}
      >
        <div style={{ whiteSpace : 'pre-wrap'}}>
          {openModal.content ?? ''}
        </div>
      </ModalComponent>

    </MainLayout>
  );
};

export default ChangePassword;
