import React from "react";
import HeaderHs2 from "../../../commons/components/Header/headerHs2";
import MainLayout from "../../../commons/layout/MainLayout/index";
import {useSelector} from "react-redux";
import {useNavigate, Link, useLocation} from "react-router-dom";
import ROUTERS from "../../../commons/constans/router";

const InformationTerm = () => {
    const { userInfo, isProcessing, token, type, statusCode , errors } = useSelector((state) => state.authReducers);
    const navigate = useNavigate();

    const location = useLocation();
    const state = location.state;


    const handleLabelLeft = () => {
        navigate(ROUTERS.REGISTER,{
            state : state,
        })
    }
    
    return (
        <MainLayout
            isLoading={isProcessing}
            userInfo={userInfo}
            pageID={"information"}
            displayBottomNav={false}
        >
            <HeaderHs2
                title={"이용약관"}
                labelLeft={true}
                labelRight={false}
                handleLabelLeft={handleLabelLeft}
            />
            <div className="wrapper">
                <div className="container">
                    <h3 className="title">스포키즈 이용약관</h3>
                </div>

                <div className="main-content">
                    <p className="main-content__detail">
                        제 1 조 (목적)
                        <br/> <br/>
                            이 약관은 ㈜아이오로라 (이하 "회사")가 제공하는 SPHOKIDZ APP 및 SPHOKIDZ 키오스크 관련 제반 서비스의 이용과 관련하여 회사와 회원과의 권리,
                            의무 및 책임사항, 기타 필요한 사항을 규정함을 목적으로 합니다.
                            <br/> <br/>
                                제 2 조 (정의)
                                <br/> <br/>
                                    이 약관에서 사용하는 용어의 정의는 다음과 같습니다.
                                    <br/> <br/>
                                        ① "서비스"라 함은 구현되는 단말기(PC, 휴대형단말기 등의 각종 유무선 장치를 포함)와 상관없이 "회원"이 이용할 수 있는 SPHOKIDZ
                                        App 및 SPHOKIDZ 키오스크 관련. 제반 서비스를 의미합니다.
                                        <br/> <br/>
                                            ② "회원"이라 함은 회사의 "서비스"에 접속하여 이 약관에 따라 "회사"의 “서비스”에서 제공하는 SNS로그인(페이스북, 카카오톡,
                                            구글)을 통해 회원가입을 하여 "회사"가 제공하는 "서비스"를 이용하는 고객을 말합니다.
                                            <br/> <br/>
                                                ③ “코인”이라 함은 “회원”이 직접 대가를 지급하지 않고 “회사” 가 정하는 일정한 행위를 마친 때에 시스템에 적립되는
                                                “서비스” 상의 데이터로서 소멸 전까지 “서비스”상에서 상품의 교환 및 이벤트 참여 등에 사용할 수 있는 것을 말합니다.
                                                <br/> <br/> <br/> <br/>
                                                    제 3 조 (약관의 게시와 개정)
                                                    <br/> <br/>
                                                        ① "회사"는 이 약관의 내용을 "회원"이 쉽게 알 수 있도록 홈페이지 초기 화면 및 SPHOKIDZ APP 내
                                                        설정에 게시합니다.
                                                        <br/> <br/>
                                                            ② "회사"는 "약관의규제에관한법률", "정보통신망이용촉진및정보보호등에관한법률(이하 "정보통신망 법")" 등
                                                            관련법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.
                                                            <br/> <br/>
                                                                ③ "회사"가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과 함께 제1항의 방식에
                                                                따라 그 개정약관의 적용일자 30일 전부터 적용일자 전일까지 공지합니다. 다만, 회원에게 불리한
                                                                약관의 개정의 경우에는 공지 외에 일정기간 e-mail, 서비스 내 메시지, 로그인시 동의창 등의
                                                                전자적 수단을 통해 따로 명확히 통지하도록 합니다.
                                                                <br/> <br/>
                                                                    ④ “회사”가 전항에 따라 개정약관을 공지 또는 통지하면서 회원에게 30일 기간 내에
                                                                    의사표시를 하지 않으면 의사표시가 표명된 것으로 본다는 뜻을 명확하게 공지 또는 통지하였음에도
                                                                    회원이 명시적으로 거부의 의사표시를 하지 아니한 경우 회원이 개정약관에 동의한 것으로 봅니다.
                                                                    <br/> <br/>
                                                                        ⑤ 회원이 개정약관의 적용에 동의하지 않는 경우 회사는 개정 약관의 내용을 적용할 수
                                                                        없으며, 이 경우 회원은 이용계약을 해지할 수 있습니다. 다만, 기존 약관을 적용할 수
                                                                        없는 특별한 사정이 있는 경우에는 회사는 이용계약을 해지할 수 있습니다.
                    </p>
                </div>

            </div>
        </MainLayout>
    )
}

export default InformationTerm;