// @flow
import React, { useEffect, useState, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { logOutRequest } from "../../../pages/Account/redux/actions";

type Props = {
  leftChildShow: Boolean,
  title: String,
  titleDate: String,
  labelRight: any,
  labelLeft: any,
  handleLabelRight: Function,
  handleLabelLeft: Function
};

const HeaderHs2 = ({
  title,
  titleDate,
  labelRight,
  labelLeft,
  handleLabelRight,
  handleLabelLeft,
}: Props) => {

  
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {});

  // const handleLabelLeft = () => {
  //   if (path && path !== '') {
  //     console.log(path);
  //     navigate(path);
  //   }else{
  //     navigate(-1);
  //   }
  // }

  return (
    <header className="header__hs2 fixed-top">
      <div className="left">
        {labelLeft && <a title=""  onClick={() => handleLabelLeft()}>
          <img className="header__hs2_prev" src="/images/header_prev.svg" />
        </a>}
      </div>
      {
        titleDate ? (
          <div className="title-date">
            
            <h2>{title}</h2>
            <p>{titleDate && titleDate}</p>
          </div>
        ) : (
          <div className="title">
            <h1>{title}</h1>
          </div>
        )
      }
      
      <div className="right">
        
        {labelRight && <a title="#" onClick={() => handleLabelRight()}>
          {titleDate ? <>
            <img src="/images/album/icon_ranking.svg" alt="" className="icon-ranking"/>
            {labelRight}
          </> : labelRight}
          </a>}
      </div>
    </header>

  );
};

HeaderHs2.defaultProps = {
  title: 'title',
  titleDate: '',
  labelRight: 'labelRight',
  url: ''
};

export default memo(HeaderHs2);
