import API from '../../../../commons/api/api';
import * as actionTypes from '../actions/type';

export const initialState = {
  isProcessing: false,
  type: '',
  codeLabel: [],
  typeOfBusiness: [],
  taxClass: []
};

const codeLabelReducers = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_CODE_LABEL_REQUEST:
      return {
        ...state,
        isProcessing: true,
        type: action.type,
        codeLabel: [],
        typeOfBusiness: [],
        taxClass: []
      };
    case actionTypes.GET_CODE_LABEL_SUCCESS:
      const typeOfBusiness = [];
      const taxClass = [];
      if (action.payload?.data.length > 0) {
        action.payload?.data.map((item, idx) => {
          if (item.type === 'TYPE_OF_BUSINESS') {
            typeOfBusiness.push({id: item.id, text: item.value})
          }
          if (item.type === 'TAX_CLASS') {
            taxClass.push({id: item.id, text: item.value})
          }
        })
      }
      return {
        ...state,
        isProcessing: true,
        type: action.type,
        codeLabel: action.payload?.data || [],
        typeOfBusiness: typeOfBusiness,
        taxClass: taxClass
      };
    default:
      return state;
  }
};

export default codeLabelReducers;
