

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';

export const GET_USER_HOME = 'GET_USER_HOME';
export const GET_USER_HOME_SUCCESS = 'GET_USER_HOME_SUCCESS';

export const UPLOAD_IMG_REQUEST = 'UPLOAD_IMG_REQUEST';
export const UPLOAD_IMG_SUCCESS = 'UPLOAD_IMG_SUCCESS';
export const OPEN_MENU = 'OPEN_MENU';
