import React, { useEffect, useState } from 'react';
import ROUTERS from '../../../commons/constans/router';
import MainLayout from '../../../commons/layout/MainLayout';
import LeftIconHeader from '../../../assets/images/leftIconHeader.svg';

import Header from '../../../commons/components/Header/header';
import { useLocation, useParams } from 'react-router-dom';
import ButtonComponent from '../../../commons/components/Button/ButtonComponent';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useRole, useUser } from '../../../commons/customHooks/Account';
import {
  useCategories,
  useCodeLabel,
  useDetailStore,
  useUpdateStore,
} from '../../../commons/customHooks/Store';
import ModalComponent from '../../../commons/components/Modal/ModalComponent';
import StoreAddress from '../../../assets/images/phone_store.svg';
import StorePhone from '../../../assets/images/address_store.svg';
import RadioComponent from '../../../commons/components/Radio/RadioComponent';
import InputComponent from '../../../commons/components/Input/InputComponent';
import ToggleSwitchComponent from '../../../commons/components/ToggleSwitch/ToggleSwitchComponent';

import TextAreaComponent from '../../../commons/components/TextArea/TextAreaComponent';
import SelectTimeComponent from '../components/SelectTime/SelectStartTimeComponent';
import SelectStartTimeComponent from '../components/SelectTime/SelectStartTimeComponent';
import SelectEndTimeComponent from '../components/SelectTime/SelectEndTimeComponent';
import API, { ROUTES } from '../../../commons/api/api';
import Loading from '../../../commons/components/Loading';
import ImageBoxAddMoreComponent from '../../../commons/components/ImageBox/ImageBoxAddMoreComponent';
import StoreMenuComponent from '../components/StoreUpdate/StoreMenuComponent';
import StoreWorkingTimeComponent from '../components/StoreUpdate/StoreWorkingTimeComponent';
import { dataWorkingTimeDefaul } from '../../../commons/constans/store';

const StoreUpdatePageContainer = () => {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const [detailStore, isProcessingDetailStore] = useDetailStore(params);
  const dispatch = useDispatch();
  const [isCheckedRadio, setIsCheckedRadio] = useState({});
  const [workingTime, setWorkingTime] = useState(detailStore.working_times);
  const [decription, setDecription] = useState('');
  const [photoGallery, setPhotoGallery] = useState([]);
  const [photoGalleryDetail, setPhotoGalleryDetail] = useState([]);
  const [indexPhotoGallery, setIndexPhotoGallery] = useState([]);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [dataMenu, setDataMenu] = useState([]);
  const [deleteMenus, setDeleteMenus] = useState([]);
  const [loadingUpLoad, setLoadingUpload] = useState(false);

  useEffect(() => {
    setIsCheckedRadio({
      ...isCheckedRadio,
      beacon_state: detailStore.beacon_state,
      nfc_state: detailStore.nfc_state,
      kiosk_state: detailStore.kiosk_state,
    });
  }, [
    detailStore.beacon_state,
    detailStore.nfc_state,
    detailStore.kiosk_state,
  ]);

  useEffect(() => {
    if (detailStore?.photo_gallery) {
      setPhotoGallery([
        ...detailStore.photo_gallery
      ])
    }else{
      setPhotoGallery([])
    }
  }, [detailStore.photo_gallery]);

  useEffect(() => {
    if (detailStore?.working_times?.length > 0) {
      setWorkingTime(detailStore.working_times);
    }else{
      setWorkingTime(dataWorkingTimeDefaul);
    }
  }, [detailStore.working_times]);

  useEffect(() => {
    setDecription(detailStore?.store_description || '')
  }, [detailStore.store_description])

  const handleLeftClick = () => {
    if (location?.state?.prev) {
      navigate(location.state.prev); 
    } else {
      navigate(ROUTERS.OPERATION+'?store='+params?.id);
    }
  };

  const handleChangeStatus = (e) => {
    setIsCheckedRadio({
      ...isCheckedRadio,
      [e.target.name]: e.target.value,
    });
  };


  const handleChangeImage = (e, idx) => {
    setLoadingUpload(true);
   
    let formData = new FormData();
    formData.append('file', e.file);
    formData.append('store_id', detailStore?.id);
    
    API.post(ROUTES.API_UPLOAD_FILE, formData)
      .then((res) => {
        setLoadingUpload(false);
        setPhotoGallery([
          ...photoGallery,
          res.data?.data
        ]);
      })
      .catch((err) => {
        setLoadingUpload(false);
      });
  };

  useEffect(() => {
    if (detailStore.photo_gallery && detailStore.photo_gallery.length > 0) {
      setPhotoGalleryDetail(detailStore.photo_gallery);
    }else{
      setPhotoGalleryDetail([]);
    }
  }, [detailStore.photo_gallery]);

  useEffect(() => {
    if (detailStore.store_menu && detailStore.store_menu.length > 0) {
      setDataMenu(detailStore.store_menu);
    }else{
      setDataMenu([]);
    }
  }, [detailStore.store_menu]);

  const handleRemoveImg = (e) => {
    // setIndexPhotoGallery({
    //   ...indexPhotoGallery,
    //   index: e.index,
    // });
    let _photoGallery = photoGallery;
    if (_photoGallery.length > 0) {
      _photoGallery.splice(e.index, 1); 
      setPhotoGallery([..._photoGallery]);
    }
  };

  const handleChangeStoreMenu = (data) => {
    setDataMenu(data);
  }

  const handleSubmitForm = () => {
    setLoadingUpdate(true);
    let formData = new FormData();
    let dataForm = {
      id: detailStore.id,
      store_name: detailStore.store_name,
      store_phone: detailStore.store_phone,
      store_address: detailStore.store_address,
      photo_gallery: '',
      index_photo_gallery: '',
      beacon_state: isCheckedRadio.beacon_state,
      nfc_state: isCheckedRadio.nfc_state,
      kiosk_state: isCheckedRadio.kiosk_state,
      store_description: decription,
    };
    Object.keys(dataForm).forEach((key) => formData.append(key, dataForm[key]));

    let flagState = false;

    Object.values(workingTime).forEach((value, key) => {
      if (value['state'] === 'Y') {
        flagState = true;
      }
    });
    
    if (!detailStore?.working_times || detailStore?.working_times?.length == 0) {
      if (flagState) {
        Object.values(workingTime).forEach((value, key) => {
          formData.append('working_time[' + key + '][state]', value['state']);
          formData.append(
            'working_time[' + key + '][starttime]',
            value['starttime']
          );
          formData.append('working_time[' + key + '][endtime]', value['endtime']);
        });
      }
    }else{
      Object.values(workingTime).forEach((value, key) => {
        formData.append('working_time[' + key + '][state]', value['state']);
        formData.append(
          'working_time[' + key + '][starttime]',
          value['starttime']
        );
        formData.append('working_time[' + key + '][endtime]', value['endtime']);
      });
    }
  
    if (photoGallery.length > 0) {
      formData.append('photo_gallery', JSON.stringify(photoGallery));
    }else{
      formData.append('photo_gallery', null);
    }

    if (dataMenu.length > 0) {
      dataMenu.map((item, idx) => {
        if (item.name && item.price) {
          formData.append('menu['+idx+'][name]', item.name);
          formData.append('menu['+idx+'][price]', +item.price.replaceAll(',', ''));
          if (item?.photo) {
            formData.append('menu['+idx+'][photo]', JSON.stringify(item?.photo) ?? null);
          }else{
            formData.append('menu['+idx+'][photo]', null);
          }
          if (item.id) {
            formData.append('menu['+idx+'][id]', item.id);
          }
        }
      })
    }


    if (deleteMenus.length > 0) {
      deleteMenus.map((item, idx) => {
          formData.append('delete_menu[]', item || []);
      })
    }else{
      formData.append('delete_menu[]', []);
    }

    //update store
    API.post(ROUTES.API_UPDATE_STORE + dataForm.id, formData)
      .then((res) => {
        setLoadingUpdate(false);
        if (location?.state?.prev) {
          navigate(location.state.prev); 
        } else {
          navigate(ROUTERS.OPERATION+'?store='+params?.id);
        }
        // navigate(ROUTERS.STORE_INFOMATION_PATH(dataForm.id));
      })
      .catch((err) => {
        setLoadingUpdate(false);
      });
  };

  const handleChangeWorkingTimes = (data) => {
    setWorkingTime(data);
  }

  const handleDeleteMenus = (data) => {
    setDeleteMenus(data);
  }

  const handleChangeDecription = (e) => {
    setDecription(e.target.value);
  };

  if (!detailStore) {
    return null
  }


  return (
    <div>
      <MainLayout pageID={'store-update-page'}>
        <Header
          leftChild={LeftIconHeader}
          handleLeftClick={handleLeftClick}
          leftIcon={true}
          centerChild={detailStore?.store_name}
          centerIcon={false}
          rightChild={'완료'}
          handleRightClick={handleSubmitForm}
        />
        <div className="wrapper">
          <div className="header-update">
            <h2>가맹점 사진({photoGallery.length}/3)</h2>
            <div className="upload-img">
              <ImageBoxAddMoreComponent
                nameFile={'photo_gallery'}
                handleChange={(e) => handleChangeImage(e)}
                removeImg={(e) => handleRemoveImg(e)}
                textCard={'내 핸드폰에서 이미지 선택하기'}
                indexImg={``}
                imageList={photoGalleryDetail}
              />
            </div>
            <div className="store-address">
              <img src={StoreAddress} />
              <span>{detailStore.store_address}</span>
            </div>
            <div className="store-phone">
              <img src={StorePhone} />
              <span>{detailStore.store_phone}</span>
            </div>
          </div>
          <div className="beacon-update">
            <label className="title">Beacon 설치</label>
            <div className="radio-status">
              <RadioComponent
                name={'beacon_state'}
                value={'Y'}
                handleChange={handleChangeStatus}
                disabled={false}
                title={'운영'}
                subTitle={``}
                labelClass={``}
                componentClass={``}
                checked={isCheckedRadio.beacon_state == 'Y' ? true : false}
              />
              <RadioComponent
                name={'beacon_state'}
                value={'N'}
                handleChange={handleChangeStatus}
                disabled={false}
                title={'미운영'}
                subTitle={``}
                labelClass={``}
                componentClass={``}
                checked={isCheckedRadio.beacon_state == 'N' ? true : false}
              />
            </div>

            <label className="title">NFC 설치 </label>
            <div className="radio-status">
              <RadioComponent
                name={'nfc_state'}
                value={'Y'}
                handleChange={handleChangeStatus}
                disabled={false}
                title={'운영'}
                subTitle={``}
                labelClass={``}
                componentClass={``}
                checked={isCheckedRadio.nfc_state == 'Y' ? true : false}
              />
              <RadioComponent
                name={'nfc_state'}
                value={'N'}
                handleChange={handleChangeStatus}
                disabled={false}
                title={'미운영'}
                subTitle={``}
                labelClass={``}
                componentClass={``}
                checked={isCheckedRadio.nfc_state == 'N' ? true : false}
              />
            </div>

            <label className="title">키오스크 설치</label>
            <div className="radio-status">
              <RadioComponent
                name={'kiosk_state'}
                value={'Y'}
                handleChange={handleChangeStatus}
                disabled={false}
                title={'운영'}
                subTitle={``}
                labelClass={``}
                componentClass={``}
                checked={isCheckedRadio.kiosk_state == 'Y' ? true : false}
              />
              <RadioComponent
                name={'kiosk_state'}
                value={'N'}
                handleChange={handleChangeStatus}
                disabled={false}
                title={'미운영'}
                subTitle={``}
                labelClass={``}
                componentClass={``}
                checked={isCheckedRadio.kiosk_state == 'N' ? true : false}
              />
            </div>
          </div>
          
          <StoreWorkingTimeComponent 
            dataWorkingTime={detailStore?.working_times?.length > 0 ? detailStore.working_times : dataWorkingTimeDefaul}  
            handleChangeWorkingTimes={handleChangeWorkingTimes}
          />

          <StoreMenuComponent 
            dataMenu={detailStore?.store_menu || []} 
            handleChangeStoreMenu={handleChangeStoreMenu}  
            handleDeleteMenus={handleDeleteMenus}
            storeId={detailStore?.id || null}
          />

          <div className="store-introduction">
            <label>매장 소개</label>
            <div className="content">
              <TextAreaComponent
                placeHolder={'매장소개를 입력하세요.'}
                handleChange={handleChangeDecription}
                value={detailStore?.store_description || ''}
              />
            </div>
          </div>
        </div>
        {loadingUpdate || loadingUpLoad && <Loading />}
      </MainLayout>
    </div>
  );
};

export default StoreUpdatePageContainer;
