import { useEffect, useState } from 'react';

import { useUpdateStore, useResetUpdateState, useDetailStoreWithoutRedux } from "../../../commons/customHooks/Store";

import ToggleSwitchComponent from "../../../commons/components/ToggleSwitch/ToggleSwitchComponent";
import ModalComponent from "../../../commons/components/Modal/ModalComponent";
import { useToast } from "../../../commons/customHooks/useToast";

function Setting ({store}) {
  useResetUpdateState(store)
  const [isLoading, onSubmit] = useUpdateStore()
  const [resStore, setResStore] = useState(null)

  const [isOpen, setIsOpen] = useState(store?.open_state === 'OPEN')
  const [isDirty, setIsDirty] = useState(false)
  const [modalShow, setModalShow] = useState(false)
  const [modalType, setModalType] = useState('off')
  const [, setToastMessage] = useToast();
  const [getStore] = useDetailStoreWithoutRedux();

  useEffect(() => {
    if (store?.id) {
      setIsDirty(false)
      setIsOpen(store?.open_state === 'OPEN')
    }
  }, [store?.id])

  useEffect(() => {
    setIsOpen(store?.open_state === 'OPEN')
  }, [store?.open_state])

  useEffect(() => {
    if (isDirty) {
      onSubmit({
        id: store.id,
        open_state: isOpen ? 'OPEN' : 'CLOSED',
        action: isOpen ? 'ON_MANUAL' : 'OFF_MANUAL'
      })
      setToastMessage(isOpen ? '‘영업 중’으로 안내됩니다.' : '‘영업 전’로 안내됩니다.')
      setIsDirty(false)
    }
  }, [isOpen])

  const handleChange = async (e) => {
    let resStore = await getStore(store.id)
    setResStore(resStore)
    // neu khong nam trong pham vi cua hang
    // va cua hang chua cai dat beacon
    const noBle = (resStore?.beacon_state === 'N' || !resStore?.beacon_uuid) && resStore?.working_times.length
    if (
      resStore.operation_state === 'ABSENT' && !isOpen
      && !noBle
    ) {
      setModalType('out-of-scope')
      setModalShow(true)

      return
    }
    setIsDirty(true)

    // show notification when user turn off status
    if (!e) {
      setModalType('off')
      setModalShow(true)
    } else {
      // if working times not set
      if (!resStore?.working_times?.length) {
        setModalType('not-installed')
        setModalShow(true)
      } else {
        setModalType('off')
        setModalShow(true)
      }
    }
  }

  const handleSubmit = () => {
    setModalShow(false)
    setResStore(null)
    const noBle = (store?.beacon_state === 'N' || !store?.beacon_uuid) && store?.working_times.length
    if (
      store.operation_state === 'ABSENT' && !isOpen
      && !noBle
    ) {
      return;
    }
    if (store?.working_times?.length) {
      setIsOpen(!isOpen)
    } else {
      setIsOpen(false)
    }
  }

  const handleCancel = () => {
    setModalShow(false)
    setResStore(null)
  }

  return (
    <div className="setting">
      <label className="title-text">영업 상태 설정</label>
      <div className="text-center">
        <ToggleSwitchComponent
          textStatusChecked="영업 중"
          textStatusUnChecked="영업 전"
          handleChange={handleChange}
          value={isOpen}
        />
      </div>

      <ModalComponent
        show={modalShow}
        submitBtn={modalType === 'off' ? '네': '확인'}
        cancelBtn={modalType === 'off' ? '아니오': null}
        handleSubmit={handleSubmit}
        handleCancel={handleCancel}
      >
        {modalType === 'off' && isOpen && <div className={'content-alert'}>
          운영 시간 중입니다.<br />
          영업 전로 설정 변경하게 되면 ‘영업 전’로 안내됩니다. <br />
          진행 하시겠습니까?
          {/*운영 시간 중입니다.<br />
          Off로 설정 변경하게 되면 ‘영업 전’로 안내됩니다. <br />
          진행 하시겠습니까?*/}
        </div>}
        {modalType === 'off' && !isOpen && <div className={'content-alert'}>
          영업 시간이 아닙니다.<br />
          영업 중으로 설정 변경하게 되면 ‘영업 중’로 안내됩니다.<br />
          진행 하시겠습니까?
        </div>}
        {modalType === 'not-installed' && <div className={'content-alert'}>
          운영 시간이 설정되어 있지 않습니다.<br />
          운영 시간 설정 후 이용 해주세요.
        </div>}
        {modalType === 'out-of-scope' && <div className={'content-alert'}>
          매장 내에 관리자 위치 확인 되지 않습니다.<br />
          위치 확인 후 선택 해주세요.
        </div>}
      </ModalComponent>
    </div>
  )
}

export default Setting
