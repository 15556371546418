import API from '../../../../commons/api/api';
import * as actionTypes from '../actions/type';

export const initialState = {
  isProcessing: false,
  type: '',

};


