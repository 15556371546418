import * as type from './type';
import {IS_PROCESSING, UPDATE_CHILDREN_PROFILE_SUCCESS} from "./type";

export const loginRequest = (payload) => {
  console.log('loginRequest action', payload);
  return {
    type: type.LOGIN_REQUEST,
    payload,
  };
};

export const loginSuccess = (payload) => {
  console.log('loginSuccess action', payload);
  return {
    type: type.LOGIN_SUCCESS,
    payload,
  };
};

export const loginFaild = (payload = {}) => {
  console.log('loginFaild action', payload);
  return {
    type: type.LOGIN_FAILD,
    payload,
  };
};


export const logOutRequest = (payload) => {
  return {
    type: type.LOGOUT_REQUEST,
    payload,
  };
};

export const logOutSuccess = (payload) => {
  console.log('logOutSuccess action', payload);
  return {
    type: type.LOGOUT_SUCCESS,
    payload,
  };
};

export const logOutFaild = (payload) => {
  console.log('logOutFaild action', payload);
  return {
    type: type.LOGOUT_FAILD,
    payload,
  };
};

export const resetState = (payload) => {
  console.log('resetState action', payload);
  return {
    type: type.RESET_STATE,
    payload,
  };
};

export const updateSuccess = payload => {
  return {
    type: type.UPDATE_SUCCESS,
    payload,
  };
}

export const updateChildrenProfileSuccess = payload => {
  return {
    type: type.UPDATE_CHILDREN_PROFILE_SUCCESS,
    payload,
  };
}

export const updateNotificationSettingSuccess = payload => {
  return {
    type: type.UPDATE_NOTIFICATION_SETTING_SUCCESS,
    payload,
  };
}


// sns login
export const loginSnsRequest = (payload) => {
  return {
    type: type.LOGIN_SNS_REQUEST,
    payload,
  };
};

export const loginSnsSuccess = (payload) => {
  return {
    type: type.LOGIN_SNS_SUCCESS,
    payload,
  };
};

export const loginSnsFaild = (payload = {}) => {
  return {
    type: type.LOGIN_SNS_FAILD,
    payload,
  };
};

export const getCurrentUser = payload => {
  return {
    type: type.GET_CURRENT_USER,
    payload,
  };
}


export const isProcessing = () => {
  return {
    type : type.IS_PROCESSING
  }
}

export const updateUserRequest = (payload) => {
  return {
    type: type.UPDATE_USER_REQUEST,
    payload,
  };
}

export const updateUserSuccess = (payload) => {
  return {
    type: type.UPDATE_USER_SUCSESS,
    payload,
  };
}

export const updateUserLocal = (payload) => {
  return {
    type: type.UPDATE_USER_LOCAL,
    payload,
  };
}