import { Fragment, useState } from 'react';
import moment from 'moment'
import DatimePickerComponent from "../../../commons/components/DatimePicker/DatimePickerComponent";
import ButtonComponent from "../../../commons/components/Button/ButtonComponent";

import IconCalender from "../../../assets/images/icon_calender.svg";
import chervonUpDown from "../../../assets/images/chervon_up_down.svg";

const BY_STATUS = {
  'ON_BLE': '영업 중',
  'OFF_BLE': '영업 전',
  'ON_MANUAL': '영업 중',
  'OFF_MANUAL': '영업 전',
  'ON_NFC': '영업 중',
  'OFF_NFC': '영업 전',
}
const BY_APP = {
  'ON_BLE': 'Bluetooth',
  'OFF_BLE': 'Bluetooth',
  'ON_MANUAL': 'App',
  'OFF_MANUAL': 'App',
  'ON_NFC': 'NFC',
  'OFF_NFC': 'NFC',
}
const STORE_ROLE = {
  'shop': '점주',
  'manager': '매니저',
}

const SORT_BY = [
  'order_by_store_role',
  'order_by_user_name',
  'order_by_state_log_action',
  'order_by_created_at',
]

function HistoryList ({histories, query, onFilter, loading}) {
  const orderBY = (type) => {
    let value = undefined
    if (query[type] === 'asc') {
      value = 'desc'
    } else {
      value = 'asc'
    }
    let sort = [{type, value}]
    SORT_BY.map(t => {
      if (t !== type) {
        sort.push({type: t, value: undefined})
      }
    })

    onFilter(query, sort)
  }

  return (
    <div className="list">
      <table className="table table-striped">
        <thead>
          <tr>
            <th scope="col">
              <div className="d-flex justify-content-between" onClick={() => orderBY('order_by_store_role')}>
                <span>구분</span>
                <span>
                  {query['order_by_store_role'] ? (
                    <Fragment>
                      {query['order_by_store_role'] === 'asc' && <i className="fa fa-chevron-up"></i>}
                      {query['order_by_store_role'] === 'desc' && <i className="fa fa-chevron-down"></i>}
                    </Fragment>
                  ) : (
                    <img src={chervonUpDown} />
                  )}
                </span>
              </div>
            </th>
            <th scope="col">
              <div className="d-flex justify-content-between" onClick={() => orderBY('order_by_user_name')}>
                <span>관리자</span>
                <span>
                  {query['order_by_user_name'] ? (
                    <Fragment>
                      {query['order_by_user_name'] === 'asc' && <i className="fa fa-chevron-up"></i>}
                      {query['order_by_user_name'] === 'desc' && <i className="fa fa-chevron-down"></i>}
                    </Fragment>
                  ) : (
                    <img src={chervonUpDown} />
                  )}
                </span>
              </div>
            </th>
            <th scope="col">
              <div className="d-flex justify-content-between" onClick={() => orderBY('order_by_state_log_action')}>
                <span>상태</span>
                <span>
                  {query['order_by_state_log_action'] ? (
                    <Fragment>
                      {query['order_by_state_log_action'] === 'asc' && <i className="fa fa-chevron-up"></i>}
                      {query['order_by_state_log_action'] === 'desc' && <i className="fa fa-chevron-down"></i>}
                    </Fragment>
                  ) : (
                    <img src={chervonUpDown} />
                  )}
                </span>
              </div>
            </th>
            <th scope="col" width="100">
              <div className="d-flex justify-content-between" onClick={() => orderBY('order_by_created_at')}>
                <span>등록일</span>
                <span>
                  {query['order_by_created_at'] ? (
                    <Fragment>
                      {query['order_by_created_at'] === 'asc' && <i className="fa fa-chevron-up"></i>}
                      {query['order_by_created_at'] === 'desc' && <i className="fa fa-chevron-down"></i>}
                    </Fragment>
                  ) : (
                    <img src={chervonUpDown} />
                  )}
                </span>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {histories.map((history, key) => (
            <tr key={key}>
              <td className="text-center">{STORE_ROLE[history.store_role]}</td>
              <td className="text-center">
                {history.user_name || history.user_phone_number}
              </td>
              <td className="text-center">
                {BY_STATUS[history.state_log_action]}<br/>by {BY_APP[history.state_log_action]}
              </td>
              <td className="text-center">
                {history.store_state_logs_created_at}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {histories.length === 0 && !loading ? (
        <div className="no-data">기록된 내용이 없습니다.</div>
      ) : null}
    </div>
  )
}

export default HistoryList
