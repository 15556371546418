import React, {memo, useEffect, useState} from "react";
import {useNavigate,useParams } from "react-router-dom";


import {useDispatch} from "react-redux";

import NoData from "../../../assets/images/search_none.svg";



type Props = {
    icon:String,
    text:String
};

const EmptyDataComponent = ({
                                 icon,text
                             }: Props) => {




    return (
        <div className='empty-data-item'>
            <img src={NoData} alt={''} />
            <label>{text}</label>
        </div>
    );
};


EmptyDataComponent.defaultProps = {
    icon:NoData,
    text:'등록된 이벤트가 없습니다.'
};

export default memo(EmptyDataComponent);
