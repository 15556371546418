/*
* Text Input Component
* */
import React, { useEffect, useState, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {randomIdentifier} from "../../../utils/helpers";

type Props = {
    id: String,
    name: String,
    value: any,
    handleChange: Function,
    disabled: boolean,
    title: String,
    subTitle: String,
    image: any,
    imageDesc: String,
    labelClass: String,
    componentClass: String,
    checked: boolean
};


const CheckboxComponent = ({
                                id,
                                name,
                                value,
                                handleChange,
                                disabled,
                               title,
                               subTitle,
                               image,
                               imageDesc,
                               labelClass,
                                componentClass,
                                checked
                            }: Props) => {

    const [isChecked, setIsChecked] = useState(checked);

    useEffect(() => {
        setIsChecked(checked);
    }, [checked])
    const _handleChange = (e) => {
        if(typeof handleChange == 'function') handleChange(e)
        setIsChecked(!isChecked)
    }

    return (
        <label className={"checkbox-component "+ componentClass}>
            <div className="checkbox">
                <input type="checkbox" id={id} name={name} value={value} checked={isChecked} onChange={_handleChange} disabled={disabled}/>
                <span></span>
            </div>
            <div className={"label " + labelClass}>
                {title.length == 0 ? "" : <h4>{title}</h4>}
                {subTitle.length == 0 ? "" : <span>{subTitle}</span>}
                {!image ? "" : image}
                {!imageDesc ? "" : <small>{imageDesc}</small>}
            </div>
        </label>
    );
};

CheckboxComponent.defaultProps = {
    id: "js-"+ randomIdentifier(),
    name: "input-"+ randomIdentifier(),
    value: "",
    handleChange: ()=>{},
    disabled: false,
    title: "",
    subTitle: "",
    image: false,
    imageDesc: false,
    labelClass: "",
    componentClass: "",
    checked: false
};

export default memo(CheckboxComponent);
