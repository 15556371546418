import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import MainLayout from '../../../commons/layout/MainLayout/index';

import HeaderHs2 from '../../../commons/components/Header/headerHs2';

import ROUTERS from '../../../commons/constans/router';
import InfiniteScroll from 'react-infinite-scroll-component';
import API, { ROUTES } from '../../../commons/api/api';

import moment from 'moment';
import SkeletonLoadingComponent from '../../../commons/components/SkeletonLoading';
import Header from '../../../commons/components/Header/header';
import LeftIconHeader from '../../../assets/images/leftIconHeader.svg';
import InputComponent from '../../../commons/components/Input/InputComponent';
import DatimePickerComponent from '../../../commons/components/DatimePicker/DatimePickerComponent';
import ImageBoxComponent from '../../../commons/components/ImageBox/ImageBoxComponent';
import TextAreaComponent from '../../../commons/components/TextArea/TextAreaComponent';
import ButtonComponent from '../../../commons/components/Button/ButtonComponent';
import IconEventClose from '../../../assets/images/event_close.svg';
import { useRole } from '../../../commons/customHooks/Account';
import IconCalender from '../../../assets/images/icon_calender.svg';
import ModalComponent from '../../../commons/components/Modal/ModalComponent';
import Loading from '../../../commons/components/Loading';
import DropDownCommon from '../../../commons/components/Dropdown';
import { nl2br } from '../../../utils/helpers';
import { isAndroid, isBrowser, isChrome, isDesktop, isIOS, isTablet } from 'react-device-detect';

const getBase64Img = async (file) => {
  return new Promise((resolve) => {
    let fileInfo;
    let baseURL = '';
    // Make new FileReader
    let reader = new FileReader();

    // Convert the file to base64 text
    reader.readAsDataURL(file);

    // on reader load somthing...
    reader.onload = () => {
      // Make a fileInfo Object
      // console.log("Called", reader);
      baseURL = reader.result;
      // console.log(baseURL);
      resolve(baseURL);
    };
    // console.log(fileInfo);
  });
};

const PreviewImage = (props) => {
  const [img, setImg] = useState(null);

  let file = props.file;
  let index = props.index;

  getBase64Img(file)
    .then((result) => {
      setImg(result);
    })
    .catch((err) => {
      console.log(err);
    });
  return (
    <div className="image-box-card image-box-card-render">
      <div className="attachment__item attachment-img">
        {props.showRemoveImg ? (
          <img
            className="icon-remove"
            src={IconEventClose}
            onClick={() => props.removeFile(index)}
          />
        ) : (
          ''
        )}
        <div className="input-selector">
          <div className="attachment__item__content">
            <img className="" src={img} />
          </div>
        </div>
      </div>
    </div>
  );
};

const EventCreatePageComponent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [stateDetail, setStateDetail] = useState(0);
  const [fileUpload, setCheckFileUpload] = useState([]);
  const [countCharacterInput, setCountCharacterInput] = useState(0);
  const [countCharacterTextArea, setCountCharacterTextArea] = useState(0);
  const [dataForm, setDataForm] = useState({
    subject: '',
    content: '',
    start_date: moment().toDate(),
    end_date: moment().toDate(),
    attachment: [],
  });
  const [dialogNotice, setDialogNotice] = useState({
    show: false,
    content: '',
    btn: 1,
  });

  // const [date, setDate] = useState(moment().toDate())
  const [date, setDate] = useState(moment().toDate());
  const [minDate, setMinDate] = useState(moment().toDate());
  const [minDateRanger, setMinDateRanger] = useState(moment().toDate());
  const [maxDate, setMaxDate] = useState(moment(minDateRanger));
  const [statusSubmit, setStatusSubmit] = useState(true);
  const [storeList, setStoreList] = useState([]);
  const [chooseTime, setChooseTime] = useState({
    start_date: false,
    end_date: false,
  });
  const [activeDropDown, setActiveDropDown] = useState(0);

  const isUser = useRole('user');
  const isShop = useRole('shop');
  const isManager = useRole('manager');

  const { userInfo } = useSelector((state) => state.authReducers);

  const params = useParams();

  var id = params?.id ? params?.id : '';

  const backMenuHome = () => {
    navigate(ROUTERS.EVENT);
  };

  useEffect(() => {
    // console.log(fileUpload,'fileUpload1111')
    setDataForm({
      ...dataForm,
      attachment: fileUpload,
    });
  }, [fileUpload]);

  useEffect(() => {
    // console.log(fileUpload,'fileUpload1111')
    fetchDataStoreId();
  }, []);

  const fetchDataStoreId = async (
    param = '?approval_state[]=ACTIVED&store_by_user_id=' + userInfo?.id
  ) => {
    try {
      const { data: response } = await API.get(
        ROUTES.API_GET_LIST_STORES + param
      );
      setStoreList(response?.data);
    } catch (error) {
      console.error(error.message);
    }
  };

  const handleChangeInput = (e) => {
    let trimContent = e.target.value.trim();

    setDataForm({
      ...dataForm,
      [e.target.name]: e.target.value,
    });
    if (e?.target?.name == 'subject') {
      setCountCharacterInput(e.target.value.length);
    } else {
      setCountCharacterTextArea(e.target.value.length);
    }
  };

  const handleFocusInput = () => {
      var element = document.getElementById('event-wrapper');
      element.classList.add('pb-50');
  };

  const handleBlurInput = () => {
      var element = document.getElementById('event-wrapper');
      element.classList.remove('pb-50');
  };
  const handleClickInput = () => {};
  const handleChangeTimePicker = (e, typeDate) => {
    if (typeDate == 'start_date') {
      setChooseTime({ ...chooseTime, start_date: true });
      setMinDateRanger(e);
      setDataForm({
        ...dataForm,
        start_date: e,
        end_date: e,
      });
    } else {
      setDataForm({
        ...dataForm,
        end_date: e,
      });
      setChooseTime({ ...chooseTime, end_date: true });
    }
  };
  const handleChangeImage1 = (e) => {
    let fileUploadChange = fileUpload.length > 0 ? fileUpload : [];

    if (e?.file && e?.file.length != 0) {
      // let fileUploadChangeGroup = [...fileUploadChange, ...e.file]
      setCheckFileUpload([...fileUpload, e.file]);
    }
  };
  const handleClickBtn = () => {};
  const handleRemoveImg = (indexImgRemove) => {
    let dataFileUpLoad = fileUpload;
    if (indexImgRemove > -1) {
      dataFileUpLoad.splice(indexImgRemove, 1); // 2nd parameter means remove one item only
    }

    setCheckFileUpload(dataFileUpLoad);
  };
  const removeFileRender = (indexImgRemove) => {
    var dataFileUpLoad = [...fileUpload];

    if (indexImgRemove > -1) {
      dataFileUpLoad.splice(indexImgRemove, 1); // 2nd parameter means remove one item only
      setCheckFileUpload(dataFileUpLoad.length > 0 ? dataFileUpLoad : []);
    }

    // setCheckFileUpload((dataFileUpLoad.length > 0 ? dataFileUpLoad : []))
  };

  const validateForm = () => {
    let statusValidate = true;
    let validateSubject = !dataForm.subject;
    let validateContent = !dataForm.content;
    let errorSet = {};

    let formatStartDate = moment(dataForm.start_date).format('Y-MM-DD');
    let formatEndDate = moment(dataForm.end_date).format('Y-MM-DD');

    if (dataForm.subject.length > 30) {
      statusValidate = false;
      errorSet.subject = '제목은  30자 이내 입력 가능합니다';
    }

    if (validateSubject) {
      statusValidate = false;
      errorSet.subject = '제목 입력해주세요';
    }
    if (activeDropDown == 0) {
      statusValidate = false;
      errorSet.subject = '제목 입력해주세요';
    }
    if (formatStartDate > formatEndDate) {
      statusValidate = false;
      errorSet.subject = 'The start date not more end time';
    }
    // if (dataForm?.content && dataForm.content.length > 1000){
    //     statusValidate = false
    //     errorSet.content='제목은  1000자 이내 입력 가능합니다';
    // }

    if (!chooseTime?.start_date || !chooseTime?.end_date) {
      statusValidate = false;
      errorSet.content = 'Please choose period time';
    }
    // console.log(chooseTime,'chooseTime11',statusValidate)
    // setErrorForm(errorSet)
    return statusValidate;
  };

  const handleSubmitForm = () => {
    const formData = new FormData();

    let formatStartDate = moment(dataForm.start_date).format('Y-MM-DD');
    let formatEndDate = moment(dataForm.end_date).format('Y-MM-DD');

    if (formatStartDate > formatEndDate) {
      setDialogNotice({
        show: true,
        btn: 1,
        content: 'The start date not more end time.',
      });
      return false;
    }

    formData.append('content', nl2br(dataForm.content));
    formData.append('title', dataForm.subject);
    formData.append('start_date', formatStartDate);
    formData.append('end_date', formatEndDate);
    if (isShop || isManager) {
      // formData.append("store_id", userInfo.id);
      formData.append('store_id', activeDropDown);
    }

    if (dataForm.attachment.length > 0) {
      dataForm.attachment.map((item, index) =>
        formData.append('event_image[]', item)
      );
    }

    let validateStatus = validateForm();

    if (validateStatus && statusSubmit) {
      setStatusSubmit(false);
      API.post(ROUTES.API_EVENT, formData)
        .then((res) => {
          // setStatusSubmit(false)
          setStatusSubmit(true);
          navigate(ROUTERS.EVENT);
        })
        .catch((e) => {
          setStatusSubmit(true);
          let errors = e.response;
          let dataError = {};

          switch (errors.status) {
            case 422:
              if (errors.data && errors.data.errors) {
                Object.keys(errors.data.errors).map(function (key, index) {
                  dataError[key] = errors.data.errors[key][0];
                });
                setDialogNotice({
                  show: true,
                  btn: 1,
                  content:
                    '기존에 등록한 이벤트와 일정이 겹칩니다.\n' +
                    '일정 확인해 주세요.\n',
                });
              }
              break;
            case 404:
              setDialogNotice({
                show: true,
                btn: 1,
                content:
                  '가입되어 있지 않은 회원 정보입니다.\n' +
                  '다시 확인해주세요.\n',
              });
          }
        });
    }
  };

  const handleCancelBtn = () => {
    // setStatusModal(false)
    setDialogNotice({ ...dialogNotice, show: false });
  };
  const handleSubmitBtn = () => {};
  const chooseStore = (e) => {
    setActiveDropDown(e?.id);
  };

  return (
    <div>
      {isShop || isManager ? (
        <MainLayout pageID={'event-create-page'}>
          <Header
            leftChild={LeftIconHeader}
            handleLeftClick={() => backMenuHome()}
            leftIcon={true}
            centerChild={'나'}
            centerIcon={false}
            rightChild={false}
            rightIcon={false}
          />

          <div className="wrapper" id='event-wrapper'>
            <div className="item-choose-store">
              <DropDownCommon
                dataList={[{ id: 0, store_name: '매장명' }, ...storeList]}
                nameDisplay={'매장명'}
                handleClick={chooseStore}
                keyName={'store_name'}
                idActive={activeDropDown}
              />
            </div>
            {!statusSubmit ? <Loading /> : ''}
            <div className="form-submit">
              <div
                className={`item-group required item-group-title ${
                  dataForm.subject != '' ? 'has-value' : 'no-value'
                }`}
              >
                <InputComponent
                  label={'이벤트 문구'}
                  isRequired={''}
                  errorMsg={''}
                  placeHolder={'30자 이하의 문구를 입력해주세요.'}
                  id={''}
                  name={'subject'}
                  value={dataForm.subject}
                  handleChange={handleChangeInput}
                  btnClass={''}
                  btnLabel={''}
                  handleFocus={handleFocusInput}
                  handleBlur={handleBlurInput}
                  btnHandleClick={''}
                  appendContent={''}
                  disabled={''}
                  readonly={''}
                  pattern={''}
                  type={'text'}
                  autoComplete={''}
                  searchIcon={false} //IconSearch
                  timeCountDown={''} // '3:00' || false
                  maxLength={'30'}
                  characterLenght={
                    countCharacterInput == 0 ? '0' : countCharacterInput
                  }
                  borderBottom={true}
                  deleteIcon={''}
                  wrapClass={'required-label'}
                />
              </div>
              <div className="item-group required">
                <label className="label-form">이벤트 기간</label>
                <div className="item-group__ranger-time d-flex align-items-center">
                  <div className="item-time">
                    {/*<DatimePickerComponent value={''} handleChange={(e) => handleChangeTimePicker(e)}/>*/}
                    <div className="item-calender-component">
                      <DatimePickerComponent
                        value={date}
                        handleChange={(e) =>
                          handleChangeTimePicker(e, 'start_date')
                        }
                        minDate={minDate}
                        // maxDate={maxDate}
                      />
                      <img
                        className={'item-calender-component__icon'}
                        src={IconCalender}
                      />
                      {!chooseTime?.start_date ? (
                        <label className="placeholder">시작날짜</label>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                  <label>~</label>
                  <div className="item-time">
                    {/*<DatimePickerComponent value={''} handleChange={(e) => handleChangeTimePicker(e)}/>*/}
                    <div className="item-calender-component">
                      <DatimePickerComponent
                        value={minDateRanger}
                        handleChange={(e) =>
                          handleChangeTimePicker(e, 'end_date')
                        }
                        minDate={minDateRanger}
                        // maxDate={maxDate}
                      />
                      <img
                        className={'item-calender-component__icon'}
                        src={IconCalender}
                      />
                      {!chooseTime?.end_date ? (
                        <label className="placeholder">종료날짜</label>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className='item-group required'>
                            <label className="label-form">이벤트 기간</label>
                            <DropDownCommon
                                dataList={[{id:0,store_name:'choose option'},...storeList]}
                                nameDisplay={'사업자 유형'}
                                handleClick={chooseStore}
                                keyName={'store_name'}
                                idActive={activeDropDown}
                            />
                        </div> */}

              <div className="item-group item-group-upload">
                <label className="label-form">이벤트 사진(최대 3장)</label>
                <div className="card-upload d-flex align-items-center">
                  {fileUpload.length > 0 &&
                    fileUpload.map((item, index) => (
                      <PreviewImage
                        file={item}
                        key={index}
                        index={index}
                        showRemoveImg={true}
                        removeFile={(index) => removeFileRender(index)}
                      />
                    ))}
                  {fileUpload.length < 3 ? (
                    <ImageBoxComponent
                      nameFile={'attachment1'}
                      handleChange={(e) => handleChangeImage1(e)}
                      showRemoveImg={true}
                      classCustom={''}
                      removeImg={(e) => handleRemoveImg(e)}
                      textCard={`이벤트 설명사진${fileUpload.length + 1} 등록`}
                      indexImg={0}
                      imgNotShow={true}
                    />
                  ) : (
                    ''
                  )}
                </div>
              </div>
              <div className="item-group ">
                <TextAreaComponent
                  placeHolder={'이벤트 내용을 입력해주세요.'}
                  contCharacter={countCharacterTextArea != 0 ? true : false}
                  label={'이벤트 내용'}
                  name={'content'}
                  handleChange={handleChangeInput}
                  value={dataForm.content}
                  fadeLastCharacter={true}
                />
              </div>

              <div className="item-group ">
                <ButtonComponent
                  label={'이벤트 등록하기'}
                  style={{ width: '100%' }}
                  disabled={validateForm() ? false : true}
                  cssClass={'mb-2'}
                  handleClick={handleSubmitForm}
                  idBtn="btn-create"
                  fixedBtn={true}
                />
              </div>
            </div>
          </div>
          <ModalComponent
            id={``}
            show={dialogNotice?.show}
            title={`알림`}
            cancelBtn={`취소`}
            submitBtn={dialogNotice?.btn == 2 ? `확인` : ''}
            handleCancel={handleCancelBtn}
            handleSubmit={handleSubmitBtn}
          >
            <div>{dialogNotice?.content}</div>
          </ModalComponent>
        </MainLayout>
      ) : (
        ''
      )}
    </div>
  );
};

export default EventCreatePageComponent;
