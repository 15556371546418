import React from 'react';
import { useSelector } from 'react-redux';
import ForgotPasswordComponent from '../components/forgot_password';

import * as action from '../redux/actions';

const ForgotPwPageContainer  = () => {
  console.log('ForgotPwPageContainer ');
  const { isProcessing,type } = useSelector((state) => state.authReducers);
  return <ForgotPasswordComponent
      isProcessing={isProcessing}
      type={type}
  />;
};

export default ForgotPwPageContainer ;
