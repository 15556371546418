import React from 'react';
import BannerComponent from '../../../../commons/components/Banner/BannerComponent';
import StoreAddress from '../../../../assets/images/phone_store.svg';
import StorePhone from '../../../../assets/images/address_store.svg';
import moment from 'moment';
import { formatTimeZone, getHoursMinus, priceFormat } from '../../../../utils/helpers';
type Props = {
  detailStore: Array,
  banner: Array,
  isProcessingDetailStore: Boolean
};

const StoreInfomation = ({ detailStore, banner, isProcessingDetailStore }: Props) => {

  const handleBackClick = () => {

  }

  const renderDay = (day) => {
    let text = '';
    switch (day) {
      case 0:
        text = '월요일'
        break;
      case 1:
        text = '화요일'
        break;
      case 2:
        text = '수요일'
        break;
      case 3:
        text = '목요일'
        break;
      case 4:
        text = '금요일'
        break;
      case 5:
        text = '토요일'
        break;
      case 6:
        text = '일요일'
        break;
      default:
        break;
    }
    return text;
  }

  const renderStoreMenu = (storeMenu) => {
    if (storeMenu && storeMenu.length > 0) {
      return storeMenu.map((item, idx) => {
        return (
          <li className="item-menus" key={idx}>
            <div className="content-left">
              <p className="name">{item.name}</p>
              <p className="price">{priceFormat(item.price)}원</p>
            </div>
              {
                item?.photo?.url_thumbnail && 
                <div className="content-right">
                  <img className="img-menu" src={item?.photo?.url_thumbnail || ''}></img>
                </div>
              }
          </li>
        )
      })
    }else{
      <li className="item-menus">
        
      </li>
    }
  }

  const renderWorkingTime = (workingTime) => {
    return workingTime.map((item, idx) => (
      
      <div className={`time`} key={idx}>
        <span>{renderDay(item.day)}</span>
        {
          item.state === 'Y' ? <>
            <span>{getHoursMinus(item.starttime)}</span>
            <span> ~ </span>
            <span>{getHoursMinus(item.endtime)}</span>
          </> : '정보없음'
        }
        
      </div>
    ))
  }

  const renderClsBaner = (lenght) => {
    let cls = '';
    switch (lenght) {
      case 1:
        cls="banner-store-info";
        break;
      case 3:
        cls="banners-store";
        break;
      default:
        cls="";
        break;
    }
    return cls;
  }

  return (
    <div className="store-info">
      <BannerComponent
        banner={banner}
        handleBackClick={handleBackClick}
        iconLeft={false}
        cssClass={renderClsBaner(banner.length)}
      />
      <div className="store-info__box-address">
        <h2>{detailStore?.store_name || ''}</h2>
        <div className="store-content">
          <div className="store-address">
            <img src={StoreAddress} />
            <span>{detailStore.store_address}</span>
          </div>
          <div className="store-phone">
            <img src={StorePhone} />
            <span>{detailStore.store_phone}</span>
          </div>
        </div>
      </div>
      <div className="store-info__box_content">
        <div className="beacon">
          <label>Beacon 설치</label>
          <p>{detailStore?.beacon_state === 'N' ? '미운영' : '운영'}</p>
          <label>NFC 설치 </label>
          <p>{detailStore?.nfc_state === 'N' ? '미운영' : '운영'}</p>
          <label>키오스크 설치</label>
          <p>{detailStore?.kiosk_state === 'N' ? '미운영' : '운영'}</p>
        </div>
        <div className="operating">
          <label className="title">운영시간 정보</label>
          {/* <div className="time">
            <span>{renderDay(6)}</span>
            <span>{getHoursMinus(detailStore.working_times[6].starttime)}</span>
            <span> ~ </span>
            <span>{getHoursMinus(detailStore.working_times[6].endtime)}</span>
          </div> */}
          {/* <div className={`time ${item.day == 6 ? 'd-none' : ''}`} key={idx}></div> */}
          {
            renderWorkingTime(detailStore.working_times)
          }
        </div>
        <div className="menu-info">
          <label>메뉴정보</label>
          <ul>
            {renderStoreMenu(detailStore.store_menu)}
          </ul>
        </div>
        <div className="store-introduction">
          <label>매장 소개</label>
          
          <div className="content" dangerouslySetInnerHTML={{__html: detailStore?.store_description || ''}}>
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default StoreInfomation;
