import ROUTERS from "./router";

const menuItems = [
  {
    id: 1,
    name: 'HOME', //'(가맹점 조회)'
    icon: '',
    action: '',
    public: false,
    role: ['shop', 'manager'],
    toUrl: ROUTERS.MAIN,
  },
  {
    id: 2,
    name: '운영관리',
    icon: '',
    action: '',
    public: false,
    role: ['shop', 'manager'],
    toUrl: ROUTERS.OPERATION,
  },
  {
    id: 3,
    name: '공지사항',
    icon: '',
    action: '',
    role: ['user','shop', 'manager '],
    public: true,
    toUrl: ROUTERS.NOTIFICATION,
  },
  {
    id: 4,
    name: '진행 이벤트',
    icon: '',
    action: '',
    public: true,
    role: ['user','shop','manager'],
    toUrl: ROUTERS.EVENT_INPROGRESS,
  },

  {
    id: 5,
    name: '가맹점 등록',
    icon: '',
    action: '',
    public: true,
    role: ['user', 'manager', 'shop'],
    toUrl: ROUTERS.STORE,
  },
  {
    id: 6,
    name: '가맹점 정보관리',
    icon: '',
    action: '',
    public: false,
    role: ['manager', 'shop'],
    toUrl: ROUTERS.STORE_LIST_MANAGER,
  },
  {
    id: 7,
    name: '매니저관리',
    icon: '',
    action: '',
    public: false,
    role: ['manager', 'shop'],
    toUrl: ROUTERS.MANAGER,
  },
  {
    id: 8,
    name: '이벤트 관리',
    icon: '',
    action: '',
    public: false,
    role: ['shop'],
    toUrl: ROUTERS.EVENT,
  },



];

export default menuItems;
