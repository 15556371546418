import { put, call, takeLatest } from 'redux-saga/effects';

import * as actionCreators from '../redux/actions';
import * as actionTypes from '../redux/actions/type';
import API, { ROUTES } from "../../../commons/api/api";
import {removeAllStorage} from "../../../utils/helpers";

function* logOut(action) {
  try {
    removeAllStorage();
    let data = {
      device_uuid : window?.appDevice?.deviceUuid || '',
      device_os : window?.appDevice?.deviceOs || '',
    };
    const response = yield call(() =>
      API.post(ROUTES.API_LOGOUT,data)
    );
    if (response.status == 200) {
      yield put(actionCreators.logOutSuccess(response));
    }else{
      yield put(actionCreators.logOutFaild());
    }
    
  } catch (error) {
    
  }
}

function* logOutSaga() {
  yield takeLatest(actionTypes.LOGOUT_REQUEST, logOut);
}

export default logOutSaga;
