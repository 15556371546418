/*
 * Text Input Component
 * */
import React, { useEffect, useState, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { randomIdentifier } from '../../../utils/helpers';

type Props = {
  label: String,
  isRequired: boolean,
  errorMsg: any,
  placeHolder: String,
  id: string,
  name: string,
  value: String,
  btnClass: String,
  btnLabel: String,
  appendContent: any,
  disabled: boolean,
  readonly: boolean,
  pattern: String,
  type: String,
  autoComplete: String,
  maxLength: String,
  searchIcon: any,
  timeCountDown: String,
  borderBottom: Boolean,
  characterLenght: String,
  deleteIcon: String,
  handleChange: Function,
  handleFocus: Function,
  handleBlur: Function,
  handleClick: Function,
  btnHandleClick: Function,
  btnHandleDelete: Function,
  wrapClass: String,
  labelClass: String,
  inputClass: String,
  handleSearchIpt: Function,
  textRight: String,
  numberType: String
};

const InputComponent = ({
  label,
  isRequired,
  errorMsg,
  placeHolder,
  id,
  name,
  value,
  handleChange,
  handleFocus,
  handleBlur,
  handleClick,
  btnClass,
  btnLabel,
  btnHandleClick,
  btnHandleDelete,
  appendContent,
  disabled,
  readonly,
  pattern,
  type,
  autoComplete,
  maxLength,
  searchIcon,
  timeCountDown,
  borderBottom,
  characterLenght,
  deleteIcon,
  wrapClass,
  labelClass,
  inputClass,
  handleSearchIpt,
  btnDisabled,
  textRight,
  numberType
}: Props) => {
  const [text, setText] = useState(value);
  useEffect(() => {
    setText(value);
  }, [value]);

  const _handleChange = (e) => {
    if (typeof handleChange == 'function') handleChange(e);
    let patternPhone =  /[^\d-]/g;
    let patternPrice =  /[^\d,]/g;
    let value = e.target.value;
    switch (numberType) {
      case 'phone-number':
        value = value.replace(patternPhone, '');
        break;
      case 'price-number':
        value = value.replace(patternPrice, '');
        break;
      default:
        value = value;
        break;
    }
    if (maxLength && +maxLength > 0) {
      if (value.length <= +maxLength) {
        setText(value);
      }else{
        return
      }
    }else{
      setText(value);
    }

  };


  return (
    <div className={`input-component ${wrapClass}`}>
      {label && <label className={isRequired ? `required ${labelClass}` : `${labelClass}`}>{label}</label>}
      <div className={`${borderBottom ? 'input-border-bottom' : 'input-group'}`}>
        <input
          className={
            `${!borderBottom ? `form-control ${inputClass}` : errorMsg.length != 0 ? `form-control-error ${inputClass}` : `${inputClass}`}`
          }
          placeholder={placeHolder}
          value={text}
          id={id}
          name={name}
          onChange={_handleChange}
          onBlur={handleBlur}
          onClick={handleClick}
          onFocus={handleFocus}
          readOnly={readonly}
          disabled={disabled}
          pattern={pattern}
          type={type}
          autoComplete={autoComplete}
          maxLength={maxLength}
        />
        {
            searchIcon && <img className='img-search' src={searchIcon} onClick={handleSearchIpt} />
        }
        {
          textRight && <span className='text-right'>{textRight}</span> 
        }
        {
            timeCountDown && <span className='time-count-down'>{timeCountDown}</span>
        }
        {
            maxLength && characterLenght && 
            <div className='character'>
                <span className='character__lenght'>{characterLenght}</span>
                <span className='seperate'>/</span> 
                <span className='character__max-lenght'>{maxLength}</span>
            </div>
        }

        {
            deleteIcon && <img className='img-delete' src={deleteIcon} onClick={btnHandleDelete}/>
        }
        {btnLabel.length == 0 ? (
          ''
        ) : (
          <button
            type="button"
            className={btnClass}
            onClick={btnHandleClick}
            disabled={btnDisabled}
          >
            {btnLabel}
          </button>
        )}
        {!appendContent ? '' : appendContent}
      </div>
      {errorMsg.length == 0 ? (
        ''
      ) : (
        <label className="input-error show">{errorMsg}</label>
      )}
    </div>
  );
};

InputComponent.defaultProps = {
  isRequired: false,
  btnHandleClick: () => {},
  handleChange: () => {},
  handleFocus: () => {},
  handleBlur: () => {},
  handleClick: () => {},
  value: '',
  id: 'js-' + randomIdentifier(),
  name: 'input-' + randomIdentifier(),
  errorMsg: '',
  placeHolder: '',
  btnClass: '',
  btnLabel: '',
  appendContent: false,
  disabled: false,
  readonly: false,
  type: 'text',
  autoComplete: 'on',
  maxLength: '255',
  searchIcon: false,
  wrapClass: '',
  labelClass: '',
  inputClass: '',
  btnDisabled : false,
  textRight: '',

};

export default memo(InputComponent);
