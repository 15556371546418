import { put, call, takeLatest } from 'redux-saga/effects';

import * as actionCreators from '../redux/actions';
import * as actionTypes from '../redux/actions/type';
import API, { ROUTES } from "../../../commons/api/api";
import {rememberMeLogin} from "../../../utils/helpers";


function* logInSns(action) {
    try {
        const response = yield call(() =>
            API.post(ROUTES.API_AUTH_SNS_LOGIN, action.payload)
        );
        if (response.status == 200) {
            rememberMeLogin(true,response.data.token);
            localStorage.setItem('sns_type',action.payload.sns_type);

            if(response.data && response.data.sign_up){
                localStorage.setItem('sign_up',true);
            }

            yield put(actionCreators.loginSnsSuccess(response));
        }else{
            yield put(actionCreators.loginSnsFaild());
        }
    } catch (error) {
        // alert(error.response.status)
        yield put(actionCreators.loginSnsFaild())
    }
}

function* logInSnsSaga() {
    yield takeLatest(actionTypes.LOGIN_SNS_REQUEST, logInSns);
}

export default logInSnsSaga;