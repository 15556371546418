
import ROUTERS from '../../commons/constans/router';
import React, { lazy } from 'react';


const StoreRegisterComponent = lazy(() => import('./containers/StoreRegisterPageContainer'));
const StoreRulesComponent = lazy(() => import('./components/StoreRules/StoreRulesComponent'));
const StoreInfomationComponent = lazy(() => import('./containers/StoreInfomationPageContainer'));
const StoreUpdateInfomationComponent = lazy(() => import('./containers/StoreUpdatePageContainer'));

const ListStoreComponent = lazy(() => import('./components/ListStore/ListStore'));
const IframeComponent = lazy(() => import('../../commons/components/IframePage/IframeComponent'));
const RulesComponent = lazy(() => import('../../commons/components/Rules/RulesComponent'));

const route = [
  {
    path: ROUTERS.STORE_REGISTER_STEP,
    title: 'Store',
    exact: true,
    public: false,
    roles: ['user', 'manager', 'shop'],
    component: <StoreRegisterComponent />,
  },
  {
    path: ROUTERS.STORE_RULE,
    title: 'Store rules',
    exact: true,
    public: false,
    roles: ['user', 'manager', 'shop'],
    component: <StoreRulesComponent />,
  },
  {
    path: ROUTERS.STORE_LIST_MANAGER,
    title: 'Store List Manager',
    exact: true,
    public: false,
    roles: ['manager', 'shop'],
    component: <ListStoreComponent />,
  },
  {
    path: ROUTERS.STORE_INFOMATION,
    title: 'Store Infomation',
    exact: true,
    public: false,
    roles: ['manager', 'shop'],
    component: <StoreInfomationComponent />,
  },
  {
    path: ROUTERS.STORE_UPDATE,
    title: 'Store Update Infomation',
    exact: true,
    public: false,
    roles: ['manager', 'shop'],
    component: <StoreUpdateInfomationComponent />,
  },
  {
    path: ROUTERS.RULES_DETAIL,
    title: 'Rules',
    exact: true,
    component: <RulesComponent />,
  },
];

export default route;
