import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import {
  createManagerRequest, getManagersRequest,
} from '../../../pages/Manager/redux/actions';
import API, {ROUTES} from '../../api/api'

export const useRegisterManager = () => {
  const dispatch = useDispatch();
  const { isProcessing, createSuccess, createFaild, status } = useSelector(state => state.managerReducers);

  const handleSubmit = values => {
    console.log(values, 'handleSubmit');
    dispatch(createManagerRequest(values));
  };

  return [isProcessing, handleSubmit, createSuccess, createFaild, status];
};


export const useListManagers = (params) => {
  const dispatch = useDispatch();
  const { listManagers, isProcessing } = useSelector((state) => state.managerReducers);

  useEffect(() => {
    dispatch(getManagersRequest(params));
  }, [params, getManagersRequest]);
  
  return [listManagers, isProcessing]
};
