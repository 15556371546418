import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import rootReducer from './rootReducers';
import { createLogger } from 'redux-logger';
import storage from 'redux-persist/lib/storage'
import rootSagas from './rootSagas';
import { persistStore, persistReducer } from 'redux-persist'

export default () => {
  
  const middleware = [];
  const persistConfig = {
    key: 'root',
    storage,
    blacklist: ['authReducers', 'storeReducers']
  }
  // if(process.env.REACT_APP_DEBUG_LOG === "true"){
  //   const logger = createLogger();
  //   middleware.push(logger);
  // }


  const sagaMiddleware = createSagaMiddleware();
  middleware.push(sagaMiddleware);

  const persistedReducer = persistReducer(persistConfig, rootReducer())
  // const store = createStore(persistedReducer, composeEnhancers(...enhancers));
  
  const store = configureStore({
    reducer: persistedReducer,
    middleware,
  });
  const persistor = persistStore(store);
  
  //run saga
  sagaMiddleware.run(rootSagas)
  return {store, persistor};
}

