import React, { useEffect, useState } from 'react';
import Header from '../../../../commons/components/Header/header';
import LeftIconHeader from '../../../../assets/images/leftIconHeader.svg';
import MainLayout from '../../../../commons/layout/MainLayout';
import { useNavigate  } from "react-router-dom";
import ROUTERS from '../../../../commons/constans/router';
import { useListStores } from '../../../../commons/customHooks/Store';
import { useUser } from '../../../../commons/customHooks/Account';


const ListStoreComponent = () => {
  const navigate = useNavigate();
  const { user } = useUser();
  const [paramsListStores, ] = useState({
    store_by_user_id: user.id,
    'approval_state[]': 'ACTIVED'
  })
  const [listStores, isProcessing] = useListStores(paramsListStores);

  const handleLeftClick = () => {
    navigate(ROUTERS.MAIN);
  };

  const goToDetailStore = (id) => {
    navigate(ROUTERS.STORE_INFOMATION_PATH(id));
  }

  const renderListStore = () => {
    if (listStores && listStores.length > 0) {
       return listStores.map((item, idx) => (
        <li className='item' key={idx} onClick={() => goToDetailStore(item.id)}>
          <a href='javascript:void(0)'>{item.store_name}</a>
          <button type="button" className='btn '>상세 보기</button>
        </li>
       ))
    }
  }

  return (
    <MainLayout pageID={'store-list-page'}>
      <Header
        leftChild={LeftIconHeader}
        handleLeftClick={handleLeftClick}
        leftIcon={true}
        centerChild={'설정'}
        centerIcon={false}
        rightChild={false}
        rightIcon={false}
      />
      <div className="wrapper">
        <ul className='store-list'>
          {renderListStore()}
        </ul>
      </div>
    </MainLayout>
  );
};

export default ListStoreComponent;
