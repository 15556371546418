import { memo, useEffect, useState } from 'react';
import DropDownCommon from '../../../../commons/components/Dropdown';
import { dataHoursArr, dataMinusArr } from '../../../../commons/constans/store';
import { formatTimeForType } from '../../../../utils/helpers';

type Props = {
  handleChangeEndTime: Function,
  dataEndtime: String,
  dataDay: Number,
  min: String,
};

const SelectEndTimeComponent = ({
  handleChangeEndTime,
  dataEndtime,
  dataDay,
  min,
}: Props) => {
  const [endtime, setEndtime] = useState({
    day: dataDay,
    timeHours:
      formatTimeForType(dataEndtime, 'H') == '00'
        ? '23'
        : formatTimeForType(dataEndtime, 'H'),
    timeMinus: formatTimeForType(dataEndtime, 'M'),
  });

  const [dataHours, setDataHours] = useState(dataHoursArr);
  const [dataMinus, setDataMinus] = useState(dataMinusArr);

  useEffect(() => {
    setEndtime({
      day: dataDay,
      timeHours:
        formatTimeForType(dataEndtime, 'H') == '00'
          ? '23'
          : formatTimeForType(dataEndtime, 'H'),
      timeMinus: formatTimeForType(dataEndtime, 'M'),
    });
  }, [dataEndtime, dataDay]);

  useEffect(() => {
    let hour = formatTimeForType(min, 'H');
    let minus = formatTimeForType(min, 'M');

    const _dataHoursArr = dataHoursArr.map((item, index) => {
      if (+item.text < +hour) {
        return {
          ...item,
          disable: true,
        };
      }
      return item;
    });

    const _dataMinusArr = dataMinusArr.map((item, index) => {
      if (+item.text < +minus && +endtime.timeHours <= hour) {
        return {
          ...item,
          disable: true,
        };
      }
      return item;
    });

    setDataHours(_dataHoursArr);
    setDataMinus(_dataMinusArr);
  }, [min]);

  const changeTime = (e, day, time) => {
    let hour = formatTimeForType(min, 'H');
    let minus = formatTimeForType(min, 'M');
    if (time == 'timeHours' && +e.text === +hour) {
      const _dataMinusArr = dataMinusArr.map((item, index) => {
        if (+item.text <= +minus + 1) {
          return {
            ...item,
            disable: true,
          };
        }
        return item;
      });
      setDataMinus(_dataMinusArr);

      if (+minus == 59) {
        setEndtime({
          ...endtime,
          day: day,
          timeHours: +e.text + 1,
          timeMinus: '00',
        });
        const _dataMinusArr = dataMinus.map((item, index) => {
          if (item.disable) {
            return {
              ...item,
              disable: false,
            };
          }
          return item;
        });

        const _dataHoursArr = dataHoursArr.map((item, index) => {
          if (+item.text <= +hour) {
            return {
              ...item,
              disable: true,
            };
          }
          return item;
        });

        setDataMinus(_dataMinusArr);
        setDataHours(_dataHoursArr);
      } else {
        setEndtime({
          ...endtime,
          day: day,
          timeHours: e.text,
          timeMinus: +minus + 1,
        });
      }
    } else {
      if (time == 'timeMinus' && +hour === +endtime.timeHours) {
        console.log('chọn phut && giờ bằng nhau => disable minus nhỏ hơn');
        const _dataMinusArr = dataMinusArr.map((item, index) => {
          if (+item.text < +minus) {
            return {
              ...item,
              disable: true,
            };
          }
          return item;
        });
        setDataMinus(_dataMinusArr);
      } else {
        console.log('reset minus disable');
        const _dataMinusArr = dataMinusArr.map((item, index) => {
          return {
            ...item,
            disable: false,
          };
        });
        setDataMinus(_dataMinusArr);
      }

      setEndtime({
        ...endtime,
        day: day,
        timeHours: time == 'timeHours' ? e.text : endtime.timeHours,
        timeMinus: time == 'timeMinus' ? e.text : endtime.timeMinus,
      });
    }
  };

  useEffect(() => {
    let endtimeStr = `${endtime.timeHours}:${endtime.timeMinus}`;
    let data = {
      day: endtime.day,
      endtime: endtimeStr,
    };
    handleChangeEndTime(data);
  }, [endtime]);

  return (
    <>
      <DropDownCommon
        dataList={[...dataHours]}
        nameDisplay={formatTimeForType(dataEndtime, 'H')}
        handleClick={(e) => changeTime(e, dataDay, 'timeHours')}
        keyName={'text'}
        idActive={endtime?.timeHours || 0}
        orderBy={'DESC'}
      />
      <span>:</span>
      <DropDownCommon
        dataList={[...dataMinus]}
        nameDisplay={formatTimeForType(dataEndtime, 'M')}
        handleClick={(e) => changeTime(e, dataDay, 'timeMinus')}
        keyName={'text'}
        idActive={endtime?.timeMinus || 0}
      />
    </>
  );
};

export default memo(SelectEndTimeComponent);
