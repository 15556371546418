import PropTypes from 'prop-types'
import {
  Map as KakaoMap,
  MapMarker
} from 'react-kakao-maps-sdk'

function Map({
  lat,
  lng,
  dragEnd,
}) {
  const _dragEnd = (e) => {
    let location = e.getPosition()
    dragEnd({
      lat: location.Ma,
      lng: location.La,
    })
  }

  return (
    <KakaoMap
      center={{
        lat,
        lng,
      }}
      style={{
        width: "100%",
        height: "300px",
      }}
      level={3}
    >
      <MapMarker
        position={{
          lat,
          lng,
        }}
        draggable
        onDragEnd={_dragEnd}
      />
    </KakaoMap>
  );
}

export default Map

Map.defaultProps = {}

Map.propTypes = {
  lat: PropTypes.number,
  lng: PropTypes.number,
  dragEnd: PropTypes.func,
}
