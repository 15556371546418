

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILD = 'LOGIN_FAILD';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILD = 'LOGOUT_FAILD';

export const RESET_STATE = 'RESET_STATE';


export const UPDATE_SUCCESS = 'UPDATE_SUCCESS';

export const UPDATE_CHILDREN_PROFILE_SUCCESS = 'UPDATE_CHILDREN_PROFILE_SUCCESS';

export const UPDATE_NOTIFICATION_SETTING_SUCCESS = 'UPDATE_NOTIFICATION_SETTING_SUCCESS';



export const LOGIN_SNS_REQUEST = 'LOGIN_SNS_REQUEST';
export const LOGIN_SNS_SUCCESS = 'LOGIN_SNS_SUCCESS';
export const LOGIN_SNS_FAILD = 'LOGIN_SNS_FAILD';


export const GET_CURRENT_USER = 'GET_CURRENT_USER';

export const IS_PROCESSING = 'IS_PROCESSING';

export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCSESS = 'UPDATE_USER_SUCSESS';

export const UPDATE_USER_LOCAL = 'UPDATE_USER_LOCAL';


