import { useRef, useState } from "react";
import moment from 'moment'
import MainLayout from "../../../commons/layout/MainLayout/index";
import Header from "../../../commons/components/Header/header";
import LogoIcon from '../../../assets/images/logo.svg'
import IconCalender from "../../../assets/images/icon_calender.svg";
import LeftIconHeader from "../../../assets/images/leftIconHeader.svg";
import { openMenu } from "../../Main/redux/actions";
import { useDispatch } from "react-redux";
import InputComponent from "../../../commons/components/Input/InputComponent";
import IconSearch from "../../../assets/images/iconSearch.svg"
import TextAreaComponent from "../../../commons/components/TextArea/TextAreaComponent";
import ToggleSwitchComponent from "../../../commons/components/ToggleSwitch/ToggleSwitchComponent";
import ItemInListComponent from "../../../commons/components/ItemInList/ItemInListComponent";
import ImageBoxComponent from "../../../commons/components/ImageBox/ImageBoxComponent";

import DatimePickerComponent from "../../../commons/components/DatimePicker/DatimePickerComponent";

import DropDownCommon from "../../../commons/components/Dropdown";
import ImageBoxAddMoreComponent from "../../../commons/components/ImageBox/ImageBoxAddMoreComponent";

const CommonComponent = ({}: props) => {
  const dispatch = useDispatch();

  const [stateUpload, setStateUpload] = useState(false)
  const [modalState, setModalState] = useState(false)
  const [activeDropDown, setActiveDropDown] = useState(0)
  const [imgItem, setImgItem] = useState('https://enjoyworks-playmoongu.s3.ap-northeast-2.amazonaws.com/iot_np/medium/store/6/store_1663293233951407.jfif')

  const [date, setDate] = useState(moment().toDate())
  const [minDate, setMinDate] = useState(moment().subtract(3, "days").toDate())
  const [maxDate, setMaxDate] = useState(moment().add(3, "days").toDate())

  const handleRightClick = () => {
    dispatch(openMenu(true));
  }

  const handleLeftClick = () => {
    console.log('handleLeftClick');
  }
  const handleChangeSwith = (e) => {
    console.log(e,'852')
  }
  const handleChangeImage = (e) => {
    console.log(e,'handleChangeImage')
  }
  const handleChangeImage1 = (e) => {
    console.log(e,'handleChangeImage1')
  }
  const handleRemoveImg = (e) => {
    console.log(e,'handleRemoveImg')
  }
  const onChangeDatePicker = (e) => {
    console.log(e,'onChangeDatePicker')
  }
  const handleChangeTimePicker = (e) => {
    console.log(e,'handleChangeTimePicker')
    setDate(e)
  }
  const handleClickConfirmUpload = (e) => {
    console.log(e,'handleClickConfirmUpload',!e)
    if(!e){
      setModalState(true)
    }
  }
  const handleConfirmModal = () => {
    setStateUpload(true)
  }
  const customRef = useRef(null);
  var dataList = [{id:1,text:'(사용자)개인정보 수집∙이용동의',
    url_medium:'https://enjoyworks-playmoongu.s3.ap-northeast-2.amazonaws.com/iot_np/medium/store_event/10/store_event_1663246842897831.jpg'}
    ,{id:2,text:'(사용자)개인정보 수집∙이용동의2',url_medium:'https://enjoyworks-playmoongu.s3.ap-northeast-2.amazonaws.com/iot_np/medium/store_event/10/store_event_1663249848734704.jfif'},
    {id:3,text:'(사용자)개인정보 수집∙이용동의22',url_medium:''},
    {id:4,text:'(사용자)개인정보 수집∙이용동의23',url_medium:''},
    {id:5,text:'(사용자)개인정보 수집∙이용동의24',url_medium:''},
    {id:6,text:'(사용자)개인정보 수집∙이용동의25',url_medium:''},
    {id:7,text:'(사용자)개인정보 수집∙이용동의26',url_medium:''},
    {id:8,text:'(사용자)개인정보 수집∙이용동의27',url_medium:''},
    {id:9,text:'(사용자)개인정보 수집∙이용동의28',url_medium:''},
  ]
  var dataListTime = [{id:1,text:'10'},{id:2,text:'20'}]

  const changeType = (e) => {
    console.log('changeType',e)
    setActiveDropDown(e.id)
  }

  return (
    <MainLayout
      pageID={"common-page"} // pageID
    >
      {/* header */}
      {/* <Header
        leftChild={LogoIcon}
        leftIcon={true}
        centerChild={'운영관리'}
        centerIcon={false}
        rightChild={HamburgerIcon}
        rightIcon={true}
        handleRightClick={handleRightClick}
      /> */}
      {/* <Header
        leftChild={LeftIconHeader}
        handleLeftClick={handleLeftClick}
        leftIcon={true}
        centerChild={'회원가입'}
        centerIcon={false}
        rightChild={false}
        rightIcon={false}
        handleRightClick={handleRightClick}
      /> */}

      <Header
        leftChild={LeftIconHeader}
        handleLeftClick={handleLeftClick}
        leftIcon={true}
        centerChild={'회원가입'}
        centerIcon={false}
        rightChild={'편집'}
        rightIcon={false}
        handleRightClick={handleRightClick}
      />

      <div className="wrapper">
        {/*<InputComponent */}
        {/*  label = {'label'}*/}
        {/*  isRequired = {''}*/}
        {/*  errorMsg = {''}*/}
        {/*  placeHolder = {''}*/}
        {/*  id = {''}*/}
        {/*  name = {''}*/}
        {/*  value = {''}*/}
        {/*  handleChange = {''}*/}
        {/*  handleFocus = {''}*/}
        {/*  handleBlur = {''}*/}
        {/*  handleClick = {''}*/}
        {/*  btnClass = {''}*/}
        {/*  btnLabel = {''}*/}
        {/*  btnHandleClick = {''}*/}
        {/*  appendContent = {''}*/}
        {/*  disabled = {''}*/}
        {/*  readonly = {''}*/}
        {/*  pattern = {''}*/}
        {/*  type = {''}*/}
        {/*  autoComplete  = {''}*/}
        {/*  maxLength  = {''}*/}
        {/*  searchIcon={IconSearch}*/}
        {/*/>*/}


        <TextAreaComponent
          placeHolder={'이벤트 내용을 입력해주세요.'}
          contCharacter
          label={'이벤트 내용'}
        />
        <div className='mt-3'>
          <ToggleSwitchComponent  handleChange={(e)=>handleChangeSwith(e)} />
          <ToggleSwitchComponent type={'noText'} />
        </div>

        <div className='mt-3'>
          {dataList.map((item,key) => (
            <ItemInListComponent
              key={key}
              onClick={()=>{console.log('333333')}}
              title={item.text}
              time={'2022-07-04 ~ 2022-08-31'}
              status={'진행중'}
              classNameCustom={''}
              classNameStatus={''}
            />
          ))}

        </div>

        <div className='mt-3 d-flex'>
          {/*<ImageBoxComponent nameFile={'attachment1'} handleChange={(e)=>handleChangeImage(e)} removeImg={(e)=>handleRemoveImg(e)} textCard={'이벤트 설명사진2 등록'} />*/}
          <ImageBoxComponent nameFile={'attachment2'} imgItem={imgItem} handleChange={(e)=>handleChangeImage1(e)} showRemoveImg={true} classCustom={'ml-1'} textCard={'이벤트 설명사진3 등록'} />
          <ImageBoxComponent nameFile={'attachment3'} handleChange={(e)=>handleChangeImage1(e)} confirmUpload={stateUpload} onClick={(e)=>handleClickConfirmUpload(e)} type={'style-text'} classCustom={'ml-1'} textCard={'이벤트 설명사진3 등록'} />

        </div>
        <label>mapItem Img</label>
        <div className='mt-3 d-flex'>

          {dataList.length > 0 && dataList.map((item,index) =>(
            <ImageBoxComponent
              nameFile={'attachment3'}
              handleChange={(e)=>handleChangeImage1(e)}
              imgItem={item?.url_medium}
              onClick={(e)=>handleClickConfirmUpload(e)}
              type={'style-text'}
              classCustom={'ml-1'}
              textCard={'이벤트 설명사진3 등록'} />
          ))}
        </div>
        <div className='mt-3'>
          Image Box Card add more
        </div>
        <div className=' d-flex'>
          <ImageBoxAddMoreComponent imageList={[]} nameFile={'attachment111'} handleChange={(e)=>handleChangeImage(e)} removeImg={(e)=>handleRemoveImg(e)} textCard={'이벤트 설명사진2 등록'} />
        </div>
        <div className='mt-3 d-flex' id='time-picker' style={{marginBottom:'300px'}}>

          <div className='item-calender-component'>
            <DatimePickerComponent
              value={date}
              handleChange={(e)=>handleChangeTimePicker(e)}
              minDate={minDate}
              // maxDate={maxDate}
            />
            <img className={'item-calender-component__icon'} src={IconCalender} />
          </div>

        </div>
        <div style={{minHeight:'100px', width:'55%'}}>
          <div className='list-choose-time'>
            <DropDownCommon
              dataList={[{id:0,text:'0'},...dataListTime]}
              nameDisplay={'10'}
              handleClick={changeType}
              keyName={'text'}
              idActive={activeDropDown}
            />
            <span>:</span>
            <DropDownCommon
              dataList={[{id:0,text:'0'},...dataListTime]}
              nameDisplay={'10'}
              handleClick={changeType}
              keyName={'text'}
              idActive={activeDropDown}
            />
            <span>~</span>
            <DropDownCommon
              dataList={[{id:0,text:'0'},...dataListTime]}
              nameDisplay={'10'}
              handleClick={changeType}
              keyName={'text'}
              idActive={activeDropDown}
            />
            <span>:</span>
            <DropDownCommon
              dataList={[{id:0,text:'0'},...dataListTime]}
              nameDisplay={'10'}
              handleClick={changeType}
              keyName={'text'}
              idActive={activeDropDown}
            />
          </div>
        </div>
        <div style={{height:'500px'}}>
          <DropDownCommon
            dataList={[{id:0,text:'choose option'},...dataList]}
            nameDisplay={'사업자 유형'}
            handleClick={changeType}
            keyName={'text'}
            idActive={activeDropDown}
          />
        </div>

      </div>

      {/*<DialogComponent*/}
      {/*    id={"MD4"}*/}
      {/*    show={modalState}*/}
      {/*    title={"알림"}*/}
      {/*    // closeBtn={"확인"}*/}
      {/*    customBtn={"사용하기"}*/}
      {/*    handleClose={()=>setModalState(false)}*/}
      {/*    handleCustom={()=>handleConfirmModal()}*/}
      {/*    displayClose={true}*/}
      {/*    className={modalState ? 'd-block' : ''}*/}
      {/*>*/}
      {/*  '영상 편지 무료 1회권'을<br/> 사용하시겠습니까?*/}
      {/*</DialogComponent>*/}

    </MainLayout>
  )

}

export default CommonComponent;
