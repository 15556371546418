import React, {useEffect, useState} from "react";

const CountDownTime = ({
                           minutes,
                           seconds,
                           setMinutes,
                            setSeconds
                       }) => {


    // const [ minutes, setMinutes ] = useState(initMinutes);
    // const [seconds, setSeconds ] =  useState(initSecond);

    // useEffect(()=>{
    //     setMinutes(initMinutes);
    //     setSeconds(initSecond);
    // },[initMinutes,initSecond]);


    useEffect(()=>{
        let myInterval = setInterval(() => {

            if (seconds > 0) {
                setSeconds(seconds - 1);
            }
            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(myInterval)
                } else {
                    setMinutes(minutes - 1);
                    setSeconds(59);
                }
            }
        }, 1000)
        return ()=> {
            clearInterval(myInterval);
        };
    });

    if(minutes === 0 && seconds === 0){
        return (<>
            0:00
        </>)
    }

    return (
        <> {minutes}:{seconds < 10 ?  `0${seconds}` : seconds}</>
    )
}
CountDownTime.CountDownTime = {
    minutes: 4,
    seconds: 59,
};
export default CountDownTime;