import React, {useEffect, useMemo, useReducer, useState} from 'react';
import Header from '../../../commons/components/Header/header'
import MainLayout from "../../../commons/layout/MainLayout/index"
import LogoIcon from '../../../assets/images/logo.svg'
import HamburgerIcon from "../../../assets/images/hamburger.svg";

import {Map, MapMarker} from 'react-kakao-maps-sdk'
import {openMenu} from "../redux/actions";
import {useDispatch} from "react-redux";

import { passiveSupport } from 'passive-events-support/src/utils'
import SwipeComponent from "../components/Swipe";
import {Swipe} from "mirsahib-react-swipe-component";
import MapListItem from "../components/MapListItem";
import IconSearch from "../../../assets/images/iconSearch.svg";
import InputComponent from "../../../commons/components/Input/InputComponent";
import API from "../../../commons/api/api";
import _ from 'lodash';
import moment from "moment";
import {storeMarker, mapMarker as markers, getMapMarker} from "../../../utils/helpers";
import { useBottomScrollListener } from 'react-bottom-scroll-listener';
import ROUTERS from "../../../commons/constans/router";
import {useNavigate} from "react-router-dom";
import useDebounce from "../../../commons/customHooks/useDebounce";
import InfiniteScroll from "react-infinite-scroll-component";
import ModalComponent from "../../../commons/components/Modal/ModalComponent";
import NoDataIcon from "../../../assets/images/noData.svg";
import NoData from "../../../commons/components/NoData";
import {useCategories} from "../../../commons/customHooks/Store";

Number.prototype.toRad = function() {
  return this * Math.PI / 180;
}

passiveSupport(['mousewheel', 'scroll', 'drag'])

const MainPageContainer = (props) => {
  const dispatch = useDispatch();

  const [headerHeight, setHeaderHeight] = useState(0)
  const [stateGps, setStateGps] = useState(false)
  const [gpsPos, setGpsPos] = useState({
    lat:window._gpsLat, lng: window._gpsLng
  })

  useEffect(()=>{
    document.getElementById('main-header') && setHeaderHeight(parseInt(document.getElementById('main-header').offsetHeight) + 0)
    fetchStoreList()
    setStateGps(window.stateGps)
  },[])

  window.setStateGps = (data) =>{
    setStateGps(data)
  }



  window.openSettingLocation = (dataSettingLocation) => {
    if (window.AndroidApp) {
      window.AndroidApp.openSettingLocation(dataSettingLocation);
    } else if (window.webkit && window.webkit.messageHandlers) {
      window.webkit.messageHandlers.openSettingLocation.postMessage(dataSettingLocation);
    }
  }
  const [categories] = useCategories()
  const navigate = useNavigate();
  const [selectedMarker, setSelectedMarker] = useState(0)
  const [selectedStore, setSelectedStore] = useState(false)
  const [mapCenterPos, setMapCenterPos] = useState(gpsPos)
  const [mapPanto, setMapPanto] = useState(false)
  const [map, setMap] = useState({idle: true})
  const [storeListFilter, setStoreListFilter] = useState({
    category: null,
    rad: window._radSearch,
    coordinates: mapCenterPos.lat+','+mapCenterPos.lng,
    pointer_coordinates: mapCenterPos.lat+','+mapCenterPos.lng,
    query: null
  })
  const [dataFilter, setDataFilter] = useState({
    value: ''
  })
  const [chooseFilter, setChooseFilter] = useState('')
  const debouncedInputValue = useDebounce(dataFilter?.value, 700)
  const [storeListRes, setStoreListRes] = useState([])
  const [storeList, setStoreList] = useState([])
  const [storeListWithIcon, setStoreListWithIcon] = useState([])
  const [storeTypeList, setStoreTypeList] = useState('')

  const [fetchMorePage, setFetchMorePage] = useState(false)
  const [stateResultNoDataSearch, setStateResultNoDataSearch] = useState(false)
  const [stateResultNoDataSearchList, setStateResultNoDataSearchList] = useState(false)

  const [show, setshow] = useState(false)
  const [isDitry, setIsDitry] = useState(false)
  const [isloading, setIsloading] = useState(false)
  const [showResult, setShowResult] = useState(false)
  const [selecting, setSelecting] = useState(-1)
  const [data, setData] = useState([])
  const [chooseName, setChooseName] = useState(false)
  const [updateLoadLocation, setUpdateLoadLocation] = useState({panable: true})

  const [dialogNotice, setDialogNotice] = useState({
    show: false,
    content: '위치 서비스를 켜주세요.',
    btn:1
  });

  const [locationKoreanNotice, setLocationKoreanNotice] = useReducer(p => !p, false);

  useEffect(() => {

    if (debouncedInputValue && debouncedInputValue.length >= 2) {
      setIsloading(true)
      if(chooseFilter != debouncedInputValue){
        fetchStoreListAutoComplete(debouncedInputValue)
      }
    } else {
      setData([])
      setIsDitry(false)
      setShowResult(false)
    }
    // eslint-disable-next-line
  }, [debouncedInputValue])

  const selectName = (e) => {
    // setData([])
    // onChange(e)

    setDataFilter({
      ...dataFilter,
      value: e
    })
    setChooseFilter(e)
    setIsDitry(false)
    setshow(false)
    setChooseName(true)
  }

  // console.log(categories,'categories')

  const listStoreCats = [
    {
      id: 21,
      icon: "other.svg",
      name: "기타",
      sort: 10,
      state: "ACTIVE",
      created_at: "2022-08-28T23:59:25.000000Z",
      updated_at: "2022-09-06T17:33:05.000000Z"
    },
    {
      id: 12,
      icon: "shopping.svg",
      name: "쇼핑",
      sort: 9,
      state: "ACTIVE",
      created_at: "2022-08-28T23:59:25.000000Z",
      updated_at: "2022-09-06T17:32:45.000000Z"
    },
    {
      id: 13,
      icon: "services.svg",
      name: "서비스",
      sort: 8,
      state: "ACTIVE",
      created_at: "2022-08-28T23:59:25.000000Z",
      updated_at: "2022-09-06T17:32:27.000000Z"
    },
    {
      id: 14,
      icon: "homestay.svg",
      name: "숙박",
      sort: 7,
      state: "ACTIVE",
      created_at: "2022-08-28T23:59:25.000000Z",
      updated_at: "2022-09-06T17:31:49.000000Z"
    },
    {
      id: 15,
      icon: "tourism.svg",
      name: "관광/문화",
      sort: 6,
      state: "ACTIVE",
      created_at: "2022-08-28T23:59:25.000000Z",
      updated_at: "2022-09-06T17:31:12.000000Z"
    },
    {
      id: 16,
      icon: "gas_station.svg",
      name: "주유소",
      sort: 5,
      state: "ACTIVE",
      created_at: "2022-08-28T23:59:25.000000Z",
      updated_at: "2022-09-06T17:31:00.000000Z"
    },
    {
      id: 17,
      icon: "medical.svg",
      name: "의료(보건)",
      sort: 4,
      state: "ACTIVE",
      created_at: "2022-08-28T23:59:25.000000Z",
      updated_at: "2022-09-06T17:29:54.000000Z"
    },
    {
      id: 18,
      icon: "market.svg",
      name: "시장/ 슈퍼",
      sort: 3,
      state: "ACTIVE",
      created_at: "2022-08-28T23:59:25.000000Z",
      updated_at: "2022-09-06T17:29:06.000000Z"
    },
    {
      id: 19,
      icon: "coffee.svg",
      name: "커피/ 디저트",
      sort: 2,
      state: "ACTIVE",
      created_at: "2022-08-28T23:59:25.000000Z",
      updated_at: "2022-09-06T17:29:06.000000Z"
    },
    {
      id: 20,
      icon: "restaurant.svg",
      name: "음식점",
      sort: 1,
      state: "ACTIVE",
      created_at: "2022-08-28T23:59:25.000000Z",
      updated_at: "2022-09-06T17:29:06.000000Z"
    }
  ]

  const [storeCats, setStoreCats] = useState(categories.sort(function(a,b){
    return a.sort - b.sort;
  }))
  const [displayStyle, setDisplayStyle] = useState('map')
  const [mapBottom, setMapBottom] = useState({
    bottomOffset: 30,
    toolTip: false,
    toolTipOffset: 58
  })
  const [listData, setListData] = useState([])
  const [loading, setLoading] = useState({
    fetchStoreList: false
  })

  useEffect(()=>{
    if(mapPanto) {
        setMapPanto(false)
    }
  }, [mapPanto])
  useEffect(()=>{
    fetchStoreList()
    setSelectedMarker(0)
    setSelectedStore(false)
  }, [storeListFilter])
  // useEffect(()=>{
  //
  //   if(countDistance(gpsPos, mapCenterPos) > 0){
  //     console.log(countDistance(gpsPos, mapCenterPos) > 0,'countDistance(gpsPos, mapCenterPos)')
  //     setUpdateLoadLocation(false)
  //   }
  //
  // }, [mapCenterPos,gpsPos])

  useEffect(()=>{

    if (stateResultNoDataSearch){
      setTimeout(() => {
        setStateResultNoDataSearch(false)
      },5000)
    }

  }, [stateResultNoDataSearch])

  useEffect(()=>{
    // const swipertop = document.getElementById('swipe-up-down-store-detail').offsetTop
    // const swiperTranslateY = document.getElementById('swipe-up-down-store-detail').computedStyleMap().get('transform')[0].y;
    // const screenHeight = screen.height
    // console.log(swiperTranslateY?.value)
    // setMapBottom({
    //   ...mapBottom,
    //   bottomOffset: (swiperTranslateY?.value === 0) ? 30 : (screenHeight - swipertop + 30),
    //   toolTipOffset: (swiperTranslateY?.value === 0) ? 58 : (screenHeight - swipertop + 58)
    // })
  }, [selectedStore])

  useEffect(() => {
    window.syncStoreMainPage = (store) => {
      if(displayStyle === 'list'){
        let _listData = listData.map(item => {
          if (item.id === store.id) {
            return {
              ...item,
              ...store
            }
          }
          return item
        })
        setListData(_listData)
        setStoreTypeList(_listData)
      }
      if(displayStyle === 'map'){
        let rawGroup = {}
        let _storeList = storeListRes.map(item => {
          if (item.id === store.id) {
            return {
              ...item,
              ...store
            }
          }
          return item
        })
        _storeList && _storeList.length > 0 && _storeList.forEach((item, k) => {
          if(!rawGroup[item.lat+'-'+item.lng]) rawGroup[item.lat+'-'+item.lng] = {icons: [], items: []}
          rawGroup[item.lat+'-'+item.lng].items.push(item)
          rawGroup[item.lat+'-'+item.lng].icons.push(storeMarker(item))
        })
        // console.log(236, rawGroup)
        let data = []
        let dataFilter = []
        Object.keys(rawGroup).forEach((k) => {
          data.push({items: rawGroup[k].items, icon: getMapMarker(rawGroup[k].items), id: 'point-'+k, lat: rawGroup[k].items[0].lat, lng: rawGroup[k].items[0].lng})
          dataFilter.push(rawGroup[k].items[0])
        })
        // console.log(240, data)
        if(dataFilter.length > 0) {
          // handleMapMarkerClick(data[0])
          dataFilter.map((item,key)=>{
            if(item?.store_name && item?.store_name == storeListFilter?.query && chooseName){
              setMapCenterPos({lat:+(item.lat),lng:+(item.lng)})
            }
          })
        }
        setStoreListRes(_storeList)
        setStoreList(data)
        setStoreListWithIcon(data)
      }
    }

    return () => {
      window.syncStoreMainPage = undefined
    }
  }, [displayStyle, listData, storeListRes]);

  window.setLocationKorean = setLocationKoreanNotice
  useEffect(() => {
    if (!window._isCheckDistance) {
      if (window.distance > MAX_DISTANCE) {
        setLocationKoreanNotice()
        window._isCheckDistance = true
      }
    }
  }, [])

  window.centerMap = () => {
    handlePantoCenter()
  }
  window.setGPSPos = (lat, lng) => {
    //true
    // console.log(updateLoadLocation.panable,'updateLoadLocation')
    if(updateLoadLocation.panable){
      const pos = {lat, lng}
      setGpsPos(pos)
      setMapCenterPos(pos)
      setMapPanto(true)
      // console.log(350)
    }
  }

  const closeModalLocation = () => {
    setLocationKoreanNotice()
    const pos = {lat: window.Jinan.lat, lng: window.Jinan.lng}
    setGpsPos(pos)
    setMapCenterPos(pos)
    setMapPanto(true)
    setStoreListFilter({
      ...storeListFilter,
      coordinates: pos.lat + ',' + pos.lng,
      pointer_coordinates: pos.lat + ',' + pos.lng
    })
  }

  //change style display
  const handleDisplayChange = (type) => {
    setStoreList([])
    setSelectedStore(false)
    setSelectedMarker(false)
    setListData([])
    let filter = {
      category: null,
      rad: window._radSearch,
      coordinates: mapCenterPos.lat+','+mapCenterPos.lng,
      query: null
    }

    if(type === 'list'){
      filter.per_page = 10;
      filter.page = 1;
    }
    setStateResultNoDataSearchList(false)

    setStoreListFilter(filter)
    setDisplayStyle(type)
    setDataFilter({...dataFilter,value: ''})

    setMapCenterPos(gpsPos)

  }

  const fetchStoreListAutoComplete = (dataSearch = '') => {

    let dataFilter = {
      query:dataSearch,
      coordinates:storeListFilter.coordinates,
      pointer_coordinates:storeListFilter.pointer_coordinates,
      rad:storeListFilter.rad
    }
    setData([])
    API.get('/stores/search'+'?event_in_progress=1', {params: dataFilter}).then((res)=> {
      // console.log(res.data.data,'44444')
      setData(res?.data || [])
      setIsDitry(true)
      setshow(true)
      setChooseFilter('')
      setIsloading(false)
      setShowResult(true)

    }).catch((e) => {

      // console.log(e)
    })
  }

  //Fetch list of stores
  const fetchStoreList = () => {
    if(displayStyle === 'list'){
      if(!fetchMorePage){
        storeListFilter.page = 1
      }
    }
    setLoading({
      ...loading, fetchStoreList: true
    })
    setStateResultNoDataSearchList(false)

    API.get('/stores/search'+'?event_in_progress=1', {params: {
      ...storeListFilter,
      pointer_coordinates: `${gpsPos.lat},${gpsPos.lng}`,
      sort_by_distance: 'asc'
    }}).then((res)=> {
        // console.log(res.data.data,'44444')
        setLoading({
          ...loading, fetchStoreList: false
        })

        if(displayStyle === 'list'){

          if(storeListFilter?.query != ''){

            if(fetchMorePage){
              setListData([...listData, ...res.data.data.data])
            }else {
              setListData([...res.data.data.data])
            }
            if(res?.data?.data?.data && res?.data?.data?.data.length == 0){
              // setStateResultNoDataSearch(true)
              setStateResultNoDataSearchList(true)
            }
          }else {

            if(fetchMorePage){
              setListData([...listData, ...res.data.data.data])
            }else {
              setListData([...res.data.data.data])
            }
            // setListData([...listData, ...res.data.data.data])
          }
          setStoreTypeList(res.data?.data)
        }
        if(displayStyle === 'map'){
          let rawGroup = {}
          res.data?.data && res.data.data.length > 0 && res.data.data.forEach((item, k) => {
            // console.log(item, k)
            if(!rawGroup[item.lat+'-'+item.lng]) rawGroup[item.lat+'-'+item.lng] = {icons: [], items: []}
            rawGroup[item.lat+'-'+item.lng].items.push(item)
            rawGroup[item.lat+'-'+item.lng].icons.push(storeMarker(item))
          })
          let data = []
          let dataFilter = []
          Object.keys(rawGroup).forEach((k) => {
            data.push({items: rawGroup[k].items, icon: getMapMarker(rawGroup[k].items), id: 'point-'+k, lat: rawGroup[k].items[0].lat, lng: rawGroup[k].items[0].lng})
            dataFilter.push(rawGroup[k].items[0])
          })
          // console.log(240, data)
          if(dataFilter.length > 0) {
            // handleMapMarkerClick(data[0])
            dataFilter.map((item,key)=>{
              if(item?.store_name && item?.store_name == storeListFilter?.query && chooseName){
                setMapCenterPos({lat:+(item.lat),lng:+(item.lng)})
              }
            })
          }
          setStoreListRes(res.data?.data)
          setStoreList(data)
          setStoreListWithIcon(data)
          // console.log(storeListFilter?.query != '' && res.data?.data,storeListFilter?.query ,res.data?.data,'555555555555')
          if(storeListFilter?.query != '' && res.data?.data && res.data?.data.length == 0){
            // console.log(storeListFilter?.query != '' && res.data?.data,storeListFilter?.query ,res.data?.data,'444444444444')
            setStateResultNoDataSearch(true)
            // setTimeout(setStateResultNoDataSearch(false),5000)
          }

        }
      }).catch((e) => {
        setLoading({
          ...loading, fetchStoreList: false
        })
        console.log(e)
      })
  }

  //Event when click "현재위치로 재검색하기"
  const searchByCurrentPos = () => {
    setStoreListFilter({
          ...storeListFilter,
          coordinates: mapCenterPos.lat + ',' + mapCenterPos.lng
        })
  }

  //Event when click on map Maker
  const handleMapMarkerClick = (item) => {
    setSelectedMarker(item.id)
    setSelectedStore(item)
    // if(item.items.length === 1) setSelectedStore(item.items[0])
    // else{
    //   console.log(item.items)
    // }
  }

  const handlePantoCenter = () => {
    setMapCenterPos(gpsPos)
    setMapPanto(true)
    setMap({...map, idle: false})
    setStoreListFilter({
      ...storeListFilter,
      coordinates: gpsPos.lat + ',' + gpsPos.lng
    })
    setTimeout(()=>setMap({...map, idle: true}), 500)
    setUpdateLoadLocation({panable: true})
  }

  //Event go to center position
  const handlePantoCenterLocation = () => {
    if(!stateGps){
      setDialogNotice({...dialogNotice,show: true})
    }else {
      handlePantoCenter()
    }

  }
  const handleSubmitBtn = () => {

    handlePantoCenter()
    handleCancelBtn()
    window.openSettingLocation(true)

  }
  const handleCancelBtn = () => {
    setDialogNotice({...dialogNotice,show: false})
  }

  //Count the distance from two point
  const countDistance = (rad1, rad2) => {
    // console.log(rad1,rad2,'33333')
    if(!rad1?.lat || !rad1?.lng || !rad2?.lat || !rad2?.lng){
      return 0
    }
    const R = 6371; // km
    const x1 = rad2.lat - rad1.lat;
    const dLat = x1.toRad();
    const x2 = rad2.lng - rad1.lng;
    const dLon = x2.toRad();
    const a = Math.sin(dLat/2) * Math.sin(dLat/2) + Math.cos(rad1.lat.toRad()) * Math.cos(rad2.lat.toRad()) * Math.sin(dLon/2) * Math.sin(dLon/2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
    const d = R * c;

    return (d * 1000).toFixed(2)
  }

  //Event when click category
  const handleClickCate = (id = null) => {
    setStoreListFilter({
      ...storeListFilter,
      category: id
    })
  }

  //Render the category
  const renderCategories = () => {
    let cates = [<li key={'all'} className={(!storeListFilter || (storeListFilter && !storeListFilter.category)) ? `active`: ``} onClick={()=>handleClickCate()}><span>전체</span></li>]
    if(storeCats && storeCats.length > 0){
      cates = [...cates, storeCats.map((item, k) => {
        return <li key={k} className={(storeListFilter && storeListFilter.category === item.id) ? `active`: ``} onClick={()=>handleClickCate(item.id)}>
          <img src={(storeListFilter && storeListFilter.category === item.id) ? `/images/categories/on_${item.icon}`: `/images/categories/${item.icon}`}/>
          <span>{item.name}</span>
        </li>
      })]
    }
    return cates
  }

  //Event detect scroll to bottom of list
  const listRef = useBottomScrollListener(()=>loadMore());

  //Event load more
  const loadMore = () => {
    if(storeTypeList?.last_page && storeListFilter.page+1 <= storeTypeList?.last_page){
      setFetchMorePage(true)
      setStoreListFilter({
        ...storeListFilter, page: storeListFilter.page+1
      })


    }else{

      setFetchMorePage(false)

    }
  }
  const toDetail = (item) => {
    // console.log(item,'item666666')
    let url = ROUTERS.MAIN_DETAIL
    url = url.replace(':id',item?.id)
    navigate(url)

  }
  const toDetailEvent = (item) => {
    // console.log('toDetailEvent',item)
    if(item?.event_state == 'Y' && item?.store_events.length > 0){
      let url = ROUTERS.EVENT_INPROGRESS_DETAIL
      url = url.replace(':id',item?.store_events[0].id)
      navigate(url)
    }
  }
  const mapIconStore = (data) => {
    let icon = ''
    storeListWithIcon.map((item,index)=>{
      let findStoreId = item?.items.filter(item =>item.id == data?.id)
      if(findStoreId && findStoreId.length > 0){
        icon = item.icon
      }
    })
    return icon;
  }
  const handleChangeInput = (e) => {

    setDataFilter({
      ...dataFilter,
      value: e?.target?.value || ''
    })
    setChooseName(false)
  }

  const fetchMoreData = () => {

  }

  window.addEventListener('click', function (e) {
    if (document.getElementById('js-filter-auto') && document.getElementById('js-filter-auto').contains(e.target)) {
      // console.log('in if event')
    } else {
      // console.log('out if event')
      setIsDitry(false)
      setshow(false)
    }
  })

  return (
    <MainLayout
      pageID={"main-page"} // pageID
      activeNav={"MAIN"}
      displayBottomNav={false} // true | false
    >
      <Header
          leftChild=""
          handleLeftClick=""
          leftIcon={false}
          centerChild={LogoIcon}
          centerIcon={true}
          rightChild={HamburgerIcon}
          rightIcon={true}
          menuShow={true}
          handleRightClick={()=>{dispatch(openMenu(true))}}
          css={'header fixed-top dropshadow'}
      >
        <div style={{padding: '0 20px',position:'relative'}} id={'js-filter-auto'}>
          <InputComponent
              label={false}
              placeHolder={'어떤 곳을 찾으세요?'}
              value={dataFilter?.value}
              id={'mainSearchKeyword'}
              type={'text'}
              handleChange={handleChangeInput}
              // handleBlur={handleChangeInput}
              wrapClass={'p-0'}
              searchIcon={IconSearch}
              handleSearchIpt={()=>{
                setStoreListFilter({
                  ...storeListFilter,
                  query: document.getElementById('mainSearchKeyword').value
                })
                setIsDitry(false)
                setshow(false)
              }}
          />
          {isDitry && show ? <div className="position-absolute">
            {isloading ? <p>로딩...</p> : (
              !data?.data.length && showResult ? <p>검색 결과가 없습니다.</p> : null
            )}
            {data?.data && data?.data.length > 0 && data?.data.map((name, key) =>
              <p key={key} onClick={() => selectName(name?.store_name)} className={selecting === name?.store_name ? 'selecting' : ''}>
                {name?.store_name}
              </p>
            )}
          </div> : null}
        </div>
        <nav className="store-categories">
          <div className="scrollable">
            <ul>{renderCategories()}</ul>
          </div>
        </nav>
        {/*Distance: {countDistance(mapCenterPos, gpsPos)} met*/}
      </Header>

      {/*DISPLAY MAP*/}
      {displayStyle === 'map' && <>
      <div className="wrapper" style={{marginTop: `${headerHeight}px`}}>
        <div className="map-container" style={{
          // 지도의 크기
          position: 'relative',
          width: "100%",
          height: `calc(100vh - ${headerHeight}px)`,
        }}>
          <Map // 지도를 표시할 Container
              center={mapCenterPos}
              style={{
                width: "100%",
                height: `100%`,
              }}
              level={2} // 지도의 확대 레벨
              isPanto={mapPanto}
              // onCenterChanged={(map)=>{console.log('lat:' + map.getCenter().getLat()+', lng: '+ map.getCenter().getLng())}}
              onDragEnd={(map) => {
                setMapCenterPos({
                  lat: map.getCenter().getLat(),
                  lng: map.getCenter().getLng(),
                })
                setUpdateLoadLocation({panable: false})
              }}
              onIdle={(map) => {
                setMap({...map, idle: true})
              }}
          >
            <MapMarker
                key={`1${gpsPos}`}
                position={gpsPos} // 마커를 표시할 위치
                image={{
                  src: `/images/maps/${markers['pos']}`, // 마커이미지의 주소입니다
                  size: {
                    width: 35,
                    height: 35
                  },
                  options: {
                    offset: {
                      x: 17,
                      y: 17
                    }
                  }
                }}
            />

            {storeList && !storeList.data && storeList.map((position, index) => {
              return (
                <MapMarker
                    key={position.id}
                    position={{lat: position.lat, lng: position.lng}} // 마커를 표시할 위치
                    image={{
                      src: position.id === selectedMarker ? `/images/maps/${markers[position.icon]['b']}` : `/images/maps/${markers[position.icon]['a']}`, // 마커이미지의 주소입니다
                      size: {
                        width: 36,
                        height: 69
                      },
                      options: {
                        offset: {
                          x: 17,
                          y: 69
                        }
                      }// 마커이미지의 크기입니다
                    }}
                    clickable={true}
                    onClick={() => handleMapMarkerClick(position)}
                    // title={position.store_name} // 마커의 타이틀, 마커에 마우스를 올리면 타이틀이 표시됩니다
                />
              )
            })}
          </Map>
          <div id="gotoCenter" onClick={handlePantoCenterLocation}>
            <img src={map.idle ? `/images/maps/panCenterIcon-off.svg` : `/images/maps/panCenterIcon-on.svg`}/>
          </div>
          {countDistance(gpsPos, mapCenterPos) > 0 &&
              <>

                <div id="search-again" onClick={searchByCurrentPos}>
                  현재위치로 재검색하기 <img src={'/images/icon_search_blue.svg'}/>
                </div>
              </>

          }

        </div>
      </div>
      <SwipeComponent id={'store-detail'} show={!!selectedMarker && selectedStore} handleClose={()=>{
        setSelectedMarker(false);
        setSelectedStore(false)
      }}>
        <div id="map-bottom" style={{display:(stateResultNoDataSearch ? 'none' : 'flex')}}>
          <div id="btn-tooltip"  onClick={()=>{setMapBottom({...mapBottom, toolTip: true})}}>
            <img src="/images/icon_tooltip.svg" alt=""/>
          </div>
          <div id="btn-list-view" onClick={()=>{handleDisplayChange('list')}}><img src="/images/icon_list.svg" alt=""/> 목록 보기</div>
        </div>
        <div id="map-tooltip-wrapper" className={mapBottom.toolTip ? 'active': ''}>
          <div id="map-tooltip-dialog">
            <div className="tooltip-header">
              <h3><img src="/images/tooltip/tooltip_guide.svg" alt=""/> 아이콘 가이드</h3>
              <div className="tooltip-close" onClick={()=>{setMapBottom({...mapBottom, toolTip: false})}}><img src="/images/tooltip/tooltip_close.svg" alt=""/></div>
            </div>
            <ul>
              <li><div className="tooltip-icon"><img src="/images/tooltip/tooltip_icon01.svg" alt=""/></div><span>영업중</span></li>
              <li><div className="tooltip-icon"><img src="/images/tooltip/tooltip_icon07.svg" alt=""/></div><span>영업중,<br/> BLE설치안됨</span></li>
              <li><div className="tooltip-icon"><img src="/images/tooltip/tooltip_icon02.svg" alt=""/></div><span>영업전</span></li>
              <li><div className="tooltip-icon"><img src="/images/tooltip/tooltip_icon08.svg" alt=""/></div><span>영업전,<br/> BLE설치안됨</span></li>
              <li><div className="tooltip-icon"><img src="/images/tooltip/tooltip_icon04.svg" alt=""/></div><span>운영자 있음</span></li>
              <li><div className="tooltip-icon"><img src="/images/tooltip/tooltip_icon05.svg" alt=""/></div><span>키오스크 매장</span></li>
              <li><div className="tooltip-icon"><img src="/images/tooltip/tooltip_icon03.svg" alt=""/></div><span>정보없음</span></li>
              <li><div className="tooltip-icon"><img style={{width: '34px', marginLeft: '-5px'}} src="/images/tooltip/tooltip_icon06@3x.webp" alt=""/></div><span>이벤트지점</span></li>
            </ul>
          </div>
        </div>
        {selectedStore?.items && selectedStore?.items.length === 1 && <>
          <MapListItem handleClick={(item)=>toDetail(item)} handClickEvent={(item)=>toDetailEvent(item)} icon={selectedStore?.icon} item={selectedStore?.items[0]}/>
        </>}
        {selectedStore?.items && selectedStore?.items.length > 1 && <>
          <ul className={'swipe-store-list'}>
            {selectedStore?.items.map((item, k)=>(
              <React.Fragment key={k}>
                <li onClick={()=>toDetail(item)}>
                  <div className="left">
                    <strong>{item.store_name}</strong> <span>{item.category.name}</span> <span>{item.distance.toFixed(0)}m</span>
                  </div>
                  <div className="right">
                    <span>상세보기</span>
                    <img src="/images/icon_arrowdown_60.png" alt=""/>
                  </div>
                </li>
              </React.Fragment>
            ))}
          </ul>
        </>}
      </SwipeComponent></>
      }

      {/*DISPLAY LIST*/}
      {displayStyle === 'list' && <>
        <div ref={listRef} className="wrapper wrapper-map-list" style={{marginTop: `${headerHeight}px`, height: `${screen.height - headerHeight}px`, overflowY: 'scroll'}}>
          <div className='list-item'>
            <InfiniteScroll
              dataLength={listData.length}
              next={loadMore}
              hasMore={loading.fetchStoreList}
              loader={null}
            >
              {listData && listData.length > 0 && listData.map((item, k)=>(
                <MapListItem handClickEvent={(item)=>toDetailEvent(item)} icon={mapIconStore(item)} handleClick={(item)=>toDetail(item)} key={k} item={item}/>
              ))}
            </InfiniteScroll>
          </div>
          {stateResultNoDataSearchList && displayStyle != 'map' ?  <NoData
            children={'다른 검색어를 입력해보세요.'}
            text={'등록된 가맹점이 없습니다.'}
            img={NoDataIcon}
          /> : ''}
          {/*{loading.fetchStoreList && <>Loading...</>}*/}
          {loading.fetchStoreList && <label className='loading-data'>Loading...</label> }
        </div>
        <div id="btn-map-view" style={{display:(stateResultNoDataSearch ? 'none' : 'block')}} onClick={()=>{handleDisplayChange('map')}}><img src="/images/icon_map.svg" alt=""/> 지도 보기</div>
      </>}
      {stateResultNoDataSearch && displayStyle == 'map'  ? <div className='result-no-data-search'>
        <label>검색결과가 없습니다.</label>
      </div> : ''}

      <ModalComponent
        id={``}
        show={dialogNotice?.show}
        title={`알림`}
        cancelBtn={`취소`}
        submitBtn={'확인'}
        handleCancel={handleCancelBtn}
        handleSubmit={handleSubmitBtn}
      >
        <div>
          {dialogNotice?.content}
        </div>
      </ModalComponent>

      <ModalComponent
        id={``}
        show={locationKoreanNotice}
        title={`알림`}
        submitBtn={'확인'}
        handleSubmit={closeModalLocation}
      >
        <div>
          <p>현재 위치가 대한민국이 아닙니다. </p>
          <p>기능을 사용하기 위해 진안군청 위치로 변경하시겠습니까? </p>
        </div>
      </ModalComponent>
    </MainLayout>
  )
}

export default MainPageContainer;
