import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import {
  getCategoriesRequest,
  getCodeLabelRequest,
  getDetailStoreRequest,
  getListStoresRequest,
  updateStoreRequest,
  resetUpdateState,
} from '../../../pages/Store/redux/actions';
import * as actionTypes from '../../../pages/Store/redux/actions/type';
import { syncStore } from '../../../pages/Store/redux/actions';
import API, {ROUTES} from '../../api/api'

export const useCodeLabel = (params) => {
  const dispatch = useDispatch();
  const { codeLabel, typeOfBusiness, taxClass, isProcessing } = useSelector((state) => state.codeLabelReducers);
  useEffect(() => {
    dispatch(getCodeLabelRequest(params));
  }, [params, getCodeLabelRequest]);
  
  return [codeLabel, typeOfBusiness, taxClass, isProcessing]
};


export const useCategories = (params) => {
  const dispatch = useDispatch();
  const { categories, isProcessing } = useSelector((state) => state.categoryReducers);

  useEffect(() => {
    dispatch(getCategoriesRequest(params));
  }, [params, getCodeLabelRequest]);
  
  return [categories, isProcessing]
};

export const useListStores = (params) => {
  const dispatch = useDispatch();
  const { listStores, isProcessing } = useSelector((state) => state.storeReducers);

  useEffect(() => {
    dispatch(getListStoresRequest(params));
  }, [params, getListStoresRequest]);
  
  return [listStores, isProcessing]
};

export const useDetailStore = (params) => {
  const dispatch = useDispatch();
  const { detailStore, isProcessingDetailStore } = useSelector((state) => state.storeReducers);

  useEffect(() => {
    dispatch(getDetailStoreRequest(params));
  }, [params, dispatch, getDetailStoreRequest]);
  
  return [detailStore, isProcessingDetailStore]
};

export const useDetailStoreWithoutRedux = () => {
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch();

  const getStore = async (id) => {
    setLoading(true)
    let res = await API.get(ROUTES.API_GET_LIST_STORES, {params: {id}})

    setLoading(false)

    dispatch(syncStore({
      data: res.data?.data || {},
      type: actionTypes.UPDATE_STORE_SUCCESS
    }))
    return res.data.data
  }
  
  return [getStore, loading]
};

export const useHistoryQueryParams = () => {
  let { id } = useParams()
  let query = new URLSearchParams(useLocation().search);
  const page = query.get('page') || 1;
  const per_page = query.get('per_page') || 15;
  const start_date = query.get('start_date');
  const end_date = query.get('end_date');

  const order_by_store_role = query.get('order_by_store_role') || undefined;
  const order_by_user_name = query.get('order_by_user_name') || undefined;
  const order_by_state_log_action = query.get('order_by_state_log_action') || undefined;
  let order_by_created_at = query.get('order_by_created_at') || undefined;

  if (!query.get('page')) {
    order_by_created_at = 'desc'
  }

  return useMemo(() => {
    return {
      page,
      per_page,
      store_id: id,
      order_by_store_role: order_by_store_role,
      order_by_user_name: order_by_user_name,
      order_by_state_log_action: order_by_state_log_action,
      order_by_created_at: order_by_created_at,
      start_date: start_date || null,
      end_date: end_date || null,
    };
    //eslint-disable-next-line
  }, [
    page,
    per_page,
    start_date,
    end_date,
    order_by_created_at,
    order_by_user_name,
    order_by_store_role,
    order_by_state_log_action,
    id,
  ]);
};

export const useHistories = (param) => {
  const [loading, setLoading] = useState(false)
  const [histories, setHistories] = useState({
    data: []
  })
  useEffect(() => {
    if (param) {
      setLoading(true)
      API.get(ROUTES.API_STORE_HISTORY, {params: param})
        .then(res => {
          setLoading(false)
          if (res.data.data.current_page === 1) {
            setHistories(res.data.data)
          } else {
            setHistories(p => ({
              ...p,
              ...res.data.data,
              data: [
                ...p.data,
                ...res.data.data.data
              ]
            }))
          }
        })
        .catch(() => {
          setLoading(false)
        })
    }
  // eslint-disable-next-line
  }, [param])

  return [histories, loading]
};

export const useCheckStoreVerifyingStores = (param) => {
  const [loading, setLoading] = useState(false)
  const [exist, setExist] = useState(false)
  useEffect(() => {
    if (param) {
      setLoading(true)
      API.get(ROUTES.API_GET_LIST_STORES, {params: param})
        .then(res => {
          setLoading(false)
          setExist(res.data.data.length)
        })
        .catch(() => {
          setLoading(false)
        })
    }
  // eslint-disable-next-line
  }, [param])

  return [exist, loading]
};

export const useUpdateStore = () => {
  const dispatch = useDispatch();
  const { isProcessingUpdate, updateSuccess, updateFail } = useSelector(state => state.storeReducers);

  const handleSubmit = values => {
    dispatch(updateStoreRequest(values));
  };

  return [isProcessingUpdate, handleSubmit];
};

export const useResetUpdateState = (store) => {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      if (store?.id) {
        dispatch(resetUpdateState());
      }
    };
  }, [store?.id])

  return null;
};
