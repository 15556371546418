import React, { useEffect, useState } from 'react';
import ROUTERS from '../../../commons/constans/router';
import MainLayout from '../../../commons/layout/MainLayout';
import LeftIconHeader from '../../../assets/images/leftIconHeader.svg';

import Header from '../../../commons/components/Header/header';
import { useParams } from 'react-router-dom';
import ButtonComponent from '../../../commons/components/Button/ButtonComponent';
import { MODAL_TYPE, STEPS } from '../../../commons/constans/store';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useRole, useUser } from '../../../commons/customHooks/Account';
import {
  useCategories,
  useCodeLabel,
  useDetailStore,
} from '../../../commons/customHooks/Store';
import StoreNoInfomation from '../components/StoreInformation/StoreNoInfomation';
import StoreInfomation from '../components/StoreInformation/StoreInfomation';
import Loading from '../../../commons/components/Loading';

const StoreInfomationPageContainer = () => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();

  const [paramsDetailStore] = useState({
    id: params.id,
  });
  const [detailStore, isProcessingDetailStore] = useDetailStore(paramsDetailStore);
  const [banner, setBanner] = useState([]);

  const [modalCreateStore, setModalCreateStore] = useState({
    type: '',
    isShow: false,
    title: '',
    msg: '',
    cancelBtn: '',
    submitBtn: '',
  });

  const handleLeftClick = () => {
    navigate(ROUTERS.STORE_LIST_MANAGER);
  };
  
  const handleRightClick = () => {
    navigate(ROUTERS.STORE_UPDATE_PATH(detailStore.id), {
      state: {
        prev: `${ROUTERS.STORE_INFOMATION_PATH(params.id)}`
      }
    })
  };

  const handleBackClick = () => {};

  useEffect(() => {
    let dataBanner = [];
    if (detailStore.photo_gallery && detailStore.photo_gallery.length > 0) {
      detailStore.photo_gallery.map((img, idx) => {
        dataBanner.push(img.url_thumbnail);
      })
    }
    setBanner(dataBanner)
  }, [detailStore.photo_gallery])

  return (
    <div>
      <MainLayout pageID={'store-infomation-page'}>
        <Header
          leftChild={LeftIconHeader}
          handleLeftClick={handleLeftClick}
          leftIcon={true}
          centerChild={detailStore?.store_name || ''}
          centerIcon={false}
          rightChild={'편집'}
          handleRightClick={handleRightClick}
        />
        {
          isProcessingDetailStore ? <Loading /> : <div className="wrapper">
          {detailStore?.working_times &&
            detailStore?.working_times.length > 0 ? (
              <StoreInfomation detailStore={detailStore} banner={banner} isProcessingDetailStore={isProcessingDetailStore}/>
            ) : (
              <StoreNoInfomation detailStore={detailStore}/>
            )}
          </div>
        }
        
      </MainLayout>
    </div>
  );
};

export default StoreInfomationPageContainer;
