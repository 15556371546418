
import ROUTERS from '../../commons/constans/router';
import React, { lazy } from 'react';


const NotificationComponent = lazy(() => import('./containers/NotificationPageContainer'));
const NotificationDetailComponent = lazy(() => import('./containers/NotificationDetailPageContainer'));


const route = [
  {
    path: ROUTERS.NOTIFICATION,
    title: 'Notification List',
    exact: true,
    // public: true,
    component: <NotificationComponent />,
  },
  {
    path: ROUTERS.NOTIFICATION_DETAIL,
    title: 'Notification Detail',
    exact: true,
    // public: true,
    component: <NotificationDetailComponent />,
  }
];

export default route;