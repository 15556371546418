import {Fragment, useEffect, useState} from "react";
import MainLayout from '../../../commons/layout/MainLayout';
import InfiniteScroll from 'react-infinite-scroll-component';

import LeftIconHeader from "../../../assets/images/leftIconHeader.svg";
import LogoIcon from '../../../assets/images/logo.svg'
import HamburgerIcon from "../../../assets/images/hamburger.svg";
import Header from '../../../commons/components/Header/header';
import { useParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { openMenu } from "../../Main/redux/actions";
import { useHistoryQueryParams, useHistories } from "../../../commons/customHooks/Store";
import useChangeUrlParams from "../../../commons/customHooks/useChangeUrlParams";

import HistoryFilter from "../components/HistoryFilter";
import HistoryList from "../components/HistoryList";

const OperationHistoryPageContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const query = useHistoryQueryParams();
  const [histories, loading] = useHistories(query);

  const { changeUrlParams } = useChangeUrlParams();

  useEffect(() => {
    if (+query.page > 1) {
      changeUrlParams(query, {type: "page", value: 1})
    }
  }, [])

  const _next = () => {
    changeUrlParams(query, {type: "page", value: +query.page + 1})
  }

  const handleLeftClick = () => {
    navigate('/operation?store=' + query.store_id);
  }

  const onFilter = (filter, option) => {
    changeUrlParams(filter, option)
  }

  return (
    <div>
      <MainLayout
        pageID={"operation-history"}
      >
        <Header
          leftChild={LeftIconHeader} 
          handleLeftClick={handleLeftClick}
          leftIcon={true}
          centerChild={'영업 상태 이력'}
          centerIcon={false}
        />
        <div className='wrapper'>
          <HistoryFilter
            query={query}
            onFilter={onFilter}
          />
          <InfiniteScroll
            dataLength={histories.data.length}
            next={_next}
            hasMore={+histories.current_page < histories.last_page}
            // loader={'loading...'}
          >
            <HistoryList
              histories={histories.data}
              query={query}
              onFilter={onFilter}
              loading={loading}
            />
          </InfiniteScroll>
        </div>
      </MainLayout>
    </div>
  )
};

export default OperationHistoryPageContainer;
