import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

import {useDispatch} from "react-redux";
import MainLayout from "../../../../commons/layout/MainLayout/index";
import ROUTERS from "../../../../commons/constans/router";
import HeaderHs2 from "../../../../commons/components/Header/headerHs2";
import CheckboxComponent from "../../../../commons/components/Forms/CheckboxComponent";
import ButtonComponent from "../../../../commons/components/Forms/ButtonComponent";
// import DialogComponent from "../../../../commons/components/Modal/DialogComponent";
import {resetState} from "../../redux/actions";
import {formatSnsType, rememberMeLogin} from "../../../../utils/helpers";

import * as LOGIN_TYPE from './../../redux/actions/type';
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isAndroid,
  isIOS
} from "react-device-detect";
import Loading from "../../../../commons/components/Loading";
import InputComponent from "../../../../commons/components/Input/InputComponent";
import {ROUTES} from "../../../../commons/api/api";
import {openMenu} from "../../../Main/redux/actions";
import LogoIcon from '../../../../assets/images/logo.svg'
import HamburgerIcon from "../../../../assets/images/hamburger.svg";
import Header from '../../../../commons/components/Header/header';
import LeftIconHeader from "../../../../assets/images/leftIconHeader.svg";
import {formatPhoneNumber} from "../../../../utils/helpers";
import ModalComponent from "../../../../commons/components/Modal/ModalComponent";
import {getRandomInt} from "../../../../utils/helpers";
import { useRole } from "../../../../commons/customHooks/Account";
const PHONE_NUMBER_NAME = `phone_number_${getRandomInt(1,1000)}`;

const LoginComponent = ({
                          loginRequest,
                          userInfo,
                          isProcessing,
                          type,
                          loginSnsRequest,
                          errors,
                          token,
                          isProcessingAction
                        }: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [disableButton, setDisableButton] = useState(true);


  const [openModal, setOpenModal] = useState({
    'isShowDialog': false,
    'content': ''
  });

  const [dataLogin, setDataLogin] = useState({
    [PHONE_NUMBER_NAME]: '',
    'password': '',
    'remember_me': true,
  });

  useEffect(() => {
    if(isProcessing || dataLogin[PHONE_NUMBER_NAME] == '' || dataLogin.password == ''){
      setDisableButton(true);
    }else{
      setDisableButton(false);
    }
  },[isProcessing,dataLogin[PHONE_NUMBER_NAME],dataLogin.password]);

  useEffect(() => {
    switch (type) {
      case LOGIN_TYPE.LOGIN_SUCCESS :
        navigate(ROUTERS.MAIN)
        break
      case LOGIN_TYPE.LOGIN_FAILD:
        if (errors && errors.phone_number) {
          setOpenModal({
            ...openModal,
            isShowDialog: true,
            content: errors.phone_number
          })
        }
        if (errors && errors.password) {
          setOpenModal({
            ...openModal,
            isShowDialog: true,
            content: errors.password
          })
        }
        if (errors && errors.message) {
          let message = errors.message;
          if (errors.message === 'Phone number is not found') {
            message = '등록되지 않은 아이디입니다.\n' +
              '다시 한 번 확인해 주세요.'
          }
          if (errors.message === 'unauthentication') {
            message = '비밀번호를 확인해주세요'
          }
          setOpenModal({
            ...openModal,
            isShowDialog: true,
            content: message
          })
        }
        dispatch(resetState())
        break;
      case LOGIN_TYPE.LOGIN_SNS_FAILD :
        break
      default:
        break;
    }

  }, [type]);

    const handleChangeIpt = (event) => {
      const target = event.target;
      const value = target.type === 'checkbox' ? target.checked : target.value;
      const name = target.name;

      if (name === PHONE_NUMBER_NAME) {
        let valuePhoneNumber = value;
        valuePhoneNumber = value.replaceAll('-', '')
        if (valuePhoneNumber.length <= 11) {
          const re = /^[0-9\b]+$/;
          if (valuePhoneNumber === '' || re.test(valuePhoneNumber)) {
            setDataLogin({
              ...dataLogin,
              [name]: formatPhoneNumber(valuePhoneNumber)
            });
          }
        }
        return false;
      }
      setDataLogin({
        ...dataLogin,
        [name]: value
      })
    }

  const handleSubmitLogin = () => {
    let data = {
      ...dataLogin,
      fcm_token: window.appDevice?.fcmToken || '',
      device_uuid : window.appDevice.deviceUuid,
      device_os : window?.appDevice?.deviceOs || 'IOS',
      phone_number : dataLogin[PHONE_NUMBER_NAME]
    }

    dispatch(loginRequest(data));
  }

  return (
    <MainLayout
      pageID={"login-page"} // pageID
      // activeNav={"MAIN"}
      displayBottomNav={false} // true | false

    >

      <Header
        leftChild={LeftIconHeader}
        handleLeftClick={() => {
          navigate(ROUTERS.INTRO);
        }}
        leftIcon={true}
        centerChild={'로그인'}
        centerIcon={false}
        // rightChild={HamburgerIcon}
        rightIcon={false}
        menuShow={true}
        handleRightClick={() => {
          dispatch(openMenu(true))
        }}
      >

      </Header>


      <div className="wrapper">
        <div className="login-form">
          <InputComponent
            label={"아이디 (휴대폰 번호)"}
            isRequired={false}
            // errorMsg={errorForm?.phone_number || ''}
            placeHolder={"아이디를 입력해 주세요."}
            id={PHONE_NUMBER_NAME}
            name={PHONE_NUMBER_NAME}
            value={dataLogin[PHONE_NUMBER_NAME]}
            handleChange={handleChangeIpt}
            disabled={isProcessing}
            readonly={false}
            type={'text'}
            maxLength={13}
            autoComplete={'off'}
            numberType={'phone-number'}
          />
          <div className={'mt-3'}>
            <InputComponent
              label={"비밀번호"}
              isRequired={false}
              // errorMsg={errorForm?.password || ''}
              placeHolder={"비밀번호를 입력해 주세요."}
              // id={"id-input"}
              name={"password"}
              value={dataLogin.password}
              type={'password'}
              handleChange={handleChangeIpt}
              disabled={isProcessing}
              readonly={false}
              autoComplete={'off'}
              maxLength={"20"}
            />
          </div>
          {/*{console.log(dataLogin[PHONE_NUMBER_NAME] && dataLogin.password )}*/}

          <div className={'fixed-btn'}>
            <ButtonComponent
              label={'로그인'}
              cssClass={'btn btn-custom mb-2'}
              type={"button"}
              loading={true}
              // disabled={propsButton.disableBtn}
              disabled={disableButton}
              handleClick={handleSubmitLogin}
              style={{width: '100%'}}

            />
          </div>
        </div>
      </div>


      <ModalComponent
        id={``}
        show={openModal.isShowDialog}
        title={`알림`}
        submitBtn={`확인`}
        handleSubmit={() => {
          setOpenModal({
            ...openModal,
            isShowDialog: false,
            content: '',
          })
          // resetState();

        }}
      >
        <div style={{ whiteSpace : 'pre-wrap'}}>
          {openModal.content ?? ''}
        </div>
      </ModalComponent>
    </MainLayout>
  );
};

export default LoginComponent;
