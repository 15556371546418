import axios from "axios";
import {formatAppSnsType, log, removeAllStorage} from "../../utils/helpers";
import * as action from './../../pages/Account/redux/actions';

import {resetState} from "./../../pages/Account/redux/actions";


// log('initAPI', 'saga');
const UNAUTHORIZED = 401;



export const ROUTES = {
    API_LOGIN: '/auth/login',
    API_GET_AUTH_ME: '/auth/me',
    API_LOGOUT: '/auth/logout',
    API_AUTH_VALIDATE: '/auth/validate',
    API_AUTH_SEND_CODE: '/code/send',
    API_AUTH_VERIFY_CODE: '/code/verify',
    API_AUTH_REGISTER_USER: '/auth/register',
    API_AUTH_UPDATE_BY_NUMBER: '/auth/update',
    API_DELETE_USER: '/auth/delete-user',
    API_GET_CODE_LABEL: '/code-label',
    API_GET_CATEGORIES: '/store-category',
    API_NOTIFICATION: '/notification',
    API_VERIFY_BIZ_REGCERT: '/verify-biz-regcert',
    API_GET_LIST_STORES: '/store',
    API_EVENT: '/store-event',
    API_EVENT_EDIT: '/store-event/:id',
    API_EVENT_REMOVE: '/delete-event',
    API_TERMS_OF_USE: '/information',
    API_CREATE_STORE: '/store',
    API_UPDATE_STORE: '/store/',
    API_STORE_HISTORY: '/store-log',
    API_RECEIVE_PUSH_EVENT: '/receive-event',
    API_CREATE_MANAGER: '/register-manager',
    API_GET_MANAGER: '/get-manager',
    API_DELETE_MANAGER: '/delete-manager',
    API_APP_DEVICE: '/app-device',
    API_UPLOAD_FILE: '/upload-image-store',
    API_UPDATE_USER: '/auth',

};

const API = axios.create({
    baseURL: process.env.REACT_APP_API_URL
});

const _app_token = localStorage.getItem('_APP_TOKEN');

if(_app_token){
    API.defaults.headers.common['Authorization'] = `Bearer ${_app_token}`;
}

export const setupAPI = store => {
    API.interceptors.response.use(function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
    }, function (error) {
        let errorResponse = error.response;
        if(errorResponse.status === UNAUTHORIZED) {
            store.dispatch(resetState());
            // let snsType = formatAppSnsType(localStorage.getItem('sns_type') || '');
            // window.nativeLogoutToken(snsType);
            removeAllStorage();
            window.nativeLogoutToken('');
        }
        return Promise.reject(error);
    });
}
export default API;
