

import React, { useEffect, useState } from "react";
import LeftIconHeader from '../../../assets/images/leftIconHeader.svg';
import Header from "../../../commons/components/Header/header";
import MainLayout from "../../../commons/layout/MainLayout";
import IconSearch from "../../../assets/images/iconSearch.svg"
import InputComponent from "../../../commons/components/Input/InputComponent";
import { useNavigate } from "react-router-dom";
import ButtonComponent from "../../../commons/components/Button/ButtonComponent";
import PlusIconBtn from "../../../assets/images/plusIconBtn.svg"
import NoData from "../../../commons/components/NoData";
import DropDownCommon from "../../../commons/components/Dropdown";
import { useListStores } from "../../../commons/customHooks/Store";
import { useUser } from "../../../commons/customHooks/Account";
import { formatPhoneNumber } from "../../../utils/helpers";
import { useRegisterManager } from "../../../commons/customHooks/Manager";
import ROUTERS from "../../../commons/constans/router";
import ModalComponent from "../../../commons/components/Modal/ModalComponent";

type Props = {

}

const ManagerRegisterPageContainer = ({}:Props) => {

  const navigate = useNavigate();
  const { user } = useUser();
  const [paramsListStores, ] = useState({
    store_by_user_id: user.id,
    'approval_state[]': 'ACTIVED'
  })
  const [listStores] = useListStores(paramsListStores);
  const [dataStoreLists, setDataStoreLists] = useState([]);
  const [dataManager, setDataManager] = useState({});
  const [activeDropDown, setActiveDropDown] = useState({
    store_id: 0,
  });
  const [handleErrorsModal, setHandleErrorsModal] = useState({
    type: '',
    isShow: false,
    title: '',
    msg: '',
    cancelBtn: '',
    submitBtn: '',
  });

  const [isLoading, onSubmit, createSuccess, createFaild, status] = useRegisterManager();

  useEffect(() => {
    let dataList = [];
    if (listStores.length > 0) {
      listStores.map((item, idx) => {
        dataList.push({ id: item.id, text: item.store_name })
      })
    }
    setDataStoreLists(dataList);
  }, [listStores])

  useEffect(() => {
    if (status === 422) {
      setHandleErrorsModal({
        isShow: true,
        title: '',
        msg: createFaild?.phone_number[0] || '',
        cancelBtn: '',
        submitBtn: '확인',
      })
    }
  }, [createFaild])

  useEffect(() => {
    if (status === 200 && createSuccess?.id) {
      navigate(ROUTERS.MANAGER)
    }
  }, [createSuccess])

  const handleLeftClick = () => {
    navigate(ROUTERS.MANAGER);
  }

  const handleChangeInput = (e) => {

    if (e.target.name === 'phone_number') {
      let valuePhoneNumber = e.target.value;
      valuePhoneNumber = valuePhoneNumber.replaceAll('-', '')
      if (valuePhoneNumber.length <= 11) {
        const re = /^[0-9\b]+$/;
        if (valuePhoneNumber === '' || re.test(valuePhoneNumber)) {
          setDataManager({
            ...dataManager,
            [e.target.name]: formatPhoneNumber(valuePhoneNumber),
          })
        }
      }
      return false;
    }else{
      setDataManager({
        ...dataManager,
        [e.target.name]: e.target.value 
      })
    }
  }

  const handleChangeDropdown = (e) => {
    setActiveDropDown({
      store_id: e.id
    })
    setDataManager({
      ...dataManager,
      store_id: e.id
    })
  }

  const handleClickRegister = () => {
    onSubmit(dataManager);
  }

  const handleCancel = () => {

  }

  const handleConfirm = () => {
    setHandleErrorsModal({
      isShow: false,
      title: '',
      msg: '',
      cancelBtn: '',
      submitBtn: '확인',
    })
  }


  return <>
    <MainLayout pageID={'manager-register-page'}>
      <Header
        leftChild={LeftIconHeader}
        handleLeftClick={handleLeftClick}
        leftIcon={true}
        centerChild={'매니저 관리'}
        centerIcon={false}
      />
      <div className="wrapper">
        <div className="notice">
          <p>주의사항</p>
          <p><span>.</span> 매니저는 1인 1매장만 등록 가능합니다.</p>
          <p><span>.</span> 진안 IoT 플랫폼 앱에 등록된 사용자만 매니저로
            등록이 가능합니다.
          </p>
        </div>
        <div className="form-create">
          <label>매장명</label>
          <DropDownCommon
            customClass={`business-drop`}
            dataList={[...dataStoreLists]}
            nameDisplay={'매장 선택'}
            // placeHolder={'매장 선택'}
            handleClick={(e) => handleChangeDropdown(e)}
            keyName={'text'}
            idActive={activeDropDown?.store_id || 0}
            errorMes={``}
            name={`store_id`}
          />

          <InputComponent
            label={'휴대폰 번호'}
            isRequired={''}
            errorMsg={''}
            placeHolder={'010-xxxx-xxxx'}
            id={'js-store-phone'}
            name={'phone_number'}
            value={dataManager?.phone_number || ''}
            handleChange={handleChangeInput}
            btnClass={''}
            btnLabel={''}
            disabled={''}
            readonly={''}
            type={'text'}
            borderBottom={true}
            maxLength={13}
            numberType={`phone-number`}
          />
        </div>
        <ButtonComponent
          label={'등록하기'} 
          style={{width: '100%'}}
          cssClass={'mb-2'}
          handleClick={handleClickRegister}
          fixedBtn={true}
          disabled={dataManager.store_id && dataManager.phone_number && !isLoading ? false : true}
        />

        <ModalComponent 
          show={handleErrorsModal.isShow || false}
          title={handleErrorsModal.title || ''}
          handleCancel={handleCancel}
          cancelBtn={handleErrorsModal.cancelBtn || ''}
          submitBtn={handleErrorsModal.submitBtn || ''}
          className={`manager-modal-errors`}
          handleSubmit={handleConfirm}
        >
          <div className="content-msg" style={{whiteSpace: 'break-spaces'}}>
            {handleErrorsModal.msg}
          </div>
        </ModalComponent>

      </div>
    </MainLayout>
  </>
}

export default ManagerRegisterPageContainer;