/*
 * Text Input Component
 * */
import React, { useEffect, useState, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { randomIdentifier } from '../../../utils/helpers';

type Props = {
  label: any,
  cssClass: String,
  style: Object,
  disabled: boolean,
  loadingBtn: boolean,
  loading: boolean,
  loadingColor: String,
  handleClick: any,
  handleDbClick: any,
  iconBtn: String,
  fixedBtn: Boolean,
  idBtn:String
};

const ButtonComponent = ({
  label,
  cssClass,
  style,
  loading,
  disabled,
  loadingColor,
  handleClick,
  handleDbClick,
  type,
  loadingBtn,
  iconBtn,
  fixedBtn,
  idBtn
}: Props) => {
  
  const [isLoading, setIsLoading] = useState(loading);
  const _handleClick = (e) => {
    if (typeof handleClick == 'function') handleClick(e);
    setIsLoading(!isLoading);
  };

  const _handleDbClick = (e) => {
    if (typeof handleDbClick == 'function') handleDbClick(e);
    setIsLoading(!isLoading);
  };

  return (
    <div className={fixedBtn ? 'fixed-btn' : ''}>
      <button
        style={{
          ...style,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        id={idBtn}
        className={`btn btn-custom ${cssClass}`}
        disabled={disabled}
        onClick={_handleClick}
        onDoubleClick={_handleDbClick}
        type={type ?? 'button'}
      >
        {
          iconBtn && <img className='img-btn' src={iconBtn} />
        }

        <span>{label}</span>
        
        {!loadingBtn ? (
          ''
        ) : (
          <>
            {!isLoading ? (
              ''
            ) : (
              <div className="loading-icon-1">
                <div className="loading-icon-1-spinner">
                  <div
                    style={{
                      borderColor: loadingColor,
                      borderTopColor: 'transparent',
                    }}
                  ></div>
                </div>
              </div>
            )}
          </>
        )}
      </button>
    </div>
  );
};

ButtonComponent.defaultProps = {
  label: 'button',
  cssClass: 'btn btn-custom',
  type: 'button',
  style: {},
  loadingBtn: false,
  loading: false,
  disabled: false,
  loadingColor: '#FFF',
  iconBtn: '',
  fixedBtn: false,
  idBtn:''
};

export default memo(ButtonComponent);
