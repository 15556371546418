import { put, call, takeLatest } from 'redux-saga/effects';

import * as actionUser from '../redux/actions';
import * as types from '../redux/actions/type';
import API, { ROUTES } from "../../../commons/api/api";

function* updateCurrentUser(action){
  console.log('dispatch updateCurrentUser', action.payload);

  try {
    const response = yield call(() => API.post(ROUTES.API_UPDATE_AUTH_ME, action.payload, {
      headers: { 'Content-Type': 'multipart/form-data' },
    }));

    console.log(response.data.data, 'updateCurrentUser');

    yield put(actionUser.updateUserSuccess(response.data.data));
  } catch (error) {
    console.log(error);
  }
}

function* updateCurrentUserSaga() {
  yield takeLatest(types.UPDATE_USER_REQUEST, updateCurrentUser);
}

export default updateCurrentUserSaga;
