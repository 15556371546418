
import ROUTERS from '../../commons/constans/router';
import React, { lazy } from 'react';


const IntroComponent = lazy(() => import('../Intro/containers/IntroPageContainer'));

const route = [
  {
    path: ROUTERS.INTRO,
    title: 'intro',
    exact: true,
    public: true,
    component: <IntroComponent />,
  }
];

export default route;