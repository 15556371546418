import { useEffect, useState } from "react";
import ToggleSwitchComponent from "../../../../commons/components/ToggleSwitch/ToggleSwitchComponent";
import SelectEndTimeComponent from "../SelectTime/SelectEndTimeComponent";
import SelectStartTimeComponent from "../SelectTime/SelectStartTimeComponent";

type Props = {
  dataWorkingTime: Array,
  handleChangeWorkingTimes: Function,
}

const StoreWorkingTimeComponent = ({dataWorkingTime, handleChangeWorkingTimes}:Props) => {

  const [workingTime, setWorkingTime] = useState(dataWorkingTime);

  useEffect(() => {
    let data = [];
    dataWorkingTime.map((item, idx) => {
      data[idx] = {
        state: item.state,
        starttime: item?.starttime || '00:00:00',
        endtime: item.endtime || '23:00:00',
        state: item.state,
        day: item.day
      };
    });

    setWorkingTime(Object.assign({}, data));
  
  }, [dataWorkingTime]);

  const renderDay = (day) => {
    let text = '';
    switch (day) {
      case 0:
        text = '월요일';
        break;
      case 1:
        text = '화요일';
        break;
      case 2:
        text = '수요일';
        break;
      case 3:
        text = '목요일';
        break;
      case 4:
        text = '금요일';
        break;
      case 5:
        text = '토요일';
        break;
      case 6:
        text = '일요일';
        break;
      default:
        break;
    }
    return text;
  };

  const handleChangeStartTime = (time) => {
    if (time) {
      setWorkingTime({
        ...workingTime,
        [time.day]: {
          ...workingTime[time.day],
          starttime: time.starttime,
        },
      });
    }
  };

  const handleChangeEndTime = (time) => {
    if (time) {
      setWorkingTime({
        ...workingTime,
        [time.day]: {
          ...workingTime[time.day],
          endtime: time.endtime,
        },
      });
    }
  };

  const handleChangeState = (value, day) => {
    setWorkingTime({
      ...workingTime,
      [day]: {
        ...workingTime[day],
        state: value ? 'Y' : 'N',
      },
    });
  };

  useEffect(() => {
    handleChangeWorkingTimes(workingTime)
  }, [workingTime])

  const renderWorkingTime = () => {
    return Object.values(workingTime).map((value, key) => (
        <div className={`time`} key={key}>
          <div className="change-time">
            <span className="day">{renderDay(value.day)}</span>
            {value.state == 'Y' && (
              <div className="select-time">
                <div className="list-choose-time">
                  <SelectStartTimeComponent
                    dataDay={value.day}
                    dataStarttime={value.starttime}
                    handleChangeStartTime={handleChangeStartTime}
                    max={value.endtime}
                  />
                  <span>~</span>
                  <SelectEndTimeComponent
                    dataDay={value.day}
                    dataEndtime={value.endtime}
                    handleChangeEndTime={handleChangeEndTime}
                    min={value.starttime}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="change-status">
            <ToggleSwitchComponent
              classNameCustom={`change-status-item`}
              type={'noText'}
              value={value.state == 'Y' ? true : false}
              handleChange={(e) => handleChangeState(e, value.day)}
            />
            <span className="title-status">
              {value.state == 'Y' ? '영업 중' : '휴무'}
            </span>
          </div>
        </div>
      )
    )
  }

  return (
    <div className="operating-update">
      <label className="title">운영시간 정보</label>
      {renderWorkingTime()}
    </div>
  )
}

export default StoreWorkingTimeComponent;
