import React, {useEffect, useState} from "react";
import {useNavigate,useLocation} from "react-router-dom";

import {useDispatch} from "react-redux";
import MainLayout from "../../../commons/layout/MainLayout/index";
import ROUTERS from "../../../commons/constans/router";
import Header from "../../../commons/components/Header/header";
import LeftIconHeader from "../../../assets/images/leftIconHeader.svg";
import InputComponent from "../../../commons/components/Input/InputComponent";
import ButtonComponent from "../../../commons/components/Button/ButtonComponent";
import {formatPhoneNumber, removeAllStorage} from "../../../utils/helpers";
import API, {ROUTES} from "../../../commons/api/api";
import {resetState} from "../redux/actions";

const DeleteMember = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [dataForm, setDataForm] = useState({
    phone_number: '',
    password: '',
  });

  const [passwordError,setPasswordError] = useState('');

  const [stateDeleteMember,setStateDeleteMember] = useState(false);

  const [disableButton, setDisableButton] = useState(true);

  useEffect(() => {
    if(dataForm.phone_number == '' || dataForm.password == ''){
      setDisableButton(true)
    }else{
      setDisableButton(false)
    }
  },[dataForm])

  const handleChangeIpt = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    setPasswordError('');
    if (name === 'phone_number') {
      let valuePhoneNumber = value;
      valuePhoneNumber = value.replaceAll('-', '')
      if (valuePhoneNumber.length <= 11) {
        const re = /^[0-9\b]+$/;
        if (valuePhoneNumber === '' || re.test(valuePhoneNumber)) {
          setDataForm({
            ...dataForm,
            [name]: formatPhoneNumber(valuePhoneNumber)
          });
        }
      }
      return false;
    }
    setDataForm({
      ...dataForm,
      [name]: value
    })
  }

  const handleSubmit = () => {

    if(!stateDeleteMember) {
      API.post(ROUTES.API_DELETE_USER,dataForm).then(res => {
        setPasswordError('');
        setStateDeleteMember(true);
        setTimeout(() => {
          removeAllStorage();
          dispatch(resetState());
          navigate(ROUTERS.INTRO);
        },1000)
      }).catch(e => {
        setPasswordError('아이디와 비밀번호가 일치하지 않습니다.');
      })
    }

  }

  return (
    <MainLayout pageID={'delete-member-page'}>
      <Header
        leftChild={LeftIconHeader}
        handleLeftClick={() => {
          navigate(ROUTERS.FORGOT_PW)
        }}
        leftIcon={true}
        centerChild={'회원 탈퇴'}
        centerIcon={false}
        rightChild={false}
        rightIcon={false}
      />

      <div className={'wrapper'}>
        <div className={'row delete-member-content'}>
          <div className={'col-12'}>


            <div className={'row'}>
              <div className={'col-12 text-center'}>
                <p className={'title-page p-0 m-0'}>본인 인증을 위해</p>
                <p className={'title-page p-0 m-0 mt-1'}>아이디와 비밀번호를 입력해 주세요.</p>
              </div>
            </div>

            <div className={'row mt-4'}>
              <div className={'col-12'}>
                <InputComponent
                  label={"아이디 (휴대폰 번호)"}
                  isRequired={false}
                  // errorMsg={errorForm?.phone_number || ''}
                  placeHolder={"아이디를 입력해 주세요."}
                  name={"phone_number"}
                  value={dataForm.phone_number}
                  handleChange={handleChangeIpt}
                  maxLength={13}
                />
              </div>
            </div>


            <div className={'row mt-2'}>
              <div className={'col-12'}>
                <InputComponent
                  label={"비밀번호 확인"}
                  isRequired={false}
                  // errorMsg={errorForm?.phone_number || ''}
                  placeHolder={"아이디를 입력해 주세요."}
                  value={dataForm.password}
                  name={'password'}
                  handleChange={handleChangeIpt}
                  // disabled={isProcessing}
                  errorMsg={passwordError}
                />
              </div>
            </div>

            {/*btn-custom-black */}
            <div className={'row mt-2'}>
              <div className={'col-12'}>
                <ButtonComponent
                  label={stateDeleteMember ? '회원탈퇴 완료되었습니다.' : '로그인'}
                  cssClass={`btn btn-block ${stateDeleteMember ? 'btn-custom-black' : ''}`}
                  type={"button"}
                  // loading={true}
                  // disabled={propsButton.disableBtn}
                  // disabled={isProcessing}
                  handleClick={handleSubmit}
                  fixedBtn={true}
                  disabled={disableButton}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

    </MainLayout>
  );
};

export default DeleteMember;
