/*
 * Text Input Component
 * */
import React, {useEffect, useState, memo, useRef} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { randomIdentifier } from '../../../utils/helpers';

type Props = {
  label: String,
  isRequired: boolean,
  errorMsg: any,
  placeHolder: String,
  id: string,
  name: string,
  value: String,
  handleChange: Function,
  handleFocus: Function,
  handleBlur: Function,
  handleClick: Function,
  btnClass: String,
  btnLabel: String,
  btnHandleClick: Function,
  appendContent: any,
  disabled: boolean,
  readonly: boolean,
  pattern: String,
  type: String,
  autoComplete: String,
  maxLength: String,
  searchIcon: any,
  defaultCountCharacter:any,
  fadeLastCharacter:boolean,
};

const TextAreaAnimationComponent = ({
  label,
  isRequired,
  errorMsg,
  placeHolder,
  id,
  name,
  value,
  handleChange,
  handleFocus,
  handleBlur,
  handleClick,
  btnClass,
  btnLabel,
  btnHandleClick,
  appendContent,
  disabled,
  readonly,
  pattern,
  type,
  autoComplete,
  maxLength,
  searchIcon,
  rows,
  contCharacter,
  defaultCountCharacter,
  fadeLastCharacter
}: Props) => {
  const [text, setText] = useState(value);
  const [character, setCountCharacter] = useState(0);
  const [characterLast, setCharacterLast] = useState('');
  const [stateOnchange, setStateOnChange] = useState(false);
  const backdropRef = useRef(null);
  const textAreaRef = useRef(null);

  useEffect(() => {
    setCountCharacter(defaultCountCharacter);
  }, []);

  useEffect(() => {
    if (value.length <= (parseInt(maxLength)) && !stateOnchange){
      setText(value);
      setCharacterLast(value);
    }

  }, [value]);


  const _handleChange = (e) => {
    e.preventDefault();
    let value = e.target.value;
    setStateOnChange(true)
    if (typeof handleChange == 'function') {
      // handleChange(e);
      if (e.target.value.length <= (parseInt(maxLength))){
        handleChange(e);
      }
    }


    if (value.length < +maxLength) {
      let arr = value.split('');
      setText(value);
      setCharacterLast(value);
      setCountCharacter(value.length)
    }else if(value.length == +maxLength){
      let arr = value.split('');
      let last = arr[value.length-1];
      let result = arr.slice(0, value.length-1);
      let textValue = result.join('') + `<mark>${last}</mark>`;
      console.log(textValue,'text')
      setText(textValue);
      setCharacterLast(result.join('') + ' ');
      setCountCharacter(value.length)
    }else{
      return;
    }

    // setText(e.target.value);
  };
  const _handleKeyUp = (e) => {
    if(e.target.value.length <= (parseInt(maxLength))){
      setCountCharacter(e.target.value.length);
    }
  }
  const listenScrollEvent = (e) => {

    var scrollTop = textAreaRef.current.scrollTop;
    // backdropRef.current.onScroll(scrollTop);
    document.getElementById('backdrop').scrollTo(0,scrollTop)
  }
  return (
    <div className="textarea-animation">
      {
        label && <label  className={`title-text ${isRequired ? 'required' : ''}`}>{label}</label>
      }
      
      <div className="textarea-animation-component position-relative">
        <div className="backdrop" ref={backdropRef} id={'backdrop'}>
          <div className="highlights" dangerouslySetInnerHTML={{__html: text}}>
          </div>

        </div>
        {
          contCharacter ? (<label className='count-character'><strong>{character}</strong> / {maxLength}</label>) : ('')
        }
        <textarea
          className={
            errorMsg.length == 0
              ? ''
              : ' form-control-error'
          }
          placeholder={placeHolder}
          value={characterLast}
          // defaultValue={''}
          id={id}
          rows={rows}
          name={name}
          onChange={(e)=>_handleChange(e)}
          // onKeyUp={_handleKeyUp}
          onBlur={handleBlur}
          onClick={handleClick}
          onFocus={handleFocus}
          readOnly={readonly}
          disabled={disabled}
          pattern={pattern}
          type={type}
          autoComplete={autoComplete}
          onScroll={listenScrollEvent}
          ref={textAreaRef}
          // maxLength={parseInt(maxLength)}
        />


        {
            searchIcon && <img src={searchIcon} />
        }
        {btnLabel.length == 0 ? (
          ''
        ) : (
          <button type="button" className={btnClass} onClick={btnHandleClick}>
            {btnLabel}
          </button>
        )}
        {!appendContent ? '' : appendContent}
      </div>
      {errorMsg.length == 0 ? (
        ''
      ) : (
        <span className="input-error show">{errorMsg}</span>
      )}
    </div>
  );
};

TextAreaAnimationComponent.defaultProps = {
  isRequired: false,
  btnHandleClick: () => {},
  handleChange: () => {},
  handleFocus: () => {},
  handleBlur: () => {},
  handleClick: () => {},
  value: '',
  id: 'js-' + randomIdentifier(),
  name: 'input-' + randomIdentifier(),
  errorMsg: '',
  placeHolder: '',
  btnClass: '',
  btnLabel: '',
  appendContent: false,
  disabled: false,
  readonly: false,
  type: 'text',
  autoComplete: 'on',
  maxLength: '1000',
  searchIcon: false,
  rows:6,
  contCharacter:false,
  defaultCountCharacter:0,
  fadeLastCharacter:false,
};

export default memo(TextAreaAnimationComponent);
