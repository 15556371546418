/*
* Text Input Component
* */
import React, { useEffect, useState, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {randomIdentifier} from "../../../utils/helpers";

type Props = {
    label: any,
    cssClass: String,
    style: Object,
    disabled: boolean,
    loadingBtn: boolean,
    loading: boolean,
    loadingColor: String,
    handleClick: any,
    handleDbClick: any
};


const ButtonComponent = ({
    label,
    cssClass,
    style,
    loading,
    disabled,
    loadingColor,
    handleClick,
    handleDbClick,
    type,
    loadingBtn
                           }: Props) => {

    const [isLoading, setIsLoading] = useState(loading);
    // const [isDisabled, setIsDisabled] = useState(disabled);

    // useEffect(() => {
    //     setIsDisabled(!disabled);
    // }, [disabled]);

    const _handleClick = (e) => {
        if(typeof handleClick == 'function') handleClick(e)

        setIsLoading(!isLoading)

        // if(type == 'submit'){
            // if(!disabled)
            //     setIsDisabled(!isDisabled)

        // }
    }

    const _handleDbClick = (e) => {
        if(typeof handleDbClick == 'function') handleDbClick(e)
        setIsLoading(!isLoading)
    }

    return (
        <>
            <button
                style={{...style, display: "flex", alignItems: "center", justifyContent: "center"}}
                className={cssClass}
                disabled={disabled}
                onClick={_handleClick}
                onDoubleClick={_handleDbClick}
                type={type ?? 'button'}
            >
                <span>{label}</span>
                {!loadingBtn ? "" : <>
                        {!isLoading ? "" :
                            <div className="loading-icon-1">
                                <div className="loading-icon-1-spinner">
                                    <div style={{borderColor: loadingColor, borderTopColor: "transparent"}}></div>
                                </div>
                            </div>
                        }
                    </>
                }
            </button>
        </>
    );
}

ButtonComponent.defaultProps = {
    label: "button",
    cssClass: "btn btn-primary",
    type: 'button',
    style: {},
    loadingBtn: false,
    loading: false,
    disabled: false,
    loadingColor: '#FFF'
};

export default memo(ButtonComponent);
