import React, { useEffect, useState, memo } from "react";

import { slide as Menu } from "react-burger-menu";
import { openMenu } from "../../../pages/Main/redux/actions";
import { useDispatch, useSelector } from "react-redux";

import SettingIcon from  "../../../assets/images/settingIcon.svg"
import CloseIconMenu from  "../../../assets/images/closeIconMenu.svg"
import ListMenuComponent from "./ListMenuComponent";
import {useNavigate} from "react-router-dom";
import ROUTERS from "../../constans/router";
type Props = {
};
const Menubar = ({}: props) => {
  const { menuStatus } = useSelector((state) => state.mainReducers);
  const { userInfo } = useSelector((state) => state.authReducers);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    setIsMenuOpen(menuStatus);
  }, [menuStatus]);

  const handleCloseMenu = () => {
    dispatch(openMenu(false));
  };

  const handleStateChange = (state) => {
    dispatch(openMenu(state.isOpen));
    setIsMenuOpen(state.isOpen);
  };

  const handleSetting = () => {
    navigate(ROUTERS.SETTING_MENU)
    dispatch(openMenu(false));
  }

  return (
    <Menu
      right
      className={"menu-wrap-right"}
      isOpen={isMenuOpen}
      onStateChange={handleStateChange}
      itemListClassName={"nav"}
      customBurgerIcon={false}
      width={'100%'}
    >
      <div className="nav__wrapper">
        <div className="nav__header">
          {
            userInfo.id && 
            <div className="nav__header__setting-btn" onClick={() => handleSetting()}>
              <img src={SettingIcon} />
            </div>
          }
          
          <div className="nav__header__phone-user" >
            <span>{userInfo?.phone_number ? (userInfo?.phone_number + '님') : ''}</span>
          </div>
          <div className="nav__header__close-btn" onClick={() => handleCloseMenu()}>
            <img src={CloseIconMenu} />
          </div>
        </div>
        <div className="nav__body">
          <ListMenuComponent />
        </div>
      </div>
    </Menu>
  );
};

export default memo(Menubar);
