import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";


import {useDispatch, useSelector} from "react-redux";
import MainLayout from "../../../commons/layout/MainLayout/index";

import HeaderHs2 from "../../../commons/components/Header/headerHs2";

import ROUTERS from "../../../commons/constans/router";
import InfiniteScroll from 'react-infinite-scroll-component';
import API, {ROUTES} from "../../../commons/api/api";

import moment from "moment";
import SkeletonLoadingComponent from "../../../commons/components/SkeletonLoading";
import Header from "../../../commons/components/Header/header";
import LeftIconHeader from "../../../assets/images/leftIconHeader.svg";
import InputComponent from "../../../commons/components/Input/InputComponent";
import DatimePickerComponent from "../../../commons/components/DatimePicker/DatimePickerComponent";
import ImageBoxComponent from "../../../commons/components/ImageBox/ImageBoxComponent";
import ImageBoxAddMoreComponent from "../../../commons/components/ImageBox/ImageBoxAddMoreComponent";
import TextAreaComponent from "../../../commons/components/TextArea/TextAreaComponent";
import ButtonComponent from "../../../commons/components/Button/ButtonComponent";
import IconEventClose from "../../../assets/images/event_close.svg";
import {useRole} from "../../../commons/customHooks/Account";
import ModalComponent from "../../../commons/components/Modal/ModalComponent";
import IconCalender from "../../../assets/images/icon_calender.svg";
import Loading from "../../../commons/components/Loading";
import DropDownCommon from "../../../commons/components/Dropdown";
import {br2nl} from "../../../utils/helpers";

import TextAreaAnimationComponent from "../../../commons/components/TextArea/TextAreaAnimationComponent";



const getBase64Img =  async (file) => {
    return new Promise(resolve => {
        let fileInfo;
        let baseURL = "";
        // Make new FileReader
        let reader = new FileReader();

        // Convert the file to base64 text
        reader.readAsDataURL(file);

        // on reader load somthing...
        reader.onload = () => {
            // Make a fileInfo Object
            // console.log("Called", reader);
            baseURL = reader.result;
            // console.log(baseURL);
            resolve(baseURL);
        };
        // console.log(fileInfo);
    });
};

const PreviewImage = (props) => {
    const [img, setImg] = useState(null)

    let file = props.file
    let index = props.index

    getBase64Img(file)
        .then(result => {
            setImg(result)
        })
        .catch(err => {
            console.log(err);
        })
    return (
        <div className='image-box-card image-box-card-render'>
            <div className="attachment__item attachment-img" >
                {props.showRemoveImg ? (<img className='icon-remove' src={IconEventClose} onClick={()=>props.removeFile(index)} />) : ''}
                <div className="input-selector">
                    <div className="attachment__item__content">
                        <img className="" src={img} />
                    </div>

                </div>
            </div>
        </div>

    )
}

const PreviewImageUploaded = (props) => {
    const [img, setImg] = useState(null)

    let index = props.index
    // console.log(props,'PreviewImageUploaded')

    return (
        <div className='image-box-card image-box-card-render'>
            <div className="attachment__item attachment-img" >
                <img className='icon-remove' src={IconEventClose} onClick={()=>props.removeFile(index)} />
                <div className="input-selector">
                    <div className="attachment__item__content">
                        <img className="" src={props?.file?.url_medium} />
                    </div>

                </div>
            </div>
        </div>

    )
}


const EventEditPageComponent = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [stateDetail, setStateDetail] = useState(0);
    const [fileUpload, setCheckFileUpload] = useState([]);
    const [countCharacterInput, setCountCharacterInput] = useState(0);
    const [countCharacterTextArea, setCountCharacterTextArea] = useState(0);
    const [statusFetchDetail, setStatusFetchDetail] = useState(false);
    const [statusModal, setStatusModal] = useState(false);
    const [statusSubmit, setStatusSubmit] = useState(true);
    const [dataForm, setDataForm] = useState({
        subject: '',
        content: '',
        start_date: moment().toDate(),
        end_date: moment().toDate(),
        attachment : []
    })
    const { userInfo  } = useSelector((state) => state.authReducers);

    const params = useParams();

    var id = params?.id ? params?.id : '';


    const [dataDetail, setDataDetail] = useState([])
    const [keyDelete, setImageKeyDelete] = useState([])
    const [dialogNotice, setDialogNotice] = useState({
        show: false,
        content: '',
        btn:1
    });

    const isUser = useRole('user');
    const isShop = useRole('shop');
    const isManager = useRole('manager');

    const [date, setDate] = useState(moment().toDate())
    const [minDate, setMinDate] = useState(moment().toDate())
    const [minDateRanger, setMinDateRanger] = useState(moment().toDate())
    const [endDate, setEndDate] = useState(moment().toDate())
    const [maxDate, setMaxDate] = useState(moment(minDateRanger))

    const [storeList, setStoreList] = useState([]);
    const [activeDropDown, setActiveDropDown] = useState({
        id:0,
        store_name:'매장명'
    })



    const fetchData = async (param = '') =>{
        try {
            const {data: response} = await API.get(ROUTES.API_EVENT+(param ? '?id='+param : ''));
            // console.log(response?.data,'id333')
            setDataDetail(response?.data);
            setDataForm({
                subject: response?.data?.title,
                content: br2nl(response?.data?.content || ''),
                start_date: response?.data?.start_date,
                end_date: response?.data?.end_date,
                attachment : response?.data?.image
            })
            setActiveDropDown({store_name:response?.data?.store?.store_name,id:response?.data?.store?.id})
            setCheckFileUpload(response?.data?.image || [])
            setCountCharacterInput(response?.data?.title.length)
            setCountCharacterTextArea(response?.data?.content ? br2nl(response?.data?.content).length : 0)

            setDate(new Date(response?.data?.start_date))
            setMinDateRanger(new Date(response?.data?.end_date))
            setEndDate(new Date(response?.data?.end_date))
            // setMinDate(new Date(response?.data?.start_date))

            setStatusFetchDetail(true)//khi fetch data xong moi load component page
        } catch (error) {
            console.error(error);

        }
    }

    const fetchDataStoreId = async (param = '?approval_state[]=ACTIVED&store_by_user_id='+userInfo?.id) =>{
        try {
        const {data: response} = await API.get(ROUTES.API_GET_LIST_STORES+param);
        setStoreList(response?.data);
        } catch (error) {
        console.error(error.message);
    
        }
    }

    useEffect(() => {
        fetchData(id);
        fetchDataStoreId()
    }, []);



    const backMenuHome = () => {
        navigate(ROUTERS.EVENT)
    }

    useEffect(() => {
        // console.log(fileUpload,'fileUpload1111')
        setDataForm({
            ...dataForm,
            attachment : fileUpload
        })
    }, [fileUpload]);

    const handleChangeInput = (e) => {
        let trimContent = e.target.value;
        console.log(trimContent,'trimContent',trimContent.length)
        setDataForm({
            ...dataForm,
            [e.target.name] : trimContent
        })

        if(e.target.name == 'content') {
            setCountCharacterTextArea(trimContent.length)
        }else {
            setCountCharacterInput(trimContent.length)
        }

    }

    const handleFocusInput = () => {
        console.log('handleFocusInput');
    }
    const handleBlurInput = () => {
        console.log('handleBlurInput');
    }
    const handleClickInput = () => {
        console.log('handleClickInput');
    }
    const handleChangeStartDateTimePicker = (e) => {
        // console.log(e,'handleChangeTimePicker')
        setDataForm({
            ...dataForm,
            start_date : e
        })
    }
    const handleChangeEndDateTimePicker = (e) => {
        // console.log(e,'handleChangeTimePicker')
        setDataForm({
            ...dataForm,
            end_date : e
        })
    }
    const handleChangeImage1 = (e) => {

        let fileUploadChange = fileUpload.length > 0 ? fileUpload : []

        if(e?.file && e?.file.length != 0){
            // let fileUploadChangeGroup = [...fileUploadChange, ...e.file]
            setCheckFileUpload([...fileUpload,e.file])
        }

    }
    const handleClickBtn = () => {

    }
    const handleRemoveImg = (indexImgRemove) => {
        
        let dataFileUpLoad = fileUpload
        if (indexImgRemove > -1) {
            dataFileUpLoad.splice(indexImgRemove, 1); // 2nd parameter means remove one item only
        }

        setCheckFileUpload(dataFileUpLoad)

    }
    const removeFileRender = (imgRemove) => {
        // console.log('handleRemoveImg1111',imgRemove)
        var dataFileUpLoad = [...fileUpload];

        if (imgRemove?.index > -1) {
            dataFileUpLoad.splice(imgRemove?.index, 1); // 2nd parameter means remove one item only
            setCheckFileUpload((dataFileUpLoad.length > 0 ? dataFileUpLoad : []))
        }
        
        if(imgRemove?.typeFile){
            setImageKeyDelete([...keyDelete,imgRemove?.index])
        }
        // setCheckFileUpload((dataFileUpLoad.length > 0 ? dataFileUpLoad : []))

    }

    const validateForm = () => {
        let statusValidate =  true;
        let validateSubject = !dataForm.subject
        let errorSet = {}
        let formatStartDate = moment(dataForm.start_date).format('Y-MM-DD');
        let formatEndDate = moment(dataForm.end_date).format('Y-MM-DD');
        let validateContent= !dataForm.content

        if (dataForm.subject.length > 30){
            statusValidate = false
            errorSet.subject='제목은  30자 이내 입력 가능합니다';
        }

        if(validateSubject) {
            statusValidate = false
            errorSet.subject='제목 입력해주세요';
        }
        if(activeDropDown?.id == 0) {
            statusValidate = false
            errorSet.subject='제목 입력해주세요';
        }
        if(formatStartDate > formatEndDate){
            statusValidate = false
            errorSet.subject='The start date not more end time';
        }
        // if (dataForm?.content && dataForm.content.length > 1000){
        //     statusValidate = false
        //     errorSet.content='제목은  1000자 이내 입력 가능합니다';
        // }

        // console.log('dataForm validateForm',errorSet,formatStartDate,formatEndDate)
        // setErrorForm(errorSet)
        return statusValidate
    }

    const handleSubmitForm = () => {

        const formData = new FormData();

        formData.append("content", dataForm.content);
        formData.append("title", dataForm.subject);
        formData.append("start_date",  moment(dataForm.start_date).format('Y-MM-DD'));
        formData.append("end_date", moment(dataForm.end_date).format('Y-MM-DD'));
        formData.append("store_id", activeDropDown.id);

        if(dataForm.attachment.length > 0) {

            dataForm.attachment.map((item,index)=>{
                if(item?.size){
                    formData.append("event_image[]", item)
                }
            })
        }
        if(keyDelete.length > 0){
            keyDelete.map((item,index)=>{
                formData.append("index_event_image[]", item)
            })
        }


        let validateStatus = validateForm();

        if(validateStatus && statusSubmit) {
            setStatusSubmit(false)
            API.post(ROUTES.API_EVENT_EDIT.replace(':id',id), formData).then(res => {

                // setStatusSubmit(false)
                navigate(ROUTERS.EVENT)
                setStatusSubmit(true)
            }).catch(e => {
                setStatusSubmit(true)
                let errors = e.response;
                let dataError = {};

                switch (errors.status) {
                    case 422 :

                        if (errors.data && errors.data.errors) {
                            Object.keys(errors.data.errors).map(function (key, index) {
                                dataError[key] = errors.data.errors[key][0];
                            });
                            setDialogNotice({
                                show : true,
                                btn:1,
                                content : '기존에 등록한 이벤트와 일정이 겹칩니다.\n' +
                                    '일정 확인해 주세요.\n'
                            })
                        }
                        break;
                    case 404 :
                        setDialogNotice({
                            show : true,
                            btn:1,
                            content : '가입되어 있지 않은 회원 정보입니다.\n' +
                                '다시 확인해주세요.\n'
                        })
                }
            })
        }

    }
    const handleRemoveEvent = () => {
        // setStatusModal(true)
        setDialogNotice({
            show : true,
            btn:2,
            content : '이벤트를 삭제하시겠습니까?'
        })
    }
    const handleCancelBtn = () => {
        // setStatusModal(false)
        setDialogNotice({...dialogNotice,show:false})
    }
    const handleSubmitBtn = () => {
        const formDataDelete = new FormData();
        formDataDelete.append("ids[]", [id])
        API.post(ROUTES.API_EVENT_REMOVE.replace(':id',id), formDataDelete).then(res => {

            // setStatusSubmit(false)
            setStatusModal(false)
            navigate(ROUTERS.EVENT)

        }).catch(error => {


        })
    }
    const handleChangeTimePicker = (e,typeDate) => {

        if(typeDate == 'start_date') {
            
            if(moment(dataForm.end_date).format('Y-MM-DD') >= moment(e).format('Y-MM-DD')){
                // console.log(e,'44444',new Date(dataForm.end_date))
                setDataForm({
                    ...dataForm,
                    start_date : e,
                })
                setMinDateRanger(e)
            }else {
                setMinDateRanger(e)
                setEndDate(e)
                setDataForm({
                    ...dataForm,
                    start_date : e,
                    end_date : e,
                })
            }
            
        }else {
            setDataForm({
                ...dataForm,
                end_date : e
            })
        }
    }

    const chooseStore = (e) => {

        setActiveDropDown({...activeDropDown,id:e?.id})
    }



    return (
        <div>
            {(isShop || isManager) && statusFetchDetail ? <MainLayout
                pageID={"event-create-page"}
            >
                <Header
                    leftChild={LeftIconHeader}
                    handleLeftClick={() => backMenuHome()}
                    leftIcon={true}
                    centerChild={'나'}
                    rightChild={(isShop || isManager) ? '완료' : ''}
                    handleRightClick={()=>handleSubmitForm()}
                    centerIcon={false}
                    rightIcon={false}
                />
                <div className='wrapper'>

                    <div className="item-choose-store is-manager">
                        <div className="input-group select-type dropdown-common ">
                            <div className="dropdown w-100">
                                <button
                                    type="button"
                                    id="dropdown-item-button"
                                    aria-expanded="false"
                                    className="dropdown-toggle btn btn-primary">
                                    {dataDetail?.store?.store_name}
                                </button>
                            </div>
                        </div>
                    </div>
                    {!statusSubmit ?  <Loading/> : ''}
                    <div className='form-submit'>

                        <div className='item-group required item-group-title'>
                            <InputComponent
                                label={'이벤트 문구'}
                                isRequired={''}
                                errorMsg={''}
                                placeHolder={'새롭게 단장하였습니다.'}
                                id={''}
                                name={'subject'}
                                value={dataForm.subject}
                                handleChange={handleChangeInput}
                                handleFocus={handleFocusInput}
                                handleBlur={handleBlurInput}
                                handleClick={handleClickInput}
                                btnClass={''}
                                btnLabel={''}
                                btnHandleClick={''}
                                appendContent={''}
                                disabled={''}
                                readonly={''}
                                pattern={''}
                                type={''}
                                autoComplete={''}
                                searchIcon={false} //IconSearch
                                timeCountDown={''} // '3:00' || false
                                maxLength={'30'}
                                characterLenght={countCharacterInput == 0 ? '0' : countCharacterInput}
                                borderBottom={true}
                                deleteIcon={''}
                                wrapClass={'required-label'}
                            />
                        </div>
                        <div className='item-group required'>
                            <label className='label-form'>이벤트 기간</label>
                            <div className='item-group__ranger-time d-flex align-items-center'>
                                <div className='item-time'>
                                    {/*<DatimePickerComponent value={''} handleChange={(e) => handleChangeStartDateTimePicker(e)}/>*/}
                                    <div className='item-calender-component'>
                                        <DatimePickerComponent
                                          value={date}
                                          handleChange={(e)=>handleChangeTimePicker(e,'start_date')}
                                          minDate={minDate}
                                          // maxDate={maxDate}
                                        />
                                        <img className={'item-calender-component__icon'} src={IconCalender} />
                                    </div>
                                </div>
                                <label>~</label>
                                <div className='item-time'>
                                    {/*<DatimePickerComponent value={''} handleChange={(e) => handleChangeEndDateTimePicker(e)}/>*/}
                                    <div className='item-calender-component'>
                                        <DatimePickerComponent
                                          value={endDate}
                                          handleChange={(e)=>handleChangeTimePicker(e,'end_date')}
                                          minDate={minDateRanger}
                                          maxDate={maxDate}
                                        />
                                        <img className={'item-calender-component__icon'} src={IconCalender} />
                                    </div>
                                </div>

                            </div>
                        </div>
                        {/* <div className='item-group required'>
                            <label className="label-form">이벤트 기간</label>
                            <DropDownCommon
                                dataList={[{id:0,store_name:'choose option'},...storeList]}
                                nameDisplay={activeDropDown?.store_name}
                                handleClick={chooseStore}
                                keyName={'store_name'}
                                idActive={activeDropDown?.id}
                            />
                        </div> */}

                        <div className='item-group item-group-upload'>
                            <label className='label-form'>이벤트 사진(최대 3장)</label>
                            <div className='card-upload d-flex align-items-center'>
                                <ImageBoxAddMoreComponent 
                                    imageList={fileUpload}
                                    nameFile={'attachment1'}
                                    handleChange={(e) => handleChangeImage1(e)}
                                    showRemoveImg={true} classCustom={''}
                                    removeImg={(e) => removeFileRender(e)}
                                    textCard={`이벤트 설명사진${fileUpload.length+1} 등록`}
                                />
                            </div>
                            {/* <div className='card-upload d-flex align-items-center mt-2'>
                                { !fileUpload  || fileUpload.length > 0 && fileUpload.map((item,index)=>(
                                    item?.url_medium ? <PreviewImageUploaded file={item}
                                    key={index}
                                    index={index}
                                    showRemoveImg={true}
                                    removeFile={(index)=>removeFileRender(index,'uploaded')}
                                    /> : <PreviewImage file={item}
                                    key={index}
                                    index={index}
                                    showRemoveImg={true}
                                    removeFile={(index)=>removeFileRender(index)}
                                    />
                                ))}
                                {fileUpload && fileUpload.length < 3 ? (<ImageBoxComponent
                                    nameFile={'attachment1'}
                                    handleChange={(e) => handleChangeImage1(e)}
                                    showRemoveImg={true} classCustom={''}
                                    removeImg={(e) => handleRemoveImg(e)}
                                    textCard={`이벤트 설명사진${fileUpload.length+1} 등록`}
                                    indexImg={0}
                                    imgNotShow={true}
                                />) : ''}

                            </div> */}
                        </div>
                        <div className='item-group '>
                            <TextAreaComponent
                                placeHolder={'이벤트 내용을 입력해주세요.'}
                                contCharacter
                                label={'이벤트 내용'}
                                name={'content'}
                                handleChange={handleChangeInput}
                                defaultCountCharacter={dataForm?.content?.length && dataForm?.content?.length > 1000 ? 1000 : dataForm?.content?.length}
                                value={dataForm.content}
                                fadeLastCharacter={true}
                            />
                            {/*<TextAreaAnimationComponent*/}
                            {/*  placeHolder={'이벤트 내용을 입력해주세요.'}*/}
                            {/*  contCharacter*/}
                            {/*  label={'이벤트 내용'}*/}
                            {/*  name={'content'}*/}
                            {/*  handleChange={handleChangeInput}*/}
                            {/*  defaultCountCharacter={dataForm?.content?.length && dataForm?.content?.length > 1000 ? 1000 : dataForm?.content?.length}*/}
                            {/*  value={dataForm.content}*/}
                            {/*  fadeLastCharacter={true}*/}
                            {/*  maxLength={1000}*/}
                            {/*/>*/}

                        </div>
                        {(isShop || isManager) ? <div className='item-group item-group-remove'>
                            <ButtonComponent
                              label={'이벤트 삭제하기'}
                              style={{width: '100%'}}
                              cssClass={'mb-2'}
                              handleClick={handleRemoveEvent}
                              fixedBtn={true}
                            />
                        </div> : ''}


                    </div>

                </div>
                <ModalComponent
                    id={``}
                    show={dialogNotice?.show}
                    title={`알림`}
                    cancelBtn={`취소`}
                    submitBtn={dialogNotice?.btn == 2 ? `확인` : ''}
                    handleCancel={handleCancelBtn}
                    handleSubmit={handleSubmitBtn}
                >
                    <div>
                        {dialogNotice?.content}
                    </div>
                </ModalComponent>
            </MainLayout> : ''}

        </div>
    );
};

export default EventEditPageComponent;
