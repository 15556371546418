import {useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {loginRequest} from "../../../pages/Account/redux/actions";
import { hasRole } from '../../../utils/hasRole';

export const useAuth = () => {
  const auth = useSelector(state => state.authReducers);

  return useMemo(() => {
    return {
      auth,
    };
    //eslint-disable-next-line
  }, [auth]);
};


export const useUser = () => {
  const auth = useSelector(state => state.authReducers);
  const user = auth.userInfo

  return useMemo(() => {
    return {
      user,
    };
    //eslint-disable-next-line
  }, [user]);
};

export const useRole = (role) => {
  const { userInfo } = useSelector(state => state.authReducers);
  return hasRole(userInfo, role)
};




// export const loginRequestHook = () => {
//   const dispatch = useDispatch();
//   const { userInfo ,type, isProcessing, token, statusCode , errors } = useSelector(state => state.authReducers);
//   // const [registerSuccess, setRegisterSuccess] = useState(false)
//
//   const handleSubmit = values => {
//     dispatch(loginRequest(values));
//   };
//
//   useEffect(() => {
//     console.log(type,'typeeeeeeeeee')
//     if (type === 'REGISTER_AWARD_SUCCESS') {
//       // setRegisterSuccess(true)
//     }
//   }, [type])
//
//   return [handleSubmit,userInfo,isProcessing];
// };