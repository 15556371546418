import React, { useEffect, useState } from 'react';
import ButtonComponent from '../../../../commons/components/Button/ButtonComponent';
import ImageBoxComponent from '../../../../commons/components/ImageBox/ImageBoxComponent';
import InputComponent from '../../../../commons/components/Input/InputComponent';
import IconDelInput from '../../../../assets/images/iconDelInput.png';
import PlusIcon from '../../../../assets/images/plus.svg';
import { numberFormat, priceFormat } from '../../../../utils/helpers';
import Loading from '../../../../commons/components/Loading';
import API, { ROUTES } from '../../../../commons/api/api';
type Props = {
  dataMenu: Array,
  handleChangeStoreMenu: Function,
  handleDeleteMenus: Function,
  storeId: Number
};

const StoreMenuComponent = ({
  dataMenu,
  handleChangeStoreMenu,
  handleDeleteMenus,
  storeId
}: Props) => {
  const [dataMenuChange, setDataMenuChange] = useState(dataMenu);
  const [dataInput, setDataInput] = useState({});
  const [deleteMenu, setDeleteMenu] = useState([]);
  const [loadingUpLoad, setLoadingUpload] = useState(false);

  useEffect(() => {
    // let dataNoMenu = {
    //   id: null,
    //   name: '',
    //   price: null,
    //   photo: null,
    // };
    // if (dataMenu.length > 0) {
    //   setDataMenuChange([dataNoMenu, ...dataMenu]);
    // }
    setDataMenuChange(dataMenu);
  }, [dataMenu]);

  const handleDeleteInput = (id, idx) => {
    let dataDelete = [...dataMenuChange];
    dataDelete.splice(idx, 1);
    setDataMenuChange(dataDelete);
    setDeleteMenu([...deleteMenu, id]);
  };

  useEffect(() => {
    handleDeleteMenus(deleteMenu);
  }, [deleteMenu]);

  const handleChangeInput = (e, idx) => {
    let value = e.target.value;
    if (value.length <= 15) {
      const _dataMenuChange = dataMenuChange.map((menu, index) => {
        if (idx === index) {
          return {
            ...menu,
            [e.target.name]: e.target.value,
          };
        }
        return menu;
      });
      setDataMenuChange(_dataMenuChange);
    }else{
      return;
    }
  };

  const handleChangeImageMenu = (e, idx) => {
    
    if (e.file instanceof File) {
      setLoadingUpload(true);
      let formData = new FormData();
      formData.append('file', e.file);
      formData.append('store_id', storeId);
      
      API.post(ROUTES.API_UPLOAD_FILE, formData)
        .then((res) => {
          setLoadingUpload(false);
          if (res.data?.data) {
            const _dataMenuChange = dataMenuChange.map((menu, index) => {
              if (idx === index) {
                return {
                  ...menu,
                  photo: res.data?.data,
                };
              }
              return menu;
            });
            setDataMenuChange(_dataMenuChange);
          }
        })
        .catch((err) => {
          setLoadingUpload(false);
        });
    }
    
  };

  const handleRemoveImgMenu = (e, idx) => {
    let _dataMenuChange = dataMenuChange;
    if (_dataMenuChange.length > 0) {
      const _dataMenuChange = dataMenuChange.map((menu, index) => {
        if (idx === index) {
          return {
            ...menu,
            photo: null,
          };
        }
        return menu;
      });
      setDataMenuChange(_dataMenuChange);

      // _dataMenuChange.splice(e.index, 1); 
      // setDataMenuChange([..._photoGallery]);
    }
  };

  useEffect(() => {
    handleChangeStoreMenu(dataMenuChange);
  }, [dataMenuChange]);

  const handleClickAddMenu = () => {
    let dataNoMenu = {
      id: null,
      name: '',
      price: '',
      photo: null,
    };
    setDataMenuChange([...dataMenuChange, dataNoMenu]);
  };

  const renderStoreMenu = (data) => {
    if (data.length > 0) {
      return data.map((item, idx) => (
        <>
          <li className="item-menus">
            <div className="content-left">
              <InputComponent
                label={''}
                isRequired={''}
                errorMsg={''}
                inputClass={'border-radius'}
                placeHolder={'메뉴를 입력해주세요.'}
                id={'input-delete-1'}
                name={'name'}
                value={item.name}
                handleChange={(e) => handleChangeInput(e, idx)}
                btnClass={''}
                btnLabel={''}
                btnHandleClick={''}
                appendContent={''}
                disabled={''}
                readonly={''}
                pattern={''}
                type={''}
                autoComplete={''}
                searchcIon={false} //IconSearch
                timeCountDown={''} // '3:00' || false
                maxLength={'15'}
                characterLenght={item?.name?.length || '0'}
                borderBottom={false}
                // deleteIcon={idx == 0 && !item.id ? '' : IconDelInput}
                deleteIcon={IconDelInput}
                btnHandleDelete={() => handleDeleteInput(item.id, idx)}
                
              />
              
              <InputComponent
                label={''}
                isRequired={''}
                errorMsg={''}
                inputClass={'border-radius input-price'}
                placeHolder={'핀매가를 숫자만 입력해주세요.'}
                name={'price'}
                value={item.price ? priceFormat(item.price) : ''}
                handleChange={(e) => handleChangeInput(e, idx)}
                btnClass={''}
                btnLabel={''}
                btnHandleClick={''}
                appendContent={''}
                disabled={''}
                readonly={''}
                pattern={''}
                type={'text'}
                textRight={`원`}
                borderBottom={false}
                numberType={`price-number`}
                maxLength={'9'}
              />
            </div>
            <div className="content-right">
              <ImageBoxComponent
                nameFile={'photo'}
                handleChange={(e) => handleChangeImageMenu(e, idx)}
                removeImg={(e) => handleRemoveImgMenu(e, idx)}
                textCard={'내 핸드폰에서 이미지 선택하기'}
                type={'style-text'}
                imgItem={item?.photo?.url_medium ? item?.photo?.url_medium : item?.photo}
              />
            </div>
          </li>
        </>
      ));
    } else {
      return (
        <li className="item-noData">
          <p>등록된 메뉴가 없습니다.</p>
        </li>
      );
    }
  };
  return (
    <div className="store-menu-update">
      <label className="title">메뉴정보</label>
      <ul>{renderStoreMenu(dataMenuChange || [])}</ul>
      <ButtonComponent
        label={'메뉴 추가'}
        style={{ width: '100%', backgroundColor: '#fff' }}
        iconBtn={PlusIcon}
        cssClass={'mb-2 btn-border'}
        handleClick={handleClickAddMenu}
      />
      {loadingUpLoad && <Loading />}
    </div>
  );
};
export default StoreMenuComponent;
