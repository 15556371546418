// @flow
import React, { memo, useEffect, useState } from 'react';

type Props = {
  id: String,
  show: boolean,
  children: any,
  title: String,
  submitBtn: any,
  cancelBtn: any,
  className: String,
  handleSubmit: Function,
  handleCancel: Function 
};

const ModalComponent = ({
  id,
  show,
  children,
  title,
  handleCancel,
  cancelBtn,
  submitBtn,
  className,
  handleSubmit,
}: Props) => {
  const [isShow, setIsShow] = useState(show);

  useEffect(() => {
    setIsShow(show);
  }, [show]);

  const _handleCancel = () => {
    setIsShow(false);
    if (typeof handleCancel == 'function') handleCancel(id, isShow);
  };

  const _handleSubmit = () => {
    if (typeof handleSubmit == 'function') handleSubmit();
  };

  return (
    <>
      <div
        className={`cs-alert ${isShow ? 'show' : ''} ${
          className ? className : ''
        }`}
      >
        <div className='modal-title'>
          {title && <h3>{title}</h3>}
        </div>
        <div className="cs-alert-body">{children}</div>
        <div
          className={`radius-btn`}
        >
          {cancelBtn && (
            <button
              onClick={_handleCancel}
              className="btn btn-cancel"
            >
              {cancelBtn}
            </button>
          )}
          {submitBtn && (
            <button
              onClick={_handleSubmit}
              className="btn btn-submit"
            >
              {submitBtn}
            </button>
          )}
        </div>
      </div>
      <div className={'cs-alert-overlay'}></div>
    </>
  );
};

ModalComponent.defaultProps = {
  id: '',
  show: false,
  title: false,
  className: '',
};

export default ModalComponent;
