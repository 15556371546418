
import ROUTERS from '../../commons/constans/router';
import React, { lazy } from 'react';


const EventComponent = lazy(() => import('./containers/EventPageContainer'));
const EventCreateComponent = lazy(() => import('./components/create'));
const EventDetailComponent = lazy(() => import('./containers/EventDetailPageContainer'));
const EventEditComponent = lazy(() => import('./components/edit'));


const route = [
  {
    path: ROUTERS.EVENT,
    title: 'Event',
    exact: true,
    // public: true,
    // roles: ['shop', 'manager','user'],
    component: <EventComponent />,
  },
  {
    path: ROUTERS.EVENT_INPROGRESS,
    title: 'Event',
    exact: true,
    // public: true,
    // roles: ['shop', 'manager','user'],
    component: <EventComponent type={'event-inprogress'} />,
  },
  {
    path: ROUTERS.EVENT_INPROGRESS_DETAIL,
    title: 'Event',
    exact: true,
    // public: true,
    // roles: ['shop', 'manager','user'],
    component: <EventDetailComponent type={'event-inprogress'} />,
  },
  // {
  //   path: ROUTERS.EVENT,
  //   title: 'Event',
  //   exact: true,
  //   public: true,
  //   roles: ['shop', 'manager','user'],
  //   component: <EventComponent />,
  // },
  {
    path: ROUTERS.EVENT_CREATE,
    title: 'Event Create',
    exact: true,
    public: false,
    roles: ['shop', 'manager'],
    component: <EventCreateComponent />,
  },
  {
    path: ROUTERS.EVENT_DETAIL,
    title: 'Event Detail',
    exact: true,
    // public: false,
    roles: ['shop', 'manager','user'],
    component: <EventDetailComponent />,
  },
  {
    path: ROUTERS.EVENT_DETAIL_NON_MEMBER,
    title: 'Event Detail',
    exact: true,
    public: true,
    roles: ['shop', 'manager','user'],
    component: <EventDetailComponent />,
  },
  {
    path: ROUTERS.EVENT_EDIT,
    title: 'Event Edit',
    exact: true,
    public: false,
    roles: ['shop', 'manager'],
    component: <EventEditComponent />,
  }
];

export default route;