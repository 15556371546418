import TermsOfUseDetailPageComponent from "../../TermsOfUse/components/detail";
import React, {useEffect, useState} from "react";
import API, {ROUTES} from "../../../commons/api/api";
import {useParams} from "react-router-dom";

const TermsOfUseDetailPageContainer  = () => {

    const [dataDetail, setDataDetail] = useState([])
    const [loading, setLoading] = useState(true)
    const params = useParams();
    console.log(params,'useParams')
    var id = params?.id ? params?.id : ''

    const fetchData = async (param = '') =>{
        try {
            const {data: response} = await API.get(ROUTES.API_TERMS_OF_USE+(param ? '?id='+param : ''));
            setDataDetail(response?.data);
            setLoading(false)
        } catch (error) {
            console.error(error.message);
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchData(id);
    }, []);

    const loadMoreData = () => {

    }

    return <TermsOfUseDetailPageComponent loading={loading} dataDetail={dataDetail}

    />;
};

export default TermsOfUseDetailPageContainer ;