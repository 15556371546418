import {Fragment, useEffect, useState} from "react";
import {useNavigate,useParams} from "react-router-dom";


import {useDispatch} from "react-redux";
import MainLayout from "../../../commons/layout/MainLayout/index";

import HeaderHs2 from "../../../commons/components/Header/headerHs2";
import ROUTERS from "../../../commons/constans/router";
import InfiniteScroll from 'react-infinite-scroll-component';

import moment from 'moment';

import SkeletonLoadingComponent from "../../../commons/components/SkeletonLoading";
import ItemInListComponent from "../../../commons/components/ItemInList/ItemInListComponent";
import EmptyDataComponent from "./emptyData";
import {useRole} from "../../../commons/customHooks/Account";


const EventPageComponent = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [EventList, setEventList] = useState([])
    const [page, setPage] = useState(1)
    const [statusLoading, setStatusLoading] = useState(false)


    useEffect(() => {
        if(props.loadMore){
            setStatusLoading(true)
        }else {
            setStatusLoading(false)
        }
    }, [props]);

    useEffect(() => {
        let dataList = []
        if(EventList.length > 0) {
            dataList= [...EventList,...(props?.eventData?.data || [])]
        }else {
            dataList=props?.eventData?.data || [];
        }
        console.log(dataList,'dataList',props?.eventData?.data)
        setEventList(dataList)
    }, [props?.eventData?.data]);

    const backMenuHome = () => {
      navigate(ROUTERS.HOME)
    }

    const openDetail = (id) => {
        let url = ROUTERS.EVENT_DETAIL
        if(props?.eventInprogress){
            url = ROUTERS.EVENT_INPROGRESS_DETAIL
        }
        url = url.replace(':id',id)
        navigate(url)
    }
    const fetchMoreData = () => {
        let dataFetch = [...EventList];
        if (props?.eventData?.from < props?.eventData?.last_page && statusLoading) {
            let pageLoad = page + 1;
            setPage(pageLoad)
            let params= '?page='+pageLoad+'&per_page=20&order_by_created_at=desc'
            props.fetchData(params)
        }
    }

    const loadingDiv = <div className="lists__item loading-content">
        <div className="lists__item__content w-100">
            <span className="w-100">
                <SkeletonLoadingComponent circle={false} width="100%" height="14px"/>
            </span>
            <h3 className="w-100">
                <SkeletonLoadingComponent circle={false} width="25%" height="14px"/>
            </h3>
            </div>
            <div className="lists__item__action">
                {/*<SkeletonLoadingComponent circle={false} width="20px" height="14px"/>*/}
        </div>
    </div>

    const checkStatusEvent = (endTime) => {
        // console.log(endTime,'checkStatusEvent',moment(moment().toDate()).format('Y-MM-DD'))
        let statusText= ''
        if(endTime < moment(moment().toDate()).format('Y-MM-DD')){
            statusText = '종료'
        }else {
            statusText = '진행중'
        }
        return statusText
    }


    return (
        <section className="lists">
            {props?.loading ? ([...Array(10).keys()].map(e => (
                <Fragment key={e}>
                    {loadingDiv}
                </Fragment>
            ))) : EventList.length > 0 ? <InfiniteScroll
                dataLength={EventList.length}
                next={fetchMoreData}
                hasMore={statusLoading}
                loader={loadingDiv}
            >
                {EventList.map((value,key)=>(
                    <Fragment key={key}>

                        <ItemInListComponent
                            key={key}
                            onClick={()=>openDetail(value.id)}
                            title={((props.isShop || props.isManager) && !props.eventInprogress ? '' : '['+value.store?.store_name+'] ')+value.title}
                            time={moment(value?.start_date).format('Y-MM-DD') + ` ~ `+moment(value?.end_date).format('Y-MM-DD')}
                            status={(props.isShop || props.isManager) && !props.eventInprogress ? checkStatusEvent(value.end_date) : false}
                            classNameCustom={''}
                            classNameStatus={checkStatusEvent(value.end_date) == '종료' ? 'end-event' : ''}
                        />

                    </Fragment>
                ))}
            </InfiniteScroll> : <EmptyDataComponent text={'등록된 이벤트가 없습니다.'} />}

        </section>
    );
};

export default EventPageComponent;
