
import ROUTERS from '../../commons/constans/router';
import React, { lazy } from 'react';


const OperationPageContainer = lazy(() => import('./containers/OperationPageContainer'));
const OperationHistoryPageContainer = lazy(() => import('./containers/OperationHistoryPageContainer'));
const ManagerRulesPageContainer = lazy(() => import('./containers/ManagerRulesPageContainer'));


const route = [
  {
    path: ROUTERS.OPERATION,
    title: 'OPERATIONS List',
    exact: true,
    public: false,
    roles: ['shop', 'manager'],
    component: <OperationPageContainer />,
  },
  {
    path: ROUTERS.OPERATION_HISTORY,
    title: 'OPERATIONS List',
    exact: true,
    public: false,
    roles: ['shop', 'manager'],
    component: <OperationHistoryPageContainer />,
  },
  {
    path: ROUTERS.MANAGER_RULES,
    title: 'MANAGER RULES',
    exact: true,
    public: false,
    roles: ['manager'],
    component: <ManagerRulesPageContainer />,
  }
];

export default route;
