import API from '../../../../commons/api/api';
import * as actionTypes from '../actions/type';

export const initialState = {
  isProcessing: false,
  isProcessingDetailStore: false,
  isProcessingListStores: false,
  type: '',
  steps: {
    step_1: {
      user_name: '',
      phone_number: '',
    },
    step_2: {
      biz_regcert: '',
      biz_regcert_validate: false,
      store_name: '',
      director: '',
      tob_id: 0,
      taxc_id: 0,
      biz_regcert_file: '',
    },
    step_3: {
      director_no: '',
      category_id: '',
      zone_code: '',
      store_address: '',
      store_address_detail: '',
      lat: '', 
      lng: ''
    },
  },
  isProcessingCreate: false,
  dataCreated: [],
  listStores: [],
  detailStore: [],
  isProcessingUpdate: false,
  updateSuccess: null,
  updateFaild: null,
};

const storeReducers = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.PUSH_DATA_STORE_STEP_ONE:
      return {
        ...state,
        isProcessing: true,
        type: action.type,
        steps: {
          ...state.steps,
          step_1: {
            ...state.steps.step_1,
            ...action.payload,
          },
        },
        dataCreated: [],
      };
    case actionTypes.PUSH_DATA_STORE_STEP_TWO:
      return {
        ...state,
        isProcessing: true,
        type: action.type,
        steps: {
          ...state.steps,
          step_2: {
            ...state.steps.step_2,
            ...action.payload,
          },
        },
      };
    case actionTypes.PUSH_DATA_STORE_STEP_THREE:
      return {
        ...state,
        isProcessing: true,
        type: action.type,
        steps: {
          ...state.steps,
          step_3: {
            ...state.steps.step_3,
            ...action.payload,
          },
        },
      };
    case actionTypes.RESET_DATA_STORE_STEP_ONE:
      return {
        ...state,
        isProcessing: true,
        type: action.type,
        steps: {
          ...state.steps,
          step_1: {
            ...state.steps.step_1,
            ...action.payload,
          },
        },
      };
    case actionTypes.RESET_DATA_STORE_STEP_TWO:
      return {
        ...state,
        isProcessing: true,
        type: action.type,
        steps: {
          ...state.steps,
          step_2: {
            biz_regcert: '',
            store_name: '',
            director: '',
            tob_id: 0,
            taxc_id: 0,
            biz_regcert_file: '',
            biz_regcert_validate: false,
          },
        },
      };
    case actionTypes.RESET_DATA_STORE_STEP_THREE:
      return {
        ...state,
        isProcessing: true,
        type: action.type,
        steps: {
          ...state.steps,
          step_3: {
            director_no: '',
            category_id: '',
            zone_code: '',
            store_address: '',
            store_address_detail: '',
            lat: '', 
            lng: ''
          },
        },
      };
    case actionTypes.RESET_ALL_DATA_STORE_STEPS:
      return {
        ...state,
        isProcessing: true,
        type: action.type,
        steps: {
          step_1: {
            user_name: '',
            phone_number: '',
          },
          step_2: {
            biz_regcert: '',
            store_name: '',
            director: '',
            tob_id: 0,
            taxc_id: 0,
            biz_regcert_file: '',
            biz_regcert_validate: false,
          },
          step_3: {
            director_no: '',
            category_id: '',
            zone_code: '',
            store_address: '',
            store_address_detail: '',
            lat: '', 
            lng: ''
          },
        },
      };
    case actionTypes.CREATE_STORE_REQUEST:
      return {
        ...state,
        isProcessingCreate: true,
        type: action.type,
      };
    case actionTypes.CREATE_STORE_SUCCESS:
      return {
        ...state,
        isProcessingCreate: true,
        type: action.type,
        dataCreated: action.payload?.data || [],
      };
    case actionTypes.GET_LIST_STORE_REQUEST:
      return {
        ...state,
        isProcessingListStores: true,
        type: action.type,
      };
    case actionTypes.GET_LIST_STORE_SUCCESS:
      return {
        ...state,
        isProcessingListStores: false,
        type: action.type,
        listStores: action.payload.data
      };
    case actionTypes.GET_DETAIL_STORE_REQUEST:
      return {
        ...state,
        isProcessingDetailStore: true,
        type: action.type,
      };
    case actionTypes.GET_DETAIL_STORE_SUCCESS:
      return {
        ...state,
        isProcessingDetailStore: false,
        type: action.type,
        detailStore: action.payload.data
      };

    case actionTypes.UPDATE_STORE_REQUEST:
      return {
        ...state,
        isProcessingUpdate: true,
        type: action.type,
      };
    case actionTypes.UPDATE_STORE_SUCCESS:
      return {
        ...state,
        isProcessingUpdate: false,
        type: action.type,
        updateSuccess: action.payload,
        detailStore: {
          ...state.detailStore,
          ...action.payload.data,
        },
        listStores: state.listStores.map((store) => ({
          ...store,
          open_state: store.id === action.payload.data.id ? action.payload.data.open_state : store.open_state,
        }))
      };
    case actionTypes.UPDATE_STORE_FAILD:
      return {
        ...state,
        isProcessingUpdate: false,
        type: action.type,
        updateFail: action.payload,
      };
      
    case actionTypes.SYNC_STORE:
      return {
        ...state,
        isProcessingUpdate: false,
        detailStore: action.payload.data.id === state.detailStore.id ? {
          ...state.detailStore,
          ...action.payload.data,
        } : state.detailStore,
        listStores: state.listStores.map((store) => (store.id === action.payload.data.id ? {
          ...store,
          ...action.payload.data,
        } : store)),
        isProcessingUpdate: false,
        updateSuccess: null,
        updateFaild: null,
      };

    case actionTypes.RESET_UPDATE_STORE:
      return {
        ...state,
        isProcessingUpdate: false,
        updateSuccess: null,
        updateFaild: null,
      };

    default:
      return state;
  }
};

export default storeReducers;
