import React, { useEffect, useRef, useState, memo } from "react";
import Menubar from "../../components/Menu";

type Props = {
  children: any,
  pageID: String,
  activeNav: String,
};

const MainLayout = ({
  children,
  pageID,
  activeNav,
}: Props) => {
  const ref = useRef();

  useEffect(() => {}, []);

  return (
    <>
      <Menubar />
      <main className={pageID} id={pageID}>
        {children}
      </main>
    </>
  );
};

MainLayout.defaultProps = {
  displayBottomNav: true,
};

export default memo(MainLayout);
