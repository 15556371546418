/*
 * Text Input Component
 * */
import React, { useEffect, useState, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { randomIdentifier } from '../../../utils/helpers';

type Props = {
  label: String,
  isRequired: boolean,
  errorMsg: any,
  placeHolder: String,
  id: string,
  name: string,
  value: String,
  handleChange: Function,
  handleFocus: Function,
  handleBlur: Function,
  handleClick: Function,
  btnClass: String,
  btnLabel: String,
  btnHandleClick: Function,
  appendContent: any,
  disabled: boolean,
  readonly: boolean,
  pattern: String,
  type: String,
  autoComplete: String,
  maxLength: String,
  searchIcon: any,
  defaultCountCharacter:any,
  fadeLastCharacter:boolean,
};

const TextAreaComponent = ({
  label,
  isRequired,
  errorMsg,
  placeHolder,
  id,
  name,
  value,
  handleChange,
  handleFocus,
  handleBlur,
  handleClick,
  btnClass,
  btnLabel,
  btnHandleClick,
  appendContent,
  disabled,
  readonly,
  pattern,
  type,
  autoComplete,
  maxLength,
  searchIcon,
  rows,
  contCharacter,
  defaultCountCharacter,
  fadeLastCharacter
}: Props) => {
  const [text, setText] = useState(value);
  const [character, setCountCharacter] = useState(0);

  useEffect(() => {
    setCountCharacter(defaultCountCharacter);
  }, []);

  useEffect(() => {
    setText(value);
  }, [value]);



  const _handleChange = (e) => {
    const value = e.target.value

    if (value && value.length > +maxLength) {
      return;
    }

    if (typeof handleChange == 'function') {
      // handleChange(e);
      if (e.target.value.length <= (parseInt(maxLength))){
          handleChange(e);
      }
    }

    setText(e.target.value);
  };
  const _handleKeyUp = (e) => {
    if(e.target.value.length <= (parseInt(maxLength))){
      setCountCharacter(e.target.value.length);
    }
  }

  return (
    <div className="textarea-component">
      {
        label && <label  className={`title-text ${isRequired ? 'required' : ''}`}>{label}</label>
      }
      
      <div className="input-group text-area-flicker">
        <textarea
          className={
            errorMsg.length == 0
              ? 'form-control'
              : 'form-control form-control-error'
          }
          placeholder={placeHolder}
          value={text}
          defaultValue={''}
          id={id}
          rows={rows}
          name={name}
          onChange={_handleChange}
          onKeyUp={_handleKeyUp}
          onBlur={handleBlur}
          onClick={handleClick}
          onFocus={handleFocus}
          readOnly={readonly}
          disabled={disabled}
          pattern={pattern}
          type={type}
          autoComplete={autoComplete}
          // maxLength={parseInt(maxLength)}
        />
        {
          contCharacter ? (<label className='count-character'><strong>{character}</strong> / {maxLength}</label>) : ('')
        }

        {
            searchIcon && <img src={searchIcon} />
        }
        {btnLabel.length == 0 ? (
          ''
        ) : (
          <button type="button" className={btnClass} onClick={btnHandleClick}>
            {btnLabel}
          </button>
        )}
        {!appendContent ? '' : appendContent}
      </div>
      {errorMsg.length == 0 ? (
        ''
      ) : (
        <span className="input-error show">{errorMsg}</span>
      )}
    </div>
  );
};

TextAreaComponent.defaultProps = {
  isRequired: false,
  btnHandleClick: () => {},
  handleChange: () => {},
  handleFocus: () => {},
  handleBlur: () => {},
  handleClick: () => {},
  value: '',
  id: 'js-' + randomIdentifier(),
  name: 'input-' + randomIdentifier(),
  errorMsg: '',
  placeHolder: '',
  btnClass: '',
  btnLabel: '',
  appendContent: false,
  disabled: false,
  readonly: false,
  type: 'text',
  autoComplete: 'on',
  maxLength: '1000',
  searchIcon: false,
  rows:6,
  contCharacter:false,
  defaultCountCharacter:0,
  fadeLastCharacter:false,
};

export default memo(TextAreaComponent);
