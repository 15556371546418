import menuItems from '../../constans/menu';
import { Link } from 'react-router-dom';
import DownIcon from '../../../assets/images/downIcon.svg';
import { openMenu } from '../../../pages/Main/redux/actions';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import ROUTERS from '../../constans/router';
import { useState } from 'react';
import ModalComponent from '../Modal/ModalComponent';
import { useRole, useUser } from '../../customHooks/Account';
import * as action from './../../../pages/Account/redux/actions'
import {removeAllStorage} from "../../../utils/helpers";
import { useCheckStoreVerifyingStores } from '../../customHooks/Store';

const ListMenuComponent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isShowStoreModal, setIsShowStoreModal] = useState({
    type: '',
    isShow: false,
    title: '',
    msg: ``,
    cancelBtn: '',
    submitBtn: '확인',
  });
  const [isShowManagerModal, setIsShowManagerModal] = useState({
    type: '',
    isShow: false,
    title: '',
    msg: ``,
    cancelBtn: '',
    submitBtn: '확인',
  });
  const {user} = useUser();
  const isUser = useRole('user');
  const isShop = useRole('shop');
  const isManager = useRole('manager');
  const [paramsListStores, ] = useState({
    store_by_user_id: user.id,
    'approval_state[]': 'VERIFYING'
  })
  const [existStoreVerifying] = useCheckStoreVerifyingStores(paramsListStores);

  // console.log(isManager, 'isManager');
  const goToPage = (path) => {
    if (user.id && existStoreVerifying) {
      if (path == ROUTERS.STORE) {
        setIsShowStoreModal({
          type: 'REGISTER_WAIT',
          isShow: true,
          title: '',
          msg: ``,
          cancelBtn: '취소',
          submitBtn: '확인',
        });
        return;
      }
    }

    if (isManager) {
      if (path == ROUTERS.STORE) {
        setIsShowManagerModal({
          type: 'rule_managers',
          isShow: true,
          title: '',
          msg: <>직원은 가맹점 등록이 불가능합니다.<br />
          가맹점을 등록 하시려면 소속된 가맹점 점주에게 <br />
          ‘직원삭제‘를 요청해 주세요.</>,
          cancelBtn: '',
          submitBtn: '확인',
        })
      }else if(path == ROUTERS.MANAGER) {
        setIsShowManagerModal({
          type: 'rule_managers',
          isShow: true,
          title: '',
          msg: `가맹점주만 볼 수 있는 항목입니다.`,
          cancelBtn: '',
          submitBtn: '확인',
        })
      }else {
        navigate(path);
        dispatch(openMenu(false));
      }
    }else{
      if (path == ROUTERS.STORE) {
        if (user.id) {
          setIsShowStoreModal({
            type: 'REGISTER_STORE',
            isShow: true,
            title: '알림',
            msg: ``,
            cancelBtn: '취소',
            submitBtn: '확인',
          });
        }else{
          //
          setIsShowStoreModal({
            type: 'REGISTER_STORE_LOGIN',
            isShow: true,
            title: '알림',
            msg: ``,
            cancelBtn: '닫기',
            submitBtn: '로그인 하기',
          });
          
        }
      } else {
        navigate(path);
        dispatch(openMenu(false));
      }
    }
    
  };

  const handleCancelBtn = () => {
    setIsShowStoreModal({
      type: '',
      isShow: false,
      title: '',
      msg: ``,
      cancelBtn: '',
      submitBtn: '확인',
    });
  }

  const handleSubmitBtn = (type) => {
    switch (type) {
      case 'REGISTER_STORE':
        navigate(ROUTERS.STORE_RULE);
        dispatch(openMenu(false));
        break;
      case 'REGISTER_WAIT':
        setIsShowStoreModal({
          type: '',
          isShow: false,
          title: '',
          msg: ``,
          cancelBtn: '',
          submitBtn: '확인',
        });
        break;
      case 'REGISTER_STORE_LOGIN':
        setIsShowStoreModal({
          type: '',
          isShow: false,
          title: '',
          msg: ``,
          cancelBtn: '',
          submitBtn: '확인',
        });
        navigate(ROUTERS.LOGIN);
        dispatch(openMenu(false));
        break;
      default:
        setIsShowStoreModal({
          type: '',
          isShow: false,
          title: '',
          msg: ``,
          cancelBtn: '',
          submitBtn: '확인',
        });
        break;
    }
  }

  const renderItemMenu = () => {
    return menuItems.map((item, idx) => {
      if (isUser && item.role.indexOf('user') != -1 && !item.public) {
        return (
          <div className={`nav__section`}
            key={idx}
            onClick={() => goToPage(item.toUrl)}
          >
            <div className="nav__section__item">
              <a href="#" >
                {item.name}
              </a>
            </div>
            <div className="nav__section__icon">
              <img src={DownIcon} alt="#" />
            </div>
          </div>
        )
      }else if((isShop || isManager) && !item.public){
        return (
          <div style={{backgroundColor: (item?.id == 2 || item?.id == 1 ? '#dbdbdb12' : '')}} className={`nav__section ${item?.id == 2 ? ' space-item-menu' : ''} `}
            key={idx}
            onClick={() => goToPage(item.toUrl)}
          >
            <div className="nav__section__item">
              <a href="#" >
                {item.name} {item?.id == 1 ? <label className={'mb-0'}>(가맹점 조회)</label> : ''}
              </a>
            </div>
            <div className="nav__section__icon">
              <img src={DownIcon} alt="#" />
            </div>
          </div>
        )
      }else if(item.public){
        // if(item?.id == 4 && (isManager)){
        //   return ''
        // }else {
        //
        // }
        return (
          <div className={`nav__section `}
               key={idx}
               onClick={() => goToPage(item.toUrl)}
          >
            <div className="nav__section__item">
              <a href="#" >
                {!user.id && item?.id == 4  ? '진행 이벤트' : item.name}
              </a>
            </div>
            <div className="nav__section__icon">
              <img src={DownIcon} alt="#" />
            </div>
          </div>
        )
      }
    });
  }

  const handleCancelManager = () => {
    setIsShowManagerModal({
      type: '',
      isShow: false,
      title: '',
      msg: ``,
      cancelBtn: '',
      submitBtn: '확인',
    })
  }

  const handleSubmitManager = () => {
    setIsShowManagerModal({
      type: '',
      isShow: false,
      title: '',
      msg: ``,
      cancelBtn: '',
      submitBtn: '확인',
    })
  }

  return (
    <>
        {renderItemMenu()}
        <ModalComponent
          id={`modal-store-confirm`}
          show={isShowStoreModal?.isShow || false}
          title={isShowStoreModal?.title || ''}
          cancelBtn={isShowStoreModal?.cancelBtn || ''}
          submitBtn={isShowStoreModal?.submitBtn || ''}
          handleCancel={handleCancelBtn}
          handleSubmit={() => handleSubmitBtn(isShowStoreModal.type)}
          className={`modal-store-confirm`}
        >
          {
            isShowStoreModal.type === "REGISTER_STORE" && <div>
            가맹점 전용 메뉴로 <span>사업자등록증을 보유한</span> <br /> 
            <span>지자체 가맹점만 가입 가능</span>합니다. <br /> 
            가맹점 등록을 하시겠습니까?
          </div>
          }

          {
            isShowStoreModal.type === "REGISTER_STORE_LOGIN" && <div>
              해당 메뉴를 이용하기 위해선<br /> 
              로그인이 필요합니다.
          </div>
          }

          {
            isShowStoreModal.type === "REGISTER_WAIT" && <div>
              가맹점 등록 신청이 완료되었습니다.<br />
              승인은 5일~7일 정도 소요됩니다
            </div>
          }
          
        </ModalComponent>

        <ModalComponent
          id={`modal-store-confirm`}
          show={isShowManagerModal.isShow || false}
          title={isShowManagerModal?.title || ''}
          cancelBtn={isShowManagerModal?.cancelBtn || ''}
          submitBtn={isShowManagerModal?.submitBtn || ''}
          handleCancel={handleCancelManager}
          handleSubmit={handleSubmitManager}
        >
          <div>
            {isShowManagerModal?.msg || ''}
          </div>
        </ModalComponent>
      </>
  )
      
};

export default ListMenuComponent;
