import {put, call, takeLatest} from 'redux-saga/effects';

import * as actionCreators from '../redux/actions';
import * as actionTypes from '../redux/actions/type';
import API, {ROUTES} from "../../../commons/api/api";
import {rememberMeLogin} from "../../../utils/helpers";

function* logIn(action) {
    try {
        const response = yield call(() =>
            API.post(ROUTES.API_LOGIN, action.payload)
        );
        if (response.status == 200) {
            // rememberMeLogin(action.payload.remember_me, response.data.token);
            // localStorage.setItem('sns_type', "");
            // if (action.payload && action.payload.sign_up) {
            //     localStorage.setItem('sign_up', true);
            // }
            yield put(actionCreators.loginSuccess(response));
        } else {
            yield put(actionCreators.loginFaild());
        }
    } catch (error) {

        let errors = error.response;
        let dataError = {};
        if(errors) {
            switch (errors.status) {
                case 401 :
                    console.log(errors.data,'errors.data')
                    yield put(actionCreators.loginFaild(errors.data))
                    break;
                case 404 :
                    yield put(actionCreators.loginFaild(errors.data))
                    break;
                case 422 :
                    if (errors.data && errors.data.errors) {
                        Object.keys(errors.data.errors).map(function (key, index) {
                            dataError[key] = errors.data.errors[key][0];
                        });
                        yield put(actionCreators.loginFaild(dataError))
                    }
                    break;
                default :
                    yield put(actionCreators.loginFaild())
                    break;
            }
        }


    }
}

function* logInSaga() {
    yield takeLatest(actionTypes.LOGIN_REQUEST, logIn);
}

export default logInSaga;
