import * as type from './type';

export const getManagersRequest = (params) => {
  return {
    type: type.GET_MANAGERS_REQUEST,
    params,
  };
}

export const getManagersSuccess = (payload) => {
  return {
    type: type.GET_MANAGERS_SUCCESS,
    payload,
  };
}

export const getManagersFaild = (payload) => {
  return {
    type: type.GET_MANAGERS_FAILD,
    payload,
  };
}

export const createManagerRequest = (payload) => {
  return {
    type: type.CREATE_MANAGER_REQUEST,
    payload,
  };
}

export const createManagerSuccess = (payload) => {
  return {
    type: type.CREATE_MANAGER_SUCCESS,
    payload,
  };
}

export const createManagerFaild = (payload) => {
  return {
    type: type.CREATE_MANAGER_FAILD,
    payload,
  };
}