import { put, call, takeLatest } from 'redux-saga/effects';

import * as actionCreators from '../redux/actions';
import * as actionTypes from '../redux/actions/type';
import API, { ROUTES } from "../../../commons/api/api";

function* home(action) {
  console.log('call api home', action);
  try {
    // const response = yield call(() => API.post(ROUTES.API_ANNOUNCEMENT, JSON.stringify(action.payload)));
    const response = yield call(() =>
      API({ url: "https://jsonplaceholder.typicode.com/todos/" })
    );
    console.log("fetchlogInData", response);
    // yield put({ type: actionTypes.LOGIN_REQUEST, response });
    yield put(actionCreators.getUserHomeSuccess({ data: response }));
    
    if (response.ok) {
      const { data } = response.data.body;
     
    }
  } catch (error) {
    
  }
}

function* getBanner({payload: params}) {
  // console.log('call api get banner', params);
  try {
    const response = yield call(() =>
      API.get(ROUTES.API_GET_BANNER, {params})
    );
    yield put(actionCreators.getBannerHomeSuccess({ data: response }));
    
    if (response.ok) {
      const { data } = response.data.body;
     
    }
  } catch (error) {
    
  }
}

function* getRecommendVideo(action) {
  // console.log('call api getRecommendVideo', action);
  try {
    const response = yield call(() =>
      API.get(ROUTES.API_VIDEO, {params: {type: 'recommend'}})
    );
    yield put(actionCreators.getRecommendVideoSuccess({ data: response }));
    
    if (response.ok) {
      const { data } = response.data.body;
     
    }
  } catch (error) {
    
  }
}

function* homeSaga() {
  yield takeLatest(actionTypes.GET_USER_HOME, home);
  yield takeLatest(actionTypes.GET_BANNER_HOME, getBanner);
  yield takeLatest(actionTypes.GET_RECOMMEND_VIDEO, getRecommendVideo);
}

export default homeSaga;
