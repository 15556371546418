import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Header from '../../../../commons/components/Header/header';
import LeftIconHeader from '../../../../assets/images/leftIconHeader.svg';
import MainLayout from '../../../../commons/layout/MainLayout';
import CheckboxComponent from '../../../../commons/components/Checkbox/CheckboxComponent';
import ButtonComponent from "../../../../commons/components/Button/ButtonComponent";
import { useLocation, useNavigate  } from "react-router-dom";
import ROUTERS from '../../../../commons/constans/router';
import { STEPS } from '../../../../commons/constans/store';
import { useInformationList, useInformationParams } from '../../../../commons/customHooks/Information'


const StoreRuleComponent = () => {
  const navigate = useNavigate();
  const [isCheckedCheckbox, setIsCheckedCheckbox] = useState({
    rule_all: false,
    rule1: false,
    rule2: false,
    rule3: false,
  });
  const location = useLocation();
  const params = useInformationParams('policy', 'store')
  const [ policies ] = useInformationList(params)

  const [isValid, setIsValid] = useState(false);

  const handleLeftClick = () => {
    navigate(ROUTERS.MAIN);
  };

  useEffect(() => {
    if (location?.state?.dataRules) {
      setIsCheckedCheckbox({
        ...location?.state?.dataRules,
      })
    }
  }, [location])

  useEffect(() => {
    if (isCheckedCheckbox.rule_all) {
      setIsCheckedCheckbox({
        rule_all: true,
        rule1: true,
        rule2: true,
        rule3: true,
      });
    } else {
      if (isCheckedCheckbox.rule1 &&
        isCheckedCheckbox.rule2 &&
        isCheckedCheckbox.rule3) {
        setIsCheckedCheckbox({
          rule_all: false,
          rule1: false,
          rule2: false,
          rule3: false,
        });
      }else{
        // if (!location?.state?.dataRules) {
        //   setIsCheckedCheckbox({
        //     ...isCheckedCheckbox,
        //     rule_all: false,
        //   });
        // }
        setIsCheckedCheckbox({
          ...isCheckedCheckbox,
          rule_all: false,
        });
      }
    }
  }, [isCheckedCheckbox.rule_all]);

  useEffect(() => {
    
    if (
      isCheckedCheckbox.rule1 &&
      isCheckedCheckbox.rule2 &&
      isCheckedCheckbox.rule3 
    ) {
      setIsCheckedCheckbox({
        ...isCheckedCheckbox,
        rule_all: true,
        rule1: true,
        rule2: true,
        rule3: true,
      });
    } else if (
      !isCheckedCheckbox.rule1 ||
      !isCheckedCheckbox.rule2 ||
      !isCheckedCheckbox.rule3 
    ) {
      // if (!location?.state?.dataRules) {
      //   setIsCheckedCheckbox({
      //     ...isCheckedCheckbox,
      //     rule_all: false,
      //   });
      // }
      setIsCheckedCheckbox({
        ...isCheckedCheckbox,
        rule_all: false,
      });
    }
  }, [
    isCheckedCheckbox.rule1,
    isCheckedCheckbox.rule2,
    isCheckedCheckbox.rule3,
  ]);

  const handleChangeCheckbox = (e) => {
    setIsCheckedCheckbox({
      ...isCheckedCheckbox,
      [e.target.name]: !isCheckedCheckbox[e.target.name],
    });
  };

  const handleSubmit = () => {
    navigate(ROUTERS.STORE_REGISTER_STEP_PATH(STEPS.ONE));
  }

  const goToPageRuleDetail = (policy) => {
    navigate(ROUTERS.RULES_DETAIL_PATH(policy.id), {
      state: {
        headerTitle: policy.title,
        type: 'store_register',
        dataRules: isCheckedCheckbox,
      }
    });
  }

  return (
    <MainLayout pageID={'store-rules-page'}>
      <Header
        leftChild={LeftIconHeader}
        handleLeftClick={handleLeftClick}
        leftIcon={true}
        centerChild={'가맹점 등록'}
        centerIcon={false}
        rightChild={false}
        rightIcon={false}
      />
      <div className="wrapper">
        <h2>가맹점 이용약관</h2>
        <div className="manager-terms">
          <CheckboxComponent
            label={``}
            componentClass={`rule-all`}
            name={`rule_all`}
            handleChange={(e) => handleChangeCheckbox(e)}
            title="전체동의"
            labelClass={``}
            checked={isCheckedCheckbox?.rule_all || false}
          />
        </div>
        <div className="rules">
          <ul className="rules-list">
            {policies.map((policy, key) => (
              <li key={policy.id}>
                <div className="item">
                  <CheckboxComponent
                    label={``}
                    componentClass={`rule`}
                    name={`rule${key + 1}`}
                    handleChange={(e) => handleChangeCheckbox(e)}
                    disabled={false}
                    title={`${policy.title}(필수)`}
                    labelClass={``}
                    checked={isCheckedCheckbox?.rule1 || false}
                  />
                </div>
                <button type="button" className='btn' onClick={() => goToPageRuleDetail(policy)}>
                  보기
                </button>
              </li>
            ))}
          </ul>
        </div>
        <div className='fixed-btn'>
          <ButtonComponent 
            label={'다음'}
            style={{width: '100%'}}
            disabled={!isCheckedCheckbox.rule_all}
            cssClass={''}
            handleClick={handleSubmit}
            fixedBtn={true}
          />
        </div>
      </div>
    </MainLayout>
  );
};

export default StoreRuleComponent;
