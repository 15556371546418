import React, { useEffect, useState } from 'react';
import ROUTERS from '../../../commons/constans/router';
import MainLayout from '../../../commons/layout/MainLayout';
import HeaderSteps from '../components/HeaderSteps';
import LeftIconHeader from '../../../assets/images/leftIconHeader.svg';
import Header from '../../../commons/components/Header/header';
import { useParams } from 'react-router-dom';
import Step1Component from '../components/Steps/Step1';
import Step2Component from '../components/Steps/Step2';
import Step3Component from '../components/Steps/Step3';
import ButtonComponent from '../../../commons/components/Button/ButtonComponent';
import { MODAL_TYPE, STEPS } from '../../../commons/constans/store';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useRole, useUser } from '../../../commons/customHooks/Account';
import {
  useCategories,
  useCodeLabel,
} from '../../../commons/customHooks/Store';
import { createStoreRequest, resetAllDataStoreSteps, resetDataStoreStepOne, resetDataStoreStepThree, resetDataStoreStepTwo } from '../redux/actions';
import ModalComponent from '../../../commons/components/Modal/ModalComponent';


const StoreRegisterPageContainer = () => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const { steps, dataCreated } = useSelector((state) => state.storeReducers);
  const { user } = useUser();
  const [categories] = useCategories();
  const [codeLabel, typeOfBusiness, taxClass] = useCodeLabel();

  const [invalid, setInvalid] = useState(true);
  const [ loadingCreateStore, setLoadingCreateStore] = useState(false);
  const [ modalCreateStore, setModalCreateStore] = useState({
    type: '',
    isShow: false,
    title: '',
    msg: '',
    cancelBtn: '',
    submitBtn: '',
  });

  const handleLeftClick = () => {
    setModalCreateStore({
      type: MODAL_TYPE.STEP_BACK,
      isShow: true,
      title: '',
      msg: `입력사항이 저장되지 않습니다. 
            이동하시겠습니까?`,
      cancelBtn: '아니오',
      submitBtn: '예',
    })
  };

  useEffect(() => {
    if (dataCreated.status) {
      switch (dataCreated.status) {
        case true:
          setLoadingCreateStore(false);
          dispatch(resetAllDataStoreSteps());
          break;
        case false:
          dispatch(resetAllDataStoreSteps());
          setModalCreateStore({
            type: MODAL_TYPE.CREATE_FAIL,
            isShow: true,
            title: '',
            msg: 'ERROR',
            cancelBtn: '',
            submitBtn: '예',
          })
          break;
        default:
          break;
      }
    }
  }, [dataCreated.status])


  useEffect(() => {
    switch (params.step) {
      case 'step-1':
        if (steps.step_1.user_name != '') {
          setInvalid(false);
        } else {
          setInvalid(true);
        }
        break;
      case 'step-2':
        if (steps.step_2) {
          const found = Object.values(steps.step_2).filter(
            (element) => element == ''
          );
          if (steps.step_2.biz_regcert.length < 12 || !steps.step_2?.biz_regcert_validate) {
            setInvalid(true);
          }else{
            if (found.length > 0) {
              setInvalid(true);
            } else {
              setInvalid(false);
            }
          }
        } else {
          setInvalid(true);
        }
        break;
      case 'step-3':
        if (steps.step_3) {
          const found = Object.values(steps.step_3).filter(
            (element) => element == ''
          );
          if (found.length == 0 && (steps.step_3.director_no.length < 12)) {
            setInvalid(true);
          }else{
            if (found.length > 0) {
              setInvalid(true);
            } else {
              setInvalid(false);
            }
          }
          
        } else {
          setInvalid(true);
        }
        break;
      default:
        break;
    }
  }, [steps]);

  const handleClickBtn = () => {
    switch (params.step) {
      case 'step-1':
        navigate(ROUTERS.STORE_REGISTER_STEP_PATH(STEPS.TWO));
        break;
      case 'step-2':
        navigate(ROUTERS.STORE_REGISTER_STEP_PATH(STEPS.THREE));
        break;
      case 'step-3':
        setLoadingCreateStore(true);  
        setModalCreateStore({
          isShow: true,
          title: '',
          msg: `가맹점 등록 신청이 완료되었습니다.
                승인은 5일~7일 정도 소요됩니다.
                `,
          cancelBtn: '',
          submitBtn: '확인',
          })
        handleSubmitRegister();
        break;
      default:
        navigate(ROUTERS.STORE_REGISTER_STEP_PATH(STEPS.ONE));
        break;
    }
  };

  const handleSubmitRegister = () => {
    let formData = new FormData();
    let dataForm = {
      user_name: steps.step_1.user_name,
      phone_number: steps.step_1.phone_number,
      biz_regcert: steps.step_2.biz_regcert,
      store_name: steps.step_2.store_name,
      store_phone: steps.step_1.phone_number,
      director: steps.step_2.director,
      tob_id: steps.step_2.tob_id,
      taxc_id: steps.step_2.taxc_id,
      director_no: steps.step_3.director_no,
      category_id: steps.step_3.category_id,      
      zone_code: steps.step_3.zone_code,
      store_address: steps.step_3.store_address,
      store_address_detail: steps.step_3.store_address_detail,
      user_id: user.id,
      lat:  steps.step_3?.lat || '', 
      lng:  steps.step_3?.lng || ''
    }

    Object.keys(dataForm).forEach(key => formData.append(key, dataForm[key]));
    formData.append('biz_regcert_file', steps.step_2?.biz_regcert_file || null);
    
    dispatch(createStoreRequest(formData));
  };

  const renderComponentSteps = () => {
    switch (params.step) {
      case 'step-1':
        return <Step1Component steps={steps} user={user} />;
      case 'step-2':
        return (
          <Step2Component steps={steps} user={user} typeOfBusiness={typeOfBusiness} taxClass={taxClass} />
        );
      case 'step-3':
        return <Step3Component steps={steps} user={user}  categories={categories}/>;
      default:
        return <Step1Component steps={steps} user={user} />;
    }
  };

  const resetDataModal = () => {
    setModalCreateStore({
      type: '',
      isShow: false,
      title: '',
      msg: '',
      cancelBtn: '',
      submitBtn: ''
    })
  }

  const handleConfirm = () => {
    switch (modalCreateStore.type) {
      case MODAL_TYPE.STEP_BACK:
        switch (params.step) {
          case 'step-1':
            dispatch(resetDataStoreStepOne());
            navigate(ROUTERS.STORE_RULE);
            resetDataModal();
            break;
          case 'step-2':
            dispatch(resetDataStoreStepTwo());
            navigate(ROUTERS.STORE_REGISTER_STEP_PATH(STEPS.ONE));
            resetDataModal();
            break;
          case 'step-3':
            dispatch(resetDataStoreStepThree());
            navigate(ROUTERS.STORE_REGISTER_STEP_PATH(STEPS.TWO));
            resetDataModal();
            break;
          default:
            dispatch(resetDataStoreStepOne());
            navigate(ROUTERS.STORE_REGISTER_STEP_PATH(ROUTERS.STORE_RULE));
            resetDataModal();
            break;
        }
        break;
      case MODAL_TYPE.REGISTER_SUCCESS:
        resetDataModal();
        
        navigate(ROUTERS.MAIN);
        break;
      case MODAL_TYPE.CREATE_FAIL:
        resetDataModal();
        // navigate(ROUTERS.MAIN);
        break;
      default:
        resetDataModal();
        navigate(ROUTERS.MAIN);
        break;
    }
  }

  const handleCancel = () => {
    resetDataModal();
  }

  return (
    <div>
      <MainLayout pageID={'store-register-page'}>
        <Header
          leftChild={LeftIconHeader}
          handleLeftClick={handleLeftClick}
          leftIcon={true}
          centerChild={'가맹점 등록'}
          centerIcon={false}
          rightChild={false}
          rightIcon={false}
        />
        <div className="wrapper">
          <HeaderSteps steps={params} />

          {renderComponentSteps()}

          <ButtonComponent
            label={'다음'}
            style={{ width: '100%' }}
            cssClass={'mb-2'}
            handleClick={handleClickBtn}
            fixedBtn={true}
            disabled={invalid || loadingCreateStore}
          />
        </div>

        <ModalComponent 
          show={modalCreateStore.isShow || false}
          title={modalCreateStore.title || ''}
          handleCancel={handleCancel}
          cancelBtn={modalCreateStore.cancelBtn || ''}
          submitBtn={modalCreateStore.submitBtn || ''}
          className={``}
          handleSubmit={handleConfirm}
        >
          <div>
            {modalCreateStore.msg}
          </div>
        </ModalComponent>
      </MainLayout>
    </div>
  );
};

export default StoreRegisterPageContainer;
