import React, {Suspense, useEffect, useMemo, useState} from "react";
import {useSelector} from "react-redux";

import {Route, Routes, useNavigate, Navigate, useLocation, matchRoutes} from 'react-router-dom';
import Loading from "../components/Loading";
import PrivateRoute from "./PrivateRoute";
import PublicRouter from "./PublicRouter";
import {formatAppSnsType, rememberMeLogin, removeAllStorage} from "../../utils/helpers";
import {useDispatch} from "react-redux";
import {getCurrentUser, logOutRequest, resetState} from "../../pages/Account/redux/actions";
import API, {ROUTES, setupAPI} from "../api/api";
import useCallSocket from "../customHooks/useCallSocket";
import {hasRole} from "../../utils/hasRole";
import ExtraRoute from "./ExtraRoute";
import ROUTERS from "../constans/router";
import ModalComponent from "../components/Modal/ModalComponent";



const NotFoundPage = ({isAuth}) => {
  const query = useLocation();
  if (isAuth) {
    return <Navigate to={query.pathname || ROUTERS.MAIN}/>;
  }
  return <Navigate to={ROUTERS.INTRO}/>;
}


const AppRouter = () => {
  const {userInfo, token, type} = useSelector((state) => state.authReducers);
  const dispatch = useDispatch();
  useCallSocket(token, userInfo);
  let routePublics = [];
  let routePrivates = [];
  let routeGuests = [];
  const context = require.context("./../../pages/", true, /route.js$/);


  window.checkAppVersion = () => {
    ///api

    let appDevice = {
      device_uuid : window.appDevice.deviceUuid,
      device_os : window?.appDevice?.deviceOs || 'IOS',
      fcm_token : window?.appDevice?.fcmToken || '',
      app_version_id : window?.appDevice?.appVersionId || ''
    }
    API.post(ROUTES.API_APP_DEVICE,appDevice).then(res => {
      if(res.data && res.data.version_status == 'OUT_OF_DATE'){
        setOpenModal({
          isShowDialog : true,
          content: res.data.app_url || ''
        })
      }
    })

  }

  const [openModal, setOpenModal] = useState({
    isShowDialog: false,
    content: '',
  });



  context.keys().forEach((path) => {
    let routes = require('../../pages/' + path.replace('./', '')).default;
    if (routes.length > 0) {
      routes.forEach((route, id) => {
        // auth route
        if (route.hasOwnProperty('public')) {
          // public route
          if (route.public) {
            routePublics.push(route);
            // private route
          } else {
            routePrivates.push(route);
          }
          // guest route
        } else {
          routeGuests.push(route);
        }
      })
    }
  });

  const getApiCurrentUser = () => {
    API.get(ROUTES.API_GET_AUTH_ME).then((res) => {
      let response = res.data;
      dispatch(getCurrentUser(response));
    }).catch(e => {
      dispatch(resetState());
    });
  }

  useEffect(() => {
    const _APP_TOKEN = localStorage.getItem('_APP_TOKEN');
    if (_APP_TOKEN) {
      getApiCurrentUser();
    }

    // setTimeout(() => {
    //   if(window.appDevice && window.appDevice.deviceUuid){
    //     let appDevice = {
    //       device_uuid : window.appDevice.deviceUuid,
    //       device_os : window?.appDevice?.deviceOs || 'IOS',
    //       fcm_token : window?.appDevice?.fcmToken || '',
    //       app_version_id : window?.appDevice?.appVersionId || '',
    //     }
    //     API.post(ROUTES.API_APP_DEVICE,appDevice)
    //   }
    //
    // }, 3000);

  }, []);



  const renderRoutesPrivates =
    routePrivates && routePrivates.map(({component: Component, path, ...rest}, index) =>
      <Route
        key={index}
        path={path}
        element={
          <PrivateRoute
            isAuth={userInfo.id}
            {...rest}
            children={Component}
          />
        }
      />);
  const renderRoutesPublics =
    routePublics && routePublics.map(({component: Component, path, ...rest}, index) =>
      <Route
        key={index}
        path={path}
        element={
          <PublicRouter
            isAuth={userInfo.id}
            {...rest}
            children={Component}
          />
        }
      />);
  const renderRouteGuests =
    routeGuests && routeGuests.map(({component: Component, path, ...rest}, index) =>
      <Route
        key={index}
        path={path}
        element={Component}
      />);

  if (localStorage.getItem('_APP_TOKEN') && !userInfo.id) {
    return <Loading/>
  }

  return (
    <Suspense fallback={<Loading/>}>
      <Routes>
        <Route path="">
          {renderRoutesPublics}
        </Route>
        <Route path="">
          {renderRoutesPrivates}
        </Route>
        <Route path="">
          {renderRouteGuests}
        </Route>
        {/*<Route*/}
        {/*  path="*"*/}
        {/*  element={<NotFoundPage isAuth={userInfo.id}/>}*/}
        {/*/>*/}
      </Routes>

      <ModalComponent
        id={``}
        show={openModal.isShowDialog}
        title={`알림`}
        submitBtn={`확인`}
        handleSubmit={() => {
          // window.open(openModal.content, '_blank');
          window.sendUpdateLink(openModal.content);
        }}
      >
        <div style={{whiteSpace: 'pre-wrap'}}>
          앱의 최신버전이 등록되었습니다. 최신 버전으로 업데이트 하기 위해 스토어로 이동합니다.
        </div>
      </ModalComponent>
    </Suspense>
  )
}

export default AppRouter;
