
const MapComponent = () => {
  
  return (
    <div className="map">
      Map here
    </div>
  )
}
export default MapComponent
