import { useRef, useState } from "react";
import MainLayout from "../../../commons/layout/MainLayout/index";
import Header from "../../../commons/components/Header/header";
import LogoIcon from '../../../assets/images/logo.svg'
import HamburgerIcon from "../../../assets/images/hamburger.svg";
import LeftIconHeader from "../../../assets/images/leftIconHeader.svg";
import { openMenu } from "../../Main/redux/actions";
import { useDispatch } from "react-redux";
import InputComponent from "../../../commons/components/Input/InputComponent";
import IconSearch from "../../../assets/images/iconSearch.svg"
import IconDelInput from "../../../assets/images/iconDelInput.png"
import NoData from "../../../commons/components/NoData";
import NoDataIcon from "../../../assets/images/noData.svg";
import ButtonComponent from "../../../commons/components/Button/ButtonComponent";
import PlusIconBtn from "../../../assets/images/plusIconBtn.svg"
import CallIcon from "../../../assets/images/iconCall.svg"
import ModalComponent from "../../../commons/components/Modal/ModalComponent";
import RadioComponent from "../../../commons/components/Radio/RadioComponent";
import CheckboxComponent from "../../../commons/components/Checkbox/CheckboxComponent";
import BannerComponent from "../../../commons/components/Banner/BannerComponent";

import GalleryImg1 from "../../../assets/images/gallery_1.jpg";
import GalleryImg2 from "../../../assets/images/gallery_2.jpg";
import GalleryImg3 from "../../../assets/images/gallery_3.jpg";


const CommonComponent = ({}: props) => {
  const dispatch = useDispatch();
  const [statusModal, setStatusModal] = useState(false);
  const [isCheckedRadio, setIsCheckedRadio] = useState({});
  const [isCheckedCheckbox, setIsCheckedCheckbox] = useState({});
  
  const banner = [GalleryImg1, GalleryImg2, GalleryImg3];
  const handleRightClick = () => {
    dispatch(openMenu(true));
  }

  const handleLeftClick = () => {
    console.log('handleLeftClick');
  }

  const handleChangeInput = (e) => {
    let trimContent = e.target.value.trim();
    console.log(trimContent, 'trimContent');
  }

  const handleFocusInput = () => {
    console.log('handleFocusInput');
  } 
  const handleBlurInput = () => {
    console.log('handleBlurInput');
  } 
  const handleClickInput = () => {
    console.log('handleClickInput');
  } 

  const handleDeleteInput = () => {
    console.log('handleDeleteInput');
  }

  const handleClickBtn = () => {
    console.log('handleClickBtn');
  }

  const handleClickOpenModal = () => {
    setStatusModal(true);
  }

  const handleCancelBtn = () => {
    setStatusModal(false);
  }

  const handleSubmitBtn = () => {
    setStatusModal(false);
  }

  const handleChangeRadio = (e) => {
    console.log(e.target.value, e.target.name, 'handleChangeRadio');
    setIsCheckedRadio({
      [e.target.name] : e.target.value
    })
  }

  const handleChangeCheckbox = (e) => {
    setIsCheckedCheckbox({
      ...isCheckedCheckbox,
      [e.target.name] : !isCheckedCheckbox[e.target.name]
    })
  }
  

  const handleBackClick = () => {
    console.log('handleBackClick');
  }

  const handleSearchInput = () => {
    console.log('handleSearchInput');
  }

  console.log(isCheckedCheckbox, 'isCheckedCheckbox');

  return (
    <MainLayout
      pageID={"common-page"} // pageID
    >
      {/* header */}
      <Header 
        leftChild={LogoIcon} 
        leftIcon={true}
        centerChild={'운영관리'}
        centerIcon={false}
        rightChild={HamburgerIcon}
        rightIcon={true}
        handleRightClick={handleRightClick}  
      />

      {/* <Header 
        leftChild={LeftIconHeader} 
        handleLeftClick={handleLeftClick}  
        leftIcon={true}
        centerChild={'회원가입'}
        centerIcon={false}
        rightChild={false}
        rightIcon={false}
        handleRightClick={handleRightClick}  
      /> */}

      {/* <Header 
        leftChild={LeftIconHeader} 
        handleLeftClick={handleLeftClick}  
        leftIcon={true}
        centerChild={'회원가입'}
        centerIcon={false}
        rightChild={'편집'}
        rightIcon={false}
        handleRightClick={handleRightClick}  
      /> */}

      <div className="wrapper">
        <InputComponent 
          label = {'label'}
          isRequired = {''}
          errorMsg = {''}
          placeHolder = {'placeHolder'}
          id = {''}
          name = {''}
          value = {''}
          handleChange = {handleChangeInput}
          handleFocus = {handleFocusInput}
          handleBlur = {handleBlurInput}
          handleClick = {handleClickInput}
          btnClass = {''}
          btnLabel = {''}
          btnHandleClick = {''}
          appendContent = {''}
          disabled = {''}
          readonly = {''}
          pattern = {''}
          type = {''}
          autoComplete  = {''}
          searchIcon={IconSearch} //IconSearch
          timeCountDown={false} // '3:00' || false
          maxLength = {''}
          characterLenght={''}
          borderBottom={false}
          deleteIcon={''}
          handleSearchIpt={handleSearchInput}
        />
        <InputComponent 
          label = {'label'}
          isRequired = {''}
          errorMsg = {''}
          placeHolder = {'placeHolder'}
          id = {''}
          name = {''}
          value = {''}
          handleChange = {handleChangeInput}
          handleFocus = {handleFocusInput}
          handleBlur = {handleBlurInput}
          handleClick = {handleClickInput}
          btnClass = {''}
          btnLabel = {''}
          btnHandleClick = {''}
          appendContent = {''}
          disabled = {''}
          readonly = {''}
          pattern = {''}
          type = {''}
          autoComplete  = {''}
          searchIcon={false} //IconSearch
          timeCountDown={'3:00'} // '3:00' || false
          maxLength = {''}
          characterLenght={''}
          borderBottom={true}
          deleteIcon={''}
        />
        <InputComponent 
          label = {'label'}
          isRequired = {''}
          errorMsg = {'errorMsg'}
          placeHolder = {'placeHolder'}
          id = {''}
          name = {''}
          value = {''}
          handleChange = {handleChangeInput}
          handleFocus = {handleFocusInput}
          handleBlur = {handleBlurInput}
          handleClick = {handleClickInput}
          btnClass = {''}
          btnLabel = {''}
          btnHandleClick = {''}
          appendContent = {''}
          disabled = {''}
          readonly = {''}
          pattern = {''}
          type = {''}
          autoComplete = {''}
          searchcIon={false} //IconSearch
          timeCountDown={'3:00'} // '3:00' || false
          maxLength = {''}
          characterLenght={''}
          borderBottom={false}
          deleteIcon={''}
        />
        <InputComponent 
          label = {'label'}
          isRequired = {''}
          errorMsg = {''}
          placeHolder = {'placeHolder'}
          id = {'input-delete-1'}
          name = {''}
          value = {''}
          handleChange = {handleChangeInput}
          handleFocus = {handleFocusInput}
          handleBlur = {handleBlurInput}
          handleClick = {handleClickInput}
          btnClass = {''}
          btnLabel = {''}
          btnHandleClick = {''}
          appendContent = {''}
          disabled = {''}
          readonly = {''}
          pattern = {''}
          type = {''}
          autoComplete = {''}
          searchcIon={false} //IconSearch
          timeCountDown={''} // '3:00' || false
          maxLength = {'15'}
          characterLenght={'6'}
          borderBottom={false}
          deleteIcon={IconDelInput}
          btnHandleDelete={handleDeleteInput}
        />

        <ButtonComponent 
          label={'ButtonComponent'}
          style={{width: '100%'}}
          disabled={true}
          cssClass={'mb-2'}
          handleClick={handleClickBtn}
          
        />

        <ButtonComponent
          label={'ButtonComponent'} 
          style={{width: '100%'}}
          cssClass={'mb-2'}
          iconBtn={PlusIconBtn}
          handleClick={handleClickBtn}
          fixedBtn={true}
        />

        <ButtonComponent 
          label={'매니저 이용약관에 동의하셨습니다'}
          style={{width: '100%', backgroundColor: '#000'}}
          iconBtn={''}
          cssClass={'mb-2 '}
          handleClick={handleClickBtn}
        />

        <ButtonComponent 
          label={'전화걸기'}
          style={{width: '100%', backgroundColor: '#fff'}}
          iconBtn={CallIcon}
          cssClass={'mb-2 btn-border'}
          handleClick={handleClickBtn}
        />

        <ButtonComponent 
          label={'open modal'}
          style={{width: '100%'}}
          iconBtn={''}
          cssClass={'mb-2'}
          handleClick={handleClickOpenModal}
        />

        <ModalComponent 
          id={``}
          show={statusModal}
          title={`알림`}
          cancelBtn={`아니오`}
          submitBtn={`예`}
          handleCancel={handleCancelBtn}
          handleSubmit={handleSubmitBtn}
        >
          <div>
            인증번호가 발송 되었습니다.
            발송된 인증 번호를 입력해 주세요.
          </div>
        </ModalComponent>

        <RadioComponent 
          name={'radio'}
          value={1}
          handleChange={handleChangeRadio}
          disabled={false}
          title={'radio 1'}
          subTitle={``}
          labelClass={``}
          componentClass={``}
          checked={isCheckedRadio.radio == '1' ? true : false}
        />

        <RadioComponent 
          name={'radio'}
          value={2}
          handleChange={handleChangeRadio}
          disabled={false}
          title={'radio 2'}
          subTitle={``}
          labelClass={``}
          componentClass={``}
          checked={isCheckedRadio.radio == '2' ? true : false}
        />

        <CheckboxComponent 
          label={`checkBox 1`}
          customClass={``}
          name={`check_box_1`}
          handleChange={(e) => handleChangeCheckbox(e)}
          disabled={false}
          title="checkBox 1"
          labelClass={``}
          componentClass={""}
          checked={isCheckedCheckbox?.check_box_1 || false}
        />
        

        <CheckboxComponent 
          label={`checkBox 2`}
          customClass={``}
          name={`check_box_2`}
          handleChange={(e) => handleChangeCheckbox(e)}
          disabled={false}
          title="checkBox 2"
          labelClass={``}
          componentClass={""}
          checked={isCheckedCheckbox?.check_box_2 || false}
        />

        <CheckboxComponent 
          label={`checkBox 3`}
          customClass={``}
          name={`check_box_3`}
          handleChange={(e) => handleChangeCheckbox(e)}
          disabled={false}
          title="checkBox 3"
          labelClass={``}
          componentClass={""}
          checked={isCheckedCheckbox?.check_box_3 || false}
        />

        <BannerComponent 
          banner={banner}
          handleBackClick={handleBackClick}
        />

        <NoData 
          children={'다른 검색어를 입력해보세요.'}
          text={'등록된 가맹점이 없습니다.'}
          img={NoDataIcon}
        />
      </div>
    
    </MainLayout>
  )

}

export default CommonComponent;