import {useEffect} from 'react';
import io from 'socket.io-client';
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import * as actionTypes from '../../pages/Store/redux/actions/type';
import { syncStore } from '../../pages/Store/redux/actions';
import {openMenu} from "../../pages/Main/redux/actions";
import * as action from "../../pages/Account/redux/actions";
import ROUTERS from "../../commons/constans/router";
import {removeAllStorage} from "../../utils/helpers";

let portStr = process.env.REACT_APP_SOCKET_PORT ? ':' + process.env.REACT_APP_SOCKET_PORT : ''
const address = `${process.env.REACT_APP_SOCKET_HOST}${portStr}`;
// const address = `http://52.77.224.252:3100`;

let socket = null

// https://iotnp-dev.dev-enjoyworks.com/
function useCallSocket(token, user) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (token) {
      socket = io(address, {
        query: "token=" + token
      });
    } else {
      socket = io(address)
    }

    return () => {
      if (socket) {
        socket.close();
      }
    }
  }, [token])

  useEffect(() => {

    window.socket = socket

    let eventGlobal = 'iot_global'
    if (socket && !socket.hasListeners(eventGlobal)) {
      socket.on(eventGlobal, ({ event, data }) => {
        if (event.includes('ChangeOperationState') || event.includes('ChangeOpenState')) {
          dispatch(syncStore({
            data: data.store || {},
            type: actionTypes.SYNC_STORE
          }))

          // update main/{detail}
          if (window.syncStoreDetailPage) {
            window.syncStoreDetailPage(data.store)
          }

          // update main
          if (window.syncStoreMainPage) {
            window.syncStoreMainPage(data.store)
          }
        }
      })
    }

    let eventPrivate = 'iot_private-user_' + user.id
    if (socket && !socket.hasListeners(eventPrivate)) {
      socket.on(eventPrivate, ({ event, data }) => {
        if (event.includes('LogoutEvent')) {
          // removeAllStorage();
          
          localStorage.removeItem('persist:root')
          localStorage.removeItem('_APP_TOKEN')
          localStorage.removeItem('remember_me')
          window.nativeLogoutToken('');
          dispatch(action.resetState());
          dispatch(action.logOutRequest());
          dispatch(openMenu(false));
          navigate(ROUTERS.LOGIN);
        }
      })
    }

  }, [socket])

  return [];
}

export default useCallSocket;
