

import React, { useState } from "react";
import LeftIconHeader from '../../../assets/images/leftIconHeader.svg';
import Header from "../../../commons/components/Header/header";
import MainLayout from "../../../commons/layout/MainLayout";
import IconSearch from "../../../assets/images/iconSearch.svg"
import InputComponent from "../../../commons/components/Input/InputComponent";
import { useNavigate } from "react-router-dom";
import ButtonComponent from "../../../commons/components/Button/ButtonComponent";
import PlusIconBtn from "../../../assets/images/plusIconBtn.svg"
import NoData from "../../../commons/components/NoData";
import ROUTERS from "../../../commons/constans/router";
import { useListManagers, useSearchManager } from "../../../commons/customHooks/Manager";
import { getManagersRequest } from "../redux/actions";
import { useDispatch } from "react-redux";
import API, { ROUTES } from "../../../commons/api/api";
import ModalComponent from "../../../commons/components/Modal/ModalComponent";
import Loading from "../../../commons/components/Loading";
import useDebounce from "../../../commons/customHooks/useDebounce";

type Props = {

}

const ManagerPageContainer = ({}:Props) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [filter, setFilter] = useState({
    relationship_store: 1,
    key_works: '',
    order_by_store_name: "asc"
  });
  const [idDel, setIdDel] = useState({
    uId: null
  });
  const debouncedSearchManager = useDebounce(filter, 500);
  const [listManagers, isProcessing] = useListManagers(debouncedSearchManager);
  const [ modalDeleteManager, setModalDeleteManager] = useState({
    type: '',
    isShow: false,
    title: '',
    msg: '',
    cancelBtn: '취소',
    submitBtn: '확인',
  });

  const handleLeftClick = () => {
    navigate(ROUTERS.MAIN);
  }

  const handleChangeInput = (e) => {
    setFilter({
      ...filter,
      key_works: e.target.value
    });
  }

  const handleSearchInput = () => {
    setFilter({
      ...filter
    })
  }

  const handleClickRegister = () => {
    navigate(ROUTERS.MANAGER_REGISTER);
  }

  const handleDeleteManager = (uId) => {
    setIdDel({
      uId: uId
    })
    setModalDeleteManager({
      type: '',
      isShow: true,
      title: '',
      msg: '매니저를 삭제하시겠습니까?',
      cancelBtn: '취소',
      submitBtn: '확인',
    })
    //call api
  }

  const handleCancel = () => {
    setModalDeleteManager({
      ...modalDeleteManager,
      type: '',
      isShow: false,
      title: '',
      msg: '',
    })
  }

  const handleConfirm = () => {
    setModalDeleteManager({
      ...modalDeleteManager,
      type: '',
      isShow: false,
      title: '',
      msg: '',
    })
    let data = {
      user_id: idDel.uId
    }
    API.post(ROUTES.API_DELETE_MANAGER, data).then((res) => {
      let response = res.data;
      if (response.success) {
        setFilter({
          relationship_store: 1,
          key_works: '',
        })
        setModalDeleteManager({
          type: '',
          isShow: false,
          title: '',
          msg: '',
          cancelBtn: '',
          submitBtn: '',
        })
      }
    }).catch(e => {
      console.log(e, 'eeeeeeeeee');
      // setVerifyErrors({
      //   type: 'verify-biz-regcert',
      //   isShow: true,
      //   title: '',
      //   msg: '이미 등록된 사업자등록번호 입니다',
      //   cancelBtn: '',
      //   submitBtn: '확인',
      // })
    });
  }

  return <>
    <MainLayout pageID={'manager-list-page'}>
      <Header
        leftChild={LeftIconHeader}
        handleLeftClick={handleLeftClick}
        leftIcon={true}
        centerChild={'매니저 관리'}
        centerIcon={false}
      />
      <div className="wrapper">
        <div className="form-search">
          <InputComponent 
            label = {''}
            isRequired = {''}
            // inputClass={'border-radius'}
            errorMsg = {''}
            placeHolder = {'매니저 휴대폰 번호를 입력해주세요.'}
            id = {''}
            name = {''}
            value = {''}
            handleChange = {handleChangeInput}
            btnClass = {''}
            btnLabel = {''}
            btnHandleClick = {''}
            appendContent = {''}
            disabled = {''}
            readonly = {''}
            pattern = {''}
            type = {''}
            autoComplete  = {''}
            searchIcon={IconSearch} //IconSearch
            timeCountDown={false} // '3:00' || false
            maxLength = {''}
            characterLenght={''}
            borderBottom={false}
            deleteIcon={''}
            handleSearchIpt={() => handleSearchInput()}
          />
        </div>
        {
          isProcessing ? <Loading /> : <div className="content-list">
          <p className="content-list__total">전체<span>({listManagers.length})</span></p>
          <ul>
            {
              listManagers.length > 0 && listManagers.map((manager, idx) => (
                <li>
                  <div className="content-left">
                    <p className="name">{manager?.store_name || ''}</p>
                    <p className="phone-number">{manager?.phone_number || ''}</p>
                  </div>
                  <div className="content-right" onClick={() => handleDeleteManager(manager.user_id)}>
                    <span className="delete">삭제</span>
                  </div>
                </li>
              ))
            }
          </ul>
          { listManagers.length === 0 && <NoData text="등록된 매니저가 없습니다."/>}
        </div>
        }
        

        <ButtonComponent
          label={'매니저 등록하기'} 
          style={{width: '100%'}}
          cssClass={'mb-2'}
          iconBtn={PlusIconBtn}
          handleClick={handleClickRegister}
          fixedBtn={true}
        />
      </div>
      <ModalComponent 
          show={modalDeleteManager.isShow || false}
          title={modalDeleteManager.title || ''}
          handleCancel={handleCancel}
          cancelBtn={modalDeleteManager.cancelBtn || ''}
          submitBtn={modalDeleteManager.submitBtn || ''}
          className={``}
          handleSubmit={handleConfirm}
        >
          <div>
            {modalDeleteManager.msg}
          </div>
        </ModalComponent>
    </MainLayout>
  </>
}

export default ManagerPageContainer;