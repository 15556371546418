
import ROUTERS from '../../commons/constans/router';
import React, { lazy } from 'react';

const ManagerPageComponent = lazy(() => import('./containers/ManagerPageContainer'));
const ManagerRegisterPageComponent = lazy(() => import('./containers/ManagerRegisterPageContainer'));

const route = [
  {
    path: ROUTERS.MANAGER,
    title: 'Manager',
    exact: true,
    public: false,
    roles: ['manager', 'shop'],
    component: <ManagerPageComponent />,
  },
  {
    path: ROUTERS.MANAGER_REGISTER,
    title: 'Manager',
    exact: true,
    public: false,
    roles: ['manager', 'shop'],
    component: <ManagerRegisterPageComponent />,
  },
];

export default route;
