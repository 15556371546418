import React from 'react';
import { useSelector } from 'react-redux';
import LoginComponent from '../components/login';

import * as action from '../redux/actions';

const LoginPageContainer = () => {
  const { userInfo ,type, isProcessing, token, statusCode , errors } = useSelector((state) => state.authReducers);
  return <LoginComponent
    loginRequest={action.loginRequest}
    userInfo={userInfo}
    isProcessing={isProcessing}
    token={token}
    logOutRequest={action.logOutRequest}
    type={type}
    statusCode={statusCode}
    resetState={action.resetState}
    errors={errors ?? {}}
    loginSnsRequest={action.loginSnsRequest}
    isProcessingAction={action.isProcessing}
  />;
};

export default LoginPageContainer;
