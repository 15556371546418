import React, { lazy } from 'react';


const CommonComponent = lazy(() => import('./index'));
const Common2Component = lazy(() => import('./common'));
const Common1Component = lazy(() => import('./common1'));

const route = [
  {
    path: '/commons',
    title: 'Common',
    exact: true,
    component: <CommonComponent />,

  },
  {
    path: '/commons2',
    title: 'Common',
    exact: true,
    // public:true,
    component: <Common2Component />,

  },
  {
    path: '/commons1',
    title: 'Common',
    exact: true,
    // public:true,
    component: <Common1Component />,

  },
];

export default route;
