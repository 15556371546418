import React from 'react';
import { useSelector } from 'react-redux';
import IntroComponent from '../components';
import ROUTERS from "../../../commons/constans/router";
import { Navigate  } from "react-router-dom";
import {useCategories} from "../../../commons/customHooks/Store";

const IntroPageContainer = () => {
  const [categories] = useCategories()
  return <IntroComponent/>;
};

export default IntroPageContainer;
