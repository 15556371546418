import React, { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import Header from '../../../commons/components/Header/header';
import LeftIconHeader from '../../../assets/images/leftIconHeader.svg';
import MainLayout from '../../../commons/layout/MainLayout';
import CheckboxComponent from '../../../commons/components/Checkbox/CheckboxComponent';
import ButtonComponent from "../../../commons/components/Button/ButtonComponent";
import { useNavigate  } from "react-router-dom";
import ROUTERS from '../../../commons/constans/router';
import { STEPS } from '../../../commons/constans/store';
import ModalComponent from "../../../commons/components/Modal/ModalComponent";
import { useInformationList, useInformationParams } from '../../../commons/customHooks/Information'

const Policy = () => {
  const navigate = useNavigate();
  const [isCheckedCheckbox, setIsCheckedCheckbox] = useState({});
  const [openModal, setOpenModal] = useState({
    isShowDialog : false,
    content : '',
  });
  const params = useInformationParams('policy', 'user')
  const [ policies ] = useInformationList(params)


  useEffect(() => {
    if (isCheckedCheckbox.rule_all) {
      setIsCheckedCheckbox({
        rule_all: true,
        rule1: true,
        rule2: true,
        rule3: true,
        rule4: true,
      });
    } else {
      if (isCheckedCheckbox.rule1 &&
        isCheckedCheckbox.rule2 &&
        isCheckedCheckbox.rule3 &&
        isCheckedCheckbox.rule4) {
        setIsCheckedCheckbox({
          rule_all: false,
          rule1: false,
          rule2: false,
          rule3: false,
          rule4: false,
        });
      }else{
        setIsCheckedCheckbox({
          ...isCheckedCheckbox,
          rule_all: false,
        });
      }
    }
  }, [isCheckedCheckbox.rule_all]);

  useEffect(() => {
    if (
      isCheckedCheckbox.rule1 &&
      isCheckedCheckbox.rule2 &&
      isCheckedCheckbox.rule3 &&
      isCheckedCheckbox.rule4
    ) {
      setIsCheckedCheckbox({
        rule_all: true,
        rule1: true,
        rule2: true,
        rule3: true,
        rule4: true,
      });
    } else if (
      !isCheckedCheckbox.rule1 ||
      !isCheckedCheckbox.rule2 ||
      !isCheckedCheckbox.rule3 ||
      !isCheckedCheckbox.rule4
    ) {
      setIsCheckedCheckbox({
        ...isCheckedCheckbox,
        rule_all: false,
      });
    }
  }, [
    isCheckedCheckbox.rule1,
    isCheckedCheckbox.rule2,
    isCheckedCheckbox.rule3,
    isCheckedCheckbox.rule4,
  ]);

  const handleChangeCheckbox = (e) => {
    setIsCheckedCheckbox({
      ...isCheckedCheckbox,
      [e.target.name]: !isCheckedCheckbox[e.target.name],
    });
  };

  const handleSubmit = () => {

    if(!isCheckedCheckbox.rule1){
      setOpenModal({
        isShowDialog : true,
        content : '개인정보 처리방침에 동의해 주세요.',
      })
    }else if(!isCheckedCheckbox.rule2){
      setOpenModal({
        isShowDialog : true,
        content : '서비스 이용약관에 동의해 주세요.',
      })
    }else if(!isCheckedCheckbox.rule3){
      setOpenModal({
        isShowDialog : true,
        content : '이벤트/프로모션 정보 제공에 동의해 주세요.',
      })
    }else if(!isCheckedCheckbox.rule4){
      setOpenModal({
        isShowDialog : true,
        content : '위치 정보 이용약관에 동의해 주세요.',
      })
    }

    if(isCheckedCheckbox.rule1 &&
      isCheckedCheckbox.rule2 &&
      isCheckedCheckbox.rule3 &&
      isCheckedCheckbox.rule4
    ){
      navigate(ROUTERS.REGISTER)
    }

  }

  return (
    <MainLayout pageID={'policy-register-page'}>
      <Header
        leftChild={LeftIconHeader}
        handleLeftClick={()=>{
          navigate(ROUTERS.INTRO)
        }}
        leftIcon={true}
        centerChild={'친구야놀자 시작하기'}
        centerIcon={false}
        rightChild={false}
        rightIcon={false}
      />
      <div className="wrapper">

        <div className={'row title-policy'} >
          <div className={'col-12 '}>
            <p className={'m-0 p-0 title-page'}>IoT 플랫폼 앱 '친구야놀자' 이용을 위해</p>
            <p className={'m-0 p-0 title-page'}>아래의 약관 동의 및 회원가입이 필요합니다.</p>
          </div>
        </div>


        <div className="manager-terms mt-4">
          <CheckboxComponent
            label={``}
            componentClass={`rule-all`}
            name={`rule_all`}
            handleChange={(e) => handleChangeCheckbox(e)}
            title="전체동의"
            labelClass={``}
            checked={isCheckedCheckbox?.rule_all || false}
          />
        </div >
        <div className="rules">
          <ul className="rules-list">
            {policies.map((policy, key) => (
              <li key={policy.id}>
                <div className="item">
                  <CheckboxComponent
                    label={``}
                    componentClass={`rule`}
                    name={`rule${key + 1}`}
                    handleChange={(e) => handleChangeCheckbox(e)}
                    disabled={false}
                    title={`${policy.title}(필수)`}
                    labelClass={``}
                    checked={isCheckedCheckbox[`rule${key + 1}`] || false}
                  />
                </div>
                <button
                  type="button"
                  className='btn '
                  onClick={()=>{
                    navigate(ROUTERS.RULES_DETAIL_PATH(policy.id), {
                      state : {
                        headerTitle: policy.title,
                        type: 'user_register',
                      }
                    })
                  }}
                >보기</button>

              </li>
            ))}
          </ul>
        </div>
        <div className='fixed-btn'>
        <ButtonComponent 
          label={'약관 동의'}
          style={{width: '100%'}}
          // disabled={false}
          cssClass={''}
          handleClick={handleSubmit}
          fixedBtn={true}
        />
        </div>
      </div>

      <ModalComponent
        id={``}
        show={openModal.isShowDialog}
        title={`알림`}
        submitBtn={`확인`}
        handleSubmit={()=>{
          setOpenModal({
            ...openModal,
            isShowDialog : false,
            content : '',
          })
        }}
      >
        <div>
          {openModal.content ?? ''}
        </div>
      </ModalComponent>
    </MainLayout>
  );
};

export default Policy;
