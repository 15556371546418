
export const GET_USER_HOME = 'GET_USER_HOME';
export const PUSH_DATA_STORE_STEP_ONE = 'PUSH_DATA_STORE_STEP_ONE';
export const PUSH_DATA_STORE_STEP_TWO = 'PUSH_DATA_STORE_STEP_TWO';
export const PUSH_DATA_STORE_STEP_THREE = 'PUSH_DATA_STORE_STEP_THREE';
export const RESET_DATA_STORE_STEP_ONE = 'RESET_DATA_STORE_STEP_ONE';
export const RESET_DATA_STORE_STEP_TWO = 'RESET_DATA_STORE_STEP_TWO';
export const RESET_DATA_STORE_STEP_THREE = 'RESET_DATA_STORE_STEP_THREE';
export const RESET_ALL_DATA_STORE_STEPS = 'RESET_ALL_DATA_STORE_STEPS';

export const GET_CODE_LABEL_REQUEST = 'GET_CODE_LABEL_REQUEST';
export const GET_CODE_LABEL_SUCCESS = 'GET_CODE_LABEL_SUCCESS';
export const GET_CODE_LABEL_FAILD = 'GET_CODE_LABEL_FAILD';

export const GET_CATEGORIES_REQUEST = 'GET_CATEGORIES_REQUEST';
export const GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS';
export const GET_CATEGORIES_FAILD = 'GET_CATEGORIES_FAILD';

export const CREATE_STORE_REQUEST = 'CREATE_STORE_REQUEST';
export const CREATE_STORE_SUCCESS = 'CREATE_STORE_SUCCESS';
export const CREATE_STORE_FAILD = 'CREATE_STORE_FAILD';

export const GET_LIST_STORE_REQUEST = 'GET_LIST_STORE_REQUEST';
export const GET_LIST_STORE_SUCCESS = 'GET_LIST_STORE_SUCCESS';
export const GET_LIST_STORE_FAILD = 'GET_LIST_STORE_FAILD';

export const GET_DETAIL_STORE_REQUEST = 'GET_DETAIL_STORE_REQUEST';
export const GET_DETAIL_STORE_SUCCESS = 'GET_DETAIL_STORE_SUCCESS';
export const GET_DETAIL_STORE_FAILD = 'GET_DETAIL_STORE_FAILD';

export const UPDATE_STORE_REQUEST = 'UPDATE_STORE_REQUEST';
export const UPDATE_STORE_SUCCESS = 'UPDATE_STORE_SUCCESS';
export const UPDATE_STORE_FAILD = 'UPDATE_STORE_FAILD';

export const RESET_UPDATE_STORE = 'RESET_UPDATE_STORE';
export const SYNC_STORE = 'SYNC_STORE';
