import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ButtonComponent from '../../../../commons/components/Button/ButtonComponent';
import DropDownCommon from '../../../../commons/components/Dropdown';
import ImageBoxComponent from '../../../../commons/components/ImageBox/ImageBoxComponent';
import InputComponent from '../../../../commons/components/Input/InputComponent';
import { formatPhoneNumber, formatRegcertNumber } from '../../../../utils/helpers';
import { pushDataStoreStepTwo } from '../../redux/actions';
import { useCountdown } from '../../../../commons/customHooks/useCountdown'
import API, { ROUTES } from '../../../../commons/api/api';
import ModalComponent from '../../../../commons/components/Modal/ModalComponent';

type Props = {
  steps: Object,
  typeOfBusiness: Array,
  taxClass: Array
};

const Step2Component = ({ steps, typeOfBusiness, taxClass }: Props) => {
  const dispatch = useDispatch();
  const [dataStep2, setDataStep2] = useState(steps.step_2);
  const [time, countDownEnd, resetTimer, startTimer, setStartTimer]=useCountdown(180);
  const [activeDropDown, setActiveDropDown] = useState({
    tob_id: 0,
    taxc_id: 0,
  });

  const [verifyErrors, setVerifyErrors] = useState({
    type: '',
    isShow: false,
    title: '',
    msg: '',
    cancelBtn: '',
    submitBtn: '',
  })

  useEffect(() => {
    setDataStep2(steps.step_2);
  }, []);


  useEffect(() => {
    dispatch(pushDataStoreStepTwo(dataStep2));
  }, [dataStep2]);

  const handleChangeDropdown = (e, name) => {
    setActiveDropDown({
      ...activeDropDown,
      [name]: e.id,
    });

    setDataStep2({
      ...dataStep2,
      [name]: e.id,
    });
  };

  const handleChangeInput = (e) => {
    if (e.target.name === 'biz_regcert') {
      let valuePhoneNumber = e.target.value;
      valuePhoneNumber = valuePhoneNumber.replaceAll('-', '')
      if (valuePhoneNumber.length <= 10) {
        const re = /^[0-9\b]+$/;
        if (valuePhoneNumber === '' || re.test(valuePhoneNumber)) {
          setDataStep2({
            ...dataStep2,
            [e.target.name]: formatRegcertNumber(valuePhoneNumber),
          })
        }
      }
      return false;
    }else{
      setDataStep2({
        ...dataStep2,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleVerifyBizRegcert = () => {
    //call api
    let data = {
      value : dataStep2.biz_regcert,
      type : 'biz_regcert'
    }
    API.post(ROUTES.API_AUTH_VALIDATE, data).then((res) => {
      let response = res.data;
      if (response.status) {
        setDataStep2({
          ...dataStep2,
          biz_regcert_validate: true
        })
        setVerifyErrors({
          type: 'verify-biz-regcert',
          isShow: true,
          title: '',
          msg: '확인되었습니다.',
          cancelBtn: '',
          submitBtn: '확인',
        })
      }
    }).catch(e => {
      setDataStep2({
        ...dataStep2,
        biz_regcert_validate: false
      })
      setVerifyErrors({
        type: 'verify-biz-regcert',
        isShow: true,
        title: '',
        msg: '이미 등록된 사업자등록번호 입니다',
        cancelBtn: '',
        submitBtn: '확인',
      })
    });

    setVerifyErrors({
      type: 'verify-biz-regcert',
      isShow: true,
      title: '',
      msg: '확인되었습니다.',
      cancelBtn: '',
      submitBtn: '확인',
    })

  }

  const handleChangeImage = (e) => {
    setDataStep2({
      ...dataStep2,
      [e.name]: e.file ?? ''
    })
  };

  const handleRemoveImg = (e) => {
    console.log(e, 'handleRemoveImg');
  };

  const handleSendCode = () => {
    setStartTimer(true);
    //call api
    setVerifyErrors({
      type: 'send-code-phone',
      isShow: true,
      title: '',
      msg: '인증 번호가 발송되었습니다.',
      cancelBtn: '',
      submitBtn: '확인',
    })
  }

  useEffect(() => {
    if(countDownEnd){
        console.log('countDownEnd');
    }
  }, [countDownEnd]);
  
  const handleConfirm = () => {
    resetVerifyErrors();
  }

  const handleCancel = () => {
    
  }

  const resetVerifyErrors = () => {
    setVerifyErrors({
      type: '',
      isShow: false,
      title: '',
      msg: '',
      cancelBtn: '',
      submitBtn: '',
    })
  }

  return (
    <div className="step-two">
      <h2>사업자 등록 정보 입력</h2>
      <div className="content">
        <div className="content__company-registration">
          <InputComponent
            label={'사업자 등록번호'}
            isRequired={''}
            errorMsg={''}
            placeHolder={'-없이 숫자만 입력'}
            id={'js-name'}
            name={'biz_regcert'}
            value={dataStep2?.biz_regcert || ""}
            handleChange={handleChangeInput}
            btnClass={''}
            btnLabel={''}
            disabled={''}
            readonly={''}
            type={'text'}
            borderBottom={true}
            maxLength={12}
            numberType={`phone-number`}
          />
          <ButtonComponent
            label={'중복확인'}
            cssClass={'btn-border btn-regis-store'}
            style={{ backgroundColor: '#fff' }}
            handleClick={handleVerifyBizRegcert}
            disabled={dataStep2?.biz_regcert && dataStep2?.biz_regcert.length === 12 ? false : true }
          />
        </div>
        <div className="content__company-registration">
          <InputComponent
            label={'상호명'}
            isRequired={''}
            errorMsg={''}
            placeHolder={'사업자등록증 상의 상호명 입력'}
            id={'js-store-name'}
            name={'store_name'}
            value={dataStep2?.store_name || ''}
            handleChange={handleChangeInput}
            btnClass={''}
            btnLabel={''}
            disabled={''}
            readonly={''}
            type={'text'}
            borderBottom={true}
          />
        </div>
        <div className="content__company-registration business-taxable">
          <DropDownCommon
            customClass={`business-drop`}
            dataList={[...typeOfBusiness]}
            nameDisplay={'사업자 유형'}
            handleClick={(e) => handleChangeDropdown(e, 'tob_id')}
            keyName={'text'}
            idActive={activeDropDown?.tob_id || 0}
            errorMes={``}
            name={`tob_id`}
          />
          <DropDownCommon
            customClass={`taxable-drop`}
            dataList={[...taxClass]}
            nameDisplay={'과세 여부'}
            handleClick={(e) => handleChangeDropdown(e, 'taxc_id')}
            keyName={'text'}
            idActive={activeDropDown?.taxc_id || 0}
            errorMes={``}
            name={`taxc_id`}
          />
        </div>
        <div className="content__company-registration">
          <InputComponent
            label={'대표자'}
            isRequired={''}
            errorMsg={''}
            placeHolder={'사업자등록증 상 대표자 명'}
            id={'js-director'}
            name={'director'}
            value={dataStep2?.director}
            handleChange={handleChangeInput}
            btnClass={''}
            btnLabel={''}
            disabled={''}
            readonly={''}
            type={'text'}
            borderBottom={true}
          />
        </div>
        <div className="content__business-license">
          <p className="title-business">사업자등록증 사본 등록</p>
          <div className="notice-business">
            가맹점 심사 담당자가 내용을 확인할 수 있는 <br />
            사업자등록증 스캔 이미지 또는 사진을 등록해주세요.
          </div>
          <div className="mt-3 d-flex">
            <ImageBoxComponent
              handleChange={(e) => handleChangeImage(e)}
              removeImg={(e) => handleRemoveImg(e)}
              textCard={'내 핸드폰에서 이미지 선택하기'}
              classCustom={`upload-step-2`}
              nameFile={`biz_regcert_file`}
              showRemoveImg={true}
              imgItem={dataStep2?.biz_regcert_file || null}
            />
          </div>
        </div>
      </div>
      <ModalComponent 
          show={verifyErrors.isShow || false}
          title={verifyErrors.title || ''}
          handleCancel={handleCancel}
          cancelBtn={verifyErrors.cancelBtn || ''}
          submitBtn={verifyErrors.submitBtn || ''}
          className={``}
          handleSubmit={handleConfirm}
        >
          <div>
            {verifyErrors.msg}
          </div>
        </ModalComponent>
    </div>
  );
};

export default Step2Component;
