const ROUTERS = {
  INTRO: `/`,
  MAIN: `/main`,
  MAIN_DETAIL: `/main/:id`,
  LOGIN: `/login`,
  FORGOT_PW: `/forgot-password`,
  REGISTER: `/register`,
  POLICY_REGISTER: `/policy-register`,
  DELETE_MEMBER: `/delete-member`,
  CHANGE_PASSWORD : '/change-password',
  // OPERATION:`/qna`,
  IFRAME: `/iframe`,
  NOTICE: `/notice`,
  MERCHANT: `/merchant`,
  STORE_REGISTER_STEP: `/store/register/:step`,
  STORE_REGISTER_STEP_PATH: (step) => `/store/register/${step}`,
  MANAGER: `/manager`,
  EVENT: `/event`,
  STORE: `/store`,
  STORE_INFOMATION: `/store/infomation/:id`,
  STORE_INFOMATION_PATH: (id) => `/store/infomation/${id}`,
  STORE_UPDATE: `/store/update/:id`,
  STORE_UPDATE_PATH: (id) => `/store/update/${id}`,
  STORE_LIST_MANAGER: `/store/list`,
  STORE_RULE: `/store/rules`,
  COMMONS: `/commons`,
  NOTIFICATION: `/notification`,
  NOTIFICATION_DETAIL:`/notification/:id`,
  EVENT_CREATE: `/event/create`,
  EVENT_DETAIL: `/event/:id`,
  EVENT_EDIT: `/event/:id/edit`,
  SETTING_PUSH_NOTICE: `/setting-push`,
  TERMS_OF_USE: `/terms-of-use`,
  TERMS_OF_USE_DETAIL: `/terms-of-use/:id`,
  OPERATION: '/operation',
  OPERATION_HISTORY: '/operation/:id/history',
  SETTING_MENU: '/setting-menu',
  MANAGER_REGISTER: `/manager/register`,
  EVENT_NON_MEMBER: `/event-non-member`,
  EVENT_DETAIL_NON_MEMBER: `/event-non-member/:id`,
  EVENT_INPROGRESS:'/event-inprogress',
  EVENT_INPROGRESS_DETAIL:'/event-inprogress/:id',
  RULES_DETAIL: '/rule/:id',
  RULES_DETAIL_PATH: (id) => `/rule/${id}`,
  MANAGER_RULES: '/manager/rules'
};
export default ROUTERS;
