import React from "react";
import HeaderHs2 from "../../../commons/components/Header/headerHs2";
import MainLayout from "../../../commons/layout/MainLayout/index";
import {useSelector} from "react-redux";
import { useNavigate, Link, useLocation } from "react-router-dom";
import ROUTERS from "../../../commons/constans/router";

const InformationPolicy = () => {
    const { userInfo, isProcessing, token, type, statusCode , errors } = useSelector((state) => state.authReducers);
    const navigate = useNavigate();

    const location = useLocation();
    const state = location.state;

   const handleLabelLeft = () => {
       navigate(ROUTERS.REGISTER,{
           state : state,
       })
   }
    return (
        <MainLayout
            isLoading={isProcessing}
            userInfo={userInfo}
            pageID={"information"}
            displayBottomNav={false}
        >
            <HeaderHs2
                title={"개인정보 처리방침"}
                labelLeft={true}
                labelRight={false}
                handleLabelLeft={handleLabelLeft}
            />
            <div className="wrapper">
                <div className="container">
                    <h3 className="title">스포키즈 개인정보 처리방침</h3>
                </div>

                <div className="main-content">
                    <p className="main-content__detail">
                        ㈜아이오로라(이하 ‘회사’)는 서비스 기획부터 종료까지 정보통신망 이용촉진 및 정보보호 등에 관한 법률(이하 ‘정보통신망 법’), 개인정보보호법 등 국내의 개인정보 보호
                        법령을 철저히 준수합니다. 또한 OECD의 개인정보 보호 가이드라인 등 국제 기준을 준수하여 서비스를 제공합니다.
                        <br/>
                            1. 개인정보 수집에 대한 동의
                            <br/> <br/>
                                회사는 고객님께서 개인정보취급방침 또는 이용약관의 내용에 대해 「동의한다」또는「동의하지 않는다」버튼을 클릭할 수 있는 절차를 마련하고 있으며,「동의한다」
                                체크박스에 체크를 하면 개인정보 수집에 대해 동의한 것으로 봅니다.
                                <br/> <br/>
                                    2. 수집하는 개인정보
                                    <br/> <br/>
                                        회사가 수집하는 개인정보는 다음과 같습니다.
                                        <br/> <br/>
                                            ① SPHOKIDZ APP 서비스 이용을 위한 최소한의 개인정보
                                            <br/> <br/>
                                                회원가입 시점에 SPHOKIDZ APP이 이용자로부터 수집하는 개인정보는 아래와 같습니다.
                                                회원 가입 시에 연동되는 SNS(페이스북, 카카오톡, 구글) 로그인 ‘아이디(이메일), 이름(또는 닉네임)을 수집합니다. 그리고
                                                각각의 서비스의 성격에 따라 생년월일, 주소 등의 추가 항목이 수집될 수 있습니다.
                                                <br/> <br/>
                                                    서비스 이용 과정에서 이용자로부터 수집하는 개인정보는 아래와 같습니다.
                                                    SPHOKIDZ APP내의 개별 서비스 이용, 이벤트 응모 및 경품 신청, 쿠폰사용 과정에서 해당 서비스의 이용자에 한해
                                                    추가 개인정보 수집이 발생할 수 있습니다. 추가로 개인정보를 수집할 경우에는 해당 개인정보 수집 시점에서 이용자에게
                                                    ‘수집하는 개인정보 항목, 개인정보의 수집 및 이용목적, 개인정보의 보관기간’에 대해 안내 드리고 동의를 받습니다.
                                                    <br/> <br/>
                                                        서비스 이용 과정에서 IP 주소, 쿠키, 방문 일시·불량 이용 기록 등의 서비스 이용 기록, 기기정보가 생성되어
                                                        수집될 수 있습니다.
                                                        구체적으로 1) 서비스 이용 과정에서 이용자에 관한 정보를 정보통신서비스 제공자가 자동화된 방법으로 생성하여 이를
                                                        저장(수집)하거나, 2) 이용자 기기의 고유한 정보를 원래의 값을 확인하지 못 하도록 안전하게 변환한 후에 수집하는
                                                        경우를 의미합니다.
                                                        <br/> <br/>
                                                            스포키즈 APP은 아래의 방법을 통해 개인정보를 수집합니다.
                                                            회원가입 및 서비스 이용 과정에서 이용자가 개인정보 수집에 대해 동의를 하고 직접 정보를 입력하는 경우,
                                                            해당 개인정보를 수집합니다.
                                                            <br/> <br/>
                                                                고객센터를 통한 상담 과정에서 웹 페이지, 메일, 팩스, 전화 등을 통해 이용자의 개인정보가 수집될
                                                                수 있습니다.
                                                                온∙오프라인에서 진행되는 이벤트, 세미나 등에서 구두∙서면을 통해 개인정보가 수집될 수 있습니다.
                                                                SPHOKIDZ APP과 제휴한 외부 기업이나 단체로부터 개인정보를 제공받을 수 있으며, 이러한
                                                                경우에는 정보통신망 법에 따라 제휴 사에서 이용자에게 개인정보 제공 동의 등을 받은 후에
                                                                SPHOKIDZ APP에 제공합니다.
                                                                기기정보와 같은 생성정보는 PC웹, 모바일 웹/앱 이용 과정에서 자동으로 생성되어 수집될 수
                                                                있습니다.
                                                                ② 영상정보촬영기기(SPHOKIDZ 키오스크) 서비스 이용을 위한 개인영상정보
                                                                <br/> <br/>
                                                                    영상정보촬영기기(SPHOKIDZ 키오스크)에서 촬영한 개인영상정보(사진)와 같은 생성정보는
                                                                    이용자가 본인의 의지로 인하여 촬영할 시에만 자동으로 생성되어 수집됩니다
                    </p>
                </div>

            </div>
        </MainLayout>
    )
}

export default InformationPolicy;