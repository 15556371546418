import { useEffect, useRef, useState } from "react";
import moment from 'moment'
import MainLayout from "../../../commons/layout/MainLayout/index";
import Header from "../../../commons/components/Header/header";
import LogoIcon from '../../../assets/images/logo.svg'
import IconCalender from "../../../assets/images/icon_calender.svg";
import LeftIconHeader from "../../../assets/images/leftIconHeader.svg";
import { openMenu } from "../../Main/redux/actions";
import { useDispatch } from "react-redux";
import InputComponent from "../../../commons/components/Input/InputComponent";
import IconSearch from "../../../assets/images/iconSearch.svg"
import TextAreaComponent from "../../../commons/components/TextArea/TextAreaComponent";
import ToggleSwitchComponent from "../../../commons/components/ToggleSwitch/ToggleSwitchComponent";
import ItemInListComponent from "../../../commons/components/ItemInList/ItemInListComponent";
import ImageBoxComponent from "../../../commons/components/ImageBox/ImageBoxComponent";

import DatimePickerComponent from "../../../commons/components/DatimePicker/DatimePickerComponent";

import DropDownCommon from "../../../commons/components/Dropdown";
import ImageBoxAddMoreComponent from "../../../commons/components/ImageBox/ImageBoxAddMoreComponent";
import TextAreaAnimationComponent from "../../../commons/components/TextArea/TextAreaAnimationComponent";

const CommonComponent = ({}: props) => {
  const dispatch = useDispatch();

  const [stateUpload, setStateUpload] = useState(false)
  const [modalState, setModalState] = useState(false)
  const [activeDropDown, setActiveDropDown] = useState(0)
  const [imgItem, setImgItem] = useState('https://enjoyworks-playmoongu.s3.ap-northeast-2.amazonaws.com/iot_np/medium/store/6/store_1663293233951407.jfif')

  const [date, setDate] = useState(moment().toDate())
  const [minDate, setMinDate] = useState(moment().subtract(3, "days").toDate())
  const [maxDate, setMaxDate] = useState(moment().add(3, "days").toDate())
  const [characterText, setCharacterText] = useState('');
  const [characterLast, setCharacterLast] = useState('');

  const handleRightClick = () => {
    dispatch(openMenu(true));
  }

  const handleLeftClick = () => {
    console.log('handleLeftClick');
  }
  const handleChangeSwith = (e) => {
    console.log(e,'852')
  }
  const handleChangeImage = (e) => {
    console.log(e,'handleChangeImage')
  }
  const handleChangeImage1 = (e) => {
    console.log(e,'handleChangeImage1')
  }
  const handleRemoveImg = (e) => {
    console.log(e,'handleRemoveImg')
  }
  const onChangeDatePicker = (e) => {
    console.log(e,'onChangeDatePicker')
  }
  const handleChangeTimePicker = (e) => {
    console.log(e,'handleChangeTimePicker')
    setDate(e)
  }
  const handleClickConfirmUpload = (e) => {
    console.log(e,'handleClickConfirmUpload',!e)
    if(!e){
      setModalState(true)
    }
  }
  const handleConfirmModal = () => {
    setStateUpload(true)
  }
  const customRef = useRef(null);
  var dataList = [{id:1,text:'(사용자)개인정보 수집∙이용동의',
    url_medium:'https://enjoyworks-playmoongu.s3.ap-northeast-2.amazonaws.com/iot_np/medium/store_event/10/store_event_1663246842897831.jpg'}
    ,{id:2,text:'(사용자)개인정보 수집∙이용동의2',url_medium:'https://enjoyworks-playmoongu.s3.ap-northeast-2.amazonaws.com/iot_np/medium/store_event/10/store_event_1663249848734704.jfif'},
    {id:3,text:'(사용자)개인정보 수집∙이용동의22',url_medium:''},
    {id:4,text:'(사용자)개인정보 수집∙이용동의23',url_medium:''},
    {id:5,text:'(사용자)개인정보 수집∙이용동의24',url_medium:''},
    {id:6,text:'(사용자)개인정보 수집∙이용동의25',url_medium:''},
    {id:7,text:'(사용자)개인정보 수집∙이용동의26',url_medium:''},
    {id:8,text:'(사용자)개인정보 수집∙이용동의27',url_medium:''},
    {id:9,text:'(사용자)개인정보 수집∙이용동의28',url_medium:''},
  ]
  var dataListTime = [{id:1,text:'10'},{id:2,text:'20'}]

  const changeType = (e) => {
    console.log('changeType',e)
    setActiveDropDown(e.id)
  }

  const handleChangeTextarea = (e) => {
    e.preventDefault();
    let value = e.target.value;
    if (value.length < 20) {
      let arr = value.split('');
      setCharacterText(value);
      setCharacterLast(value);
    }else if(value.length == 20){
      let arr = value.split('');
      let last = arr[value.length-1];
      let result = arr.slice(0, value.length-1);
      let text = result.join('') + `<mark>${last}</mark>`;
      setCharacterText(text);
      setCharacterLast(result.join('') + ' ');
    }else{
      return;
    }
  }

  const handleChangeTextarea2 = () => {

  }


  return (
    <MainLayout
      pageID={"common-page"} // pageID
    >
      {/* header */}
      {/* <Header
        leftChild={LogoIcon}
        leftIcon={true}
        centerChild={'운영관리'}
        centerIcon={false}
        rightChild={HamburgerIcon}
        rightIcon={true}
        handleRightClick={handleRightClick}
      /> */}
      {/* <Header
        leftChild={LeftIconHeader}
        handleLeftClick={handleLeftClick}
        leftIcon={true}
        centerChild={'회원가입'}
        centerIcon={false}
        rightChild={false}
        rightIcon={false}
        handleRightClick={handleRightClick}
      /> */}

      {/* <Header
        leftChild={LeftIconHeader}
        handleLeftClick={handleLeftClick}
        leftIcon={true}
        centerChild={'회원가입'}
        centerIcon={false}
        rightChild={'편집'}
        rightIcon={false}
        handleRightClick={handleRightClick}
      /> */}

      <div className="wrapper w-100" style={{minHeight:'350px'}}>
      
      <div className="container mt-5 textarea-animation-component">
        <div className="backdrop">
          <div className="highlights" dangerouslySetInnerHTML={{__html: characterText}}>
          </div>
        </div>
        <textarea
          onChange={(e) => handleChangeTextarea(e)}
          maxLength={20}
          value={characterLast}
        >
        </textarea>
      </div>



      </div>

       <div className="wrapper w-100 m-auto" style={{minHeight:'350px'}}>

        <TextAreaAnimationComponent
          placeHolder='이벤트 내용을 입력해주세요.'
          handleChange={handleChangeTextarea2}
          contCharacter={true}
          maxLength={20} value={characterLast}
        />

      </div>

      {/*<DialogComponent*/}
      {/*    id={"MD4"}*/}
      {/*    show={modalState}*/}
      {/*    title={"알림"}*/}
      {/*    // closeBtn={"확인"}*/}
      {/*    customBtn={"사용하기"}*/}
      {/*    handleClose={()=>setModalState(false)}*/}
      {/*    handleCustom={()=>handleConfirmModal()}*/}
      {/*    displayClose={true}*/}
      {/*    className={modalState ? 'd-block' : ''}*/}
      {/*>*/}
      {/*  '영상 편지 무료 1회권'을<br/> 사용하시겠습니까?*/}
      {/*</DialogComponent>*/}

      
    </MainLayout>
  )

}

export default CommonComponent;
