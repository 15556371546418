import { put, call, takeLatest } from 'redux-saga/effects';

import * as action from '../redux/actions';
import * as actionTypes from '../redux/actions/type';
import API, { ROUTES } from "../../../commons/api/api";

function* codeLabel({params}) {
  try {
    const response = yield call(() => API.get(ROUTES.API_GET_CODE_LABEL, {params}));
    yield put(action.getCodeLabelSuccess({
      data: response.data?.data || [],
      type: actionTypes.GET_CODE_LABEL_SUCCESS
    }));
  } catch (error) {
    yield put(action.getCodeLabelFaild(error))
  }
}

function* codeLabelSaga() {
  yield takeLatest(actionTypes.GET_CODE_LABEL_REQUEST, codeLabel);
}

export default codeLabelSaga;
