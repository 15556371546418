// @flow
import React, { useState, memo } from "react";

type Props = {
  id: String,
  leftChild: any,
  centerChild: any,
  rightChild: any,
  leftIcon: Boolean,
  centerIcon: String,
  rightIcon: String,
  action: Boolean,
  logOutRequest: Function,
  handleLeftClick: Function,
  handleRightClick: Function,
  css: String
};

const Header = ({
    id,
  handleLeftClick,
  handleRightClick,
  leftChild,
  rightChild,
  leftIcon,
  centerChild,
  centerIcon,
  rightIcon,
    children,
    css,
}: Props) => {

  return (
    <header className={css} id={id}>
      <div className="header-wrapper">
        <div className="header-content">
          <div className="left" style={{maxWidth:(centerChild && !centerIcon && rightChild && rightIcon ? '35%' : 'initial')}}>
            <a title="" href={'#'}
               // href="javascript:void(0)"
               onClick={() => handleLeftClick()}>
              {leftChild && leftIcon && <img src={leftChild} />}
            </a>
          </div>
          <div className="center">
            <a title=""
               // href="javascript:void(0)"
            >
              {centerChild && centerIcon && <img src={centerChild} />}
              {centerChild && !centerIcon && <h2>{centerChild}</h2>}
            </a>
          </div>
          <div className="right">
            <a title=""
               // href="javascript:void(0)"
               onClick={() => handleRightClick()}>
              {rightChild && rightIcon && <img src={rightChild} />}
              {rightChild && !rightIcon && <h2>{rightChild}</h2>}
            </a>
          </div>
        </div>
      </div>
      {children}
    </header>
  );
};

Header.defaultProps = {
  id: 'main-header',
  css: 'header fixed-top',
  leftChild: '', 
  leftIcon: false,
  centerChild: '',
  centerIcon: false,
  rightChild: '',
  rightIcon: '',
  menuShow: false,  
};

export default memo(Header);
