import {Fragment, useEffect, useState} from "react";
import {useNavigate,useParams} from "react-router-dom";


import {useDispatch} from "react-redux";
import MainLayout from "../../../commons/layout/MainLayout/index";

import HeaderHs2 from "../../../commons/components/Header/headerHs2";
import ROUTERS from "../../../commons/constans/router";
import InfiniteScroll from 'react-infinite-scroll-component';

import moment from 'moment';

import SkeletonLoadingComponent from "../../../commons/components/SkeletonLoading";
import ItemInListComponent from "../../../commons/components/ItemInList/ItemInListComponent";


const NotificationPageComponent = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [notificationList, setNotificationList] = useState([])
    const [page, setPage] = useState(1)
    const [statusLoading, setStatusLoading] = useState(false)

    useEffect(() => {
        if(props.loadMore){
            setStatusLoading(true)
        }else {
            setStatusLoading(false)
        }
    }, [props]);

    useEffect(() => {
        let dataList = []
        if(notificationList.length > 0) {
            dataList= [...notificationList,...(props?.notificationData?.data || [])]
        }else {
            dataList=props?.notificationData?.data || [];
        }
        console.log(dataList,'dataList',props?.notificationData?.data)
        setNotificationList(dataList)
    }, [props?.notificationData?.data]);

    const backMenuHome = () => {
      navigate(ROUTERS.HOME)
    }

    const openDetail = (id) => {
        let url = ROUTERS.NOTIFICATION_DETAIL
        url = url.replace(':id',id)
        navigate(url)
    }
    const fetchMoreData = () => {

        if (props?.notificationData?.current_page < props?.notificationData?.last_page && statusLoading) {
            let pageLoad = page + 1;
            setPage(pageLoad)
            let params= '?page='+pageLoad+'&per_page=20'
            props.fetchData(params)
        }
    }

    const loadingDiv = <div className="lists__item loading-content">
        <div className="lists__item__content w-100">
            <span className="w-100">
                <SkeletonLoadingComponent circle={false} width="100%" height="14px"/>
            </span>
            <h3 className="w-100">
                <SkeletonLoadingComponent circle={false} width="25%" height="14px"/>
            </h3>
            </div>
            <div className="lists__item__action">
                {/*<SkeletonLoadingComponent circle={false} width="20px" height="14px"/>*/}
        </div>
    </div>


    return (
        <section className="lists">
            {props?.loading ? ([...Array(10).keys()].map(e => (
                <Fragment key={e}>
                    {loadingDiv}
                </Fragment>
            ))) : notificationList.length > 0 ? <InfiniteScroll
                dataLength={notificationList.length}
                next={fetchMoreData}
                hasMore={props?.loadMore}
                loader={loadingDiv}
            >
                {notificationList.map((value,key)=>(
                    <Fragment key={key}>

                        <ItemInListComponent
                            key={key}
                            onClick={()=>openDetail(value.id)}
                            title={value.title}
                            time={moment(value?.published_at || value?.created_at).format('Y-MM-DD')}
                            classNameCustom={''}
                            classNameStatus={''}
                        />

                    </Fragment>
                ))}
            </InfiniteScroll> :''}

        </section>
    );
};

export default NotificationPageComponent;
