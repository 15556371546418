import NotificationDetailPageComponent from "../../Notification/components/detail";
import React, {useEffect, useState} from "react";
import API, {ROUTES} from "../../../commons/api/api";
import {useNavigate, useParams} from "react-router-dom";
import MainLayout from "../../../commons/layout/MainLayout";
import Header from "../../../commons/components/Header/header";
import LeftIconHeader from "../../../assets/images/leftIconHeader.svg";
import IconGift from "../../../assets/images/icon_gift.webp";
import ROUTERS from "../../../commons/constans/router";
import CallIcon from "../../../assets/images/iconCall.svg";
import IconLocationStore from "../../../assets/images/icon_location_store.svg";
import IconPhoneStore from "../../../assets/images/icon_phone_store.svg";
import IconCalenderStore from "../../../assets/images/icon_calender_store.svg";
import IconActorStore from "../../../assets/images/icon_actor_store.svg";
import IconCommentStore from "../../../assets/images/icon_comment_store.svg";
import IconDefaultDetailStore from "../../../assets/images/icon_default_detail_store.svg";
import IconArrowDown from "../../../assets/images/icon_arrowdown.svg";
import IconBackWhite from "../../../assets/images/header_back_white_cus.svg";
import IconArrowTop from "../../../assets/images/icon-arrowtop.svg";
import IconMenuStore from "../../../assets/images/icon_menu_store.svg";
import ButtonComponent from "../../../commons/components/Button/ButtonComponent";
import {getMapMarker, mapTimeStoreOpenClose, priceFormat, storeMarker} from "../../../utils/helpers";
import {useRole} from "../../../commons/customHooks/Account";
import ModalComponent from "../../../commons/components/Modal/ModalComponent";
import moment from "moment";

const MainDetailPageContainer  = () => {

    const navigate = useNavigate();
    const [dataDetail, setDataDetail] = useState([])
    const [loading, setLoading] = useState(true)
    const [stateShowHeader, setStateShowHeader] = useState(false)
    const [showTime, setStateShowTime] = useState(false)
    const [modalCallPhone, setModalCallPhone] = useState({
        show:false,
        content:''
    })
    const params = useParams();
    // console.log(params,'useParams')
    var id = params?.id ? params?.id : '';

    const isUser = useRole('user');
    const isShop = useRole('shop');
    const isManager = useRole('manager');

    const convertIcon = (data) => {
        let dataFetch = getMapMarker(data)
        return dataFetch
    }

    const fetchData = async (param = '') =>{
        try {
            const {data: response} = await API.get(ROUTES.API_GET_LIST_STORES+(param ? '?id='+param + '&event_in_progress=1' : ''));
            let dataGet = response?.data
            dataGet.icon = convertIcon([response?.data])
            setDataDetail(dataGet);
            setLoading(false)
        } catch (error) {
            console.error(error.message);
            setLoading(false)
        }
    }

    window.onscroll = function (e) {
        // console.log(window.scrollY,'window.onscroll'); // Value of scroll Y in px
        if(window.scrollY > 58){
            setStateShowHeader(true)
        }else {
            setStateShowHeader(false)
        }
    };

    useEffect(() => {
        fetchData(id);
    }, []);

    useEffect(() => {
        window.syncStoreDetailPage = (store) => {
            setDataDetail(p => ({
                ...p,
                ...store
            }))
        }

        return () => {
            window.syncStoreDetailPage = undefined
        }
    }, []);

    const loadMoreData = () => {

    }
    const backMenuHome = () => {
        navigate(ROUTERS.MAIN)
    }
    const handleClickBtn = () => {
        setModalCallPhone({
            show:true,
            content:dataDetail?.store_phone
        })
    }
    const switchDate = (date) => {
        let day = ''
        switch(date) {
            case 0:
                day = '월'
                break;
            case 1:
                day = '화'
                break;
            case 2:
                day = '수'
                break;
            case 3:
                day = '목'
                break;
            case 4:
                day = '금'
                break;
            case 5:
                day = '토'
                break;
            default:
                day = '일'
        }
        return day
    }
    const convertTimeInWeek = (data) => {
        let dataTime = ''

        data && data.length > 0 && data.map(item =>{
            if(item?.state == 'Y'){
                dataTime += `<p>${switchDate(item?.day) + ' ' + item?.starttime.slice(0, -3) + ' ~ ' + item?.endtime.slice(0, -3)}</p>`
            }else {
                dataTime += `<p>${switchDate(item?.day) + ' 휴무'}</p>`
            }
        })
        return <div
          dangerouslySetInnerHTML={{__html: dataTime}}
        />;
    }
    const checkConvertTimeInWeek = (data) => {
        let dataTime = ''

        data && data.length > 0 && data.map(item =>{
            if(item?.state == 'Y'){
                dataTime += `<p>${switchDate(item?.day) + ' ' + item?.starttime.slice(0, -3) + ' ~ ' + item?.endtime.slice(0, -3)}</p>`
            }
        })
        return dataTime
    }
    const showTimeInWeek = () => {
        setStateShowTime(!showTime)
    }
    const RenderImageStore = (data) => {
        let imgHtml = ''
        if(data){
            data && data.length > 0 && data.map((item,index) =>{
                imgHtml += `<img className='img-${index}' src='${item.url_medium}' alt="">`
            })
        }else {
            imgHtml = `<img src='${IconDefaultDetailStore}' alt="">`
        }

        return <div className='item-render-img'
          dangerouslySetInnerHTML={{__html: imgHtml}}
        />;
    }
    const handleCancelBtn = () => {
        setModalCallPhone({...modalCallPhone,show:false})
    }
    window.loadData  = (data) => {
        console.log(data,'loadData')
    }
    const handleSubmitBtn = () => {

        let dataPhone = dataDetail?.store_phone
        if (window.AndroidApp) {
            window.AndroidApp.openCallPhone(dataPhone);
        } else if (window.webkit && window.webkit.messageHandlers) {
            window.webkit.messageHandlers.openCallPhone.postMessage(dataPhone);
        }
        handleCancelBtn()
    }
    const RenderTimeStoreOpenClose = (dataItem) => {
        let timeStore = ''
        dataItem?.working_times && dataItem?.working_times.length > 0 && dataItem?.working_times.map((item,index) =>{
            if(item?.state == 'Y') {
                if (moment(new Date()).day() != 0 && item?.day == (moment(new Date()).day() - 1)){
                    timeStore = dataItem?.open_state != 'OPEN' ? item.starttime : item.endtime
                }else {
                    // timeStore = dataItem?.open_state != 'OPEN' ? item.starttime : item.endtime
                }
                // if (moment(new Date()).day() != 0 && item?.day == (moment(new Date()).day() - 1)){
                //     timeStore = item.starttime.slice(0,-3) + ' 영업 시작' +' , ' + item.endtime.slice(0,-3) + ' 영업 종료'
                // }

            }
        })
        return timeStore.slice(0,-3) + (dataItem?.open_state != 'OPEN' ? ' 영업 시작' : ' 영업 종료')
        // return timeStore
    }


    return (!loading ? <div>
        <MainLayout
          pageID={"store-detail-page"}
        >
            <Header
              leftChild={LeftIconHeader}
              handleLeftClick={()=>backMenuHome()}
              leftIcon={true}
              centerChild={dataDetail?.store_name}
              centerIcon={false}
              rightChild={false}
              rightIcon={false}
              css={stateShowHeader ? 'header fixed-top' : 'header fixed-top height0'}
            />


            <div className='wrapper-detail'>
                <div className='wrapper-detail__card-img'>
                    {!stateShowHeader ? <img className='img-back-left' onClick={()=>backMenuHome()} src={IconBackWhite} alt=""/> : ''}
                    <div className={`content-img photo_gallery${dataDetail?.photo_gallery ? dataDetail?.photo_gallery.length : ''}`}>

                        {!dataDetail?.photo_gallery || dataDetail?.photo_gallery.length < 3 ?
                          RenderImageStore(dataDetail?.photo_gallery) :
                          <div className='item-render-img'>
                              <div className='img-first img-item'>
                                  <img className='img-0' src={dataDetail?.photo_gallery[0].url_medium}/>
                              </div>
                              <div className='img-other img-item'>
                                  <img className='img-1' src={dataDetail?.photo_gallery[1].url_medium} />
                                  <img className='img-2' src={dataDetail?.photo_gallery[2].url_medium} />
                              </div>
                          </div>
                        }

                    </div>
                </div>
                <div className='wrapper-detail__store-phone'>
                    <h2 className='info-store-name'>
                        {dataDetail?.store_name}
                        <label>{dataDetail?.category?.name}</label>
                    </h2>
                    <ButtonComponent
                      label={'전화걸기'}
                      style={{width: '100%', backgroundColor: '#fff'}}
                      iconBtn={CallIcon}
                      cssClass={'mb-2 btn-border'}
                      handleClick={handleClickBtn}
                    />
                </div>
                {dataDetail?.kiosk_state == 'N' && dataDetail?.event_state == 'Y' && dataDetail?.store_events && dataDetail?.store_events.length > 0 ? <div className='wrapper-detail__store-info-event'>
                    <div className='info-event-cart'>
                        <div className='info-event-cart__title'>
                            <img src={IconGift} alt='' />
                            <label>진행중인 이벤트</label>
                        </div>
                        <label className='info-event-cart__description'>{dataDetail?.store_events[0].title}</label>
                    </div>
                </div> : ''}

                <div className='wrapper-detail__store-info'>
                    <div className='item-info info-address'>
                        <img className='item-info__icon' src={IconLocationStore} />
                        <label className='item-info__text'>{dataDetail?.store_address}</label>
                    </div>
                    <div className='item-info info-address'>
                        <img className='item-info__icon' src={IconPhoneStore} />
                        <label className='item-info__text'>{dataDetail?.store_phone}</label>
                    </div>
                    {/*IconArrowTop*/}
                    {checkConvertTimeInWeek(dataDetail?.working_times) && dataDetail?.working_times && dataDetail?.working_times.length > 0 ? (<div className='item-info info-time-open'>
                        <img className='item-info__icon' src={IconCalenderStore} />
                        <div className='item-info__text d-flex align-items-center'>
                            {dataDetail?.open_state != 'OPEN' ? <label className='font-weight-bold'>영업 전</label> :
                              <label className='status-store'>영업중</label>
                            }
                            <label className='icon_arrow_top'>|</label>
                            <label className='time-close-store' onClick={()=>showTimeInWeek()}>{RenderTimeStoreOpenClose(dataDetail)}</label>
                            <img className={'icon-arrow-time'} src={ !showTime ? IconArrowDown : IconArrowTop} />
                        </div>
                        <div className={`show-time-in-week ${showTime ? 'showTime' : ''}`} >
                            {convertTimeInWeek(dataDetail?.working_times)}
                        </div>

                    </div>) : ''}
                    <div className='item-info info-address'>
                        <img className='item-info__icon' src={IconActorStore} />
                        <label className='item-info__text'>운영자<strong className='pl-1'>{dataDetail?.operation_state == 'ABSENT' ? '없음' : '운영자 있음'}</strong></label>
                    </div>
                    {dataDetail?.store_description ? (<div className='item-info info-address align-items-start'>
                        <img className='item-info__icon' src={IconCommentStore} />
                        <label className='item-info__text '>{dataDetail?.store_description}</label>
                    </div>) : ''}
                    {dataDetail?.store_menu && dataDetail?.store_menu.length > 0 ?
                        <div className='item-info info-menu-order'>
                            <img className='item-info__icon' src={IconMenuStore} />
                            <label className='item-info__text font-weight-bold'>메뉴</label>
                            <div className='list-menu-store'>
                                {
                                    dataDetail?.store_menu.map((item,index) =>(<div className='item-menu' key={index}>
                                        <div className={'info-menu'}>
                                            <label className={'name'} >{item?.name}</label>
                                            <label className={'price'} >{priceFormat(item?.price)}원</label>
                                        </div>
                                        <div className={'info-img-menu'}>
                                            {item?.photo ? <img className='item-info__icon' src={item?.photo?.url_medium} /> : ''}
                                        </div>
                                    </div>))
                                }

                            </div>
                        </div> : ''
                    }

                </div>
            </div>

            <ModalComponent
              id={``}
              show={modalCallPhone?.show}
              title={`알림`}
              cancelBtn={`취소`}
              submitBtn={'통화'}
              handleCancel={handleCancelBtn}
              handleSubmit={handleSubmitBtn}
            >
                <div>
                    {modalCallPhone?.content}
                </div>
            </ModalComponent>

        </MainLayout>
    </div> : '')
};

export default MainDetailPageContainer ;
