import * as type from './type';

export const getUserHome = (payload) => {
  console.log('getUserHome action', payload);
  return {
    type: type.GET_USER_HOME,
    payload,
  };
};

export const getUserHomeSuccess = (payload) => {
  console.log('getUserHomeSuccess action', payload);
  return {
    type: type.GET_USER_HOME_SUCCESS,
    payload,
  };
};

export const uploadRequest = (payload) => {
  console.log('uploadRequest', payload);
  return {
    type: type.UPLOAD_IMG_REQUEST,
    payload,
  };
}

export const uploadImgSuccess = (payload) => {
  console.log('uploadImgSuccess', payload);
  return {
    type: type.UPLOAD_IMG_SUCCESS,
    payload,
  };
}


export const openMenu = (payload) => {
  console.log('openMenu', payload);
  return {
    type: type.OPEN_MENU,
    payload,
  };
}