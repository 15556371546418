import EventDetailPageComponent from "../../Event/components/detail";
import React, {useEffect, useState} from "react";
import API, {ROUTES} from "../../../commons/api/api";
import {useParams} from "react-router-dom";
import {useRole} from "../../../commons/customHooks/Account";

const EventDetailPageContainer  = (props) => {

    const [dataDetail, setDataDetail] = useState([])
    const [loading, setLoading] = useState(true)
    const [statusPushEvent, setStatusPushEvent] = useState(false)
    const [eventInprogress, setEventInprogress] = useState(false)
    const params = useParams();

    var id = params?.id ? params?.id : '';

    const isUser = useRole('user');
    const isShop = useRole('shop');
    const isManager = useRole('manager');

    const fetchData = async (param = '') =>{
        try {

            const {data: response} = await API.get(ROUTES.API_EVENT+(param ? '?id='+param : ''));
            setDataDetail(response?.data);
            setLoading(false)
        } catch (error) {
            console.error(error.message);
            setLoading(false)
        }
    }
    const fetchReceiveEventPush = async (param = '') =>{
        try {
            const formDataReceivePush = new FormData();
            formDataReceivePush.append("event_id", id);
            if(param){
                formDataReceivePush.append("receive_event", param);
            }
            API.post(ROUTES.API_RECEIVE_PUSH_EVENT, formDataReceivePush).then(res => {
                console.log(res,'fetchReceiveEventPush')
                setStatusPushEvent(res?.data?.data?.receive_event == 'ON' ? false : true)
                // setStatusSubmit(false)
            }).catch(e => {
                let errors = e.response;
                let dataError = {};
                console.log(errors,'catch error',errors.response)

            })
        } catch (error) {
            console.error(error.message);
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchData(id);
        if(isUser || isShop || isManager){
            fetchReceiveEventPush()
        }
        if(props?.type && props?.type == 'event-inprogress') {
            setEventInprogress(true)
        }
    }, []);

    const handChangPushEvent = (status) => {
        let statusPush = status ? 'OFF' : 'ON'
        fetchReceiveEventPush(statusPush)
    }

    return <EventDetailPageComponent
        loading={loading}
        statusPushEvent={statusPushEvent}
        eventInprogress={eventInprogress}
        handChangPushEvent={(e)=>handChangPushEvent(e)}
        dataDetail={dataDetail}
    />;
};

export default EventDetailPageContainer ;
