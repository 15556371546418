import { useNavigate } from 'react-router-dom';

import ItemInListComponent from "../../../commons/components/ItemInList/ItemInListComponent";

function Setting ({store}) {
  const navigate = useNavigate();

  return (
    <div>
      <div className="text-center">
        <ItemInListComponent
          onClick={()=>{navigate(`/operation/${store.id}/history`);}}
          title={'영업 상태 변경 이력'}
          classNameCustom={'history'}
        />
      </div>
    </div>
  )
}

export default Setting
