import React, { Fragment, useEffect, useState } from 'react';
import MainLayout from '../../../commons/layout/MainLayout';

import LeftIconHeader from "../../../assets/images/leftIconHeader.svg";
import LogoIcon from '../../../assets/images/logo.svg'
import HamburgerIcon from "../../../assets/images/hamburger.svg";
import Header from '../../../commons/components/Header/header';
import { useParams, useLocation } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { openMenu } from "../../Main/redux/actions";
import { useListStores } from "../../../commons/customHooks/Store";
import { useRole, useUser } from "../../../commons/customHooks/Account";

import Title from "./../components/Title";
import WorkingTime from "./../components/WorkingTime";
import Setting from "./../components/Setting";
import History from "./../components/History";
import ModalComponent from '../../../commons/components/Modal/ModalComponent';
import ROUTERS from '../../../commons/constans/router';
import API, { ROUTES } from '../../../commons/api/api';
import Loading from '../../../commons/components/Loading';
import { updateUserLocal } from '../../Account/redux/actions';

const OperationPageContainer = () => {
  const dispatch = useDispatch();
  const {user} = useUser();
  const isManager = useRole('manager');
  const [loadingHidePopup, setLoadingHidePopup] = useState(false);
  let query = new URLSearchParams(useLocation().search);

  const navigate = useNavigate();
  const [params] = useState({
    approval_state: ['ACTIVED'],
    store_by_user_id: user.id
  });
  const [stores] = useListStores(params);
  const [storeSelected, setStoreSelected] = useState(null);
  const [isShowManagerModal, setIsShowManagerModal] = useState({
    type: '',
    isShow: false,
    title: '',
    msg: ``,
    cancelBtn: '',
    submitBtn: '확인',
  });

  useEffect(() => {
    if (stores) {
      let store = stores.find(e => e.id == query.get('store'))
      setStoreSelected(store || stores[0])
    }
  }, [stores.length])

  useEffect(() => {
    if (stores && storeSelected) {
      let store = stores.find(e => e.id === storeSelected.id)
      setStoreSelected(store || stores[0])
    }
  }, [stores])

  useEffect(() => {
    if(storeSelected) {
      navigate('/operation?store=' + storeSelected.id)
    }
  }, [storeSelected])

  useEffect(() => {
    // let idManagerLogin = localStorage.getItem('MANAGER_LOGIN_ID');
    // let idManagerLoginArr = [];
    // if (idManagerLogin && idManagerLogin != 'null') {
    //   idManagerLoginArr = JSON.parse(idManagerLogin)
    // }

    if (isManager && user?.read_terms && user?.read_terms == 'ON') {
      setIsShowManagerModal({
        type: '',
        isShow: true,
        title: '',
        msg: `가맹점 메뉴를 이용하시려면 매니저 
              이용약관을 동의하셔야 합니다.`,
        cancelBtn: '',
        submitBtn: '확인',
      })
    }
  }, [isManager])

  const handleLeftClick = () => {
    navigate('/main');
  }

  const handleRightClick = () => {
    dispatch(openMenu(true));
  }

  const handleSubmitManager = () => {
    //call update read_terms
    setLoadingHidePopup(true);
    let data = {
      phone_number: user.phone_number,
      read_terms: 'OFF',
    }
    API.post(ROUTES.API_UPDATE_USER+`/${user.id}`, data)
      .then((res) => {
        setLoadingHidePopup(false);
        setIsShowManagerModal({
          type: '',
          isShow: false,
          title: '',
          msg: ``,
          cancelBtn: '',
          submitBtn: '확인',
        })
        navigate(ROUTERS.MANAGER_RULES);
        //update redux
        dispatch(updateUserLocal());
      })
      .catch((err) => {
        setLoadingHidePopup(false);
        navigate(ROUTERS.MANAGER_RULES)
      });
  }

  const handleCancelManager = () => {

  }

  return (
    <div>
      <MainLayout 
        pageID={"operation-page"}
      >
        <Header
          leftChild={LogoIcon} 
          leftIcon={true}
          handleLeftClick={handleLeftClick}
          centerChild={'운영관리'}
          centerIcon={false}
          rightChild={HamburgerIcon}
          rightIcon={true}
          handleRightClick={handleRightClick}  
        />
        <div className='wrapper'>
          <Title
            stores={stores}
            storeSelected={storeSelected}
            setStoreSelected={setStoreSelected}
          />
          <WorkingTime
            store={storeSelected}
          />

          <Setting store={storeSelected} />

          <History store={storeSelected} />

        </div>

        <ModalComponent
          id={`modal-manager-confirm`}
          show={isShowManagerModal.isShow || false}
          title={isShowManagerModal?.title || ''}
          cancelBtn={isShowManagerModal?.cancelBtn || ''}
          submitBtn={isShowManagerModal?.submitBtn || ''}
          handleCancel={handleCancelManager}
          handleSubmit={handleSubmitManager}
        >
          <div>
            {isShowManagerModal?.msg || ''}
          </div>
        </ModalComponent>
        {loadingHidePopup && <Loading />}
      </MainLayout>
    </div>
  )
};

export default OperationPageContainer;
