import React from 'react';
import Skeleton from 'react-loading-skeleton';
type props = {
  type: String,
  width: Number,
  cssClass: String,
  count: Number,
  height: Number,
  circle: Boolean
};

const SkeletonLoadingComponent = ({
  type,
  width,
  cssClass,
  count,
  height,
  circle
}: props) => {
  let loadingHtml = <></>;
  switch (type) {
    case 'video':
      loadingHtml = (
        <div className={`photo-selector__thumb-loading mt-3 ${cssClass}`}>
          <Skeleton
            containerClassName={`border-image width-height-image thumbnail-skeleton`}
            height={height}
          />
          <div className="marker-loading">
            <img src="/images/album/icon_play-btn.svg" alt="" />
          </div>
        </div>
      );
      break;
    case 'album':
      loadingHtml = (
        <div className='img-list'>
          <label className={cssClass}>
            <div className={`${cssClass}__thumb`}>
              <Skeleton containerClassName={`album-page__border-image album-skeleton `} height={170}/>
            </div>
          </label>
        </div>
      );
      break;
    default:
      loadingHtml = (
        <>
          <Skeleton
            containerClassName={cssClass}
            width={width}
            count={count}
            height={height}
            circle={circle}
          />
        </>
      );
      break;
  }
  return <>{loadingHtml}</>;
};

SkeletonLoadingComponent.defaultProps = {
  type: null,
  cssClass: '',
  count: 1,
  height: 15,
  width: '100%'
};

export default SkeletonLoadingComponent;
