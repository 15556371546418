
export const STEPS = {
  ONE: 'step-1',
  TWO: 'step-2',
  THREE: 'step-3',
};

export const MODAL_TYPE = {
  REGISTER_SUCCESS: 'register-success',
  STEP_BACK: 'step-back',
  CREATE_FAIL: 'create_fail'
};


export const dataHoursArr = [
  { id: 0, text: '00', disable: false },
  { id: 1, text: '01', disable: false },
  { id: 2, text: '02', disable: false },
  { id: 3, text: '03', disable: false },
  { id: 4, text: '04', disable: false },
  { id: 5, text: '05', disable: false },
  { id: 6, text: '06', disable: false },
  { id: 7, text: '07', disable: false },
  { id: 8, text: '08', disable: false },
  { id: 9, text: '09', disable: false },
  { id: 10, text: '10', disable: false },
  { id: 11, text: '11', disable: false },
  { id: 12, text: '12', disable: false },
  { id: 13, text: '13', disable: false },
  { id: 14, text: '14', disable: false },
  { id: 15, text: '15', disable: false },
  { id: 16, text: '16', disable: false },
  { id: 17, text: '17', disable: false },
  { id: 18, text: '18', disable: false },
  { id: 19, text: '19', disable: false },
  { id: 20, text: '20', disable: false },
  { id: 21, text: '21', disable: false },
  { id: 22, text: '22', disable: false },
  { id: 23, text: '23', disable: false },
];
export const dataMinusArr = [
  { id: 0, text: '00', disable: false },
  { id: 1, text: '01', disable: false },
  { id: 2, text: '02', disable: false },
  { id: 3, text: '03', disable: false },
  { id: 4, text: '04', disable: false },
  { id: 5, text: '05', disable: false },
  { id: 6, text: '06', disable: false },
  { id: 7, text: '07', disable: false },
  { id: 8, text: '08', disable: false },
  { id: 9, text: '09', disable: false },
  { id: 10, text: '10', disable: false },
  { id: 11, text: '11', disable: false },
  { id: 12, text: '12', disable: false },
  { id: 13, text: '13', disable: false },
  { id: 14, text: '14', disable: false },
  { id: 15, text: '15', disable: false },
  { id: 16, text: '16', disable: false },
  { id: 17, text: '17', disable: false },
  { id: 18, text: '18', disable: false },
  { id: 19, text: '19', disable: false },
  { id: 20, text: '20', disable: false },
  { id: 21, text: '21', disable: false },
  { id: 22, text: '22', disable: false },
  { id: 23, text: '23', disable: false },
  { id: 24, text: '24', disable: false },
  { id: 25, text: '25', disable: false },
  { id: 26, text: '26', disable: false },
  { id: 27, text: '27', disable: false },
  { id: 28, text: '28', disable: false },
  { id: 29, text: '29', disable: false },
  { id: 30, text: '30', disable: false },
  { id: 31, text: '31', disable: false },
  { id: 32, text: '32', disable: false },
  { id: 33, text: '33', disable: false },
  { id: 34, text: '34', disable: false },
  { id: 35, text: '35', disable: false },
  { id: 36, text: '36', disable: false },
  { id: 37, text: '37', disable: false },
  { id: 38, text: '38', disable: false },
  { id: 39, text: '39', disable: false },
  { id: 40, text: '40', disable: false },
  { id: 41, text: '41', disable: false },
  { id: 42, text: '42', disable: false },
  { id: 43, text: '43', disable: false },
  { id: 44, text: '44', disable: false },
  { id: 45, text: '45', disable: false },
  { id: 46, text: '46', disable: false },
  { id: 47, text: '47', disable: false },
  { id: 48, text: '48', disable: false },
  { id: 49, text: '49', disable: false },
  { id: 50, text: '50', disable: false },
  { id: 51, text: '51', disable: false },
  { id: 52, text: '52', disable: false },
  { id: 53, text: '53', disable: false },
  { id: 54, text: '54', disable: false },
  { id: 55, text: '55', disable: false },
  { id: 56, text: '56', disable: false },
  { id: 57, text: '57', disable: false },
  { id: 58, text: '58', disable: false },
  { id: 59, text: '59', disable: false },
];

export const dataWorkingTimeDefaul = [
  {id: null, store_id: null, day: 0, state: 'N', starttime: '00:00:00', endtime: '00:00:00'},
  {id: null, store_id: null, day: 1, state: 'N', starttime: '00:00:00', endtime: '00:00:00'},
  {id: null, store_id: null, day: 2, state: 'N', starttime: '00:00:00', endtime: '00:00:00'},
  {id: null, store_id: null, day: 3, state: 'N', starttime: '00:00:00', endtime: '00:00:00'},
  {id: null, store_id: null, day: 4, state: 'N', starttime: '00:00:00', endtime: '00:00:00'},
  {id: null, store_id: null, day: 5, state: 'N', starttime: '00:00:00', endtime: '00:00:00'},
  {id: null, store_id: null, day: 6, state: 'N', starttime: '00:00:00', endtime: '00:00:00'},
]
