import { put, call, takeLatest } from 'redux-saga/effects';

import * as action from '../redux/actions';
import * as actionTypes from '../redux/actions/type';
import API, { ROUTES } from "../../../commons/api/api";

function* category({params}) {
  try {
    const response = yield call(() => API.get(ROUTES.API_GET_CATEGORIES, {params}));
    yield put(action.getCategoriesSuccess({
      data: response.data?.data || [],
      type: actionTypes.GET_CATEGORIES_SUCCESS
    }));
  } catch (error) {
    yield put(action.getCategoriesFaild(error))
  }
}

function* categoriesSaga() {
  yield takeLatest(actionTypes.GET_CATEGORIES_REQUEST, category);
}

export default categoriesSaga;
