import * as React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import ROUTERS from '../constans/router';
import { useRole } from '../customHooks/Account';

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const  PublicRouter = ({ children, isAuth }) => {
  const query = useQuery();
  const isManager = useRole(['manager', 'shop']);
  let route = '';

  if (isManager) {
    route = ROUTERS.OPERATION
  }else{
    route = query.get('redirect') || ROUTERS.MAIN
  }
  
  return <>
    {!isAuth
      ? children
      : <Navigate to={route} />
    }
  </>;
};

export default PublicRouter;
