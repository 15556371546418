import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import ButtonComponent from "../../../../commons/components/Button/ButtonComponent";
import DropDownCommon from "../../../../commons/components/Dropdown";
import InputComponent from "../../../../commons/components/Input/InputComponent";
import ROUTERS from "../../../../commons/constans/router";
import { formatPhoneNumber, formatPhoneNumberForAddress } from "../../../../utils/helpers";
import { pushDataStoreStepThree } from "../../redux/actions";
import ButtonSearchMap from "../Map/ButtonSearch";

type Props = {
  steps: Object,
  categories: Array
}

const categoriesList = [];
const Step3Component = ({steps, categories}: Props) => {

  const dispatch = useDispatch();
  const [dataStep3, setDataStep3] = useState(steps.step_3);
  const [activeDropDown, setActiveDropDown] = useState({
    category_id: '',
    text: '',
  })

  useEffect(() => {
    setDataStep3(steps.step_3);
  }, [])

  useEffect(() => {
    dispatch(pushDataStoreStepThree(dataStep3))
  }, [dataStep3])

  const handleChangeDropdown = (e, name) => {
    setActiveDropDown({
      ...activeDropDown,
      [name]: e.id,
      text: e.text
    })

    setDataStep3({
      ...dataStep3,
      [name] : e.id
    })
  }

  // useEffect(() => {
  //   if (categories.length > 0) {
  //     categories.map((item, idx) => {
  //       categoriesList.push({id: item.id, text: item.name})
  //     })
  //   }
  // }, [categories])
  
  const handleChangeInput = (e) => {
    //xxx-xxx-xxxx còn 11 số thì xxx-xxxx-xxxx
    if (e.target.name === 'director_no') {
      let valuePhoneNumber = e.target.value;
      valuePhoneNumber = valuePhoneNumber.replaceAll('-', '');
      if (valuePhoneNumber.length <= 10) {
        const re = /^[0-9\b]+$/;
        if (valuePhoneNumber === '' || re.test(valuePhoneNumber)) {
          setDataStep3({
            ...dataStep3,
            [e.target.name]: formatPhoneNumberForAddress(valuePhoneNumber),
          });
        }
      }
      if (valuePhoneNumber.length == 11) {
        const re = /^[0-9\b]+$/;
        if (valuePhoneNumber === '' || re.test(valuePhoneNumber)) {
          setDataStep3({
            ...dataStep3,
            [e.target.name]: formatPhoneNumber(valuePhoneNumber),
          });
        }
      }
      return false;
    }else{
      setDataStep3({
        ...dataStep3,
        [e.target.name]: e.target.value 
      })
    }
  }

  const handleMap = (q) => {
    setDataStep3({
      ...dataStep3,
      zone_code : q.zone_no,
      store_address: q.address,
      store_address_detail: q.address_detail,
      lat: q.lat, 
      lng: q.lng
    })
  }

  return (
    <div className="step-three">
      <h2>가맹점 등록 정보입력</h2>
      <div className="content">
        <div className="content__company-registration">
          <InputComponent 
            label = {'대표번호(전화)'}
            isRequired = {''}
            errorMsg = {''}
            placeHolder = {'-없이 숫자만 입력'}
            id = {'js-director-no'}
            name = {'director_no'}
            value = {dataStep3?.director_no || ''}
            handleChange = {handleChangeInput}
            btnClass = {''}
            btnLabel = {''}
            disabled = {''}
            readonly = {''}
            type = {'text'}
            borderBottom={true}
            maxLength={13}
            numberType={`phone-number`}
          />
        </div>
        <div className="content__company-registration">
          <label className="industry-type">업종유형</label>
          <DropDownCommon
            customClass={`industry-drop`}
            dataList={[{id:0,text:'유형'},...categories]}
            nameDisplay={'사업자 유형'}
            handleClick={(e) => handleChangeDropdown(e, 'category_id')}
            keyName={'text'}
            idActive={activeDropDown?.category_id || ''}
            name={`category_id`}
          />
          <label className="notice-industry-type">*선택한 업종 유형으로 가맹점의 카테고리가 구분되어 <br /> &nbsp; 제공됩니다.</label>
        </div>
        <div className="content__company-registration">
          <InputComponent 
            label = {'주소'}
            isRequired = {''}
            errorMsg = {''}
            placeHolder = {'우편번호검색'}
            id = {'js-name'}
            name = {'zone_code'}
            value = {dataStep3?.zone_code || ''}
            handleChange = {handleChangeInput}
            btnClass = {''}
            btnLabel = {''}
            disabled = {true}
            readonly = {''}
            type = {'number'}
            borderBottom={true}
          />

          <ButtonSearchMap 
            cb={handleMap}
          />
        
        </div>
        <div className="content__company-registration mt-1">
          <InputComponent 
            label = {''}
            isRequired = {''}
            errorMsg = {''}
            placeHolder = {'주소입력'}
            id = {'js-name'}
            name = {'store_address'}
            value = {dataStep3?.store_address || ''}
            handleChange = {handleChangeInput}
            btnClass = {''}
            btnLabel = {''}
            disabled = {true}
            readonly = {''}
            type = {'text'}
            borderBottom={true}
          />
        </div>
        <div className="content__company-registration mt-1">
          <InputComponent 
            label = {''}
            isRequired = {''}
            errorMsg = {''}
            placeHolder = {'상세주소 입력'}
            id = {'js-name'}
            name = {'store_address_detail'}
            value = {dataStep3?.store_address_detail || ''}
            handleChange = {handleChangeInput}
            btnClass = {''}
            btnLabel = {''}
            disabled = {''}
            readonly = {''}
            type = {'text'}
            borderBottom={true}
          />
          
        </div>
      </div>
    </div>
  )
}

export default Step3Component;