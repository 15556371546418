
import { useNavigate, useLocation } from 'react-router-dom'
import queryString from 'query-string'

const useChangeUrlParams = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const changeUrlParams = (searchQuery, option) => {
    const params = {...searchQuery}
    if (option) {
      if (Array.isArray(option)) {
        let hasPage = false
        for (const { type, value } of option){
          params[type] = value
          if (type === 'page') {
            hasPage = true
          }
        }
        if (!hasPage) {
          params.page = 1
        }
      } else {
        const { type, value } = option
        params[type] = value
        if (type !== 'page') {
          params.page = 1
        }
      }
    }
    navigate(
      {
        pathname: location.pathname,
        search: `?${queryString.stringify(params)}`,
      },
      { replace: true }
    )
  }

  return {
    changeUrlParams
  }

};

export default useChangeUrlParams;

// Usage
// const changeUrlParams = useChangeUrlParams();
