
import ROUTERS from '../../commons/constans/router';
import React, { lazy } from 'react';


const NotificationComponent = lazy(() => import('./containers/SettingPushNoticeContainer'));



const route = [
  {
    path: ROUTERS.SETTING_PUSH_NOTICE,
    title: 'Setting Push Notice',
    exact: true,
    // public: false,
    component: <NotificationComponent />,
  },
];

export default route;