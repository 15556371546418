import { put, call, takeLatest } from 'redux-saga/effects';

import * as actionCreators from '../redux/actions';
import * as actionTypes from '../redux/actions/type';
import API, { ROUTES } from "../../../commons/api/api";

function* upload(action) {
  console.log('3r234234234');
  try {
    const response = yield call(() => API.post(ROUTES.API_UPLOAD, action.payload, {
      headers: { 'Content-Type': 'multipart/form-data' },
    }));
    yield put(actionCreators.uploadImgSuccess({ data: response }));
  } catch (error) {
    
  }
}

function* uploadSaga() {
  yield takeLatest(actionTypes.UPLOAD_IMG_REQUEST, upload);
}

export default uploadSaga;
