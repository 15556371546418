import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import {
  createManagerRequest, getManagersRequest,
} from '../../../pages/Manager/redux/actions';
import API, {ROUTES} from '../../api/api'

const PER_PAGE = {
  'user': 4,
  'store': 3,
  'manager': 1,
}

export const useInformationParams = (type, id) => {
  return useMemo(() => ({
    per_page: PER_PAGE[id],
    type,
    order_by_version: 'asc',
  }), [])
}

export const useInformationList = (params) => {
  const [loading, setLoading] = useState(false)
  const [list, setList] = useState([])
  useEffect(() => {
    if (params) {
      setLoading(true)
      API.get(ROUTES.API_TERMS_OF_USE, {params})
        .then(res => {
          setLoading(false)
          setList(res.data.data.data)
        })
        .catch(() => {
          setLoading(false)
        })
    }
  // eslint-disable-next-line
  }, [params])

  return [list, loading]
};

export const useInformationDetail = (params) => {
  const [loading, setLoading] = useState(false)
  const [list, setList] = useState({})
  useEffect(() => {
    if (params) {
      setLoading(true)
      API.get(ROUTES.API_TERMS_OF_USE, {params})
        .then(res => {
          setLoading(false)
          setList(res.data.data)
        })
        .catch(() => {
          setLoading(false)
        })
    }
  // eslint-disable-next-line
  }, [params])

  return [list, loading]
};
