import * as React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import ROUTERS from '../constans/router';

const  PrivateRoute = ({ children, isAuth, ...rest }) => {
  const location = useLocation()

  return <>
  {isAuth
    ? children
    : <Navigate to={ROUTERS.INTRO + '?redirect=' + location.pathname + location.search} />
  }
  </>;
};

export default PrivateRoute;
