import API from '../../../../commons/api/api';
import * as actionTypes from '../actions/type';

export const initialState = {
  isProcessing: false,
  createSuccess: [],
  createFaild: [],
  listManagers: [],
  isProcessingCreate: false,
  status: '',
};

const managerReducers = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_MANAGERS_REQUEST:
      return {
        ...state,
        isProcessing: true,
        type: action.type,
        createFaild: [],
        createSuccess: [],
        status: 200,
      };
    case actionTypes.GET_MANAGERS_SUCCESS:
      return {
        ...state,
        isProcessing: false,
        type: action.type,
        listManagers: action.payload?.data || [],
        createFaild: [],
        status: 200,
      };
    case actionTypes.GET_MANAGERS_FAILD:
      return {
        ...state,
        isProcessing: false,
        type: action.type,
        listManagers: [],
        createFaild: [],
        status: 400,
      };

    case actionTypes.CREATE_MANAGER_REQUEST:
      return {
        ...state,
        isProcessingCreate: true,
        type: action.type,
      };
    case actionTypes.CREATE_MANAGER_SUCCESS:
      return {
        ...state,
        isProcessingCreate: false,
        type: action.type,
        createSuccess: action.payload?.data || [],
        createFaild: [],
        status: 200,
      };
    case actionTypes.CREATE_MANAGER_FAILD:
      return {
        ...state,
        isProcessingCreate: false,
        type: action.type,
        createSuccess: [],
        createFaild: action.payload?.data || [],
        status: action.payload?.status
      };
    default:
      return state;
  }
};

export default managerReducers;
