import React, { memo } from 'react';
import HeaderBack from "../../../assets/images/header_back_w.svg";
import ImageNoStore from "../../../assets/images/img_nostore.svg";
type Props = {
  banner: Array,
  handleBackClick: Function,
  iconLeft: Boolean,
  cssClass: String
};

const BannerComponent = ({ banner, handleBackClick, iconLeft, cssClass }: Props) => {
  const renderGallery = () => {
    let element = '';

    if (banner.length > 0) {
      if (banner.length <= 1) {
        element = <img src={banner[0]} />;
      }else{
        element = (<>
          <div className="gallery__left">
            <img src={banner[0]} />
          </div>
          <div className={`gallery__right ${cssClass}`}>
            <img src={banner[1]} />
            {
              banner[2] && <img src={banner[2]} />
            }
          </div>
        </>)
      }
    }else{
      element = <img src={ImageNoStore} />;
    }
    
    return element;
  };
  return <div className={`gallery ${cssClass}`}>
            {
              iconLeft && <div className='gallery__header' onClick={handleBackClick}>
                <img className='img-back' src={HeaderBack} />
              </div>
            }
            
            {renderGallery()}
          </div>;
};

export default memo(BannerComponent);
