import { Link } from 'react-router-dom'
import { timeWithoutSecondFormat } from '../../../utils/helpers'
import workingTimeNone from "../../../assets/images/working_time_none.svg";
import moment from 'moment'

function WorkingTime ({store}) {
  const renderTime = (working_time) => {
    if (working_time.state === 'N') {
      return '정보 없음'
    }

    return `${timeWithoutSecondFormat(working_time.starttime)}~${timeWithoutSecondFormat(working_time.endtime)}`
  }

  const weekday = moment().day()

  const noBle = (store?.beacon_state === 'N' || !store?.beacon_uuid) && store?.working_times.length

  return (
    <div className="working-time">
      <label className="title-text ">매장 현황</label>
      <div className="info">
        <ul
          className={`
            row list-group-horizontal
            ${store?.open_state === 'CLOSED' ? ' inactive' : ' active' }
            ${noBle ? ' no-ble' : ''}`
          }
        >
          <li className="col-6 list-group-item text-center">
            {store?.open_state === 'CLOSED' ? '영업 전' :'영업 중' }
          </li>
          <li className="col-6 list-group-item text-center">
            {!(noBle) ? (
              store?.operation_state === 'ABSENT' ? '운영자 없음' :'운영자 있음'
            ) : (
              'BLE 설치 않음'
            )}
          </li>
        </ul>
        {store?.working_times?.length ? (
          <div className="row installed">
            <div className="col-6">
              <p className={`d-flex justify-content-between time-in-day${weekday === 1  ? ' active' : ''}`}>
                <span>월</span>
                <span>{renderTime(store?.working_times[0])}</span>
              </p>
              <p className={`d-flex justify-content-between time-in-day${weekday === 2  ? ' active' : ''}`}>
                <span>화</span>
                <span>{renderTime(store?.working_times[1])}</span>
              </p>
              <p className={`d-flex justify-content-between time-in-day${weekday === 3  ? ' active' : ''}`}>
                <span>수</span>
                <span>{renderTime(store?.working_times[2])}</span>
              </p>
              <p className={`d-flex justify-content-between time-in-day${weekday === 4  ? ' active' : ''}`}>
                <span>목</span>
                <span>{renderTime(store?.working_times[3])}</span>
              </p>
            </div>
            <div className="col-6">
              <p className={`d-flex justify-content-between time-in-day${weekday === 5  ? ' active' : ''}`}>
                <span>금</span>
                <span>{renderTime(store?.working_times[4])}</span>
              </p>
              <p className={`d-flex justify-content-between time-in-day${weekday === 6  ? ' active' : ''}`}>
                <span>토</span>
                <span>{renderTime(store?.working_times[5])}</span>
              </p>
              <p className={`d-flex justify-content-between time-in-day${weekday === 0  ? ' active' : ''}`}>
                <span>일</span>
                <span>{renderTime(store?.working_times[6])}</span>
              </p>
            </div>
          </div>
        ) : (
          <div className="row not-installed">
            <div className="col-12 text-center">
              <img src={workingTimeNone} width="37" height="37"/>
              <p className="mb-1">운영시간 설정 후,</p>
              <p className="mb-0">서비스 이용이 가능합니다.</p>
              <Link to={'/store/update/' + store?.id}>운영 시간 설정</Link>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default WorkingTime
