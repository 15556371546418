// import { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useState } from 'react';
import Loading from '../../../../commons/components/Loading';
import ModalComponent from '../../../../commons/components/Modal/ModalComponent';

function request(url) {
  const requestOptions = {
    method: 'GET',
    headers: { Authorization: 'KakaoAK 4a7276aac35bc743144dc33b709db73b' }
  };
  return fetch(url, requestOptions)
    .then(response => response.json())
}

function ButtonSearchMap({cb, label}) {
  
  const [isShowModal, setIsShowModal] = useState(false);
  const [loadingMap, setLoadingMap] = useState(false)
  
  const clickSearch = () => {
    setIsShowModal(true);
    const element_layer = document.getElementById('js-daum-map');
    if (window.daum) {
      new window.daum.Postcode({
        oncomplete: function(data) {
          setIsShowModal(false);
          let roadAddr = data.roadAddress;
          let extraRoadAddr = ''; 
          if(data.bname !== '' && /[동|로|가]$/g.test(data.bname)){
            extraRoadAddr += data.bname;
          }
          if(data.buildingName !== '' && data.apartment === 'Y'){
            extraRoadAddr += (extraRoadAddr !== '' ? ', ' + data.buildingName : data.buildingName);
          }
          if(extraRoadAddr !== ''){
            extraRoadAddr = ' (' + extraRoadAddr + ')';
          }
          let address = roadAddr + extraRoadAddr;
          let address_detail = data.jibunAddress
          setLoadingMap(true)
          request("https://dapi.kakao.com/v2/local/search/address.json?query=" + roadAddr)
            .then(function(res) { 
              if(res.documents.length > 0) {
                let data = res.documents[0];
                let lat = data.road_address.y
                let lng = data.road_address.x
                let zone_no = data.road_address.zone_no
                cb({address, address_detail, zone_no, lat, lng});
                setLoadingMap(false);
              }
            })
            
        },
        width : '100%',
        overflow: 'scroll',
      }).embed(element_layer);
      // initLayerPosition();
    }
  }
  

  return (
    <>
      <button
        type="button"
        className="btn-border btn-regis-store"
        onClick={clickSearch}
      >
      {label || "우편번호"}
      </button>

      <ModalComponent
        show={isShowModal || false}
        cancelBtn={'닫기'}
        handleCancel={() => setIsShowModal(false)}
      >
        <div id='js-daum-map'></div>
      </ModalComponent>

      {loadingMap && <Loading />}
    </>

  )
}

export default ButtonSearchMap

ButtonSearchMap.defaultProps = {
}

ButtonSearchMap.propTypes = {
  cb: PropTypes.func,
  label: PropTypes.string,
}

// search example
// 진안군청
