import React from 'react';
import { useSelector } from 'react-redux';
import RegisterComponent from '../components/register';

import * as action from '../redux/actions';

const RegisterPageContainer = () => {
  const { isProcessing,type } = useSelector((state) => state.authReducers);
  return <RegisterComponent
    loginRequest={action.loginRequest}
    isProcessing={isProcessing}
    resetState={action.resetState}
    type={type}
  />;
};

export default RegisterPageContainer;
