import { put, call, takeLatest } from 'redux-saga/effects';

import * as actions from '../redux/actions';
import * as actionTypes from '../redux/actions/type';
import API, { ROUTES } from "../../../commons/api/api";

function* store(action) {
  try {
    const response = yield call(() => API.post(ROUTES.API_CREATE_STORE, action.payload));
    yield put(actions.createStoreSuccess({
      data: response.data || [],
      type: actionTypes.CREATE_STORE_SUCCESS
    }));
  } catch (error) {
    yield put(action.getCategoriesFaild({
      data: error,
      type: actionTypes.CREATE_STORE_FAILD
    }))
  }
}

function* listStores({params}) {
  try {
    const response = yield call(() => API.get(ROUTES.API_GET_LIST_STORES, {params}));
    yield put(actions.getListStoresSuccess({
      data: response.data?.data || [],
      type: actionTypes.GET_LIST_STORE_SUCCESS
    }));
  } catch (error) {
    // yield put(action.getCategoriesFaild(error))
  }
}

function* detailStore({params}) {
  try {
    const response = yield call(() => API.get(ROUTES.API_GET_LIST_STORES, {params}));
    yield put(actions.getDetailStoreSuccess({
      data: response.data?.data || [],
      type: actionTypes.GET_DETAIL_STORE_SUCCESS
    }));
  } catch (error) {
    // yield put(action.getCategoriesFaild(error))
  }
}

function* updateStore({params}) {
  try {
    const response = yield call(() => API.post(ROUTES.API_UPDATE_STORE + params.id, params));
    yield put(actions.updateStoreSuccess({
      data: response.data?.data || {},
      type: actionTypes.UPDATE_STORE_SUCCESS
    }));
  } catch (error) {
    yield put(actions.updateStoreFaild({
      data: error,
      type: actionTypes.UPDATE_STORE_FAILD
    }))
  }
}

function* storeSaga() {
  yield takeLatest(actionTypes.CREATE_STORE_REQUEST, store);
  yield takeLatest(actionTypes.GET_LIST_STORE_REQUEST, listStores);
  yield takeLatest(actionTypes.GET_DETAIL_STORE_REQUEST, detailStore);
  yield takeLatest(actionTypes.UPDATE_STORE_REQUEST, updateStore);
}

export default storeSaga;
