// @flow
// libs
import React, { memo } from 'react';
import NoDataIcon from "../../../assets/images/noData.svg"

type Props = {
  text?: string,
  children?: any,
  img?: string,
};

export const NoData = ({
  children,
  text,
  img,
}: Props) => {
  return (
    <div className="no-data">
      <div className='content'>
        <img className='img-no-data' src={img} alt="" />
        <h2>{text || ''}</h2>
        {children && <div className='text-content'>{children}</div>}
      </div>
    </div>
  );
};

NoData.defaultProps = {
  children: '',
  text: '',
  img: NoDataIcon,
};

export default memo<Props>(NoData);
