import NotificationDetailPageComponent from "../../Notification/components/detail";
import React, {useEffect, useRef, useState} from "react";
import API, {ROUTES} from "../../../commons/api/api";
import {useNavigate, useParams} from "react-router-dom";
import MainLayout from "../../../commons/layout/MainLayout";
import Header from "../../../commons/components/Header/header";
import LeftIconHeader from "../../../assets/images/leftIconHeader.svg";
import EventPageComponent from "../../Event/components";
import ButtonComponent from "../../../commons/components/Button/ButtonComponent";
import PlusIconBtn from "../../../assets/images/plusIconBtn.svg";
import {useRole} from "../../../commons/customHooks/Account";
import ToggleSwitchComponent from "../../../commons/components/ToggleSwitch/ToggleSwitchComponent";
import ModalComponent from "../../../commons/components/Modal/ModalComponent";
import ROUTERS from "../../../commons/constans/router";
import ListPageComponent from "../../TermsOfUse/components";

const TermsOfUseContainer  = () => {


    const [listData, setListData] = useState([])
    const [loadMore, setloadMore] = useState(false)
    const [loading, setLoading] = useState(true)

    const navigate = useNavigate();
    const handleLeftClick = () => {
        navigate(ROUTERS.MAIN);
    }

    const fetchData = async (param = '?per_page=20&order_by_created_at=desc') =>{
        try {
            const {data: response} = await API.get(ROUTES.API_TERMS_OF_USE+param);
            setListData(response?.data);
            setLoading(false)
            if(response?.data?.current_page == response?.data?.last_page) {
                setloadMore(false)
            }else {
                setloadMore(true)
            }
        } catch (error) {
            console.error(error.message);
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    const loadMoreData = () => {
        // setloadMore(false)
    }

    console.log(listData,'listData')


    return (<div>
        <MainLayout
          pageID={"terms-list-page"}
        >
            <Header
              leftChild={LeftIconHeader}
              handleLeftClick={handleLeftClick}
              leftIcon={true}
              centerChild={'이용약관 및 정책'}
              centerIcon={false}
              rightChild={false}
              rightIcon={false}
            />
            <div className='wrapper'>

                <ListPageComponent listData={listData} loading={loading}
                                           fetchData={fetchData} loadMore={loadMore} loadMoreData={loadMoreData}
                />




            </div>
        </MainLayout>
    </div>)
};

export default TermsOfUseContainer ;