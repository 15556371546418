
import React, {Fragment, useEffect, useRef, useState} from "react";
import SettingIcon from "../../../assets/images/settingIcon.svg";
import CloseIconMenu from "../../../assets/images/closeIconMenu.svg";
import ListMenuComponent from "../../../commons/components/Menu/ListMenuComponent";
import {slide as Menu} from "react-burger-menu";
import {useDispatch, useSelector} from "react-redux";
import {openMenu} from "../../Main/redux/actions";

import MainLayout from "../../../commons/layout/MainLayout";
import Header from "../../../commons/components/Header/header";
import LeftIconHeader from "../../../assets/images/leftIconHeader.svg";
import ListPageComponent from "../../TermsOfUse/components";
import ItemInListComponent from "../../../commons/components/ItemInList/ItemInListComponent";
import moment from "moment";
import ROUTERS from "../../../commons/constans/router";
import {useNavigate} from "react-router-dom";
import {useRole, useUser} from "../../../commons/customHooks/Account";
import {removeAllStorage} from "../../../utils/helpers";
import * as action from "../../Account/redux/actions";
import ModalComponent from "../../../commons/components/Modal/ModalComponent";

const menuItems = [{
    id: 9,
    name: '설정',
    icon: '',
    action: '',
    role: ['user'],
    public: false,
    toUrl: ROUTERS.SETTING_MENU,
    subMenu:[
        {
            id: 10,
            name: 'PUSH 알림 설정',
            icon: '',
            action: '',
            role: ['user', 'manager', 'shop'],
            toUrl: ROUTERS.SETTING_PUSH_NOTICE,
        },
        {
            id: 11,
            name: '이용약관 및 정책',
            icon: '',
            action: '',
            role: ['user', 'manager', 'shop'],
            toUrl: ROUTERS.TERMS_OF_USE,
        },
        {
            id: 12,
            name: '회원탈퇴',
            icon: '',
            action: '',
            role: ['user','manager','shop'],
            toUrl: ROUTERS.DELETE_MEMBER,
        },
        {
            id: 13,
            name: '로그아웃',
            icon: '',
            action: '',
            role: ['user','manager','shop'],
            toUrl: 'logout',
        }
    ]
},];

const SettingMenuContainer  = (props) => {

    const { user } = useUser();
    let roleText = ''
    const isManager = useRole('manager');
    const isShop = useRole('shop');
    const isUser = useRole('user');

    if(isShop){
        roleText = 'shop'
    }else if (isUser){
        roleText = 'user'
    }else {
        roleText = 'manager'
    }

    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [modalLogout, setModalLogout] = useState(false);
    const [dataMenu, setDataMenu] = useState([]);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    useEffect(() => {
        let menuRouterList = props?.menu || ROUTERS.SETTING_MENU
        let findMenuSetting = menuItems.filter((item)=>item.toUrl == ROUTERS.SETTING_MENU)
        if(findMenuSetting && findMenuSetting.length > 0) {
            setDataMenu(findMenuSetting[0].subMenu)
        }

    }, []);

    const handleLeftClick = () => {
        navigate(ROUTERS.MAIN);
        dispatch(openMenu(true));
    }
    const toPageUrl = (url) => {
        if(url === 'logout'){
            setModalLogout(true)
        }else {
            navigate(url);
        }

    }
    console.log(dataMenu,'dataMenu2333',roleText)
    const handleCancelBtn = () => {
        setModalLogout(false)
    }
    const handleSubmitBtn = () => {
        removeAllStorage();
        window.nativeLogoutToken('');
        dispatch(action.resetState());
        dispatch(action.logOutRequest());
        dispatch(openMenu(false));
        navigate(ROUTERS.INTRO);
        return true;
    }

    return (<div>
        <MainLayout
          pageID={"setting-menu-page"}
        >
            <Header
              leftChild={LeftIconHeader}
              handleLeftClick={handleLeftClick}
              leftIcon={true}
              centerChild={'설정'}
              centerIcon={false}
              rightChild={false}
              rightIcon={false}
            />
            <div className='wrapper'>


                {dataMenu.map((value,key)=>(
                  <Fragment key={key}>

                      {value.role.includes(roleText) && value.name != '로그아웃' ? <ItemInListComponent
                        key={key}
                        onClick={()=>{
                            toPageUrl(value.toUrl)
                        }}
                        title={value.name}
                        classNameCustom={''}
                        classNameStatus={''}
                      /> : ''}
                      {value.name == '로그아웃' ? <ItemInListComponent
                        key={key}
                        onClick={()=>toPageUrl(value.toUrl)}
                        title={value.name}
                        classNameCustom={''}
                        classNameStatus={''}
                      /> : ''}

                  </Fragment>
                ))}



            </div>

            <ModalComponent
              id={``}
              show={modalLogout}
              title={`알림`}
              cancelBtn={`아니요`}
              submitBtn={'예'}
              handleCancel={handleCancelBtn}
              handleSubmit={handleSubmitBtn}
            >
                <div>
                    로그아웃 하시겠습니까?
                </div>
            </ModalComponent>

        </MainLayout>
    </div>)
};

export default SettingMenuContainer ;