import React from "react";
import StoreAddress from '../../../../assets/images/phone_store.svg';
import StorePhone from '../../../../assets/images/address_store.svg';

type Props = {
  detailStore: Array
}
const StoreNoInfomation = ({detailStore}:Props) => {
  return (
    <div className="store-no-info">
      <h2>{detailStore?.store_name || ''}</h2>
      <div className="store-content">
        <div className="store-address">
          <img src={StoreAddress} />
          <span>{detailStore?.store_address || ''}</span>
        </div>
        <div className="store-phone">
          <img src={StorePhone} />
          <span>{detailStore?.store_phone || ''}</span>
        </div>
      </div>
    </div>
  )
}

export default StoreNoInfomation;
