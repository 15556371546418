/*
 * Text Input Component
 * */
import React, { useEffect, useState, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { randomIdentifier } from '../../../utils/helpers';

type Props = {
  label: String,
  isRequired: boolean,
  errorMsg: any,
  placeHolder: String,
  id: string,
  name: string,
  value: String,
  handleChange: Function,
  handleFocus: Function,
  handleBlur: Function,
  handleClick: Function,
  btnClass: String,
  btnLabel: String,
  btnHandleClick: Function,
  appendContent: any,
  disabled: boolean,
  readonly: boolean,
  pattern: String,
  type: String,
  autoComplete: String,
  maxLength: String,
  searchIcon: any,
  classNameCustom: any,
  inptRef: any,
};

const ToggleSwitchComponent = ({
  isRequired,
  errorMsg,
  id,
  name,
  value,
  handleChange,
  handleFocus,
  handleBlur,
  handleClick,
  textStatusChecked,
  textStatusUnChecked,
  type,
  classNameCustom,
  inptRef
}: Props) => {

  const [checked, setChecked] = useState(value);
  const [textType, setTextType] = useState(textStatusChecked);

  useEffect(() => {
    if(checked){
      setTextType(textStatusChecked)
    }else {
      setTextType(textStatusUnChecked)
    }
  }, [checked]);

  useEffect(() => {
    setChecked(value)
  }, [value]);

  const _handleChange = () => {
    // setChecked(!checked)
    handleChange(!checked)
  }

  return (
      <label className={`switch switch-group ${checked ? '' : 'unchecked'} ${type} ${classNameCustom}`}>
          <input
              ref={inptRef}
              type="checkbox"
              checked={checked}
              onChange={() => _handleChange()}
          />
          <span className="slider round"></span>
          <span className='text-switch'>{textType}</span>
      </label>
  );
};

ToggleSwitchComponent.defaultProps = {
  isRequired: false,
  btnHandleClick: () => {},
  handleChange: () => {},
  handleFocus: () => {},
  handleBlur: () => {},
  handleClick: () => {},
  value: false,
  textStatusChecked:'운영관',
  textStatusUnChecked:'영관운',
  type:'hasText',
  classNameCustom:'',
  inptRef:{},
};

export default memo(ToggleSwitchComponent);
