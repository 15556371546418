import API from '../../../../commons/api/api';
import * as actionTypes from '../actions/type';
import {isProcessing} from "../actions";
import {formatAppSnsType, removeAllStorage} from "../../../../utils/helpers";

export const initialState = {
  token: '',
  userInfo: {} ,
  isProcessing: false,
  statusCode: null,
  isProcessingUpdate: false,
  errors : {}
};


const authReducers = (state  = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOGIN_REQUEST:
      return {
        ...state, 
        isProcessing: true, 
        type: action.type 
      };
    case actionTypes.LOGIN_SUCCESS:
      let res = action.payload;
      console.log(res.data.data, 'LOGIN_SUCCESS res.data.data');
      localStorage.setItem('_APP_TOKEN', `${res.data.token}`);
      API.defaults.headers.common['Authorization'] = `Bearer ${res.data.token}`;
      window.nativeLoginToken(res.data.token, res.data.data?.roles[0]?.name || '');
      return {
        ...state,
        isProcessing: false,
        token: res.data.token,
        statusCode: res.status,
        statusText: res.msg,
        userInfo: res.data.data,
        type: action.type,
      };
    case actionTypes.LOGIN_FAILD:
      return {
        ...state,
        isProcessing: false,
        token:'',
        statusCode: 400,
        statusText: '',
        userInfo: {},
        type: action.type,
        errors : action.payload ?? {}
      }

    case actionTypes.LOGOUT_REQUEST:
      return {
        ...state, 
        isProcessing: true, 
        type: action.type 
      };
    case actionTypes.LOGOUT_SUCCESS:
      window.nativeLogoutToken('');
      return {
        ...state,
        isProcessing: false,
        token:'',
        statusCode: 400,
        statusText: '',
        userInfo: {},
        type: action.type,
      };
    case actionTypes.LOGOUT_FAILD:
      return {
        ...state,
        isProcessing: false,
        token:'',
        statusCode: 400,
        statusText: '',
        userInfo: {},
        type: action.type,
      };
    case actionTypes.UPDATE_SUCCESS :
      return {
        ...state,
        userInfo :  action.payload
      }
    case actionTypes.UPDATE_CHILDREN_PROFILE_SUCCESS :
      return {
        ...state,
        userInfo : {
          ...state.userInfo,
          childrens : action.payload.childrens,
          favorite_characters :  action.payload.favorite_characters,
        }
      }
    case actionTypes.UPDATE_NOTIFICATION_SETTING_SUCCESS :
      return {
        ...state,
        userInfo : {
          ...state.userInfo,
          setting : action.payload
        }
      }

    case actionTypes.LOGIN_SNS_REQUEST:
      return {
        ...state,
        isProcessing: true,
        type: action.type
      };
    case actionTypes.LOGIN_SNS_SUCCESS:
      let response = action.payload;
      localStorage.setItem('_APP_TOKEN', `${response.data.token}`);
      API.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`;
      window.nativeLoginToken(response.data.token)

      if(response && response.data && response.data.v1_data){
        let v1Data = JSON.stringify(response.data.v1_data);
        window.sendUserInfo(v1Data);
      }

      return {
        ...state,
        isProcessing: false,
        token: response.data.token,
        statusCode: response.status,
        statusText: response.msg,
        userInfo: response.data.data,
        type: action.type,
      };
    case actionTypes.LOGIN_SNS_FAILD:
      return {
        ...state,
        isProcessing: false,
        token:'',
        statusCode: 400,
        statusText: '',
        userInfo: {},
        type: action.type,
        errors : action.payload ?? {}
      }
    case actionTypes.GET_CURRENT_USER:
      let token = localStorage.getItem('_APP_TOKEN') || '';
      // console.log(action.payload.data, 'state.userInfo');
      window.nativeLoginToken(token, action.payload.data?.roles[0]?.name || '');
      return {
        ...state,
        isProcessing: false,
        token: token,
        statusCode: 200,
        type: action.type,
        userInfo :  {
          ...state.userInfo,
          ...action.payload.data
        }
      }
    case actionTypes.RESET_STATE:
      return {
        ...state,
        isProcessing: false,
        token:'',
        statusCode: null,
        statusText: '',
        userInfo: {},
        type: action.type
      }
    case actionTypes.IS_PROCESSING :
      return {
        ...state,
        isProcessing: true,
      }
    case actionTypes.UPDATE_USER_REQUEST: 
      return {
        ...state,
        isProcessingUpdate: true
      }
    case actionTypes.UPDATE_USER_SUCSESS: 
      return {
        ...state,
        userInfo :  action.payload
      }
    case actionTypes.UPDATE_USER_LOCAL:
      return {
        ...state,
        userInfo : {
          ...state.userInfo,
          read_terms: 'OFF'
        }
      }
    default:
      return state;
  }
};

export default authReducers;
