import React from 'react';
import {Provider, useSelector} from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import AppRouter from './commons/router';
import createStore from './commons/store/createStore';
import {log, removeAllStorage} from './utils/helpers';
import { setupAPI } from'./commons/api/api'
import { ToastProvicer } from'./commons/customHooks/useToast'
import './App.scss';
import { PersistGate } from 'redux-persist/integration/react'
// import 'bootstrap/dist/css/bootstrap.min.css';
import "swiper/css";
import "swiper/css/pagination";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
function App() {
  const { store, persistor } = createStore();
  // log(process.env.REACT_APP_NAME, "app_name", "success");
  // log(process.env.REACT_APP_VERSION, "app_version", "success")
  setupAPI(store);

  // let {authReducers} = store.getState();

  return (
      <PersistGate loading={null} persistor={persistor}>
        <Provider store={store}>
          <ToastProvicer>
            <BrowserRouter>
              <AppRouter/>
            </BrowserRouter>
          </ToastProvicer>
        </Provider>
      </PersistGate>
  );
}

export default App;
