import React, { useEffect, useState } from 'react';
import ButtonComponent from '../../../commons/components/Button/ButtonComponent';
import CheckboxComponent from '../../../commons/components/Checkbox/CheckboxComponent';
import Header from '../../../commons/components/Header/header';
import MainLayout from '../../../commons/layout/MainLayout';
import LeftIconHeader from '../../../assets/images/leftIconHeader.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import ROUTERS from '../../../commons/constans/router';
import ModalComponent from '../../../commons/components/Modal/ModalComponent';
import Loading  from '../../../commons/components/Loading'
import { useInformationList, useInformationParams } from '../../../commons/customHooks/Information'

type Props = {};

const ManagerRulesPageContainer = ({}: Props) => {
  const navigate = useNavigate();
  const [isCheckedCheckbox, setIsCheckedCheckbox] = useState({
    rule_all: false,
    rule1: false,
    // mobile_local: false,
  });
  const location = useLocation();
  const params = useInformationParams('policy', 'manager')
  const [ policies ] = useInformationList(params)

  const [isShowManagerModal, setIsShowManagerModal] = useState({
    type: '',
    isShow: false,
    title: '',
    msg: ``,
    cancelBtn: '',
    submitBtn: '확인',
  });

  const [isShowToast, setIsShowToast] = useState(false);

  const handleLeftClick = () => {
    setIsShowManagerModal({
      type: '',
      isShow: true,
      title: '알림',
      msg: `매니저 이용약관에 동의해 주세요.`,
      cancelBtn: '',
      submitBtn: '확인',
    });
  };

  useEffect(() => {
    if (location?.state?.dataRules) {
      setIsCheckedCheckbox({
        ...location?.state?.dataRules,
      });
    }
  }, [location]);

  useEffect(() => {
    if (isCheckedCheckbox.rule_all) {
      setIsCheckedCheckbox({
        rule_all: true,
        rule1: true,
        // mobile_local: true,
      });
    } else {
      if (
        isCheckedCheckbox.rule1
        // isCheckedCheckbox.mobile_local
      ) {
        setIsCheckedCheckbox({
          rule_all: false,
          rule1: false,
          // mobile_local: false,
        });
      } else {
        setIsCheckedCheckbox({
          ...isCheckedCheckbox,
          rule_all: false,
        });
      }
    }
  }, [isCheckedCheckbox.rule_all]);

  useEffect(() => {
    if (
      isCheckedCheckbox.rule1
      // isCheckedCheckbox.mobile_local
    ) {
      setIsCheckedCheckbox({
        ...isCheckedCheckbox,
        rule_all: true,
        rule1: true,
        // mobile_local: true,
      });
    } else if (
      !isCheckedCheckbox.rule1
      // !isCheckedCheckbox.mobile_local
    ) {
      setIsCheckedCheckbox({
        ...isCheckedCheckbox,
        rule_all: false,
      });
    }
  }, [isCheckedCheckbox.rule1]);

  const handleChangeCheckbox = (e) => {
    setIsCheckedCheckbox({
      ...isCheckedCheckbox,
      [e.target.name]: !isCheckedCheckbox[e.target.name],
    });
  };

  const handleSubmit = () => {
    setIsShowToast(true);
  };

  const goToPageRuleDetail = (policy) => {
    navigate(ROUTERS.RULES_DETAIL_PATH(policy.id), {
      state: {
        headerTitle: policy.title,
        type: 'manager_rules',
        dataRules: isCheckedCheckbox,
      },
    });
  };

  const handleCancelManager = () => {};

  const handleSubmitManager = () => {
    setIsShowManagerModal({
      type: '',
      isShow: false,
      title: '알림',
      msg: ``,
      cancelBtn: '',
      submitBtn: '확인',
    });
    
  };

  useEffect(() => {
    if (isShowToast) {
      setTimeout(() => {
        setIsShowToast(false);
        navigate(ROUTERS.MAIN);
      }, 3000);
    }
  }, [isShowToast]);

  const handleClickBtn = () => {
    navigate(ROUTERS.MAIN);
  }
  return (
    <MainLayout pageID={'store-rules-page'}>
      <Header
        leftChild={LeftIconHeader}
        handleLeftClick={handleLeftClick}
        leftIcon={true}
        centerChild={'이용약관'}
        centerIcon={false}
        rightChild={false}
        rightIcon={false}
      />
      <div className="wrapper">
        <h2>매니저 이용약관</h2>
        <div className="manager-terms">
          <CheckboxComponent
            label={``}
            componentClass={`rule-all`}
            name={`rule_all`}
            handleChange={(e) => handleChangeCheckbox(e)}
            title="전체동의"
            labelClass={``}
            checked={isCheckedCheckbox?.rule_all || false}
          />
        </div>
        <div className="rules">
          <ul className="rules-list">
            {policies.map((policy, key) => (
              <li key={policy.id}>
                <div className="item">
                  <CheckboxComponent
                    label={``}
                    componentClass={`rule`}
                    name={`rule${key + 1}`}
                    handleChange={(e) => handleChangeCheckbox(e)}
                    disabled={false}
                    title={`${policy.title}(필수)`}
                    labelClass={``}
                    checked={isCheckedCheckbox?.rule1 || false}
                  />
                </div>
                <button
                  type="button"
                  className="btn"
                  onClick={() => goToPageRuleDetail(policy)}
                >
                  보기
                </button>
              </li>
            ))}
          </ul>
        </div>
        <div className="fixed-btn">
          {isShowToast ? (
            <ButtonComponent
              label={'회원탈퇴 완료되었습니다.'}
              style={{ width: '100%', backgroundColor: '#000' }}
              iconBtn={''}
              cssClass={'mb-2 '}
              handleClick={handleClickBtn}
            />
          ) : (
            <ButtonComponent
              label={'약관 동의'}
              style={{ width: '100%' }}
              disabled={!isCheckedCheckbox.rule_all}
              cssClass={''}
              handleClick={handleSubmit}
              fixedBtn={true}
            />
          )}
        </div>
      </div>

      <ModalComponent
        id={`modal-manager-confirm`}
        show={isShowManagerModal.isShow || false}
        title={isShowManagerModal?.title || ''}
        cancelBtn={isShowManagerModal?.cancelBtn || ''}
        submitBtn={isShowManagerModal?.submitBtn || ''}
        handleCancel={handleCancelManager}
        handleSubmit={handleSubmitManager}
      >
        <div>{isShowManagerModal?.msg || ''}</div>
      </ModalComponent>

      {isShowToast && <Loading />}
    </MainLayout>
  );
};

export default ManagerRulesPageContainer;
