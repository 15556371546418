import moment from 'moment-timezone';
import { TIME_ZONE, IMAGE_PREVIEW_FORMAT, IMAGE_DETAIL_FORMAT } from '../commons/constans/moment';
import {forEach} from "react-bootstrap/ElementChildren";

export const log = (debugVar, desc = false, type = false) => {
    if(process.env.NODE_ENV !== "production" && process.env.REACT_APP_DEBUG_LOG === "true"){
        if(desc){
            let color = 'white';
            let bgc = 'black';
            let icon = "📌";
            switch (type) {
                case "success":  color = "#FFF";      bgc = "LimeGreen";    icon="✅";       break;
                case "info":     color = "#FFF";      bgc = "Turquoise";    icon="❕";      break;
                case "error":    color = "Red";        bgc = "Black";           break;
                case "start":    color = "OliveDrab";  bgc = "PaleGreen";       break;
                case "warning":  color = "Tomato";     bgc = "Black";           break;
                case "end":      color = "Orchid";     bgc = "MediumVioletRed"; break;
                default: color = 'white';
            }
            console.log(icon+" %c" + desc, "color:" + color + ";font-weight:bold; background-color: " + bgc + ";", debugVar);
        }else{
            console.log(debugVar);
        }
    }
}

export const randomIdentifier = () => {
    let id = (Math.random()*1000).toString() + ((new Date()).getTime()).toString()
    return id
}

export function priceFormat(price, n = 0, x = 3, s = ',', c = '.') {

    if (price) {
        price = +price.replaceAll(',', '');
        let re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')'
        let num = price.toFixed(Math.max(0, ~~n));
        let result = (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ','));
        if (result === 'NaN') {
            return null;
        }else{
            return result;
        }
    }
    return 0
}

export function currencyFormat(number, separate = ",") {
    var string = number.toString();
    var regex = /\B(?=(\d{3})+(?!\d))/g;
    var res = string.replace(/,/g, '').replace(regex, separate);
    return res;
}

export function validateEmail (email) {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};

export function formatPhoneNumber (phone) {
    var phoneFormat = phone;
    phoneFormat.replaceAll('','-')
    var phoneArray = phone.split("");
    if(phone.length >= 8){
        phoneArray.map((item,index) => {
            if(index === 2 || index === 6) {
                phoneArray[index] = item + '-'
            }
        })

        phoneFormat = phoneArray.join('');
    } else if(phone.length >= 4) {
        phoneArray.map((item,index) => {
            if(index === 2) {
                phoneArray[index] = item + '-'
            }
        })
        phoneFormat = phoneArray.join('');
    }
    return phoneFormat;
}

export function formatPhoneNumberForAddress (phone) {
    var phoneFormat = phone;
    phoneFormat.replaceAll('','-')
    var phoneArray = phone.split("");
    if(phone.length >= 7 || phone.length >= 8){
        phoneArray.map((item,index) => {
            if(index === 2 || index === 5) {
                phoneArray[index] = item + '-'
            }
        })

        phoneFormat = phoneArray.join('');
    } else if(phone.length >= 3) {
        phoneArray.map((item,index) => {
            if(index === 2) {
                phoneArray[index] = item + '-'
            }
        })
        phoneFormat = phoneArray.join('');
    }
    return phoneFormat;
}


export function formatRegcertNumber (regcert) {
    var regcertFormat = regcert;
    regcertFormat.replaceAll('','-')
    var regcertArray = regcert.split("");
    console.log(regcertArray.length, 'length');
    if(regcert.length >= 6){
        regcertArray.map((item,index) => {
            console.log(index,item, 'index');
            if(index === 2 || index === 4) {
                regcertArray[index] = item + '-'
            }
        })

        regcertFormat = regcertArray.join('');
    } else if(regcert.length >= 3) {
        regcertArray.map((item,index) => {
            if(index === 2) {
                regcertArray[index] = item + '-'
            }
        })
        regcertFormat = regcertArray.join('');
    }
    return regcertFormat;
}


export function sliceIntoChunks(arr, chunkSize) {
    const res = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
        const chunk = arr.slice(i, i + chunkSize);
        res.push(chunk);
    }
    return res;
}

export function formatDateKorea(date) {
   let dateArr = date.split('-');
   if (dateArr.length === 3) {
       return `${dateArr[0]}년 ${dateArr[1]}월 ${dateArr[2]}일`;
   }
   return date;
}


export function rememberMeLogin (remember_me = true,token = ''){
    localStorage.setItem('remember_me',remember_me ? 1 : 0);
    localStorage.setItem('_APP_TOKEN',token);
    if(!remember_me){
        sessionStorage.setItem('_APP_TOKEN',token);
    }
}

export function removeAllStorage () {
    // localStorage.clear();
    sessionStorage.clear();
    localStorage.removeItem('persist:root')
    localStorage.removeItem('_APP_TOKEN')
    localStorage.removeItem('remember_me')
}


export function ploorPricePoint (price) {
    let result = 0;
    if (price > 1000) {
        result = Math.floor(price / 1000) * 1000;
    } else {
        result = price;
    }
    return result
}

export function convertUrlTObject (url, type = 'album') {
    // if (!url) return null;
    // let dataArr = url.split('/');
    // let file_name = dataArr[dataArr.length - 1];
    // let bucket_name;
    // if (type == 'album') {
    //     bucket_name = `${dataArr[dataArr.length - 3]}/${dataArr[dataArr.length - 2]}`;
    // }else{
    //     bucket_name = `${dataArr[3]}`;
    // }
    // let baseUrl = url.replace(`${bucket_name}/${file_name}`, '');
    // let data = {
    //     baseUrl: baseUrl,
    //     bucketName: bucket_name,
    //     fileName: file_name,
    // }
    // return data;

    if (!url) return null;
    let urlObj = new URL(url);
    let baseUrl = urlObj.origin;
    let dataArr = urlObj.pathname.split('/');
    let file_name = dataArr[dataArr.length - 1];
    var newArr = dataArr.slice(0, -1);
    let bucket_name = newArr.join('/')
    let data = {
        baseUrl: baseUrl,
        bucketName: bucket_name,
        fileName: file_name,
    }
    console.log('convertUrlTObject', data);
    return data;
}

export function debounce(func, wait = 300) {
  var timeout;

  return function() {
    var context = this,
        args = arguments;

    var executeFunction = function() {
      func.apply(context, args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(executeFunction, wait);
  };
}


export function formatDateTimeStringKorea(data) {
    const format = "dddd, YYYY-MM-DD, HH:mm a";
    let weekday = '';
    let time = '';
    let date = new Date(data);
    // let dateTime = moment(date).format(format);
    let dateTime = formatTimeZone(data, 'time');
    let datatimeArr = dateTime.split(', ');
    let dateYear = formatDateKorea(datatimeArr[1]);
    let timeArr = datatimeArr[2].split(' ');
    switch (datatimeArr[0]) {
        case 'Monday':
            weekday = '월요일'
            break;
        case 'Tuesday':
            weekday = '화요일'
            break;
        case 'Wednesday':
            weekday = '수요일'
            break;
        case 'Thursday':
            weekday = '목요일'
            break;
        case 'Friday':
            weekday = '금요일'
            break;
        case 'Saturday':
            weekday = '토요일'
        break;
        case 'Sunday':
            weekday = '일요일'
        break;
    
        default:
            break;
    }

    if (timeArr[1] === 'am') {
        time = '오전'
    }else{
        time = '오후'
    }
    let fullDateTime = ` ${dateYear} ${weekday} ${time} ${timeArr[0]} `;
    let timeStr = `${time} ${timeArr[0]}`;
    return {
        fullDateTime,dateYear,timeStr
    };
 }

 export function formatTimeZone(dateTime, type) {
    if (!dateTime) return null;
    if (type == 'time') {
        return moment(dateTime).tz(TIME_ZONE).format(IMAGE_DETAIL_FORMAT)
    }else{
        return moment(dateTime).tz(TIME_ZONE).format(IMAGE_PREVIEW_FORMAT)
    }

 }

export const timeWithoutSecondFormat = time => {
  if (!time) {
    return ''
  }
  
  return moment('1990-01-01 ' + time).format('HH:mm')
}

export function addRankToEntries(entries, key = 'total_vote') {
    const map_key = entries.map(e => e[key])
    const ranks = map_key.map(v => map_key.indexOf(v) + 1);

    return entries.map((e, index) => ({...e, rank: ranks[index]}))
}

export function formatSnsType (sns) {
    let snsType = '';
    switch (sns) {
        case "GP" :
            snsType = 'google';
            break;
        case "NV" :
            snsType = 'naver';
            break;
        case "KT" :
            snsType = 'kakao';
            break;
        case "AP" :
            snsType = 'apple';
            break;
    }
    return snsType;
}

export function formatAppSnsType (sns) {
    let snsType = '';

    switch (sns) {
        case "google" :
            snsType = 'GP';
            break;
        case "naver" :
            snsType = 'NV';
            break;
        case "kakao" :
            snsType = 'KT';
            break;
        case "apple" :
            snsType = 'AP';
            break;
    }

    return snsType;

}

export function checkImgUrl (url){
    if(url){
        let checkUrl = url.split(':')[0];
        if(checkUrl === 'http' || checkUrl === 'https'){
            return true;
        }
    }
    return false;
}

export function hasWhiteSpace (s) {
    return /\s/g.test(s);
}


export function shareKakaoTalk(title, message, url, thumbnail = '', linkRedirect = '') {
    let data = {
        title,
        message,
        url,
        thumbnail,
        linkRedirect
    }
    console.log(data, 'shareKakaoTalk');
    window.nativeKakaoShare(data);
}

export const mapMarker = {
    "pos": "icon_nowposition@3x.png",
    "kiosk": {
        a: "pin_kiosk.svg",
        b: "pin-kiosk-select.svg"
    },
    "kiosk-on": {
        a: "pin_kiosk.svg",
        b: "pin-kiosk-select.svg"
    },
    "kiosk-off": {
        a: "pin_kiosk.svg",
        b: "pin-kiosk-select.svg"
    },
    "kiosk-on-ope": {
        a: "pin_kiosk_open.svg",
        b: "pin-kiosk-open-select.png"
    },
    "kiosk-on-ble": {
        a: "pin_kiosk.svg",
        b: "pin-kiosk-select.svg"
    },
    "kiosk-on-ble-ope": {
        a: "pin_kiosk_open.svg",
        b: "pin-kiosk-open-select.png"
    },
    "kiosk-on-event": {
        a: "pin_kiosk-event.png",
        b: "pin_kiosk-event_select.png"
    },
    "kiosk-on-ble-event": {
        a: "pin_kiosk-event.png",
        b: "pin_kiosk-event_select.png"
    },
    "kiosk-on-ope-event": {
        a: "pin_kiosk-ope-event.png",
        b: "pin_kiosk-ope-event_select@3x.png"
    },
    "kiosk-on-ble-ope-event": {
        a: "pin_kiosk-ope-event.png",
        b: "pin_kiosk-ope-event_select@3x.png"
    },
    "noinfo": {
        a: "pin_nodata_cus.svg",
        b: "pin_x_select@3x.png"
    },
    "on-ble-ope-event": {
        a: "pin_open_event_master@3x.png",
        b: "pin_event_master_select@3x.png"
    },
    "off-ble-ope-event": {
        a: "pin_close_event_master@3x.png",
        b: "pin-event-master-select@3x.png"
    },
    "on-ble-event": {
        a: "pin-open-event@3x.png",
        b: "pin-select-event@3x.png"
    },
    "off-ble-event": {
        a: "pin-close-event@3x.png",
        b: "pin-select-event@3x.png"
    },
    "on-ble-ope": {
        a: "pin-open-master.svg",
        b: "pin-master-select.svg"
    },
    "off-ble-ope": {
        a: "pin_close_master.svg",
        b: "pin-master-select.svg"
    },
    "on": {
        a: "pin_open_x_cus.svg",
        b: "pin_x_select@3x.png"
    },
    "off": {
        // a: "pin_close_x.svg",
        a: "pin-close-x.svg",
        b: "pin_x_select@3x.png"
    },
    "on-ble": {
        a: "pin_open.svg",
        b: "pin-select.svg"
    },
    "off-ble": {
        a: "pin_close.svg",
        b: "pin_select@3x.png",
    },
    "on-event": {
        a: "pin-open-event@3x.png",
        b: "pin-select-event@3x.png"
    },
    "off-event": {
        a: "pin-close-event@3x.png",
        b: "pin-select-event@3x.png"
    },
    "on-ope-event": {
        a: "pin-open-event@3x.png",
        b: "pin-select-event@3x.png"
    },
    "off-ope-event": {
        a: "pin_close_event_master@3x.png",
        b: "pin-event-master-select@3x.png"
    },
}

export const storeMarker = (item) => {
    //beacon_status is null and operation_state = OPERATED
    if(item.beacon_status != "NORMAL" && item.operation_state == "OPERATED"){
        return 'noinfo';
    }

    //Return noinfo
    if(item.working_times.length === 0) return 'noinfo';
    if(item.working_times.length > 0) {
        let checkStateWorkingTime = item.working_times.filter(item =>item.state == 'Y')
        if(checkStateWorkingTime.length == 0) {
            return 'noinfo';
        }
    }

    //Define on/off - ble - ope - event
    let state = [];

    //Return kiosk
    if(item.kiosk_state === 'Y') state.push('kiosk');

    //Check on/off
    state.push(item.open_state === 'OPEN' ? 'on': 'off')

    //Check BLE state
    if(item.beacon_state === 'Y') state.push('ble')

    //Check Operator state
    if(item.operation_state === 'OPERATED') state.push('ope')

    //Check event state
    if(item.event_state === 'Y') state.push('event')

    state = state.join('-')
    // if (Object.keys(mapMarker).includes(state)) {
        return state
    // }

    return 'noinfo'
}

export const getMapMarker = (items) => {
    // console.log(384, items)
    let statuses = {
        exception: 0,
        noinfo: 0,
        kiosk: 0,
        on: 0,
        off: 0,
        ble: 0,
        ope: 0,
        event: 0
    }
    // return 'noinfo';
    items.forEach((item) => {
        if(item.beacon_status != "NORMAL" && item.operation_state == "OPERATED"){
            statuses.exception ++;
        }
        //Return noinfo
        if(item.working_times.length === 0) statuses.noinfo ++;
        else{
            // Check workTime
            if(item.working_times.length > 0) {
                let checkStateWorkingTime = item.working_times.filter(item =>item.state == 'Y')
                if(checkStateWorkingTime.length == 0) {
                    statuses.noinfo ++;
                }else {

                    //Return kiosk
                    if(item.kiosk_state === 'Y') statuses.kiosk++;
                    //Check on/off
                    item.open_state === 'OPEN' ? statuses.on++: statuses.off++

                    //Check BLE state
                    if(item.beacon_state === 'Y') statuses.ble++

                    //Check Operator state
                    if(item.operation_state === 'OPERATED') statuses.ope++

                    //Check event state
                    if(item.event_state === 'Y') statuses.event++


                }
            }

        }

    })
    let status = []

    if(statuses.exception > 0){
        status = ['noinfo'];
    }else {
        if(statuses.kiosk > 0) status.push('kiosk')
        if(statuses.on > 0) status.push('on');
        else{
            if(statuses.off > 0) status.push('off')
        }
        if(statuses.ble > 0) status.push('ble')
        if(statuses.ope > 0) status.push('ope')
        if(statuses.event > 0) status.push('event')

        if(statuses.on === 0 && statuses.off === 0) {
            if(statuses.noinfo > 0)
                status = ['noinfo'];
        }
    }

    return status.join('-')
}

export function getHoursMinus(time) {
    if (!time) return null;

    let timeArr = time.split(':');

    return `${timeArr[0]} : ${timeArr[1]}`;

 }

 export function formatTimeForType (time, type) {
    if (!time) {
        return;
    }
    let arr = time.split(':');
    let select;
    if (type == 'H') {
        select = arr[0]
    }
    if (type == 'M') {
        select = arr[1]
    }
    return select;
}

export function mapTimeStoreOpenClose (dataItem) {
    let timeStore = ''
    let textOpenClose = ''
    if(dataItem?.working_times && dataItem?.working_times.length > 0){
        dataItem?.working_times && dataItem?.working_times.length > 0 && dataItem?.working_times.map((item,index) =>{
            if(item.state == 'Y'){
                if (moment(new Date()).day() != 0 && item?.day == (moment(new Date()).day() - 1)){
                    timeStore = dataItem?.open_state != 'OPEN' ? item.starttime : item.endtime
                }else {
                    // timeStore = dataItem?.open_state != 'OPEN' ? item.starttime : item.endtime
                }
            }
        })
        textOpenClose = (dataItem?.open_state != 'OPEN' ? ' 영업 시작' : '  영업 종료')
        timeStore = timeStore.slice(0,-3)
    }

    return timeStore + textOpenClose
}

export function getRandomInt (min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function nl2br (str, replaceMode, isXhtml) {

    var breakTag = (isXhtml) ? '<br />' : '<br>';
    var replaceStr = (replaceMode) ? '$1'+ breakTag : '$1'+ breakTag +'$2';
    return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, replaceStr);
}

/**
 * This function inverses text from PHP's nl2br() with default parameters.
 *
 * @param {string} str Input text
 * @param {boolean} replaceMode Use replace instead of insert
 * @return {string} Filtered text
 */
export function br2nl (str, replaceMode) {

    var replaceStr = (replaceMode) ? "\n" : '';
    // Includes <br>, <BR>, <br />, </br>
    // eslint-disable-next-line
    return str.replace(/<\s*\/?br\s*[\/]?>/gi, replaceStr);
}
