import {Swipe} from "mirsahib-react-swipe-component";
import {randomIdentifier, priceFormat, mapTimeStoreOpenClose} from "../../../../utils/helpers";
import React, {useEffect, useState} from "react";
import IconDefaultStore from "../../../../assets/images/icon_default_store.svg";
import IconEvent from "../../../../assets/images/icon_event.webp";

type Props = {
    id: String,
    item: Object,
    handleClick:Function,
    handClickEvent:Function,
    icon:String
};

const MapListItem = ({
                            id,
                            item,
                            handleClick,
                            handClickEvent,
                            icon
                        }: Props) => {
    // console.log(item,'item111',icon)
    return (
        <div className="map-list-item" >
            {item && <>
                <div className="description" onClick={()=>handleClick(item)}>
                    <h3>{item.store_name}<span>{item.category.name}</span></h3>
                    <p className='address'>{item.store_address}</p>
                    <p className='location-space' style={{color:(item?.open_state == 'CLOSED' ? '#131313' : '')}}><strong>
                      {item?.open_state == 'CLOSED' ? '영업전' : '영업중'}</strong>
                      {/*<em>{priceFormat(item.distance, 0, 3)}m</em>*/}
                      <em>{' '}{item.distance.toFixed(0)}m</em>
                    </p>
                  {item?.working_times.length > 0 ? <p className='has-manager'>
                    {item?.operation_state == 'ABSENT' ? '운영자 없음' : '운영자 있음'}
                    <span className="divider"/> {mapTimeStoreOpenClose(item)}
                  </p> : <p className='has-manager'>
                    {item?.operation_state == 'ABSENT' ? '운영자 없음 ' : '운영자 있음 '}
                    <span className="divider"/> 운영 시간 정보 없음
                  </p>}

                </div>
                <div className="photo" >
                    <div className="thumbnail">
                        {/*<img src="https://retailinasia.com/wp-content/uploads/2020/01/Japan-convenience-store-770x514.jpg" alt=""/>*/}
                        <img onClick={()=>handleClick(item)} src={item?.photo_gallery ?
                          item?.photo_gallery[0].url_medium :
                          IconDefaultStore} alt=""/>
                      {icon != 'kiosk' && item?.event_state == 'Y' && item?.store_events && item?.store_events.length > 0 ? <img onClick={()=>handClickEvent(item)} className={'thumbnail-has-event'} src={IconEvent} /> : ''}
                    </div>
                </div>
                {icon != 'kiosk' && item?.event_state == 'Y' && item?.store_events && item?.store_events.length > 0 ? <div className='data-description'>
                <div className="arrow-up"></div>
                <label className='text-truncate d-block'>
                  {item?.store_events[0].title}
                </label>
              </div> : '' }
            </>
            }
        </div>
    )
}
MapListItem.defaultProps = {
    id: randomIdentifier(),
    handleClick:()=>{},
    handClickEvent:()=>{},
    icon:''
};


export default MapListItem;
