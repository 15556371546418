import NotificationDetailPageComponent from "../../Notification/components/detail";
import React, {useEffect, useRef, useState} from "react";
import API, {ROUTES} from "../../../commons/api/api";
import {useNavigate, useParams} from "react-router-dom";
import MainLayout from "../../../commons/layout/MainLayout";
import Header from "../../../commons/components/Header/header";
import LeftIconHeader from "../../../assets/images/leftIconHeader.svg";
import EventPageComponent from "../../Event/components";
import ButtonComponent from "../../../commons/components/Button/ButtonComponent";
import PlusIconBtn from "../../../assets/images/plusIconBtn.svg";
import {useRole} from "../../../commons/customHooks/Account";
import ToggleSwitchComponent from "../../../commons/components/ToggleSwitch/ToggleSwitchComponent";
import ModalComponent from "../../../commons/components/Modal/ModalComponent";
import ROUTERS from "../../../commons/constans/router";
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile,
    isAndroid,
    isIOS
} from "react-device-detect";

const SettingPushNoticeContainer  = () => {


    const [statePush, setStatePush] = useState(window.stateDefautSettingPush)
    const [modalShow, setModalShow] = useState(false)
    const [statePushDefault, setStatePushDefault] = useState(window.stateDefautSettingPush)
    const refPush = useRef();


    const isUser = useRole('user');
    const isShop = useRole('shop');
    const isManager = useRole('manager');

    const navigate = useNavigate();



    useEffect(() => {
        if (window.AndroidApp) {
        window.AndroidApp.isCheckSettingPushNotice(true);
        } else if (window.webkit && window.webkit.messageHandlers) {
        window.webkit.messageHandlers.isCheckSettingPushNotice.postMessage(true);
        }
    }, [])

    window.loadSettingPushNoticeInPage = function(data) {
        setStatePushDefault(data);
        setStatePush(data)
    }

    const handleLeftClick = () => {
        navigate(ROUTERS.MAIN);
    }

    const changePush = (e) => {
        console.log(e,'changePush')
        setStatePush(e)
        if(e){
            setModalShow(true)
        }else {
            setStatePushDefault(false)
            window.openSettingPushNotice(false)
        }
    }
    const handleCancelBtn = () => {
        setModalShow(false)
        setStatePushDefault(false)
    }

    window.openSettingPushNotice = (dataSettingPush) => {
        if (window.AndroidApp) {
            window.AndroidApp.openSettingPushNotice(dataSettingPush);
        } else if (window.webkit && window.webkit.messageHandlers) {
            window.webkit.messageHandlers.openSettingPushNotice.postMessage(dataSettingPush);
        }
    }
    window.checkSettingPushNotice = (status = false) => {
        if (window.AndroidApp) {
            window.AndroidApp.checkSettingPushNotice(status);
        } else if (window.webkit && window.webkit.messageHandlers) {
            window.webkit.messageHandlers.checkSettingPushNotice.postMessage(status);
        }
    }
    const handleSubmitBtn = () => {

        if(!statePushDefault){
            refPush.current.click();
            setModalShow(false)
            setStatePushDefault(true)
            window.openSettingPushNotice(true)
        }

    }
    const clickPushState = () => {
        if(!statePush){
            setModalShow(true)
        }

    }


    return (<div>
        <MainLayout
            pageID={"setting-push-page"}
        >
            <Header
                leftChild={LeftIconHeader}
                handleLeftClick={handleLeftClick}
                leftIcon={true}
                centerChild={isShop ? 'Push 알림 설정' : 'Push 알림 설정'}
                centerIcon={false}
                rightChild={false}
                rightIcon={false}
            />
            <div className='wrapper'>



                <div className='main-setting-push'>
                    <div className='main-setting-push__action'>
                        <label className='text-label'>PUSH 알림 설정</label>
                        <div className={`${statePushDefault ? '' : 'confirm-checked'}`} onClick={()=>clickPushState()}>
                            <ToggleSwitchComponent
                                value={statePushDefault} inptRef={refPush} classNameCustom={'confirm-checked'} handleChange={(e)=>changePush(e)} type={'noText'} />
                        </div>
                    </div>
                    <div className='main-setting-push__card'>
                        <div className='main-setting-push__card__item'>
                            <label>※</label>
                            <label>
                                가맹점주 및 직원 회원에 대한 결재 내역에 <br/>
                                대한 Push 메시지 등 부가적인 알림 수신 여부를 <br/>
                                설정할 수 있습니다.
                            </label>
                        </div>
                        <div className='main-setting-push__card__item'>
                            <label>※</label>
                            <label>
                                디바이스 내 설정에서 알림을 끄셨을 경우 <br/>
                                알림이 수신되지 않습니다.
                            </label>
                        </div>
                    </div>
                </div>


            </div>
            <ModalComponent
                id={``}
                show={modalShow}
                title={`알림`}
                cancelBtn={isIOS ? `닫기` : '취소'}
                submitBtn={isIOS ? '권한설정' : '확인'}
                handleCancel={handleCancelBtn}
                handleSubmit={handleSubmitBtn}
            >
                <div>
                    <div className={'content-alert'}>
                        기기의 알림이 꺼져있습니다.<br/>
                        알림을 켜주세요.
                    </div>
                </div>
            </ModalComponent>
        </MainLayout>
    </div>)
};

export default SettingPushNoticeContainer ;