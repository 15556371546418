import React, { useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { useUser } from "../../../commons/customHooks/Account";

import iconChevronDown from "../../../assets/images/icon_chevron_down.svg";

// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <span
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
    <img src={iconChevronDown} className="ml-1" />
  </span>
));

// forwardRef again here!
// Dropdown needs access to the DOM of the Menu to measure it
const CustomMenu = React.forwardRef(
  ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
    const [value, setValue] = useState('');

    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        <ul className="list-unstyled mb-0">
          {React.Children.toArray(children).filter(
            (child) =>
              !value || child.props.children.toLowerCase().startsWith(value),
          )}
        </ul>
      </div>
    );
  },
);

function Title ({stores, storeSelected, setStoreSelected}) {
  const { user } = useUser()
  return (
    <div className="text-center title">
      <Dropdown>
        <h3>
          {stores.length > 1 ? (
            <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
              {storeSelected?.store_name}
            </Dropdown.Toggle>
          ) : storeSelected?.store_name}
          {' '}<span className="font-weight-normal">|</span>{' '}
          {user?.roles?.map(e => e.display_name)}
        </h3>

        <Dropdown.Menu as={CustomMenu}>
          {stores.map((store) => (
            <Dropdown.Item
              key={store.id}
              eventKey={store.id}
              className={store.id === storeSelected?.id ? 'active' : ''}
              onClick={() => setStoreSelected(store)}
            >
              {store.store_name}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
}

export default Title
