import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

import {useDispatch} from "react-redux";
import MainLayout from "../../../../commons/layout/MainLayout/index";
import HeaderHs2 from "../../../../commons/components/Header/headerHs2";
// import DialogComponent from "../../../../commons/components/Modal/DialogComponent";
import TabsComponent from "../../../../commons/components/Tabs/TabsComponent";
import API, {ROUTES} from "../../../../commons/api/api";
import {formatPhoneNumber} from '../../../../utils/helpers'

import ROUTERS from "../../../../commons/constans/router";

import './_styles.scss';
import Header from "../../../../commons/components/Header/header";
import LeftIconHeader from "../../../../assets/images/leftIconHeader.svg";
import InputComponent from "../../../../commons/components/Input/InputComponent";
import ButtonComponent from "../../../../commons/components/Button/ButtonComponent";
import CountDownTime from "../../../../commons/components/CountDownTime";
import ModalComponent from "../../../../commons/components/Modal/ModalComponent";

const ForgotPasswordComponent = ({}: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [openModal, setOpenModal] = useState({
    isShowDialog : false,
    content : '',
  });

  const [dataForm, setDataForm] = useState({
    phone_number: '',
    verify_code: '',
  });

  const [verifyPhoneNumber, setVerifyPhoneNumber] = useState('wait'); // status : wait,waiting,done

  const initMinutes = 2;
  const initSecond = 59;

  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  const handleChangeIpt = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    if (name === 'phone_number') {
      let valuePhoneNumber = value;
      valuePhoneNumber = value.replaceAll('-', '')
      if (valuePhoneNumber.length <= 11) {
        const re = /^[0-9\b]+$/;
        if (valuePhoneNumber === '' || re.test(valuePhoneNumber)) {
          setDataForm({
            ...dataForm,
            [name]: formatPhoneNumber(valuePhoneNumber),
            verify_code : ''
          });
          setVerifyPhoneNumber('wait')
        }
      }
      return false;
    }
    setDataForm({
      ...dataForm,
      [name]: value
    })
  }

  const clickSendCodeNumberPhone = () => {
    let dataApi = {
      value : dataForm.phone_number,
      type : 'check_number_phone'
    }
    API.post(ROUTES.API_AUTH_VALIDATE,dataApi).then(res => {

      setOpenModal({
        isShowDialog : true,
        content : '인증번호가 발송 되었습니다.\n' +
          '발송된 인증 번호를 입력해 주세요.',
      });
      // setVerifyPhoneNumber('done');

      API.post(`${ROUTES.API_AUTH_SEND_CODE}?type=sms_forgot_password`,{phone_number:dataForm.phone_number }).then(res => {
        setVerifyPhoneNumber('waiting');
        setMinutes(initMinutes)
        setSeconds(initSecond)
      })

    }).catch(e =>{
      let error = e.response;
      if(error.status === 422) {
        let dataError = error.data.errors;
        let errorMessage = '';
        Object.keys(dataError).map(function (key, index) {
          errorMessage = dataError[key][0];
        });
        setOpenModal({
          isShowDialog : true,
          content : errorMessage,
        })

        setDataForm({
          phone_number: '',
          verify_code: '',
        })
      }

    })
  }

  const clickVerifyNumberPhone = () => {
    if(minutes === 0 && seconds === 0) {
      setOpenModal({
        isShowDialog : true,
        content : '인증번호 시간이 만료되었습니다.\n' +
          '인증 번호를 다시 받아주세요.',
      });
      return false;
    }

    let dataVerifyCode = {
      phone_number:dataForm.phone_number,
      code : dataForm.verify_code
    }
    API.post(`${ROUTES.API_AUTH_VERIFY_CODE}?type=sms_forgot_password`,dataVerifyCode).then(res => {
      if(res && res.data && res.data.status) {
        setVerifyPhoneNumber('done');
        navigate(ROUTERS.CHANGE_PASSWORD,{state : dataForm.phone_number});
      }
    }).catch(e => {
      let error = e.response;
      if(error.status === 422) {
        //ma xac minh khong khop vui long nhap lai
        setOpenModal({
          isShowDialog : true,
          content : '인증번호가 일치하지 않습니다.\n' +
            '다시 입력해 주세요.',
        });
      }
    })
  }

  let countDown = verifyPhoneNumber === 'waiting' && (minutes  || seconds ) ? <CountDownTime
    minutes={minutes}
    seconds={seconds}
    setMinutes={setMinutes}
    setSeconds={setSeconds}
  />: '';


  return (
    <MainLayout pageID={'forgot-password-page'}>
      <Header
        leftChild={LeftIconHeader}
        handleLeftClick={() => {
          navigate(ROUTERS.INTRO)
        }}
        leftIcon={true}
        centerChild={'비밀번호 찾기'}
        centerIcon={false}
        rightChild={false}
        rightIcon={false}
      />

      <div className={'wrapper'}>
        <div className={'row register-content'}>
          <div className={'col-12'}>

            <div className={'row'}>
              <div className={'col-12'}>
                <InputComponent
                  label={"아이디 (휴대폰 번호)"}
                  isRequired={false}
                  // errorMsg={errorForm?.phone_number || ''}
                  placeHolder={"아이디를 입력해 주세요."}
                  id={"id-input"}
                  name={"phone_number"}
                  value={dataForm.phone_number}
                  handleChange={handleChangeIpt}
                  btnDisabled={dataForm.phone_number.length > 0 ? false : true}
                  readonly={false}
                  btnLabel={'본인 인증'}
                  btnClass={'ml-2 btn btn-custom register-btn'}
                  maxLength={13}
                  autoComplete={'off'}
                  btnHandleClick={clickSendCodeNumberPhone}
                  type={'text'}
                  numberType={'phone-number'}
                />
              </div>
            </div>


            <div className={'row'}>
              <div className={'col-12'}>
                <InputComponent
                  isRequired={false}
                  // errorMsg={errorForm?.phone_number || ''}
                  placeHolder={"아이디를 입력해 주세요."}
                  id={"id-input"}
                  name={"verify_code"}
                  value={dataForm.verify_code}
                  handleChange={handleChangeIpt}
                  disabled={verifyPhoneNumber === 'waiting' ? false : true}
                  btnDisabled={verifyPhoneNumber === 'waiting' && dataForm.verify_code.length > 0 ? false : true}
                  // readonly={false}
                  btnLabel={'확인'}
                  btnClass={'ml-2 btn register-btn btn-secondary'}
                  maxLength={4}
                  // btnDisabled={true}
                  timeCountDown={countDown}
                  btnHandleClick={clickVerifyNumberPhone}
                />
              </div>
            </div>


          </div>
        </div>
      </div>

      <ModalComponent
        id={``}
        show={openModal.isShowDialog}
        title={`알림`}
        submitBtn={`확인`}
        handleSubmit={()=>{

          let actionButton = openModal.action ? openModal.action : ''

          setOpenModal({
            ...openModal,
            isShowDialog : false,
            content : '',
            action: ''
          });

        }}
      >
        <div style={{ whiteSpace : 'pre-wrap'}}>
          {openModal.content ?? ''}
        </div>
      </ModalComponent>

    </MainLayout>
  );
};

export default ForgotPasswordComponent;
