import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import InputComponent from "../../../../commons/components/Input/InputComponent";
import { pushDataStoreStepOne } from "../../redux/actions";

type props = {
  steps: Object,
  user: Object
}

const Step1Component = ({steps, user}:Props) => {

  const dispatch = useDispatch();
  const [dataStep1, setDataStep1] = useState({
    user_name: user.name,
    phone_number: user.phone_number
  })
  
  useEffect(() => {
    setDataStep1({
      user_name: user.name,
      phone_number: user.phone_number
    });
  }, [])

  useEffect(() => {
    dispatch(pushDataStoreStepOne(dataStep1))
  }, [dataStep1])

  const handleChangeInput = (e) => {
    setDataStep1({
      ...dataStep1,
      [e.target.name]: e.target.value
    })
  }

  const formatHideNumber = (phone) => {
    //010 - 12**-*123
    if (phone.length < 13) return phone;
    let groupOne;
    let groupTwo;
    let groupThree;
    let phoneArr = phone.split('-');
    groupOne = phoneArr[0];
    
    let groupTwoArr = phoneArr[1].split('');
    groupTwo = `${groupTwoArr[0]}${groupTwoArr[1]}**`

    let groupThreeArr = phoneArr[2].split('');
    groupThree = `*${groupThreeArr[1]}${groupThreeArr[2]}${groupThreeArr[3]}`
    
    let result = `${groupOne} - ${groupTwo}-${groupThree}`
    return result;
  }

  return (
    <div className="step-one">
      <h2>신청자 정보 입력 및 확인 </h2>
      <div className="content">
        <div className="content__name">
          <InputComponent 
            label = {'이름'}
            isRequired = {''}
            errorMsg = {''}
            placeHolder = {'실명을 입력해 주세요.'}
            id = {'js-name'}
            name = {'user_name'}
            value = {dataStep1?.user_name || ''}
            handleChange = {handleChangeInput}
            btnClass = {''}
            btnLabel = {''}
            disabled = {''}
            readonly = {''}
            type = {'text'}
            inputClass={`border-radius`}
          />
        </div>
        {/* <input type={'file'}>updaload</input> */}
        <div className="content__phone">
          <label className="label">
            휴대폰 번호
          </label>
          <span>{formatHideNumber(dataStep1.phone_number)}</span>
        </div>
        
      </div>
      {/* <input type={'file'}>updaload</input> */}
      <div className="notice">
        가맹점 가입 심사 결과는 등록된 휴대폰 번호로 <br /> 
        문자 발송됩니다.
        </div>
        
    </div>
  )
}

export default Step1Component;