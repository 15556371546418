import { put, call, takeLatest } from 'redux-saga/effects';

import * as actions from '../redux/actions';
import * as actionTypes from '../redux/actions/type';
import API, { ROUTES } from "../../../commons/api/api";

function* getManagers({params}) {
  try {
    const response = yield call(() => API.get(ROUTES.API_GET_MANAGER, {params}));
    yield put(actions.getManagersSuccess({
      data: response.data?.data || {},
      type: actionTypes.GET_MANAGERS_SUCCESS
    }));
  } catch (error) {
    yield put(actions.getManagersFaild({
      data: error,
      type: actionTypes.GET_MANAGERS_FAILD
    }))
  }
}

function* createManager(payload) {
  try {
    const response = yield call(() => API.post(ROUTES.API_CREATE_MANAGER, payload.payload));
    yield put(actions.createManagerSuccess({
      data: response.data?.data || {},
      type: actionTypes.CREATE_MANAGER_SUCCESS,
      status: 200,
    }));
  } catch (error) {
    yield put(actions.createManagerFaild({
      data: error.response?.data?.errors || [],
      status: 422,
      type: actionTypes.CREATE_MANAGER_FAILD
    }))
  }
}


function* managerSaga() {
  yield takeLatest(actionTypes.CREATE_MANAGER_REQUEST, createManager);
  yield takeLatest(actionTypes.GET_MANAGERS_REQUEST, getManagers);
}

export default managerSaga;
