import { createContext, useState, useEffect, useContext } from 'react';
import Toast from 'react-bootstrap/Toast';
import {Collapse, Toast as ToastContainer} from "react-bootstrap";

const DELAY = 2000

export const ToastContext = createContext();

export const useToast = () => useContext(ToastContext);

export function ToastProvicer({children}) {
  const [isShowToart, setShowToart] = useState('')

  useEffect(() => {
    if (isShowToart) {
      setTimeout(() => {
        setShowToart('')
      }, DELAY);
    }
  }, [isShowToart])

  return <ToastContext.Provider value={[ isShowToart, setShowToart ]}>
    {children}
    <div className="position-fixed text-center w-100" style={{bottom: '10px'}}>
      <Toast
        className="d-inline-block w-100 text-center"
        bg={'dark'}
        show={!!isShowToart}
        delay={DELAY}
        animation
        transition={Collapse}
      >
        <Toast.Body className={'text-white'}>
          {isShowToart}
        </Toast.Body>
      </Toast>
    </div>
  </ToastContext.Provider>
}

// usage
// const [, setToastMessage] = useToast();
// setToastMessage('message')
