import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment'
import DatimePickerComponent from "../../../commons/components/DatimePicker/DatimePickerComponent";
import ButtonComponent from "../../../commons/components/Button/ButtonComponent";

import IconCalender from "../../../assets/images/icon_calender.svg";

function HistoryFilter ({query, onFilter}) {
  const [filter, setFilter] = useState(query)

  const _setFilter = (type, value) => {
    setFilter(p => ({
      ...p,
      [type]: value ? moment(value).format('YYYY-MM-DD') : null
    }))
  }

  const _onFilter = () => {
    onFilter(filter);
  }

  return (
    <div className="filter">
      <div className="input-group flex-nowrap align-items-center">
        <div className='item-calender-component'>
          <DatimePickerComponent
            value={filter.start_date ? moment(filter.start_date).toDate() : null}
            handleChange={(e) => _setFilter('start_date', e)}
            maxDate={filter.end_date ? moment(filter.end_date).toDate() : null}
          />
          <img className={'item-calender-component__icon'} src={IconCalender} />
        </div>
        <span className="mx-1">~</span>
        <div className='item-calender-component'>
          <DatimePickerComponent
            value={filter.end_date ? moment(filter.end_date).toDate() : null}
            handleChange={(e) => _setFilter('end_date', e)}
            minDate={filter.start_date ? moment(filter.start_date).toDate() : null}
          />
          <img className={'item-calender-component__icon'} src={IconCalender} />
        </div>
        <span className="mx-1"></span>
        <ButtonComponent
          label={'조회'}
          handleClick={_onFilter}
        />
      </div>
    </div>
  )
}

export default HistoryFilter
