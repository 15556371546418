import { useEffect, useState } from 'react';
import DropDownCommon from '../../../../commons/components/Dropdown';
import { dataHoursArr, dataMinusArr } from '../../../../commons/constans/store';
import { formatTimeForType } from '../../../../utils/helpers';
type Props = {
  handleChangeStartTime: Function,
  dataStarttime: String,
  dataDay: Number,
  max: String,
};

const SelectStartTimeComponent = ({
  handleChangeStartTime,
  dataStarttime,
  dataDay,
  max,
}: Props) => {
  const [starttime, setStarttime] = useState({
    day: dataDay,
    timeHours: formatTimeForType(dataStarttime, 'H'),
    timeMinus: formatTimeForType(dataStarttime, 'M'),
  });
  const [dataHours, setDataHours] = useState(dataHoursArr);
  const [dataMinus, setDataMinus] = useState(dataMinusArr);

  useEffect(() => {
    setStarttime({
      day: dataDay,
      timeHours: formatTimeForType(dataStarttime, 'H'),
      timeMinus: formatTimeForType(dataStarttime, 'M'),
    });
  }, [dataStarttime, dataDay]);

  useEffect(() => {
    let hour = formatTimeForType(max, 'H');
    let minus = formatTimeForType(max, 'M');

    const _dataHoursArr = dataHoursArr.map((item, index) => {
      if (+item.text > +hour && +minus >= 0) {
        return {
          ...item,
          disable: true,
        };
      }
      return item;
    });

    const _dataMinusArr = dataMinusArr.map((item, index) => {
      if (+item.text >= +minus && +starttime.timeHours >= hour) {
        return {
          ...item,
          disable: true,
        };
      } else {
        return item;
      }
    });

    setDataHours(_dataHoursArr);
    setDataMinus(_dataMinusArr);
  }, [max]);

  const changeTime = (e, day, time) => {
    let hour = formatTimeForType(max, 'H');
    let minus = formatTimeForType(max, 'M');

    if (time == 'timeHours' && +e.text === +hour) {
      if (+minus == 0) {
        const _dataHoursArr = dataHoursArr.map((item, index) => {
          if (+item.text >= +hour) {
            return {
              ...item,
              disable: true,
            };
          }
          return item;
        });
        setDataHours(_dataHoursArr);

        setStarttime({
          ...starttime,
          day: day,
          timeHours: +e.text - 1 > 0 ? +e.text - 1 : '00',
          timeMinus: '59',
        });
      } else {
        setStarttime({
          ...starttime,
          day: day,
          timeHours: +e.text,
          timeMinus: +minus - 1 > 0 ? +minus - 1 : '00',
        });
      }

      if (+minus - 1 >= 0) {
        const _dataMinusArr = dataMinusArr.map((item, index) => {
          if (+item.text >= +minus - 1) {
            return {
              ...item,
              disable: true,
            };
          }
          return item;
        });
        setDataMinus(_dataMinusArr);
      }
    } else {
      if (time == 'timeMinus' && +hour === +starttime.timeHours) {
        console.log('chọn phut && giờ bằng nhau => disable minus lớn hơn');
        const _dataMinusArr = dataMinusArr.map((item, index) => {
          if (+item.text > +minus) {
            return {
              ...item,
              disable: true,
            };
          }
          return item;
        });
        setDataMinus(_dataMinusArr);
      } else {
        console.log('reset minus disable');
        const _dataMinusArr = dataMinusArr.map((item, index) => {
          return {
            ...item,
            disable: false,
          };
        });
        setDataMinus(_dataMinusArr);
      }

      setStarttime({
        ...starttime,
        day: day,
        timeHours: time == 'timeHours' ? e.text : starttime.timeHours,
        timeMinus: time == 'timeMinus' ? e.text : starttime.timeMinus,
      });
    }
  };

  useEffect(() => {
    let starttimeStr = `${starttime.timeHours}:${starttime.timeMinus}`;
    let data = {
      day: starttime.day,
      starttime: starttimeStr,
    };
    handleChangeStartTime(data);
  }, [starttime]);

  return (
    <>
      <DropDownCommon
        dataList={[...dataHours]}
        nameDisplay={formatTimeForType(dataStarttime, 'H')}
        handleClick={(e) => changeTime(e, dataDay, 'timeHours')}
        keyName={'text'}
        idActive={starttime?.timeHours || 0}
      />
      <span>:</span>
      <DropDownCommon
        dataList={[...dataMinus]}
        nameDisplay={formatTimeForType(dataStarttime, 'M')}
        handleClick={(e) => changeTime(e, dataDay, 'timeMinus')}
        keyName={'text'}
        idActive={starttime?.timeMinus || 0}
      />
    </>
  );
};

export default SelectStartTimeComponent;
