/*
 * Text Input Component
 * */
import React, {useEffect, useState, memo, useRef} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { randomIdentifier } from '../../../utils/helpers';
import IconAddImageUpload from "../../../assets/images/icon_add_image_upload.svg";
import IconEventClose from "../../../assets/images/event_close.svg";

const getBase64Img =  async (file) => {
    return new Promise(resolve => {
        let fileInfo;
        let baseURL = "";
        // Make new FileReader
        let reader = new FileReader();

        // Convert the file to base64 text
        reader.readAsDataURL(file);

        // on reader load somthing...
        reader.onload = () => {
            // Make a fileInfo Object
            // console.log("Called", reader);
            baseURL = reader.result;
            // console.log(baseURL);
            resolve(baseURL);
        };
        // console.log(fileInfo);
    });
};

const PreviewImage = (props) => {
    const [img, setImg] = useState(props?.file)
    let file = props.file
    let index = props.index

    const removeFileImg = (index) => {
        if(props.showRemoveImg){

        }else {
            props.removeFile(index)
        }
    }
    if(file?.name){
      getBase64Img(file)
        .then(result => {
          setImg(result)
        })
        .catch(err => {
          console.log(err);
        })
    }
    return (
        <div className="attachment__item" onClick={()=>removeFileImg(index)}>
            {props.showRemoveImg ? (<img className='icon-remove' src={IconEventClose} onClick={()=>props.removeFile(index)} />) : ''}
            <div className="input-selector">
                <div className="attachment__item__content">
                    <img className="" src={img} />
                </div>
                {props.type != 'style-img' ? (<div className='background-img-black' ></div>) : ''}
                {props.type != 'style-img' ? (<label className='style-text'>수정 I 삭제</label>) : ''}

            </div>
        </div>
    )
}

type Props = {
  handleChange: Function,
  onClick: Function,
  removeImg: Function,
  textCard:String,
  classCustom:String,
  type:String,
  confirmUpload:Boolean,
  nameFile:String,
  indexImg:Number,
  imgNotShow:Boolean,
  imgItem:any,
};

const ItemImageBoxAddMoreComponent = ({
   handleChange,
   textCard,
   classCustom,
   removeImg,
   showRemoveImg,
   type,
   confirmUpload,
   onClick,
   nameFile,
   indexImg,
   imgNotShow,
   imgItem
}: Props) => {
    const [image, setDataImage] = useState(imgItem)
    const [value, setValue] = useState('')
    const [imageChange, setImageChange] = useState()
    const focusFile = useRef(null);

    useEffect(() => {

        let dataUpLoadFile = {name:nameFile,file:image}
        if (dataUpLoadFile.name) {
            handleChange(dataUpLoadFile)
        }
    }, [image]);

    useEffect(() => {

        // console.log('confirmUpload',confirmUpload)
        if(imgItem?.name){
            // focusFile.current.click()
        }else {
          setDataImage(imgItem)
        }

    }, [imgItem]);

    const handleBrowse = function (e) {
        e.preventDefault();
        onClick(confirmUpload)
        focusFile.current.click()

    };
    const onFileSelected = async (e)=>{
        e.preventDefault();
        let listFile = ''
        // {Object.keys(e.target.files).map((item, i) => {
        //     if((e.target.files[item].size/1024/1024) > 10) {
        //         alert('최대 용량 10mb')
        //     }else {
        //         listFile = (e.target.files[item])
        //     }
        // })}
        setDataImage(e.target.files[0])
        setValue('')

    }
    const removeFileRender = (index) => {
        // console.log(image,'removeFileRender')
        let listImgRender = image
        // if (index > -1) {
        //     listImgRender.splice(index, 1); // 2nd parameter means remove one item only
        // }
        //
        // let dataRemove = []
        // if(listImgRender.length > 0) {
        //     setDataImage([...image,listImgRender])
        //     dataRemove = [...image,listImgRender]
        // }else {
        //     setDataImage([])
        // }
        setDataImage(null)
        let afterRemove = {index:indexImg,data:null}
        removeImg(afterRemove)

    }

    const RenderImageBox = () => {
        // console.log(image,'RenderImageBox')
        return (<div className={`image-box-card ${classCustom}`} data-index={indexImg}>
            <input type="file" className='d-none' accept="image/png, image/jpeg, image/jpg, image/jfif,image/gif" value={value}
                   name={nameFile} ref={focusFile} onChange={onFileSelected} />
            <div className='image-box-card__upload-image' onClick={handleBrowse}>
                <img className='icon-plus' src={IconAddImageUpload} />
                <label className='text-image-card'>{textCard}</label>
            </div>
            {/*{!imgNotShow && image.map((item,index)=>(*/}
            {/*    <PreviewImage file={item} type={type} key={index} index={index} showRemoveImg={showRemoveImg} removeFile={removeFileRender} />*/}
            {/*))}*/}
            {!imgNotShow && image ? <PreviewImage file={image} type={type} key={0} index={0} showRemoveImg={showRemoveImg} removeFile={removeFileRender} /> : ''}

        </div>)
    }



  return (
      RenderImageBox()
  );
};

ItemImageBoxAddMoreComponent.defaultProps = {

  handleChange: () => {},
  onClick: () => {},
  removeImg: () => {},
  textCard:'',
  classCustom:'',
  showRemoveImg: '',
  type: 'style-img',
  confirmUpload: true,
  nameFile:'attachment',
  indexImg:0,
  imgNotShow:false,
  imgItem:null,

};

export default memo(ItemImageBoxAddMoreComponent);
