import { all } from 'redux-saga/effects';
import logInSaga from '../../pages/Account/sagas/login';
import logOutSaga from '../../pages/Account/sagas/logOut';
import logInSnsSaga from "../../pages/Account/sagas/loginSns";
import updateCurrentUserSaga from '../../pages/Account/sagas/updateUser';
import codeLabelSaga from '../../pages/Store/sagas/codeLabel';
import categoriesSaga from '../../pages/Store/sagas/category';
import storeSaga from '../../pages/Store/sagas';
import managerSaga from '../../pages/Manager/sagas';

export default function* RootSagas() {
  yield all([
    logInSnsSaga(),
    logInSaga(),
    // mainSaga(),
    logOutSaga(),
    updateCurrentUserSaga(),
    codeLabelSaga(),
    categoriesSaga(),
    storeSaga(),
    managerSaga()
  ]);
}
