import React, {Fragment, memo, useEffect, useRef, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";


import {useDispatch} from "react-redux";
import ContentEditable from 'react-contenteditable'
import {randomIdentifier} from "../../../utils/helpers";


type
Props = {
  label: String,
  isRequired: boolean,
  errorMsg: any,
  placeHolder: String,
  id: string,
  name: string,
  value: String,
  handleChange: Function,
  handleFocus: Function,
  handleBlur: Function,
  handleClick: Function,
  btnClass: String,
  btnLabel: String,
  btnHandleClick: Function,
  appendContent: any,
  disabled: boolean,
  readonly: boolean,
  pattern: String,
  type: String,
  autoComplete: String,
  maxLength: String,
  searchIcon: any,
  defaultCountCharacter: any,
  fadeLastCharacter: boolean,
  className: String,
};

const TextAreaEditTableComponent = ({
                             label,
                             isRequired,
                             errorMsg,
                             placeHolder,
                             id,
                             name,
                             value,
                             handleChange,
                             handleFocus,
                             handleBlur,
                             handleClick,
                             btnClass,
                             btnLabel,
                             btnHandleClick,
                             appendContent,
                             disabled,
                             readonly,
                             pattern,
                             type,
                             autoComplete,
                             maxLength,
                             searchIcon,
                             rows,
                             contCharacter,
                             defaultCountCharacter,
                             fadeLastCharacter,
                             className
                           }: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [contentTextArea, setContentTextArea] = useState(value)
  const [statusLoading, setStatusLoading] = useState(false)
  const refContentEditable = useRef();
  const [character, setCountCharacter] = useState(0);

  useEffect(() => {
    setCountCharacter(defaultCountCharacter);
  }, []);

  useEffect(() => {
    console.log(value.length,'value.length')
    if(maxLength && value.length <= +maxLength){

      setContentTextArea(value)
    }

  }, [value]);

  const _handleChange = (e) => {

    const valueChange = e.target.value

    if (valueChange && valueChange.length > +maxLength) {
      return;
    }
    // console.log(92,maxLength , valueChange.length <= +maxLength,'ddddd1')
    if(maxLength && valueChange.length <= +maxLength){

      setContentTextArea(valueChange)
    }

    if (typeof handleChange == 'function') {
      // handleChange(e);
      if (e.target.value.length <= (parseInt(maxLength))){
        e.target.name = name
        handleChange(e);
      }
    }
  }
  const _handleKeyUp = (e) => {d

    console.log(e,'e?.target',e?.target?.innerText.length)
    if(e?.target?.innerText  && e?.target?.innerText.length <= (parseInt(maxLength))){
      setCountCharacter(e?.target?.innerText.length);
    }else {

    }
  }

  // console.log(contentTextArea,'   99999contentTextArea.html')
  const [contentEditAbleValue, setContentEditAbleValue] = useState('')
  const [isDisabledContentEditAble, setDisabledContentEditAble] = useState(false)
  const handleKeyUpContentEditAble2 = (e) => {
    let pattern = /(\w|\s)/g;
    const _val = e.target.innerText.trim().replace(`\n`, '')
    console.log(e, (String.fromCharCode(e.keyCode).match(pattern), [8,13].includes(e.keyCode)), e.target.innerText.length, (maxLength-1))
      // console.log(e,'eeee',e.target.innerHTML.length,e.target.innerText.length,e.target.outerText.length)

    // let pattern = /[\W_,.]/g;

      if((String.fromCharCode(e.keyCode).match(pattern) || [13].includes(e.keyCode)) && e.target.innerText.length >= (maxLength-1)) {
          console.log(131)
          let val = _val.substring(0,+maxLength)
          setCountCharacter(val.length);
          const val1 = val.slice()
          const val2 = val.slice()
          if(![8].includes(e.keyCode)){
            const _val2 = val1.substring(0, +maxLength - 1)+'<span>'+val2.substring(+maxLength-1, +maxLength)+'</span>'
            console.log(138, _val2, val2)
            setContentEditAbleValue(_val2)
          }else{
            setCountCharacter(_val.length);
            setContentEditAbleValue(_val)
          }

      }else{
          console.log(142)
          setCountCharacter(e.target.innerText.length);
          setContentEditAbleValue(e.target.innerText)

      }
  }

  const getPlainText = (str) => {
    return str.trim().replace(/\\n$/, '');
  }

  const handleKeyUpContentEditAble = (e) => {
    const text = getPlainText(e.target.innerText)
    console.log(160, text)
    if((String.fromCharCode(e.keyCode).match(pattern) || [13].includes(e.keyCode)) && e.target.innerText.length >= (maxLength)) {
      let val = text.substring(0,maxLength)
      let originVal = e.target.innerText.substring(0, maxLength)
      const val1 = val.slice()
      const val2 = val.slice()
      const _val = val1.substring(0, (val1.length - 1))+'<span>'+val2.substring((val2.length - 1), (val2.length))+'</span>' + originVal.substring(text.length+1, originVal.length)
      console.log(167, val, _val)
      setContentEditAbleValue(_val)
    }else{
      console.log(169)
      setCountCharacter(e.target.innerText.length);
      setContentEditAbleValue(e.target.innerHTML)
    }
  }
  const handleKeyDownContentEditAble = (e) =>{
    const text = getPlainText(e.target.innerText)
    console.log(164,e.keyCode, e.target.innerText, e.target.innerText.length, text, text.length, maxLength)
    if( ![37, 38, 39, 40, 8].includes(e.keyCode) && e.target.innerText.length >= +maxLength){
      e.preventDefault()
    }
  }

  return (
    <div className='textarea-edit-table-component'>
      {
        label && <label  className={`title-text ${isRequired ? 'required' : ''}`}>{label}</label>
      }
      <ContentEditable
        innerRef={refContentEditable}
        html={contentEditAbleValue} // innerHTML of the editable div
        disabled={isDisabledContentEditAble}       // use true to disable editing
        // onChange={_handleChange} // handle innerHTML change
        // onKeyUp={_handleKeyUp}
        onKeyDown={handleKeyDownContentEditAble}
        onKeyUp={handleKeyUpContentEditAble}
        tagName='article' // Use a custom HTML tag (uses a div by default)
        className={`content-edit-table ${className}`}
      />
      {
        contCharacter ? (<label className='count-character'><strong>{character}</strong> / {maxLength}</label>) : ('')
      }
    </div>
  );
};

TextAreaEditTableComponent.defaultProps = {
  isRequired: false,
  btnHandleClick: () => {},
  handleChange: () => {},
  handleFocus: () => {},
  handleBlur: () => {},
  handleClick: () => {},
  value: '',
  id: 'js-' + randomIdentifier(),
  name: 'input-' + randomIdentifier(),
  errorMsg: '',
  placeHolder: '',
  btnClass: '',
  btnLabel: '',
  appendContent: false,
  disabled: false,
  readonly: false,
  type: 'text',
  autoComplete: 'on',
  maxLength: '1000',
  searchIcon: false,
  rows: 6,
  contCharacter: false,
  defaultCountCharacter: 0,
  fadeLastCharacter: false,
  className:''
};

export default memo(TextAreaEditTableComponent);
