
import ROUTERS from '../../commons/constans/router';
import React, { lazy } from 'react';


const MainPageContainer = lazy(() => import('../Main/containers/MainPageContainer'));
const MainDetailPageContainer = lazy(() => import('../Main/containers/MainDetailPageContainer'));

const route = [
  {
    path: ROUTERS.MAIN,
    title: 'Main',
    exact: true,
    component: <MainPageContainer />,
  },
  {
    path: ROUTERS.MAIN_DETAIL,
    title: 'Main Detail',
    exact: true,
    // public: false,
    component: <MainDetailPageContainer />,
  }
];

export default route;
