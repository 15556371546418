import { combineReducers } from 'redux';
 
import authReducers from "../../pages/Account/redux/reducers";
import mainReducers from "../../pages/Main/redux/reducers";
import storeReducers from "../../pages/Store/redux/reducers";
import codeLabelReducers from "../../pages/Store/redux/reducers/codeLabel";
import categoryReducers from "../../pages/Store/redux/reducers/category";
import managerReducers from "../../pages/Manager/redux/reducers"

 let reducer = {
    authReducers,
    mainReducers,
    storeReducers,
    codeLabelReducers,
    categoryReducers,
    managerReducers
 }

 export default function createReducer(injectedReducers = {}) {

    const rootReducer = combineReducers({
      ...reducer,
      ...injectedReducers,
    });
 
    return rootReducer;
 }


