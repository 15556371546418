import * as actionTypes from '../actions/type';

export const initialState = {
  userList: {} ,
  isProcessing: false,
  statusCode: 0,
  type: null,
  banners: [],
  recommend_videos: [],
};

const mainReducers = (state  = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_USER_HOME:
      // console.log('homeReducers ', action.type);
      return { 
        ...state, 
        isProcessing: true, 
        type: action.type 
      };
    case actionTypes.GET_USER_HOME_SUCCESS:
      // console.log('homeReducers success', action.type);
      return {
        ...state,
        isProcessing: false,
        statusCode: action.payload.statusCode,
        userList: action.payload.data.data,
        type: action.type,
      };
   
    case actionTypes.OPEN_MENU:
      return {
        ...state,
        isProcessing: false, 
        menuStatus: action.payload,
        type: action.type,
      };
    default:
      return state;
  }
};

export default mainReducers;
