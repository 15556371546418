import React from 'react';
import {Fragment, useEffect, useState} from "react";
import MainLayout from '../../../commons/layout/MainLayout';

import LeftIconHeader from "../../../assets/images/leftIconHeader.svg";
import Header from '../../../commons/components/Header/header';
import { useParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import ItemInListComponent from "../../../commons/components/ItemInList/ItemInListComponent";
import API, {ROUTES} from "../../../commons/api/api";
import EventPageComponent from "../components";
import PlusIconBtn from "../../../assets/images/plusIconBtn.svg";
import ButtonComponent from "../../../commons/components/Button/ButtonComponent";
import ROUTERS from "../../../commons/constans/router";
import {useRole, useUser} from "../../../commons/customHooks/Account";

const EventPageContainer = (props) => {

  const navigate = useNavigate();
  const handleLeftClick = () => {
    navigate(ROUTERS.MAIN);
  }

  const isUser = useRole('user');
  const isShop = useRole('shop');
  const isManager = useRole('manager');
  const {user} = useUser();
  const params = useParams();

  console.log(params,'params333',props)

  const [eventData, setEventData] = useState([])
  const [storeList, setStoreList] = useState([])
  const [loadMore, setloadMore] = useState(false)
  const [loading, setLoading] = useState(true)
  const [eventInprogress, setEventInprogress] = useState(false)



  const fetchData = async (param = '?per_page=20') =>{
    try {
      // if((isShop) && !props?.type){
      //   // console.log(user,'user333')
      //   param= param + '&store_id='+user?.id
      // }
      console.log(!props?.type,'!props?.type')
      if((isManager || isShop) && !props?.type){
        // console.log(user,'user333')
        param= param + '&get_event_by_manager_and_shop=1&order_by_in_process=desc'
      }else {

        if(isManager || isShop){
          // param= param + '&get_event_by_manager_and_shop=1&get_data_in_process=1'
          param= param + '&get_data_in_process=1'
        }else {
          param= param + '&get_data_in_process=1'
        }
      }


      const {data: response} = await API.get(ROUTES.API_EVENT+param);
      setEventData(response?.data);
      setLoading(false)
      if(response?.data?.current_page == response?.data?.last_page) {
        setloadMore(false)
      }else {
        setloadMore(true)
      }
    } catch (error) {
      console.error(error.message);
      setLoading(false)
    }
  }

  // const fetchDataStoreId = async (param = '?store_by_user_id=21&approval_state[]='+user?.id) =>{
  //   try {
  //     const {data: response} = await API.get(ROUTES.API_GET_LIST_STORES+param);
  //     setStoreList(response?.data);
  //   } catch (error) {
  //     console.error(error.message);
  //
  //   }
  // }

  useEffect(() => {
    if(props?.type && props?.type == 'event-inprogress') {
      setEventInprogress(true)
    }
    fetchData();
    // fetchDataStoreId()
  }, []);

  const loadMoreData = () => {
    setloadMore(false)
  }
  const handleClickBtn = () => {
    navigate(ROUTERS.EVENT_CREATE)
  }



  return (
    <div>
      <MainLayout 
      pageID={"event-page"}
    >
      <Header
        leftChild={LeftIconHeader}
        handleLeftClick={handleLeftClick}
        leftIcon={true}
        centerChild={isShop || isManager ? (props?.type ? '진행 이벤트' : '이벤트 관리') : '진행 이벤트'}
        centerIcon={false}
        rightChild={false}
        rightIcon={false}
      />
      <div className='wrapper'>



        <EventPageComponent eventData={eventData} loading={loading} isShop={isShop} isManager={isManager} isUser={isUser} eventInprogress={eventInprogress}
                                   fetchData={fetchData} loadMore={loadMore} loadMoreData={loadMoreData}
        />

        {(isShop || isManager) && !eventInprogress ? <ButtonComponent
            label={'이벤트 등록하기'}
            style={{width: '100%'}}
            cssClass={'mb-2'}
            iconBtn={PlusIconBtn}
            handleClick={handleClickBtn}
            fixedBtn={true}
        /> : ''}


      </div>
    </MainLayout>
    </div>
  )
};

export default EventPageContainer;
