import React, {useEffect, useState} from "react";
import {useNavigate,useParams } from "react-router-dom";


import {useDispatch} from "react-redux";
import MainLayout from "../../../commons/layout/MainLayout/index";

import HeaderHs2 from "../../../commons/components/Header/headerHs2";

import ROUTERS from "../../../commons/constans/router";
import InfiniteScroll from 'react-infinite-scroll-component';
import API, {ROUTES} from "../../../commons/api/api";

import moment from "moment";
import SkeletonLoadingComponent from "../../../commons/components/SkeletonLoading";
import Header from "../../../commons/components/Header/header";
import LeftIconHeader from "../../../assets/images/leftIconHeader.svg";
import IconAlarm from "../../../assets/images/img_alarm.svg";
import IconCheckBoxOff from "../../../assets/images/checkbox_off_square.svg";
import {useRole, useUser} from "../../../commons/customHooks/Account";
import CheckboxComponent from "../../../commons/components/Checkbox/CheckboxComponent";
import {nl2br} from "../../../utils/helpers";
import ModalComponent from "../../../commons/components/Modal/ModalComponent";
import CloseIconMenu from  "../../../assets/images/closeIconMenu.svg"

const RenderImageDetail = (props) => {
    let image_render = '';
    let images =props?.images
    let from = props?.from
    let imgMap = []

    if(props?.images && images && images.length > 0){
        if(from && images.length > 1) {
            images.map((value,key)=>{
                if(key != 0){
                    // image_render += `<img src=${value.url_medium} />`
                    imgMap.push(value.url_medium)
                }
            })
        }
        if(from == undefined && images.length == 1) {
            images.map((value,key)=>{
                // image_render += `<img src=${value.url_medium} />`
                value?.url_medium && value?.url_medium != 'undefined' ? imgMap.push(value?.url_medium) : ''
            })
        }

    }

    // return <div className="attachments"  dangerouslySetInnerHTML={{__html: image_render}} />
    
    return <>
        {imgMap.length > 0 && imgMap.map((value,key)=>(
            <div className="attachments"> <img onClick={(value)=>props?.handClickImg(value)} key={key} src={value} /> </div>
        ))}
    </>
   
}

const EventDetailPageComponent = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [stateDetail, setStateDetail] = useState(0);
    const [detailEvent, setdetailEvent] = useState({});
    const [statusPush, setstatusPush] = useState(false);
    const params = useParams();
    const [dialogNotice, setDialogNotice] = useState({
        show: false,
        content: '',
        btn:1
    });

    var id = params?.id ? params?.id : '';
    const [isCheckedCheckbox, setIsCheckedCheckbox] = useState({});

    const isUser = useRole('user');
    const isShop = useRole('shop');
    const isManager = useRole('manager');

    const {user} = useUser();

    const backMenuHome = () => {
        if(props?.eventInprogress){
            navigate(ROUTERS.EVENT_INPROGRESS)
        }else {
            navigate(ROUTERS.EVENT)
        }

    }

    useEffect(() => {
        setstatusPush(props?.statusPushEvent)
    }, [props?.statusPushEvent]);
    useEffect(() => {
        setdetailEvent(props?.dataDetail)
    }, [props]);

    const loadingDiv = <div className="wrapper">
        <section className="lists">
            <div className="lists__item last">
                <div className="lists__item__content w-100 loading-content">
                    <span><SkeletonLoadingComponent circle={false} width="100%" height="14px"/></span>
                    <h3><SkeletonLoadingComponent circle={false} width="30%" height="14px"/></h3>
                </div>
            </div>
        </section>
        <div className="container">
            <p className="content mb-0">
                <SkeletonLoadingComponent circle={false} width="100%" height="14px"/>
                <SkeletonLoadingComponent circle={false} width="100%" height="14px"/>
                <SkeletonLoadingComponent circle={false} width="100%" height="14px"/>
                <SkeletonLoadingComponent circle={false} width="100%" height="14px"/>
                <SkeletonLoadingComponent circle={false} width="100%" height="14px"/>
            </p>
        </div>

    </div>
    const checkStatusEvent = (endTime) => {

        let statusText= ''
        if(endTime < moment(moment().toDate()).format('Y-MM-DD')){
            statusText = '종료'
        }else {
            statusText = '진행중'
        }
        return statusText
    }
    const toEdit = () => {
        let url = ROUTERS.EVENT_EDIT
        url = url.replace(':id',id)
        navigate(url)
    }
    const handleChangeCheckbox = (e) => {
        // console.log(e,'handleChangeCheckbox',e.target.checked)
        setstatusPush(e.target.checked)
        setIsCheckedCheckbox({
            ...isCheckedCheckbox,
            // [e.target.name] : !isCheckedCheckbox[e.target.name]
            [e.target.name] : e.target.checked
        })
        props?.handChangPushEvent(e.target.checked)
    }
    const handClickZoom = (url) => {

        setDialogNotice({...dialogNotice,show: true,content: url?.target?.src})
    }
    const handCloseModal = () => {
        setDialogNotice({...dialogNotice,show: false})
    }



    return (
        <div>
            <MainLayout
                pageID={"event-detail-page"}
            >
                <Header
                    leftChild={LeftIconHeader}
                    handleLeftClick={()=>backMenuHome()}
                    handleRightClick={()=>toEdit()}
                    leftIcon={true}
                    centerChild={detailEvent?.store?.store_name || ''}
                    centerIcon={false}
                    rightChild={(isShop || isManager) ? (!props?.eventInprogress ? '편집' : '') : ''}
                    rightIcon={false}
                />
                <div className='wrapper'>
            {props?.loading ? (loadingDiv) : (id != '' && detailEvent?.title ? (<div className="wrapper-container">
                <section className="lists">
                    <div className="lists__item last">
                        <div className="lists__item__content">
                            {(isShop || isManager) ? <label className={`status ${checkStatusEvent(detailEvent.end_date) == '진행중' ? ' proceeding' : ''}`}>{ checkStatusEvent(detailEvent.end_date)}</label> : ''}
                            <h3 className='title'>{detailEvent.title}</h3>
                            <span className='time'>{moment(detailEvent?.start_date).format('Y-MM-DD') + ` ~ `+moment(detailEvent?.end_date).format('Y-MM-DD')}</span>
                        </div>
                    </div>
                </section>
                <div className="container-description">
                    {detailEvent?.image ?
                        (<RenderImageDetail handClickImg={(e)=>handClickZoom(e)}  images={[detailEvent?.image[0]]} />) : ''
                    }
                    {detailEvent?.content ? <div className="content " dangerouslySetInnerHTML={{ __html: nl2br(detailEvent.content) }}/> : ''}
                    {detailEvent?.image ?
                        (<RenderImageDetail handClickImg={(e)=>handClickZoom(e)} from={1} images={detailEvent?.image} />) : ''
                    }

                </div>

            </div>) : '')}
                    {props?.eventInprogress && user?.id ? <div className='not-recive-notice'>
                        <div className="not-recive-notice__item" style={{pointerEvents:(user?.id ? 'auto' : 'none')}}>
                            <CheckboxComponent
                              label={`해당 이벤트 알림 받지 않기`}
                              customClass={``}
                              name={`check_box_1`}
                              handleChange={(e) => handleChangeCheckbox(e)}
                              disabled={false}
                              title="해당 이벤트 알림 받지 않기"
                              labelClass={``}
                              componentClass={""}
                              checked={statusPush}
                            />
                        </div>
                        <img className='icon-alart' src={IconAlarm} alt='' />
                    </div> : ''}



                </div>
            </MainLayout>
            <ModalComponent
              id={`detail-zoom-img`}
              show={dialogNotice?.show}
              // title={`알림`}
              cancelBtn={``}
              submitBtn={dialogNotice?.btn == 2 ? `확인` : ''}
              className={'modal-detail-zoom-img'}
            >
                <div className={'main-render'}>

                    <img className={'close-modal-icon'} onClick={()=>handCloseModal()} src={CloseIconMenu}/>
                    <img className={'img-zoom'} src={dialogNotice?.content}/>
                </div>
            </ModalComponent>
        </div>
    );
};

export default EventDetailPageComponent;
