import ROUTERS from '../../commons/constans/router';
import React, { lazy } from 'react';
import Policy from "./containers/Policy";
import ChangePassword from "./containers/ChangePassword";
import DeleteMember from "./containers/DeleteMember";



const LoginComponent = lazy(() => import('../../pages/Account/containers/LoginPageContainer'));
const RegisterComponent = lazy(() => import('../../pages/Account/containers/RegisterPageContainer'));
const ForgotPasswordComponent = lazy(() => import('../../pages/Account/containers/ForgotPwPageContainer'));

const InformationPolicy = lazy( () => import('../Account/containers/InformationPolicy') )

const InformationTerm = lazy( () => import('../Account/containers/InformationTerm') )
const RulesComponent = lazy( () => import('../../commons/components/Rules/RulesComponent') )

const route = [
  {
    path: ROUTERS.LOGIN,
    title: 'Login',
    exact: true,
    public: true,
    component: <LoginComponent />,

  },
  {
    path: ROUTERS.REGISTER,
    title: 'Register',
    exact: true,
    public: true,
    component: <RegisterComponent />,

  },
  {
    path: ROUTERS.FORGOT_PW,
    title: 'Forgot password',
    exact: true,
    public: true,
    component: <ForgotPasswordComponent />,

  },

  {
    path: ROUTERS.CHANGE_PASSWORD,
    title: 'Change password',
    exact: true,
    public: true,
    component: <ChangePassword />,
  },

  {
    path: ROUTERS.POLICY_REGISTER,
    title: 'Policy Register',
    exact: true,
    public: true,
    component: <Policy />,
  },

  {
    path: ROUTERS.DELETE_MEMBER,
    title: 'Delete member',
    exact: true,
    public: false,
    roles : ['user','manager','shop'],
    component: <DeleteMember />,
  },
  // {
  //   path: ROUTERS.RULES_DETAIL,
  //   title: 'Rules',
  //   exact: true,
  //   public: true,
  //   component: <RulesComponent />,
  // },


];

export default route;