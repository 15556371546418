import API from '../../../../commons/api/api';
import * as actionTypes from '../actions/type';

export const initialState = {
  isProcessing: false,
  type: '',
  categories: []
};

const categoryReducers = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_CATEGORIES_REQUEST:
      return {
        ...state,
        isProcessing: true,
        type: action.type,
        categories: []
      };
    case actionTypes.GET_CATEGORIES_SUCCESS:
      let _categories = [];
      if (action.payload.data.length > 0) {
        action.payload.data.map((item, idx) => {
          item.text=item.name
          _categories.push(item)
        })
      }
      return {
        ...state,
        isProcessing: true,
        type: action.type,
        categories: _categories || []
      };
    default:
      return state;
  }
};

export default categoryReducers;
