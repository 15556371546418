import React from 'react';
import ReactLoading from 'react-loading';
import IconLoading from '../../../assets/images/loading.gif';
 
type Props = {
    width: String,
    height: String
}

const Loading = ({ type, color, width }:Props) => (
    <div className='loading-page'>
        {/* <ReactLoading type={'spin'} color={'#0632e578'} height={'20%'} width={width} className="style-loading"/> */}
        <img className='style-loading' src={IconLoading} />
    </div>
);

const defaultProps = {
    width: '20%',
}
 
export default Loading;